import { axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';

export interface UpdateProfileBody {
  email: string;
  phone: {
    number: string;
  };
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  picture: string | ArrayBuffer | undefined;
  donorId: string;
  id: string;
}

const PROFILE_URL = '/donor/profile';
export const updateProfileRequest = (data: Partial<UpdateProfileBody>) => {
  const httpRequest = axiosClient.put(PROFILE_URL, data);
  return makeApiRequest(httpRequest);
};
