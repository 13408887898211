import React, { useMemo } from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';
import { COMPONENT_VALUES } from '../../../theme';
import MetaTags from '../../../MetaTags';
import DonorRecurring from '../../../../pages/DonorRecurring';

interface RecurringDonationsProps {
  className?: string;
}

const StyledDiv = styled.div`
  ${COMPONENT_VALUES.box}
  background-color: ${colors.white};
`;

const RecurringDonations: React.FC<RecurringDonationsProps> = ({
  className
}) => {
  const metaTags = useMemo(
    () => ({
      title: 'Recurring Donations | Givelify',
      description: 'View all your recurring donations through Givelify'
    }),
    []
  );
  return (
    <StyledDiv className={className}>
      <MetaTags {...metaTags} />
      <DonorRecurring />
    </StyledDiv>
  );
};

export default RecurringDonations;
