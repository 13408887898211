import Axios from 'axios';
import { User } from '../../routes';
import { axiosClient } from '../../../../utils/axiosClient';
import {
  makeApiRequest,
  makeGoogleRequest
} from '../../../../utils/makeApiRequest';
import { webConfig } from '../../../../utils/webConfig';

export const DISABLE_CAPTCHA = webConfig.disableCaptcha;

export interface LoginTypesResponse {
  loginTypes: string[];
}

const loginTypeUrl = (email: string, token?: string) =>
  `/access/email/login-types?email=${email}${
    token ? `&recaptcha=${token}` : ''
  }`;
export const getLoginTypes = (email: string, token: string | undefined) => {
  const httpRequest = axiosClient.get(
    loginTypeUrl(encodeURIComponent(email), token)
  );
  return makeApiRequest(httpRequest);
};

type ZipTypes = (
  | 'locality'
  | 'political'
  | 'postal_code'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'country'
  | string
)[];

interface LongitudeLatitude {
  lat: number;
  lng: number;
}

export interface AddressComponent {
  longName: string;
  shortName: string;
  types: ZipTypes;
}

export interface GoogleResult {
  placeId: string;
  formattedAddress: string;
  types: ZipTypes;
  addressComponents: AddressComponent[];
  geometry: {
    locationType: string;
    location: LongitudeLatitude;
    bounds: {
      northeast: LongitudeLatitude;
      southwest: LongitudeLatitude;
    };
    viewport: {
      northeast: LongitudeLatitude;
      southwest: LongitudeLatitude;
    };
  };
}

export interface GoogleLookupResponse {
  status: 'OK' | 'ZERO_RESULTS' | string;
  results: GoogleResult[];
}

// Google has the worst docs and inconsistencies in their APIs.
// Their JS package returns snake_case.
const getStupidGoogleNames = (
  element: AddressComponent | google.maps.GeocoderAddressComponent
): { longName: string; shortName: string } => ({
  longName: (element as any).longName || (element as any).long_name,
  shortName: (element as any).shortName || (element as any).short_name
});

export const getAddressComponents = (
  addresses: AddressComponent[] | google.maps.GeocoderAddressComponent[]
) => {
  const result = {
    streetNumber: '',
    route: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    countryCode: ''
  };
  addresses.forEach((element) => {
    const { longName, shortName } = getStupidGoogleNames(element);
    if (element.types.includes('locality')) {
      result.city = longName;
    } else if (element.types.includes('administrative_area_level_1')) {
      result.state = shortName;
    } else if (element.types.includes('country')) {
      result.country = longName;
      result.countryCode = shortName;
    } else if (element.types.includes('postal_code')) {
      result.zip = longName;
    } else if (element.types.includes('street_number')) {
      result.streetNumber = longName;
    } else if (element.types.includes('route')) {
      result.route = longName;
    }
  });
  return result;
};

const googleUrl = (zip: string) =>
  `https://maps.google.com/maps/api/geocode/json?address=${zip}&sensor=false&key=${webConfig.googlePlaceApiKey}`;
export const googleZipLookup = (zip: string) => {
  const httpRequest = Axios.get(googleUrl(zip));
  return makeGoogleRequest<GoogleLookupResponse>(httpRequest);
};

export interface SignupResponse {
  tokenType: 'Bearer';
  expiresIn: number; // 3600
  donorId: string; // "1072239150760783"
  accessToken: string; // Bearer token
  refreshToken: string; // Bearer token
}

const userSetPasswordUrl = '/access/password/reset/update';
export const userSetPassword = (
  password: string,
  token: string,
  email?: string
) => {
  const httpRequest = axiosClient.post(userSetPasswordUrl, {
    email,
    code: token,
    newPassword: password
  });
  return makeApiRequest<unknown>(httpRequest);
};

const signupUrl = '/online-giving/access/email/join';
export const userSignup = (
  user: User,
  password: string,
  captchaToken: string | undefined
) => {
  const httpRequest = axiosClient.post(signupUrl, {
    password: password,
    emailAddress: user.email,
    name: user.name,
    captchaToken,
    verification: captchaToken
  });
  return makeApiRequest<SignupResponse>(httpRequest);
};

interface Expiry {
  month: number;
  year: number;
}

const expiryStringParser = (expiry?: string): Expiry | undefined => {
  if (!expiry) {
    return;
  }
  const expiration = expiry.split('/');
  if (expiration.length !== 2) {
    return;
  }
  const month = parseInt(expiration[0], 10);
  const year = parseInt('20' + expiration[1], 10);
  if (!month || !year) {
    return;
  }
  return {
    month,
    year
  };
};

export const mapUserToWallet = (
  user: User,
  captchaToken: string | undefined
) => {
  return {
    captchaToken,
    verification: captchaToken,
    billingInfo: {
      country: user.address?.countryCode,
      zipCode: user.address?.zip,
      state: user.address?.state,
      address: user.address?.street,
      city: user.address?.city
    },
    cardInfo: {
      accountNumber: user.card?.cardNumber,
      accountHolderName: user.name,
      cvv: user.card?.cardCVV,
      expiration: expiryStringParser(user.card?.cardExpiryDate)
    }
  };
};

export interface WalletResponse {
  walletId: number;
  paymentInstrumentId: string;
}

const addWalletUrl = '/gift/wallet/add';
export const addWallet = (user: User, recaptcha: string | undefined) => {
  const httpRequest = axiosClient.post(
    addWalletUrl,
    mapUserToWallet(user, recaptcha)
  );
  return makeApiRequest<WalletResponse>(httpRequest);
};
