import React from 'react';
import {
  WrapperContainer as StyledWrapperContainer,
  OffsetComponent as StyleOffsetComponent
} from './styles';

interface WrapperOffsetsProps {
  component: React.ReactNode;
  wrapper: React.ReactNode;
}

const WrapperOffset: React.FC<WrapperOffsetsProps> = ({
  component,
  wrapper,
  ...props
}) => {
  // const [height, setHeight] = useState(0);
  // const offsetRef = useRef<HTMLDivElement>(null);

  // const getOffset = (height: number) => height / 2;

  // const measure = (element: HTMLDivElement | null) => {
  //   if (element) {
  //     return element.offsetHeight;
  //   }
  //   return 0;
  // };

  // useEffect(() => {
  //   if (offsetRef.current) {
  //     setHeight(measure(offsetRef.current));
  //   }
  // }, [offsetRef.current]);

  return (
    <StyledWrapperContainer {...props}>
      {wrapper}
      <StyleOffsetComponent
      // ref={offsetRef}
      // offset={getOffset(height)}
      >
        {component}
      </StyleOffsetComponent>
    </StyledWrapperContainer>
  );
};

export default WrapperOffset;
