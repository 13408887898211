import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { Row, Col, Container, Alert } from '@bootstrap-styled/v4';
import OwlCarousel from 'react-owl-carousel';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  HistoryTable,
  TableRow,
  TableCell,
  HistoryTabItem,
  HistoryTabLink,
  TableResponsive,
  ForgotPasswordContainer,
  ForgotBody,
  SendLinkContainer,
  AnnualTotal,
  ExportContent,
  IconBox,
  TabContant,
  PageLoading,
  HistoryAmount,
  LinkArrow,
  HistoryRecurring,
  LeftBox,
  RecurringImg
} from './styles';
import colors from '../../../../theme/colors';
import { Text } from '../../common/Text/Text';
import { MainSection } from '../../common/Layout';
import H2 from '../../common/H2';
import { amountNumberFormat } from '../../../../../utils/functions';
import Icon from '../../common/Icon';
import { assetUrl } from '../../../../../utils/webConfig';
import Modal from '../../common/Modal';
import SolidButton from '../../common/Button/SolidButton';
import { ById } from '../../../../../utils/axiosClient';
import { GiftHistoryResponse } from './api';
import { HeartLoading } from '../../../../HeartLoading';
import GivelifyModal from '../../common/Modal/GivelifyModal';

const useStyles = makeStyles({
  CancelLink: {
    color: colors.primaryGuidingViolet
  },
  ExportLink: {
    cursor: 'pointer',
    color: colors.brandDarkGray
  }
});

interface HistoryListProps {
  email: string | undefined;
  exporter: boolean;
  exportError: string | undefined;
  exportPopup: boolean;
  exportPopupModalOpen: () => void;
  exportPopupModalClose: () => void;
  exportData: () => void;
  currentYear: number;
  closeMessage: () => () => void;
  success: string | undefined;
  serverError: string;
  setServerError: React.Dispatch<React.SetStateAction<string>>;
  handleOnChanged: (value: any) => void;
  startPosition: number;
  changeType: (type: number) => void;
  loader: boolean;
  historyItems: ById<GiftHistoryResponse['data']>;
  total: ById<string> | undefined;
  error: string;
  handleHistoryDetailInfo: (data: GiftHistoryResponse['data'][number]) => void;
  dateFormat: (value: string) => string;
  yearArr: number[];
  loadMoreItems: () => void;
  isLoadMore: boolean;
}

const HistoryList: React.FC<HistoryListProps> = ({
  email,
  exporter,
  exportError,
  exportPopup,
  exportPopupModalOpen,
  exportPopupModalClose,
  exportData,
  currentYear,
  closeMessage,
  loadMoreItems,
  isLoadMore,
  handleOnChanged,
  startPosition,
  changeType,
  loader,
  historyItems,
  total,
  error,
  handleHistoryDetailInfo,
  dateFormat,
  yearArr,
  success,
  serverError,
  setServerError
}) => {
  const classes = useStyles();
  const invisible = email ? true : '';

  return (
    <div className={'mainpart'}>
      <center>
        {success !== undefined && success && (
          <Alert
            className={'common-alert'}
            color={'success'}
            isOpen
            onClick={closeMessage()}
          >
            <Text
              variant={'xSmall'}
              color={'grey3'}
            >
              {success}
            </Text>
          </Alert>
        )}
        {serverError !== '' && (
          <Alert
            className={'common-alert'}
            color={'danger'}
            isOpen
            onClick={() => setServerError('')}
          >
            <Text
              variant={'xSmall'}
              color={'red1'}
            >
              {serverError}
            </Text>
          </Alert>
        )}
      </center>
      <Container>
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <center>
                <H2
                  size={'large'}
                  color={'grayscaleBlack'}
                  weight={'medium'}
                >
                  Giving History
                </H2>
                <Text color={'primarySecondaryContent'}>All Organizations</Text>
              </center>
              <br />
            </Col>
          </Row>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              {exporter && (
                <ExportContent>
                  <Text
                    color="darkBlue"
                    variant={'medium'}
                  >
                    <div
                      onClick={exportPopupModalOpen}
                      className={classes.ExportLink}
                    >
                      Export
                    </div>
                  </Text>
                </ExportContent>
              )}
              {yearArr !== null && (
                <OwlCarousel
                  className={'owl-theme historytab-main'}
                  onChanged={handleOnChanged}
                  startPosition={startPosition}
                  margin={10}
                  nav
                  mouseDrag={false}
                  dots={false}
                  items={6}
                  responsiveClass
                  responsive={{
                    0: {
                      items: 3
                    },
                    575: {
                      items: 6
                    }
                  }}
                >
                  {yearArr.map((year: any) => (
                    <HistoryTabItem
                      key={year}
                      className={'item'}
                    >
                      <HistoryTabLink
                        active={currentYear === year}
                        onClick={(e) => changeType(year)}
                      >
                        {year}
                      </HistoryTabLink>
                    </HistoryTabItem>
                  ))}
                </OwlCarousel>
              )}
              <TabContant>
                {loader && <HeartLoading maxWidth={300} />}
                {!loader &&
                  historyItems !== null &&
                  total &&
                  total[currentYear] && (
                    <AnnualTotal>
                      <div style={{ float: 'right' }}>
                        <Text
                          variant={'xlarge'}
                          color={'black'}
                          weight={'bold'}
                        >
                          {amountNumberFormat(total[currentYear])}
                        </Text>
                        <br />
                        <Text
                          color="grey3"
                          variant={'small'}
                        >
                          Annual Giving
                        </Text>
                      </div>
                    </AnnualTotal>
                  )}
                <br />
                {error !== '' && (
                  <center className={'mt-30'}>
                    <Icon
                      size={'xLarge'}
                      name={'empty_history_icon'}
                    />
                    <Row className={'justify-content-center mt-15'}>
                      <Col lg={10}>
                        <Text
                          color={'grey3'}
                          variant={'medium'}
                        >
                          {error}
                        </Text>
                      </Col>
                    </Row>
                  </center>
                )}
                {!loader && currentYear && (
                  <TableResponsive>
                    <HistoryTable>
                      <tbody>
                        <InfiniteScroll
                          dataLength={historyItems[currentYear]?.length || 0}
                          next={loadMoreItems}
                          hasMore={isLoadMore}
                          loader={<HeartLoading maxWidth={300} />}
                        >
                          {historyItems[currentYear]?.map(
                            (donationRow, index) => (
                              <TableRow
                                key={index}
                                onClick={(e) =>
                                  handleHistoryDetailInfo(donationRow)
                                }
                              >
                                <TableCell>
                                  <Text color={'grey3'}>
                                    {donationRow.donee.name}
                                  </Text>
                                  <br />
                                  {dateFormat(donationRow.gift.date)}
                                </TableCell>
                                <TableCell
                                  className={'transaction-id'}
                                  hidden
                                >
                                  {donationRow.gift.txnId}
                                </TableCell>
                                <TableCell>
                                  <HistoryAmount>
                                    <LeftBox>
                                      <span className={'amount-inner'}>
                                        {amountNumberFormat(
                                          donationRow.gift.amount
                                        )}
                                      </span>
                                      {!!donationRow.gift.recurringId && (
                                        <HistoryRecurring>
                                          <Text
                                            className={'left-side'}
                                            color={'grey3'}
                                          >
                                            Recurring
                                          </Text>
                                          <RecurringImg
                                            alt={'MasterCard'}
                                            src={assetUrl(
                                              '/images/svg/recurring-icon.svg'
                                            )}
                                          />
                                        </HistoryRecurring>
                                      )}
                                    </LeftBox>
                                    <LinkArrow>
                                      <IconBox className={'m-0'}>
                                        <Icon
                                          size={'medium'}
                                          name={'history-list-arrow'}
                                        />
                                      </IconBox>
                                    </LinkArrow>
                                  </HistoryAmount>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </InfiniteScroll>
                      </tbody>
                    </HistoryTable>
                  </TableResponsive>
                )}
              </TabContant>
            </Col>
          </Row>
        </MainSection>
      </Container>
      <GivelifyModal
        open={exportPopup}
        onClose={exportPopupModalClose}
      >
        <ForgotPasswordContainer>
          {exportError && (
            <Text
              variant={'xSmall'}
              className={'text-left'}
            >
              <Alert
                color={'danger'}
                isOpen
                onClick={closeMessage()}
              >
                {exportError}
              </Alert>
            </Text>
          )}

          <center>
            <IconBox>
              <Icon
                name={'email_icon'}
                size={'small'}
              />
            </IconBox>
            <Text
              className={'popup-title mb-10'}
              color={'grayscaleBlack'}
            >
              Send To Email
            </Text>
            <Text
              className={'mb-10 dib'}
              variant={'medium'}
              color={'grayscaleBlack'}
            >
              We will send you a PDF summary of your annual donations from{' '}
              {currentYear} to:
            </Text>
          </center>
          <br />
          <ForgotBody>{email}</ForgotBody>
          <br />
          <br />
          <SendLinkContainer>
            <SolidButton
              fluid
              size={'small'}
              width={'minwidth'}
              raised={'skyblue'}
              value={'Send'}
              color={'skyblue'}
              onClick={exportData}
              disabled={!invisible}
              children={undefined}
            />
          </SendLinkContainer>
        </ForgotPasswordContainer>
      </GivelifyModal>
    </div>
  );
};

export default HistoryList;
