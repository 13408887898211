import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactChildren } from '../../providers/DoneeProvider';

interface OpacityAnimationProps {
  duration?: number; // 1 or 0.5 (in seconds)
}

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledDiv = styled.div<OpacityAnimationProps>`
  opacity: 0;
  animation: ${opacityAnimation} ${({ duration }) => duration}s ease-in forwards;
`;

const OpacityAnimation: React.FC<OpacityAnimationProps & ReactChildren> = ({
  duration = 0.5,
  children
}) => {
  return <StyledDiv duration={duration}>{children}</StyledDiv>;
};

export default OpacityAnimation;
