import styled, { createGlobalStyle } from 'styled-components';

import ReactModal from 'react-modal';
import layout from '../../../../theme/layout';

const { breakpoints } = layout;
const { mobile_sm } = breakpoints;

export const StyledModal = styled(ReactModal)`
  border: none;
  outline: none;
  background-color: transparent;
`;

export const modalBreakPoint = 480;
export const GlobalStyles = createGlobalStyle`
  .givelify-modal.ReactModal__Content,
  .login-modal.ReactModal__Content {
    position: relative;
    max-width: ${modalBreakPoint}px;
    padding: 0px;
  }
  .givelify-modal .ReactModal__Content_inner,
  .login-modal .ReactModal__Content_inner {
    max-width: 100%;
  }
  .ReactModal__Overlay {
    opacity: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity 200ms ease-out 200ms;
  }
  .ReactModal__Content {
    opacity: 0;
    width: calc(100% - 30px);
    max-width: 512px;
    background: #fff;
    padding: 50px;
    border-radius: 32px;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
    -o-border-radius: 32px;
    text-align: center;
    @media screen and (max-width: ${mobile_sm.max}px) {
      padding: 50px 20px;
    }
  }
  
  .donationmemo-main.ReactModal__Content {
    max-width: 688px;
  }

  .success-login-popup.ReactModal__Content {
    max-width: 460px;
  }

  .ReactModal__Content_inner {
    max-width: 350px;
    margin: auto;
    width: 100%;
  }

  .donationmemo-main .ReactModal__Content_inner {
    max-width: 512px;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transition: opacity 200ms ease-in 200ms;
    position: relative;
  }
  .ReactModal__Content--before-close {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
  @media only screen and (max-width: ${modalBreakPoint}px) {
    .givelify-modal.ReactModal__Content,
    .login-modal.ReactModal__Content {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
    .givelify-modal .donee-logo,
    .login-modal .donee-logo {
      display: none;
    }
    .givelify-modal .ReactModal__Content_inner,
    .login-modal .ReactModal__Content_inner {
      padding-bottom: 24px;
    }
    .ReactModal__Overlay {
      background-color: #ffffff !important;
    }
  }
`;
