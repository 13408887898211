import { Logger } from './logger';
import { LogLevel } from './logger/models';

export const copyToClipboard = (
  text: string,
  callback?: () => unknown
): void => {
  if (window.navigator.clipboard) {
    window.navigator.clipboard
      .writeText(text)
      .then(callback)
      .catch((error) => {
        Logger.log('Unable to save navigator clipboard', error, LogLevel.ERROR);
      });
  } else {
    // Fallback for older browsers
    const textarea = document.createElement('textarea');
    textarea.value = text;

    // Avoid scrolling to bottom
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    try {
      document.execCommand?.('copy')
        ? callback?.()
        : Logger.log('Unable to save to clipboard.', undefined, LogLevel.ERROR);
    } catch (err) {
      Logger.log(
        'Unable to save to clipboard. Error thrown',
        undefined,
        LogLevel.ERROR
      );
    }
    document.body.removeChild(textarea);
  }
};

export const copyDoneeIdToClipboard = () => {
  if (!window.doneeIdHashed) return;
  copyToClipboard(`https://givelify.com/dl/donee/${window.doneeIdHashed}`);
};
