import React, { useCallback, useEffect } from 'react';

import { StyledModal, GlobalStyles } from './styles';

import { defaults } from './constants';
import { ReactChildren } from '../../../../../providers/DoneeProvider';

const ModalComponent: React.FC<ReactModal.Props & { parentClass?: string }> = (
  props
) => (
  <StyledModal
    overlayClassName={props.parentClass !== '' ? props.parentClass : undefined}
    {...defaults}
    {...props}
  />
);

interface ModalProps extends Omit<ReactModal.Props, 'isOpen'> {
  active: boolean;
  onClose: () => unknown;
  className?: string;
}

const Modal: React.FC<ModalProps & ReactChildren> = ({
  active,
  onClose,
  children,
  ...props
}) => {
  useEffect(() => {
    return () => {
      document.body.classList.remove('ReactModal__Body--open');
    };
  }, []);

  const handleClose = useCallback(() => {
    document.body.classList.remove('ReactModal__Body--open');
    onClose();
  }, [onClose]);

  return (
    <ModalComponent
      isOpen={active}
      onRequestClose={handleClose}
      {...props}
    >
      <GlobalStyles />
      <div className={'ReactModal__Content_inner'}>{children}</div>
    </ModalComponent>
  );
};

export default Modal;
