import React, { useEffect } from 'react';
import styled from 'styled-components';
import AmountDisplay from '../CheckoutWizard/AmountDisplay';
import { CapsuleButton } from './AmountCapsule';
import { PHONE_WIDTH, RecurringTypes } from '../../utils';
import { Wallet } from '../../../../providers/DonorProvider/interfaces';
import { Text } from '../common/Text/Text';
import { logInfo } from '../../../../utils/logError';
import OpacityAnimation from '../../../Animations/OpacityAnimation';

export type SelectedEnvelope = Envelope & {
  amount: number;
};

export interface Envelope {
  description: string;
  envelopeId: number;
  name: string;
  priority: number;
}

export interface Cart {
  envelopes: {
    [x: Envelope['envelopeId']]: SelectedEnvelope | undefined;
    ['new']?: number;
  };
  receipt?: {
    receiptId: number | string;
    totalAmount: number;
  };
  message?: string;
  recurring: RecurringTypes;
  wallet: Wallet | undefined;
}

interface EnvelopeCapsuleProps {
  currentAmount: number;
  envelope: Envelope;
  onClick: (
    envelope: Envelope,
    currentAmount: EnvelopeCapsuleProps['currentAmount']
  ) => unknown;
  isSelectedEnvelope: SelectedEnvelope | undefined;
}

const StyledCapsuleButton = styled(CapsuleButton)`
  padding: 16px;
  justify-self: center;
  width: 351px;
  @media only screen and (max-width: ${PHONE_WIDTH}) {
    width: 100%;
  }
`;

const EnvelopeCapsule: React.FC<EnvelopeCapsuleProps> = ({
  envelope,
  isSelectedEnvelope,
  currentAmount,
  onClick
}) => {
  const handleClick = () => onClick(envelope, currentAmount);
  return (
    <StyledCapsuleButton onClick={handleClick}>
      <Text>
        {envelope.name}
        {isSelectedEnvelope && ` : $${isSelectedEnvelope.amount}`}
      </Text>
    </StyledCapsuleButton>
  );
};

export interface EnvelopesProps {
  cart: Cart;
  envelopes: Envelope[];
  onClick: EnvelopeCapsuleProps['onClick'];
  currentAmount: EnvelopeCapsuleProps['currentAmount'];
}

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

export const Envelopes: React.FC<EnvelopesProps> = ({
  envelopes,
  currentAmount,
  cart,
  onClick
}) => {
  useEffect(() => {
    logInfo('Envelope viewed', {
      ...cart,
      wallet: undefined,
      ogaVersions: '4.0'
    });
  }, []);

  const handleClick = (
    envelope: Envelope,
    currentAmount: EnvelopeCapsuleProps['currentAmount']
  ) => {
    onClick(envelope, currentAmount);
  };
  return (
    <OpacityAnimation>
      <AmountDisplay
        amount={cart.envelopes['new']}
        amountText={'This gift is for'}
      />
      <StyledDiv>
        {envelopes.map((e, i) => (
          <EnvelopeCapsule
            key={i}
            envelope={e}
            currentAmount={currentAmount}
            isSelectedEnvelope={cart.envelopes[e.envelopeId]}
            onClick={handleClick}
          />
        ))}
      </StyledDiv>
    </OpacityAnimation>
  );
};
