import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactChildren } from '../../../../providers/DoneeProvider';
import Icon from '../../../Donee/components/common/Icon';
import { Text } from '../../../Donee/components/common/Text/Text';
import GivelifyModal from '../../../Donee/components/common/Modal/GivelifyModal';
import { SecuredCheck } from '../../../icons';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';

const StyledDiv = styled.div`
  display: flex;
  cursor: pointer;
  .lock-icon {
    margin-top: -2px;
  }
  .text {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 4px;
  }
  .right-arrow {
    width: 9px;
    height: 9px;
  }
`;

const StyledModalContentDiv = styled.div`
  padding-top: 36px;
  text-align: center;
  .icon {
    margin-bottom: 26px;
  }
  .margin-bottom {
    margin-bottom: 24px;
  }
`;

export interface SecureProps {
  text?: string;
  className?: string;
}

export const Secure: React.FC<SecureProps & ReactChildren> = ({
  text = 'Your information is secure',
  className,
  children
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <StyledDiv
        onClick={handleOpen}
        className={className}
      >
        <Icon
          size={'small'}
          fluid={undefined}
          name={'lock-icon'}
          className={'lock-icon'}
          color={'primarySecondaryContent'}
        />
        <Text
          variant={'xSmall'}
          weight={'bold'}
          onClick={undefined}
          color={'primarySecondaryContent'}
          className={'text'}
        >
          {text || children}
        </Text>
        <Icon
          size={'xSmall'}
          fluid={undefined}
          name={'history-list-arrow'}
          color={'utilityInputColor'}
          className={'right-arrow'}
        />
      </StyledDiv>
      <GivelifyModal
        hideHeader={true}
        open={open}
        onClose={handleClose}
      >
        <StyledModalContentDiv>
          <SecuredCheck className={'icon'} />
          <Text
            variant={'large'}
            weight={'bold'}
            className={'margin-bottom'}
          >
            Your information is secure
          </Text>
          <Text className={'margin-bottom'}>
            Givelify never stores card data on your phone. We use PCI compliant,
            military-grade encryption to protect all sensitive data.
          </Text>
          <GivelifyButton onClick={handleClose}>Got it, thanks!</GivelifyButton>
        </StyledModalContentDiv>
      </GivelifyModal>
    </>
  );
};
