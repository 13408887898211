import React, { useCallback } from 'react';
import { webConfig } from '../../../utils/webConfig';

interface DonorAvatarProps {
  src?: string | null;
  title?: string;
  className?: string;
}

const DonorAvatar: React.FC<DonorAvatarProps> = ({ src, title, className }) => {
  const imageRef = React.useRef<HTMLImageElement>(null);

  const handleError = useCallback(() => {
    if (!imageRef.current) {
      return;
    }
    imageRef.current.src = webConfig.doneeAvatarDefault;
  }, [imageRef.current]);

  return (
    <img
      ref={imageRef}
      src={src || webConfig.doneeAvatarDefault}
      title={title}
      alt={title}
      className={className}
      onError={handleError}
    />
  );
};

export default DonorAvatar;
