import React from 'react';
import SomethingWentWrong from './SomethingWentWrong';
import { useLocation, Navigate, useParams } from 'react-router-dom';

const DONEE_IDS_KEY = 'DONEE_IDS';
export const getDoneeIdsFromLocalStorage = (): string[] => {
  const ids = window.localStorage.getItem(DONEE_IDS_KEY);
  if (!ids) return [];
  try {
    return JSON.parse(ids);
  } catch (e) {
    return [];
  }
};

export const pushDoneeIds = (doneeId: string | undefined) => {
  if (!doneeId) return;
  const ids = getDoneeIdsFromLocalStorage();
  const index = ids.findIndex((x) => x === doneeId);
  if (index !== -1) {
    ids.splice(index, 1);
  }
  ids.push(doneeId);
  window.localStorage.setItem(DONEE_IDS_KEY, JSON.stringify(ids));
};

export const getLastDoneeIdFromLocalStorage = (): string => {
  const ids = getDoneeIdsFromLocalStorage();
  const index = ids?.length === undefined ? 0 : ids.length - 1;
  return ids[index];
};

const RedirectToDonee: React.FC = () => {
  const { doneeId } = useParams();
  const location = useLocation();
  const searchParams = location.search;
  const doneeIdLocal = doneeId || getLastDoneeIdFromLocalStorage();

  if (doneeIdLocal) {
    return (
      <Navigate
        replace={true}
        to={`/${doneeIdLocal}${searchParams}`}
      />
    );
  }

  return <SomethingWentWrong />;
};

export default RedirectToDonee;
