import React from 'react';
import styled from 'styled-components';
import { TextProps } from '../common/Text/styles';
import colors from '../../../theme/colors';
import { Text } from '../common/Text/Text';
import { CheckIcon } from '../../../icons';

export interface StepProps {
  state: 'selected' | 'completed' | 'disable';
  count: '1' | '2' | '3';
  name: 'Gift Amount' | 'Gift Purpose' | 'Gift Summary';
  onClick?: (count: StepProps['count'], name: StepProps['name']) => unknown;
}

const getColor = (state: StepProps['state']): TextProps['color'] => {
  switch (state) {
    case 'completed':
    case 'selected':
      return 'primaryGuidingViolet';
    case 'disable':
      return 'utilityInputColor';
  }
};

const getBorderColor = (state: StepProps['state']): string => {
  switch (state) {
    case 'selected':
      return colors.primaryGuidingViolet;
    case 'completed':
    case 'disable':
      return colors.utilityInputColor;
  }
};

interface StyleProps {
  state: StepProps['state'];
  onClick: StepProps['onClick'];
}

const StyledDiv = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  &,
  .circled,
  .name {
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  }
  .circled {
    transition: border 1s, color 1s;
    border-radius: 100%;
    border: 2px solid ${({ state }) => getBorderColor(state)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 48px;
    height: 48px;
    span {
      margin-bottom: -2px;
    }
  }
  .name {
    transition: color 1s;
  }
`;

export const Step: React.FC<StepProps> = ({ state, count, name, onClick }) => {
  const color = getColor(state);
  const handleClick = () => {
    state !== 'disable' && onClick?.(count, name);
  };
  return (
    <StyledDiv
      state={state}
      onClick={undefined}
    >
      <Text
        variant={'large'}
        className={'circled'}
        color={color}
        weight={'extraBold'}
      >
        <span>{state === 'completed' ? <CheckIcon /> : count}</span>
      </Text>
      <Text
        variant={'medium'}
        className={'name'}
        color={color}
        weight={'extraBold'}
      >
        {name}
      </Text>
    </StyledDiv>
  );
};
