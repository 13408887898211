import React, { ReactNode } from 'react';

import {
  RadioOptionRow as StyledRadioOptionRow,
  RadioOptionButton as StyledRadioOptionButton,
  RadioOptionButtonContainer as StyledRadioButtonOptionContainer,
  CardRadioOptionButton
} from './styles';

export interface RadioOptionProps {
  label?: ReactNode;
  value: string;
  selected: string;
  type: any;
  modal?: any;
  onSelect: (vale: any) => unknown;
  selectReccuringVal?: (vale: any) => unknown;
}

class RadioOption extends React.Component<RadioOptionProps> {
  reccuringModalOpen = () => {
    if (this.props.modal === 'reccuring') {
      this.props.selectReccuringVal?.(this.props.value);
    } else {
      this.props.onSelect(this.props.value);
    }
  };

  render() {
    const {
      label = 'Twice a month (1st & 16th)',
      value,
      selected,
      type,
      modal,
      onSelect,
      ...props
    } = this.props;
    const isSelected = value === selected;
    return (
      <StyledRadioOptionRow
        onClick={this.reccuringModalOpen}
        {...props}
      >
        <StyledRadioButtonOptionContainer selected={isSelected}>
          {label}
          {type === 'selectcard' || type === 'preferredcard' ? (
            <CardRadioOptionButton selected={isSelected} />
          ) : (
            <StyledRadioOptionButton selected={isSelected} />
          )}
        </StyledRadioButtonOptionContainer>
      </StyledRadioOptionRow>
    );
  }
}

export default RadioOption;
