import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import AddPaymentMethodButton from './AddPaymentMethodButton';
import ChangePaymentMethodButton from './ChangePaymentMethodButton';
import AddPaymentModal from './AddPaymentModal';
import { BREAKPOINTS } from '../../../../../theme/breakpoints';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';

interface DefaultCardProps {
  addPaymentOpen: boolean;
  wallet: Wallet[] | undefined;
  selectedCard: Wallet | undefined;
  addCard: (card: Wallet) => unknown;
  setCard: (wallet: Wallet | undefined) => unknown;
  setAddPaymentOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledDiv = styled.div`
  padding: 14px;
  @media screen and (max-width: ${BREAKPOINTS.MOBILE}px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 6px;
  }
`;

export const findDefaultCard = (wallet: Wallet[]) =>
  wallet.find((x) => x.isPreferred) || wallet[0];
const DefaultCard: React.FC<DefaultCardProps> = ({
  selectedCard,
  wallet,
  addPaymentOpen,
  setAddPaymentOpen,
  setCard,
  addCard
}) => {
  const handleAddPaymentOpen = useCallback(() => {
    setAddPaymentOpen(true);
  }, [setAddPaymentOpen]);

  const handleAddPaymentClose = useCallback(() => {
    setAddPaymentOpen(false);
  }, [setAddPaymentOpen]);

  useEffect(() => {
    if (!wallet || wallet.length === 0) {
      return;
    }
    if (!selectedCard) {
      setCard(findDefaultCard(wallet));
    }
  }, [selectedCard, setCard]);

  if (!wallet) {
    return null;
  }

  return (
    <>
      <StyledDiv>
        {wallet.length === 0 || !selectedCard ? (
          <AddPaymentMethodButton handleAddPaymentOpen={handleAddPaymentOpen} />
        ) : (
          <ChangePaymentMethodButton
            wallet={wallet}
            selectedCard={selectedCard}
            setCard={setCard}
            handleAddPaymentOpen={handleAddPaymentOpen}
          />
        )}
      </StyledDiv>
      <AddPaymentModal
        addPaymentOpen={addPaymentOpen}
        addCard={addCard}
        handleAddPaymentClose={handleAddPaymentClose}
      />
    </>
  );
};

export default DefaultCard;
