import React, { useMemo } from 'react';
import styled from 'styled-components';
import { COMPONENT_VALUES, colors } from '../../../theme';
import MetaTags from '../../../MetaTags';
import DonorSupport from './DonorSupport';

interface SupportProps {
  className?: string;
}

const StyledDiv = styled.div`
  ${COMPONENT_VALUES.box}
  background-color: ${colors.white};
`;

const Support: React.FC<SupportProps> = ({ className }) => {
  const metaTags = useMemo(
    () => ({
      title: 'Support | Givelify',
      description: 'Givelify support'
    }),
    []
  );
  return (
    <StyledDiv className={className}>
      <MetaTags {...metaTags} />
      <DonorSupport />
    </StyledDiv>
  );
};

export default Support;
