import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import colors from '../../../../../theme/colors';
import { Text } from '../../../common/Text/Text';

interface MessageTextareaProps {
  message: string;
  maxLength?: number;
  placeholder: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

const StyledDiv = styled.div`
  textarea {
    width: 100%;
    padding: 16px;
    resize: vertical;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    font-family: Area Normal;
    border: 2px solid ${colors.gray200};
    &:focus,
    &:focus-within {
      border: 2px solid ${colors.primaryGuidingViolet};
    }
  }
  .text-count {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: flex-end;
  }
`;

const MessageTextarea: React.FC<MessageTextareaProps> = ({
  message,
  inputRef,
  maxLength = 140,
  placeholder,
  onChange
}) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    onChange(value);
  };
  return (
    <StyledDiv>
      <textarea
        rows={5}
        ref={inputRef}
        value={message}
        autoFocus={true}
        maxLength={maxLength}
        defaultValue={message}
        id={'donation-message'}
        name={'donation-message'}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <div className={'text-count'}>
        <Text
          variant={'xSmall'}
          color={'grayscaleGray500'}
        >
          {message.length}/140 characters
        </Text>
      </div>
    </StyledDiv>
  );
};

export default MessageTextarea;
