import { gradients, fonts, width, colors, shadows } from '../../../../../theme';

export const backgroundColors = {
  ...colors,
  transparent: colors.primaryGuidingViolet,
  muted: colors.grey5,
  white: colors.white,
  blue: colors.primaryBlue,
  primary: gradients.primary,
  secondary: gradients.secondary,
  skyblue: colors.primaryGuidingViolet,
  darkblue: colors.darkBlue,
  whiteshadow: colors.white,
  disable: colors.disable
};

export const textColors = {
  ...colors,
  black: colors.white,
  transparent: colors.white,
  muted: colors.primarySecondaryContent,
  white: colors.primarySecondaryContent,
  blue: colors.white,
  primary: colors.white,
  secondary: colors.white,
  skyblue: colors.white,
  darkblue: colors.white,
  green: colors.white,
  greenshadow: colors.white,
  disable: colors.white,
  primaryGenerosityOrange: colors.white
};

export const buttonWeights = fonts.fontWeight;

export const buttonSizes = {
  xSmall: {
    text: 14,
    container: 32,
    horizontal: 16
  },
  small: {
    text: 14,
    container: 32,
    horizontal: 16
  },
  medium: {
    text: 16,
    container: 40,
    horizontal: 20
  },
  large: {
    text: 20,
    container: 56,
    horizontal: 28
  },
  xLarge: {
    text: 20,
    container: 56,
    horizontal: 28
  }
};

export const buttonWidth = {
  default: {
    width: width.default.width,
    padding: width.default.padding
  },
  full: {
    width: width.full.width,
    padding: width.full.padding
  },
  big: {
    width: width.full.width,
    padding: width.big.padding
  },
  minwidth: {
    width: width.minwidth.width,
    padding: width.default.padding,
    minWidth: width.minwidth.minwidth
  },
  link: {
    width: width.default.width,
    padding: width.link.padding
  }
};

export const shadowTypes = shadows.depth;

export const defaultShadowType = 'none';
export const defaultColor = 'grayscaleBlack';
export const defaultButtonSize = 'medium';
export const defaultButtonWidth = 'default';
export const defaultButtonWeight = 'medium';
