import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DefaultCard, { findDefaultCard } from './DefaultCard';
import { getTotalCartAmount } from './api';
import { Cart } from '../../../Capsules/Envelopes';
import { RequestState } from '../../../../../../hooks/useRequestState';
import { Donee } from '../../../../../../providers/DoneeProvider';
import { ErrorResponse } from '../../../../../../utils/ErrorResponse';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import colors from '../../../../../theme/colors';
import { BREAKPOINTS } from '../../../../../theme/breakpoints';
import {
  isDonorLoggedIn,
  useDonorContext
} from '../../../../../../providers/DonorProvider/DonorProvider';
import { logging } from '../../../../../../utils/logError';
import { Text } from '../../../common/Text/Text';
import ReCaptchaV3 from '../../../../../Wrappers/ReCaptchaV3';
import { DISABLE_CAPTCHA } from '../../../../../LoginModal/pages/Landing/api';
import { GivelifyButton } from '../../../common/Button/GivelifyButton';
import { LoginModal } from '../../../../../LoginModal';
import { LogLevel } from '../../../../../../utils/logger/models';

const observerOption: IntersectionObserverInit = {
  root: null,
  rootMargin: '-86px',
  threshold: 0
};

interface GiveButtonProps {
  cart: Cart;
  errorState: ErrorResponse | undefined;
  disabled?: boolean;
  donee: RequestState<Donee>;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  onSubmit: () => unknown;
  setWallet: (wallet: Wallet | undefined) => unknown;
}

const stickyStyles = (isLoggedIn: boolean) => `
  @media screen and (max-width: ${BREAKPOINTS.MOBILE}px) {
    z-index: 2;
    bottom: 0vh;
    width: 100%;
    position: fixed;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    background-color: ${colors.white};
    .error-message {
      font-size: 13px;
    }
    .give-button {
      margin-top: ${isLoggedIn ? 6 : 8}px;
    }
  }
`;

const StyledDiv = styled.div<{ isLoggedIn: boolean; isVisible: boolean }>`
  border-top: 1px solid ${colors.utilitySeparatorColor};
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  .error-message {
    padding-top: 14px;
    text-align: center;
  }
  .give-button {
    max-width: 323px;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ isLoggedIn }) => (isLoggedIn ? 6 : 24)}px;
  }
  ${({ isLoggedIn, isVisible }) => (isVisible ? '' : stickyStyles(isLoggedIn))}
`;

const defaultErrorMessage = 'Unable to send the donation, please try again.';
const GiveButton: React.FC<GiveButtonProps> = ({
  cart,
  disabled,
  errorState,
  setWallet,
  onSubmit
}) => {
  const domDetectRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [addPaymentOpen, setAddPaymentOpen] = useState<boolean>(false);
  const { donorContext, setDonorContext } = useDonorContext();
  const isLoggedIn = isDonorLoggedIn(donorContext);
  const wallet = isLoggedIn ? donorContext.donor.data!.wallets : undefined;
  const handleOpen = () => setOpen(true);
  const handlePaymentOpen = () => setAddPaymentOpen(true);

  useEffect(() => {
    setWallet(wallet ? findDefaultCard(wallet) : undefined);
  }, [wallet, setWallet]);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, observerOption);

    if (domDetectRef.current) {
      observer.observe(domDetectRef.current);
    }

    return () => {
      if (domDetectRef.current) {
        observer.unobserve(domDetectRef.current);
      }
    };
  }, []);

  const addCard = useCallback(
    (card: Wallet) => {
      setDonorContext((s) => {
        const newState = { ...s };
        if (!newState.donor.data) {
          logging(
            'No user information available while adding card.',
            {
              donorId: s.login.data?.donorId || undefined,
              ogaVersions: '4.0'
            },
            LogLevel.EMERGENCY
          );
          return s;
        }
        if (!newState.donor.data.wallets) {
          newState.donor.data.wallets = [card];
        } else {
          newState.donor.data.wallets.push(card);
        }
        return newState;
      });
      setWallet(card);
    },
    [setDonorContext, setWallet]
  );

  return (
    <>
      <div ref={domDetectRef} />
      <StyledDiv
        isVisible={isVisible}
        isLoggedIn={isLoggedIn}
      >
        {errorState && (
          <Text
            color={'utilityError'}
            className={'error-message'}
          >
            {errorState.message || defaultErrorMessage}
          </Text>
        )}
        <DefaultCard
          addPaymentOpen={addPaymentOpen}
          setAddPaymentOpen={setAddPaymentOpen}
          wallet={wallet}
          addCard={addCard}
          selectedCard={cart.wallet}
          setCard={setWallet}
        />
        {!DISABLE_CAPTCHA && <ReCaptchaV3 hideText={true} />}
        <GivelifyButton
          color={'secondary'}
          className={'give-button'}
          disabled={disabled}
          onClick={
            cart.wallet ? onSubmit : isLoggedIn ? handlePaymentOpen : handleOpen
          }
        >
          GIVE NOW
        </GivelifyButton>
      </StyledDiv>
      <LoginModal
        skipsCreditCard={false}
        open={open}
        setOpen={setOpen}
        donation={getTotalCartAmount(cart.envelopes).amount.toString()}
      />
    </>
  );
};

export default GiveButton;
