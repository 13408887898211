import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReceiptCard from './ReceiptCard';
import GivelifyBottomSheet from '../../common/Modal/GivelifyBottomSheet';
import { Donee } from '../../../../../providers/DoneeProvider';
import { RequestState } from '../../../../../hooks/useRequestState';
import { GivingApiResponse } from '../../../../../utils/makeApiRequest';
import { ReceiptResponse } from '../CartWizard/Payment/api';
import { Text } from '../../common/Text/Text';
import { isAppleDevice } from '../../common/utils';
import { GivelifyButton } from '../../common/Button/GivelifyButton';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import { appDownloadEvent } from '../../../../../hooks/PushEvents/clevertapEvents';
import { DonorContextProps } from '../../../../../providers/DonorProvider/interfaces';
import { useDeepLink } from '../../../../../hooks/useDeepLink';
import DeepLinkCopiedModal from './DeepLinkCopiedModal';

const eventPayload = {
  location: 'receiptbottomsheet',
  action: 'clicked'
};

const deepLinkTitle = (
  <>
    We’ve <span className="deep-link-copied-text">copied</span> a link
    <br />
    to your full receipt
  </>
);

const disableScroll = () => {
  // Scroll to the bottom of the page
  window.scrollTo(0, document.body.scrollHeight);
  // Disable scrolling
  // const scrollY = window.scrollY;
  // document.body.style.position = 'fixed';
  // document.body.style.top = `-${scrollY}px`;
};

const enableScroll = () => {
  // Scroll to the top of the page
  window.scrollTo(0, 0);
  // Enable scrolling
  // const scrollY = document.body.style.top;
  // document.body.style.position = '';
  // document.body.style.top = '';
  // window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

const appStoreLink =
  'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_receiptbottomsheet&mt=8';
const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_receiptbottomsheet';

const handleDownloadButton = () => {
  // copyDoneeIdToClipboard();
  window.location.href = isAppleDevice() ? appStoreLink : playStoreLink;
};

interface ReceiptModalProps {
  donee: Donee;
  isReceiptBlur: boolean;
  donorContext: DonorContextProps;
  requestState: RequestState<GivingApiResponse<ReceiptResponse, number>>;
}

const StyledDiv = styled.div`
  .receipt-modal-heading {
    font-weight: 800;
    text-align: center;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  .receipt-card {
    margin-top: 16px;
    margin-bottom: 24px;
    filter: drop-shadow(0px 1px 2px #ddd);
  }
`;

const showThis = window.innerWidth < 501;
const ReceiptModal: React.FC<ReceiptModalProps> = ({
  donee,
  donorContext,
  isReceiptBlur,
  requestState
}) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const receipt = requestState.data?.response.payload.gift;
  const { pushEvent } = usePushEvents();
  const [show, setShow] = useState<boolean>(showThis);
  const { deepLinkRequestState, getDeepLink, showCopiedModal } = useDeepLink(
    eventPayload,
    handleDownloadButton
  );

  useEffect(() => {
    if (show) {
      disableScroll();
    } else {
      enableScroll();
    }
    return () => enableScroll();
  }, [show]);

  useEffect(() => {
    if (show) {
      pushEvent(appDownloadEvent, {
        location: 'receiptbottomsheet',
        action: 'shown'
      });
    }
  }, [pushEvent]);

  const handleDownloadClicked = useCallback(() => {
    pushEvent(appDownloadEvent, eventPayload);
    if (receipt) {
      getDeepLink(donee, donorContext?.donor?.data, '/dl/receipt', {
        key: 'receipt',
        data: receipt
      }).then((s) => {
        s && setUrl(s);
      });
    } else {
      handleDownloadButton();
    }
  }, [pushEvent, receipt, donee, donorContext]);

  const handleClose = useCallback(() => {
    enableScroll();
    setShow(false);
    pushEvent(appDownloadEvent, {
      location: 'receiptbottomsheet',
      action: 'dismissed'
    });
  }, [setShow]);

  if (!show) return null;

  return (
    <>
      <GivelifyBottomSheet
        onClosedCallback={handleClose}
        initialOpenValue={window.innerWidth < 501}
        contentPadding="12px 24px 24px 24px"
      >
        <StyledDiv>
          <Text
            variant="large"
            className="receipt-modal-heading"
          >
            Get the free Givelify App to view your full receipt
          </Text>
          <ReceiptCard
            donee={donee}
            isReceiptBlur={isReceiptBlur}
            donorContext={donorContext}
            requestState={requestState}
            className="receipt-card"
          />
          <GivelifyButton
            color="primary"
            isLoading={
              requestState.type === 'REQUEST_INIT' ||
              requestState.type === 'REQUEST_START' ||
              deepLinkRequestState.type === 'REQUEST_START' ||
              deepLinkRequestState.type === 'REQUEST_SUCCESS'
            }
            onClick={handleDownloadClicked}
          >
            Get the Givelify App
          </GivelifyButton>
        </StyledDiv>
      </GivelifyBottomSheet>
      <DeepLinkCopiedModal
        url={url}
        open={showCopiedModal}
        title={deepLinkTitle}
        onClick={handleDownloadButton}
      />
    </>
  );
};

export default ReceiptModal;
