import React from 'react';
import styled from 'styled-components';
import { SmallCheckIcon } from '../icons';
import { Text } from '../Donee/components/common/Text/Text';

interface GetAppPointProps {
  text: string;
}

const StyledDiv = styled.div`
  display: flex;
  gap: 8px;
  .icon {
    width: 20px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const GetAppPoint: React.FC<GetAppPointProps> = ({ text }) => {
  return (
    <StyledDiv>
      <div className={'icon'}>
        <SmallCheckIcon />
      </div>
      <Text variant={'xSmall'}>{text}</Text>
    </StyledDiv>
  );
};

export default GetAppPoint;
