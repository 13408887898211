import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MetaTags from '../components/MetaTags';
import ActivateState from '../components/ActivateState';
import { useQueryString } from '../hooks/useQueryString';
import { useApiRequest } from '../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../utils/makeApiRequest';
import { activateDonorEmail } from '../providers/DonorProvider/api';
import { webConfig } from '../utils/webConfig';
import { GivelifyButton } from '../components/Donee/components/common/Button/GivelifyButton';
import { GivelifyLogo } from '../components/icons';
import { StyledActivateDiv } from './Activate';

const unableToActivate =
  'Unable to activate your account, please try generating another link below.';
const error = (
  <>
    We are unable to resend another link at this time. Please contact our{' '}
    <a
      href={webConfig.supportContactUrl}
      rel={'noreferrer'}
    >
      support team
    </a>{' '}
    if the issue persist.
  </>
);

const handleHomePage = () => {
  window.location.href = webConfig.mainSearchSite;
};

const activateSuccessState = (
  <>
    <div className={'success-box'}>
      You have successfully activated your email address. You may close this
      tab.
    </div>
    <GivelifyButton onClick={handleHomePage}>Go to homepage</GivelifyButton>
  </>
);

const resendSuccessState = (
  <>
    <div className={'success-box'}>
      Activation sent! Please check your inbox for an email from Givelify and
      click the link there.
    </div>
    <GivelifyButton onClick={handleHomePage}>Go to homepage</GivelifyButton>
  </>
);

const ActivateEmail: React.FC = () => {
  const query = useQueryString();
  const token = query.get('token') || query.get('id');

  const [activate, makeActivateRequest] =
    useApiRequest<GivingApiResponse<unknown>>();
  const [resend, makeResendRequest] =
    useApiRequest<GivingApiResponse<unknown>>();

  useEffect(() => {
    makeActivateRequest(
      activateDonorEmail({
        token,
        resend: false
      })
    );
  }, [token]);

  const resendEmail = useCallback(() => {
    makeResendRequest(
      activateDonorEmail({
        token,
        resend: true
      })
    );
  }, [token]);

  const errorState = resend.type === 'REQUEST_ERROR' ? error : undefined;

  const activateFailedState = useMemo(
    () => (
      <>
        <div className={'failure-box'}>{errorState || unableToActivate}</div>
        {!errorState && (
          <GivelifyButton onClick={resendEmail}>
            Send another link
          </GivelifyButton>
        )}
      </>
    ),
    [errorState, resendEmail]
  );

  return (
    <StyledActivateDiv>
      <GivelifyLogo className={'givelify-logo'} />
      <MetaTags
        title={'Activate Account'}
        description={'Activate your account with Givelify'}
      />
      <ActivateState
        activateType={activate.type}
        resendType={resend.type}
        activateFailedState={activateFailedState}
        activateSuccessState={activateSuccessState}
        resendSuccessState={resendSuccessState}
      />
    </StyledActivateDiv>
  );
};

export default ActivateEmail;
