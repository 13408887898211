import React, { useState } from 'react';
import styled from 'styled-components';
import ProfileDropdown from './ProfileDropdown';
import { Link, useNavigate } from 'react-router-dom';
import colors from '../theme/colors';
import { getNewDoneePath } from '../../utils/webConfig';
import { Text } from '../Donee/components/common/Text/Text';
import { useQueryString } from '../../hooks/useQueryString';
import { useDonor } from '../../providers/DonorProvider/useDonor';
import { LoginModal } from '../LoginModal';
import { GivelifyLogo } from '../icons';
import { Cart, CartInner } from './styles';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ROUTES } from '../../utils/routes';

export const maxContainerWidth = 1200;
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${maxContainerWidth}px;
  margin: auto;
  padding-left: 16px;
  padding-right: 16px;
  .givelify-logo {
    cursor: pointer;
  }
  .cart-button {
    margin-left: 10px;
  }
  .sign-in.loading {
    min-width: 100px;
  }
  .sign-in {
    font-size: 14px;
    font-family: Area Normal;
    background-color: transparent;
    color: ${colors.primaryGuidingViolet};
    padding: 8px 24px;
    outline: none;
    border: none;
    border-radius: 26px;
    cursor: pointer;
    transition: background 0.3s ease-in;
    &:hover {
      background-color: ${colors.utilityGuidingVioletDark};
    }
    &:active {
      transition: none;
      background-color: ${colors.utilityGuidingVioletLight};
    }
  }
`;

interface CartButtonProps {
  doneeId: string | undefined;
  donationsCount: number;
  className?: string;
}

const CartButton: React.FC<CartButtonProps> = ({
  doneeId,
  donationsCount,
  className
}) => {
  return (
    <Link
      to={getNewDoneePath(`/${doneeId}/donation/checkout`)}
      className={className}
    >
      <Cart>
        <CartInner>
          <Text
            variant={'xSmall'}
            weight={'medium'}
            color={'grey3'}
            // lineheight={'16'}
          >
            {donationsCount}
          </Text>
        </CartInner>
      </Cart>
    </Link>
  );
};

export const TopNavBar: React.FC = () => {
  const push = useNavigate();
  const query = useQueryString();

  const [open, setOpen] = useState<boolean>(
    Boolean(query.get('login')) || false
  );
  const { donorContext, logout } = useDonorContext();
  const { donor } = useDonor() || {};

  const onClick = () => {
    push(ROUTES.main);
  };

  const isLoading = donorContext.donor.type === 'REQUEST_START';

  if (donor || isLoading) {
    return (
      <StyledDiv>
        <GivelifyLogo
          className={'givelify-logo'}
          onClick={onClick}
        />
        {!donor ? (
          <CircularProgress
            size={20}
            color="secondary"
            className="spinner"
          />
        ) : (
          <ProfileDropdown
            donor={donor}
            onLogout={logout}
          />
        )}
        {/* <CartButton
          doneeId={doneeId}
          donationsCount={donationsCount}
          className={'cart-button'}
        /> */}
      </StyledDiv>
    );
  }

  return (
    <>
      <StyledDiv>
        <GivelifyLogo
          className={'givelify-logo'}
          onClick={onClick}
        />
        <button
          className={'sign-in'}
          onClick={() => setOpen(true)}
        >
          Join or Sign in
        </button>
        {/* <CartButton
            doneeId={doneeId}
            donationsCount={donationsCount}
            className={'cart-button'}
          /> */}
      </StyledDiv>
      <LoginModal
        skipsCreditCard={true}
        open={open}
        setOpen={setOpen}
        donation={undefined}
      />
    </>
  );
};
