import React, { useEffect } from 'react';
import { useGivingApiRequest } from './hooks/useGivingApiRequest';
import {
  ClientAccessToken,
  ClientTokenContext,
  getClientAccessToken
} from './providers/ClientTokenProvider';
import { HeartLoading } from './components/HeartLoading';
import Router from './components/Router';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import fonts from './components/theme/fonts';
import DoneeProvider from './providers/DoneeProvider';
import { BrowserRouter } from 'react-router-dom';
import SomethingWentWrong from './components/SomethingWentWrong';
import DonorProvider from './providers/DonorProvider/DonorProvider';
import { colors } from './components/theme';
import PushEventsProvider from './hooks/PushEvents';
import LoginToastProvider from './providers/LoginToastProvider';
import { LoginToast } from './components/LoginModal/components/LoginToast';
import EnabledFeaturesProvider from './hooks/EnabledFeatures';
import { Donor } from './providers/DonorProvider/interfaces';
import { LocalStorageCart } from './components/Donee/components/CheckoutWizard/CartLocalStorage';
import LoginBottomSheet from './components/LoginBottomSheet';

export const theme = createTheme({
  typography: {
    fontFamily: fonts.fontfamily
  },
  palette: {
    primary: {
      main: colors.primaryOrange
    },
    secondary: {
      main: colors.primaryGuidingViolet
    }
  }
});

type FBQCommand = 'track' | 'trackCustom' | 'init';

interface FBQPayload {
  [key: string]: any;
}

declare global {
  interface Window {
    version?: string;
    doneeIdShort?: string;
    doneeIdHashed?: string;
    cart: LocalStorageCart;
    opera: any;
    MSStream: any;
    accessToken?: string;
    setLogLevel?: () => unknown;
    fbq?: (command: FBQCommand, event: string, payload?: FBQPayload) => unknown;
    clevertap?: {
      setLogLevel?: () => unknown;
      profile?: {
        Site: { donor_name: string; Email: string; Identity: string };
      }[];
    };
  }
}

const App: React.FC = () => {
  const donorEmailRef = React.useRef<Donor | undefined>(undefined);
  const [clientRequestState, makeClientRequest] =
    useGivingApiRequest<ClientAccessToken>();
  useEffect(() => {
    if (clientRequestState.type === 'REQUEST_INIT') {
      makeClientRequest(getClientAccessToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeClientRequest]);

  if (
    clientRequestState.type === 'REQUEST_INIT' ||
    clientRequestState.type === 'REQUEST_START'
  ) {
    return (
      <HeartLoading
        maxWidth={500}
        className="heart-loading"
      />
    );
  }

  if (clientRequestState.type === 'REQUEST_ERROR') {
    return <SomethingWentWrong />;
  }

  return (
    <ClientTokenContext.Provider value={clientRequestState.data}>
      <BrowserRouter basename="/donate">
        <EnabledFeaturesProvider>
          <PushEventsProvider donorEmailRef={donorEmailRef}>
            <DoneeProvider>
              <DonorProvider donorEmailRef={donorEmailRef}>
                <ThemeProvider theme={theme}>
                  <LoginToastProvider>
                    <Router />
                    <LoginToast />
                    <LoginBottomSheet />
                  </LoginToastProvider>
                </ThemeProvider>
              </DonorProvider>
            </DoneeProvider>
          </PushEventsProvider>
        </EnabledFeaturesProvider>
      </BrowserRouter>
    </ClientTokenContext.Provider>
  );
};

export default App;
