import { useDonorContext } from './DonorProvider';
import { DonorWithWallet } from './interfaces';

export const useDonorLogout = () => {
  return () => null;
};

const testDonor = {
  donor: {
    address: {
      city: 'Test Edmonton',
      country: {
        flagImageUrl: '',
        isoAlpha2: '',
        name: 'Test Canada'
      },
      state: 'Test Alberta',
      street: '123 test street',
      zip: '12345'
    },
    email: 'test@testing.com',
    id: '123',
    name: 'Test Account Name',
    phone: {
      country: {
        flagImageUrl: '',
        isoAlpha2: '',
        name: 'Canada'
      },
      number: '7896'
    },
    pictureUrl: '',
    uuid: '123'
  },
  wallets: []
};

export const useDonor = (): DonorWithWallet | undefined => {
  const { donorContext } = useDonorContext();
  return donorContext.donor?.data || undefined;
};
