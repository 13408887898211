import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { logError } from '../../../../utils/logError';

const StyledDiv = styled.div<{ defaultUrl?: string }>`
  ${({ defaultUrl }) => (defaultUrl ? `background: url(${defaultUrl})` : '')};
  transition: background 1s;
`;

interface ImageLoaderProps {
  url: string | undefined | null;
  defaultUrl: string | undefined;
  className?: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({
  url,
  defaultUrl,
  className
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!url || !imageRef.current) {
      return;
    }
    const image = new Image();
    image.onload = () => {
      try {
        if (imageRef.current && imageRef.current.style) {
          imageRef.current.style.backgroundImage = `url(${image.src})`;
        }
      } catch (error) {
        logError('Exception in Image Loader Component', {
          error,
          imageUrl: url
        });
      }
    };
    image.src = url;
  }, [url, imageRef]);
  return (
    <StyledDiv
      defaultUrl={defaultUrl}
      className={className}
      ref={imageRef}
    />
  );
};

export default ImageLoader;
