import styled from 'styled-components';
import layout from '../../../../theme/layout';
import ContentLayout from '../../common/Layout/Content';
import colors from '../../../../theme/colors';
import { assetUrl } from '../../../../../utils/webConfig';

const { breakpoints } = layout;
const { desktop, laptop, tablet, mobile } = breakpoints;

export const HistoryContainer = styled(ContentLayout)`
  display: flex;
`;

export const HistoryTable = styled.table.attrs(
  () => ({ ceilpadding: 5 } as any)
)`
  width: 100%;
`;

export const TableRow = styled.tr`
  ${(props) => (props.onClick ? 'cursor: pointer' : '')};
`;

export const TableHead = styled.th`
  color: ${colors.grayscaleBlack};
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 5px;
`;

export const TableCell = styled.td`
  width: 100%;
`;

export const HistoryTabs = styled.ul`
  border-bottom: 1px solid #c1c1c1;
  padding-right: 60px;
`;

export const HistoryTabItem = styled.li`
  text-align: center;
  display: inline-block;
`;

export const HistoryTabLink = styled.a<{ active?: boolean }>`
  color: ${(props) => (props.active ? '#715aff' : '#3A4C63')};
  display: inline-block;
  padding: 13px 10px;
  font-weight: 700;
  margin-bottom: -2px;
  font-size: 18px;
  position: relative;
  :after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    background: ${(props) => (props.active ? '#715aff' : 'transparent')};
    width: 100%;
    height: 4px;
    border-radius: 2px;
  }
  @media screen and (max-width: ${mobile.max}px) {
    margin: 0 25px 0 0px;
    :after {
      bottom: 0px;
    }
  }
`;

export const TableResponsive = styled.li`
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

export const ForgotPasswordContainer = styled(ContentLayout)`
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

export const SendLinkContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
`;

export const ForgotBody = styled.div``;

export const FormItem = styled.div`
  padding: 2px 0 30px 0;
`;

export const AnnualTotal = styled.div`
  background: #f9fafc;
  border-top: 1px solid #cad2db;
  border-bottom: 1px solid #cad2db;
  display: inline-block;
  width: 100%;
  padding: 20px;
  text-align: right;
  overflow: auto;
`;

export const ExportContent = styled.div`
  position: absolute;
  right: 15px;
  top: -47px;
  z-index: 1;
`;

export const IconBox = styled.div`
  margin-bottom: 10px;
`;

export const TabContant = styled.div``;

export const PageLoading = styled.div`
  position: relative;
  min-height: 200px;
  :after {
    background: url(${assetUrl('/images/history-loading.gif?v=1')}) no-repeat
      scroll center 70px #fff;
    position: absolute;
    width: 100%;
    min-height: 200px;
    content: '';
    z-index: 1;
    top: 0px;
    height: 100%;
  }
`;

export const HistoryAmount = styled.div`
  position: relative;
  padding-right: 20px;
`;
export const HistoryRecurring = styled.div`
  display: inline-flex;
`;
export const LeftBox = styled.div``;

export const RecurringImg = styled.img`
  width: 19px;
  height: 24px;
  padding-top: 5px;
  margin-left: 8px;
`;

export const LinkArrow = styled.div`
  margin: 3px 0 0 10px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;
