import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Icon from '../../../Donee/components/common/Icon';
import { Text } from '../../../Donee/components/common/Text/Text';
import { CardThumb } from '../../../Donee/components/common/constants';
import { StyledMaskedInput, getCardsThumbFormat } from '../../utils';

const CardThumbStyles = styled.div`
  position: absolute;
  top: 9px;
  right: 7px;
  img {
    max-height: 28px !important;
  }
`;

export const CardType = styled.div`
  position: relative;
`;

export interface CreditCardNumberProps {
  placeholder: string | undefined;
  cardNumber: string;
  className?: string;
  cardThumb: CardThumb | undefined;
  cardError: string | undefined;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

export const CreditCardNumber: React.FC<CreditCardNumberProps> = React.memo(
  ({
    cardThumb,
    cardNumber,
    placeholder,
    className,
    cardError,
    handleChange
  }) => {
    const cardThumbType = cardThumb?.type;
    return (
      <CardType className={className}>
        <StyledMaskedInput
          placeholder={placeholder}
          mask={getCardsThumbFormat(cardThumbType)}
          guide={false}
          value={cardNumber}
          onChange={handleChange}
          inputMode={'numeric'}
        />
        {cardThumbType && (
          <CardThumbStyles>
            <Icon
              fluid={undefined}
              name={`${cardThumbType.replace(' ', '_')}_badge` as any}
              size={'large'}
            />
          </CardThumbStyles>
        )}
        {cardError && (
          <Text
            variant={'xSmall'}
            color={'red'}
          >
            {cardError}
          </Text>
        )}
      </CardType>
    );
  }
);

CreditCardNumber.displayName = 'CreditCardNumber';
