import React from 'react';
import styled from 'styled-components';
import { CheckoutWizardRouterProps } from '../../CheckoutWizardInterfaces';
import AddMessage from './AddMessage';
import AddRecurring from './AddRecurring';
import { DonationAddonProps } from '../../../../utils';
import GivelifyPaper from '../../../GivelifyPaper';
import colors from '../../../../../theme/colors';

interface DonationDetailsProps extends DonationAddonProps {
  setRoute: CheckoutWizardRouterProps['setRoute'];
}

const StyledPaper = styled(GivelifyPaper)`
  margin-top: 36px;
  .border-bottom {
    border-bottom: 1px solid ${colors.utilitySeparatorColor};
  }
`;

const DonationDetails: React.FC<DonationDetailsProps> = ({
  setRoute,
  ...rest
}) => {
  return (
    <StyledPaper padding={'0'}>
      <AddMessage
        {...rest}
        className={'border-bottom'}
      />
      <AddRecurring {...rest} />
    </StyledPaper>
  );
};

export default DonationDetails;
