import React, { useEffect } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import LoadingAnimation from './animations/LoadingLoop_Grey.json';
import ConfirmCheck from './animations/ConfirmCheck.json';
import SparkleLoop from './animations/SparkleLoop.json';
import styled from 'styled-components';

export interface HeartLoadingProps {
  maxWidth?: number;
  showConfirmationAnimation?: boolean;
  onConfirmationAnimationCompleted?: () => unknown;
  className?: string;
}

const StyledDiv = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) =>
    maxWidth
      ? `max-width: ${maxWidth}px; margin-left: auto; margin-right: auto;`
      : ''}
`;

export const HeartLoading: React.FC<HeartLoadingProps> = React.memo(
  ({
    maxWidth,
    showConfirmationAnimation,
    className,
    onConfirmationAnimationCompleted
  }) => {
    const [lottieProps, setLottieProps] = React.useState<LottieProps>();
    const startLoadingAnimation = () => {
      setLottieProps({
        isClickToPauseDisabled: true,
        ariaRole: 'figure',
        options: {
          // name: 'LoadingAnimation',
          animationData: LoadingAnimation,
          loop: true,
          autoplay: true
        }
      });
    };

    const startSparkleLoopAnimation = () => {
      setLottieProps({
        isClickToPauseDisabled: true,
        ariaRole: 'figure',
        options: {
          // name: 'SparkleLoop',
          animationData: SparkleLoop,
          loop: true,
          autoplay: true
          // width: '250px'
        }
      });
    };
    const startConfirmCheckAnimation = () => {
      setLottieProps({
        isClickToPauseDisabled: true,
        ariaRole: 'figure',
        options: {
          // name: 'ConfirmCheck',
          animationData: ConfirmCheck,
          loop: false,
          autoplay: true
        },
        eventListeners: [
          {
            eventName: 'complete',
            callback: startSparkleLoopAnimation
          }
        ]
      });

      setTimeout(() => {
        onConfirmationAnimationCompleted?.();
      }, 1800);
    };
    useEffect(() => {
      startLoadingAnimation();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      if (showConfirmationAnimation) {
        startConfirmCheckAnimation();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showConfirmationAnimation]);
    return (
      <StyledDiv
        maxWidth={maxWidth}
        className={className}
      >
        {lottieProps && <Lottie {...lottieProps} />}
      </StyledDiv>
    );
  }
);

HeartLoading.displayName = 'HeartLoading';
