import React from 'react';

import { Icon as StyledIcon, Container as StyledContainer } from './styles';

import { icons, sizes, defaultSize } from './constants';
import { assetUrl } from '../../../../../utils/webConfig';
import { colors, width } from '../../../../theme';
import { shadowTypes } from '../Button/SolidButton/constants';

export interface IconProps {
  fluid?: boolean;
  name: keyof typeof icons;
  size?: keyof typeof sizes;
  color?: keyof typeof colors;
  className?: string;
  width?: keyof typeof width;
  raised?: keyof typeof shadowTypes;
  iconProps?: any;
  onClick?: () => unknown;
}

const Icon: React.FC<IconProps> = ({
  name,
  size = defaultSize,
  fluid = false,
  ...props
}) => (
  <StyledContainer
    fluid={fluid}
    size={size}
  >
    <StyledIcon
      fluid={fluid}
      size={size}
      src={assetUrl(icons[name])}
      {...props}
    />
  </StyledContainer>
);

export default Icon;
