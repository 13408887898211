import React, { useCallback, useEffect, useState } from 'react';
import { setErrorResponse } from '../../utils/ErrorResponse';
import {
  RequestState,
  requestError,
  requestInit,
  requestStart,
  requestSuccess
} from '../../hooks/useRequestState';
import { webConfig } from '../../utils/webConfig';

const types = ['address'];
const componentRestrictions = { country: ['us', 'ca'] };
const googleAutocompleteUrl = `https://maps.googleapis.com/maps/api/js?key=${webConfig.googleAutocompleteApiKey}&libraries=places&callback=initMap&v=weekly`;

declare global {
  interface Window {
    initMap: () => void;
  }
}
const checkGoogle = () => {
  return Boolean(
    window.google?.maps?.places?.AutocompleteService &&
      window.google.maps.Geocoder
  );
};

export const useGoogleAutocomplete = () => {
  const [requestState, setRequestState] = useState<
    RequestState<google.maps.places.AutocompletePrediction[]>
  >(requestInit());

  const [autocomplete, setAutocomplete] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | undefined>(
    undefined
  );

  const handleMapInit = useCallback(() => {
    if (checkGoogle()) {
      setGeocoder(new window.google.maps.Geocoder());
      setAutocomplete(new window.google.maps.places.AutocompleteService());
    }
  }, [setAutocomplete, setGeocoder]);

  useEffect(() => {
    if (checkGoogle()) {
      return;
    }
    window.initMap = () => handleMapInit();
    const script = document.createElement('script');
    script.src = googleAutocompleteUrl;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [setAutocomplete, handleMapInit]);

  const handleAddress = useCallback(
    (
      a: google.maps.places.AutocompletePrediction[] | null,
      b: google.maps.places.PlacesServiceStatus
    ) => {
      if (a && b === google.maps.places.PlacesServiceStatus.OK) {
        setRequestState(requestSuccess(a));
      } else {
        setRequestState(
          requestError(
            setErrorResponse({
              status: 400,
              message: 'Unable to find the requested address.',
              isAxiosError: false,
              name: 'Address not found.'
            })
          )
        );
      }
    },
    [setRequestState]
  );

  const makeGeocoderRequest = useCallback(
    (
      placeId: string,
      dumbGoogleCallback: (place: google.maps.GeocoderResult) => unknown
    ) => {
      if (!geocoder) {
        handleMapInit();
        return;
      }
      geocoder.geocode({ placeId }).then((results) => {
        dumbGoogleCallback(results.results[0]);
      });
    },
    [geocoder, handleMapInit]
  );

  const makeRequest = useCallback(
    (input: string) => {
      if (!autocomplete) {
        handleMapInit();
        return;
      }
      setRequestState(requestStart());
      autocomplete.getPlacePredictions(
        {
          input,
          types,
          componentRestrictions
        },
        handleAddress
      );
    },
    [autocomplete, handleMapInit, setRequestState]
  );

  return [requestState, makeRequest, makeGeocoderRequest] as const;
};
