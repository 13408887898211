import React from 'react';
import styled from 'styled-components';
import { getMobileAppHeading, getMobileAppPoints } from './GetAppMobile';
import colors from '../theme/colors';
import IconButton from '../Donee/components/common/Button/IconButton';
import { Text } from '../Donee/components/common/Text/Text';
import { webConfig } from '../../utils/webConfig';

interface GetAppDesktopProps {
  className?: string;
  onClose?: () => unknown;
}

const StyledDiv = styled.div`
  display: flex;
  .qr-column,
  .details-column {
    flex: 1;
    width: 368px;
  }
  .details-column {
    padding: 36px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background-color: ${colors.lightBrown};
    .heading {
      margin-bottom: 12px;
    }
    img {
      margin-top: 32px;
    }
  }
  .qr-column {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    .close-button-header {
      display: flex;
      padding: 24px;
      position: absolute;
      top: 0;
      right: 0;
      justify-content: flex-end;
    }
    .qr-content {
      flex: 1;
      gap: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

const GetAppDesktop: React.FC<GetAppDesktopProps> = ({
  className,
  onClose
}) => {
  return (
    <StyledDiv className={className}>
      <div className={'details-column'}>
        <div className={'heading'}>{getMobileAppHeading}</div>
        {getMobileAppPoints}
        <img src={webConfig.getAppMobileUrl} />
      </div>
      <div className={'qr-column'}>
        {onClose && (
          <div className={'close-button-header'}>
            <IconButton
              name={'close-icon'}
              size={'xSmall'}
              width={'link'}
              raised={'none'}
              color={'transparent'}
              onClick={onClose}
              iconProps={undefined}
            />
          </div>
        )}
        <div className={'qr-content'}>
          <Text variant={'medium'}>
            Scan QR code with your phone to download the app
          </Text>
          <img src={webConfig.qrCodeUrl} />
        </div>
      </div>
    </StyledDiv>
  );
};

export default GetAppDesktop;
