import { useCallback, useEffect } from 'react';
import { getProfile } from './api';
import { usePushEvents } from '../../hooks/PushEvents';
import { DonorContextProps, DonorWithWallet } from './interfaces';
import { useGivingApiRequest } from '../../hooks/useGivingApiRequest';
import {
  getAccessTokenFromLocalStorage,
  setLoginTypeLocalStorage
} from './donorLocalStorage';
import { getGiftSummaryRequest } from '../../pages/DonorProfileDetails/api';
import { getAllRecurringDonations } from '../../pages/DonorRecurring/api';

window.accessToken = getAccessTokenFromLocalStorage() || undefined;

export const useLoadDonor = (
  donorContext: DonorContextProps,
  setDonorContext: React.Dispatch<React.SetStateAction<DonorContextProps>>
) => {
  const { addProfile, extendProfile } = usePushEvents();
  const [profileState, makeProfileRequest] =
    useGivingApiRequest<DonorWithWallet>(donorContext.donor);

  const loadProfile = useCallback(
    (forcePull?: boolean) => {
      if (
        window.accessToken &&
        (forcePull || profileState.type === 'REQUEST_INIT')
      ) {
        makeProfileRequest(getProfile());
      }
    },
    [profileState.type]
  );

  useEffect(() => {
    if (profileState.type === 'REQUEST_INIT') return;
    if (profileState.type === 'REQUEST_SUCCESS') {
      const { id, name, email } = profileState.data.donor;
      setLoginTypeLocalStorage(name, email, undefined);
      addProfile({
        name,
        email,
        donor_id: id
      });
      getGiftSummaryRequest().then((r) => {
        if (!r.success) return;
        const totalGifts = r.data?.response.payload.all.count || 0;
        extendProfile(totalGifts);
        setDonorContext((s) => ({
          ...s,
          totalGifts
        }));
      });
      getAllRecurringDonations().then((r) => {
        if (!r.success) return;
        const totalRecurringDonations = r.data?.response.payload.length || 0;
        extendProfile(undefined, totalRecurringDonations > 0);
        setDonorContext((s) => ({
          ...s,
          totalRecurringDonations
        }));
      });
    }
    setDonorContext((s) => ({
      ...s,
      donor: profileState
    }));
  }, [profileState]);

  return loadProfile;
};
