import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  EnabledFeaturesResponse,
  ENABLED_FEATURES_KEY,
  getEnabledFeatures
} from './api';
import { ReactChildren } from '../../providers/DoneeProvider';
import { GivingApiResponse } from '../../utils/makeApiRequest';
import { useApiRequest } from '../useGivingApiRequest';

type EnabledFeatures = {
  [x in ENABLED_FEATURES_KEY]+?: boolean | undefined;
};

export const EnabledFeaturesContext = createContext<{
  isFeatureEnabled: (featureKey: ENABLED_FEATURES_KEY) => boolean;
}>({
  isFeatureEnabled: () => false
});

export const useEnabledFeatures = () => useContext(EnabledFeaturesContext);

const mapResponse = (
  features: EnabledFeaturesResponse['enabledFeatures']
): EnabledFeatures => {
  const result: EnabledFeatures = {};
  for (const f of features) {
    result[f.label] = f.isEnabled;
  }
  return result;
};

const EnabledFeaturesProvider: React.FC<ReactChildren> = ({ children }) => {
  const [enabledFeatures, setEnabledFeatures] = useState<EnabledFeatures>({});
  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<EnabledFeaturesResponse>>();

  const isFeatureEnabled = useCallback(
    (featureKey: ENABLED_FEATURES_KEY) => {
      return enabledFeatures[featureKey] || false;
    },
    [enabledFeatures]
  );

  useEffect(() => {
    if (requestState.type !== 'REQUEST_SUCCESS') {
      return;
    }
    setEnabledFeatures(
      mapResponse(requestState.data.response.payload.allFeatures)
    );
  }, [requestState]);

  useEffect(() => {
    makeRequest(getEnabledFeatures());
  }, [makeRequest]);

  return (
    <EnabledFeaturesContext.Provider value={{ isFeatureEnabled }}>
      {children}
    </EnabledFeaturesContext.Provider>
  );
};

export default EnabledFeaturesProvider;
