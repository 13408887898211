import React from 'react';

import { DonationStep, Step, Line, Circle } from './styles';

const DonationStepComponent = (props: any) => {
  const table = [];
  for (let i = 1; i <= props.limit; i++) {
    table.push(
      <Step key={i}>
        {i > 1 ? <Line active={i <= props.step} /> : ''}
        <Circle
          active={i <= props.step}
          shadow={i === props.step}
        />
      </Step>
    );
  }
  return <DonationStep>{table}</DonationStep>;
};

export default DonationStepComponent;
