import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import {
  SolidButtonStyle,
  SolidButton as StyledSolidButton,
  SolidButtonWrapper as StyledSolidButtonWrapper,
  SolidOrangeButtonStyle
} from './styles';
import {
  defaultShadowType,
  defaultButtonSize,
  defaultButtonWidth,
  defaultColor,
  buttonWidth
} from './constants';
import colors from '../../../../../theme/colors';
import { sizes } from '../../Icon/constants';

interface SolidButtonProps {
  color?: keyof typeof colors;
  size?: keyof typeof sizes;
  width?: keyof typeof buttonWidth;
  raised?: string;
  fluid?: boolean;
  value?: string;
  children?: ReactNode;
  className?: string;
}

// Should remove the v1 version of this when able
const SolidButton: React.FC<any> = ({
  color = defaultColor,
  size = defaultButtonSize,
  width = defaultButtonWidth,
  raised = defaultShadowType,
  fluid,
  value,
  children,
  ...props
}) => {
  return (
    <StyledSolidButtonWrapper
      size={size}
      width={width}
      color={color}
      raised={raised}
      fluid={fluid}
    >
      <StyledSolidButton
        size={size}
        width={width}
        color={color}
        raised={raised}
        fluid={fluid}
        {...props}
      >
        {children || value}
      </StyledSolidButton>
    </StyledSolidButtonWrapper>
  );
};

export default SolidButton;

export const useSolidButtonV2Styles = makeStyles({
  solidButton: SolidButtonStyle,
  solidOrangeButton: SolidOrangeButtonStyle
});

interface SolidButtonV2Props {
  disableRipple?: boolean;
  [x: string]: any;
}

export const SolidButtonV2: React.FC<SolidButtonV2Props> = (props) => {
  const classes = useSolidButtonV2Styles();
  const { disableRipple = true, ...ownProps } = props;

  return (
    <Button
      className={classes.solidButton}
      disableRipple={disableRipple}
      {...ownProps}
    />
  );
};
