import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../theme/colors';
import NameAvatar from './NameAvatar';
import GivelifyBottomSheet from '../Donee/components/common/Modal/GivelifyBottomSheet';
import { getLoginTypeLocalStorage } from '../../providers/DonorProvider/donorLocalStorage';
import { GivelifyLogo, MailIcon } from '../icons';
import { Text } from '../Donee/components/common/Text/Text';
import { GivelifyButton } from '../Donee/components/common/Button/GivelifyButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import { FacebookButton } from '../FacebookButton';
import { usePushEvents } from '../../hooks/PushEvents';
import {
  lastLoginBannerDismiss,
  lastLoginBannerShown
} from '../../hooks/PushEvents/clevertapEvents';
import { useEnabledFeatures } from '../../hooks/EnabledFeatures';

const StyledDiv = styled.div`
  .logo-wrapper {
    text-align: center;
    margin-bottom: 16px;
    .logo {
      width: 85px;
    }
  }
  .user-wrapper {
    padding: 24px;
    border-radius: 30px;
    text-align: center;
    border: 1px solid ${colors.utilitySeparatorColor};
    background-color: ${colors.utilityBackgroundLightColor};
    .initial-avatar {
      margin-bottom: 8px;
    }
    .donor-name {
      margin-bottom: 8px;
    }
    .donor-email-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .donor-email {
        margin-left: 4px;
      }
    }
    .login-with-password {
      margin-top: 24px;
    }
  }
  .login-button {
    padding: 16px;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
  }
`;

let timeout: number = 0;
const LoginBottomSheet: React.FC = () => {
  const push = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const { isFeatureEnabled } = useEnabledFeatures();
  const { pushEvent } = usePushEvents();
  const { donorContext } = useDonorContext();
  const donor = donorContext.donor?.data || undefined;
  const loginType = getLoginTypeLocalStorage();
  const type = loginType?.loginType;
  const donorName = loginType?.donorName;
  const donorEmail = loginType?.donorEmail;

  useEffect(() => {
    window.clearTimeout(timeout);
    const shouldShow =
      Boolean(type) && isFeatureEnabled('giving_rememberlastlogin_oga');
    switch (donorContext.donor.type) {
      case 'REQUEST_INIT':
        timeout = window.setTimeout(() => {
          setShow(shouldShow);
          shouldShow && pushEvent(lastLoginBannerShown);
        }, 600);
        break;
      case 'REQUEST_ERROR':
        setShow(shouldShow);
        shouldShow && pushEvent(lastLoginBannerShown);
        break;
      case 'REQUEST_SUCCESS':
        setShow(false);
        break;
      case 'REQUEST_START':
      default:
        break;
    }
  }, [donorContext.donor.type, pushEvent, isFeatureEnabled]);

  const handleFacebookClose = useCallback(() => {
    setShow(false);
    pushEvent(lastLoginBannerDismiss, { reason: 'Facebook Button Clicked' });
  }, [setShow, pushEvent]);

  const handleCancel = useCallback(() => {
    pushEvent(lastLoginBannerDismiss, {
      reason: 'Cancel button clicked'
    });
  }, [pushEvent]);

  const handleLogin = useCallback(() => {
    setShow(false);
    pushEvent(lastLoginBannerDismiss, {
      reason: 'Sign in with another account button clicked'
    });
    push(ROUTES.login);
  }, [pushEvent]);
  const handleLoginWithPassword = useCallback(() => {
    setShow(false);
    pushEvent(lastLoginBannerDismiss, {
      reason: 'Sign in with password button clicked'
    });
    push(ROUTES.emailLogin(donorEmail));
  }, [setShow, pushEvent]);

  if (donor || !show || donorContext.donor.type === 'REQUEST_START') {
    return null;
  }

  return (
    <GivelifyBottomSheet onClosedCallback={handleCancel}>
      <StyledDiv>
        <div className="logo-wrapper">
          <GivelifyLogo className="logo" />
        </div>
        <div className="user-wrapper">
          {donorName && (
            <NameAvatar
              name={donorName}
              className="initial-avatar"
            />
          )}
          {donorName && (
            <Text
              weight="extraBold"
              className="donor-name"
            >
              Welcome back,
              <br />
              {donorName}!
            </Text>
          )}
          {donorEmail && (
            <div className="donor-email-wrapper">
              <MailIcon />
              <Text
                variant="xSmall"
                className="donor-email"
                color="primarySecondaryContent"
              >
                {donorEmail}
              </Text>
            </div>
          )}
          {type === 'facebook' ? (
            <FacebookButton onSuccess={handleFacebookClose} />
          ) : (
            <GivelifyButton
              onClick={handleLoginWithPassword}
              className="login-with-password"
            >
              Sign in with Password
            </GivelifyButton>
          )}
        </div>
        <Text
          variant="small"
          className="login-button"
          color="primarySecondaryContent"
          onClick={handleLogin}
        >
          Sign in with Another Account
        </Text>
      </StyledDiv>
    </GivelifyBottomSheet>
  );
};

export default LoginBottomSheet;
