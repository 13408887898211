import React from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import { ReactChildren } from './DoneeProvider';

export const LoginToastContext = createContext<{
  showLoginToast: boolean;
  handleOpenLoginToast: () => unknown;
  handleCloseLoginToast: () => unknown;
}>({
  showLoginToast: false,
  handleOpenLoginToast: () => null,
  handleCloseLoginToast: () => null
});

export const useLoginToastContext = () => useContext(LoginToastContext);

const LoginToastProvider: React.FC<ReactChildren> = ({ children }) => {
  const [showLoginToast, setShowLoginToast] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setShowLoginToast(true);
  }, [setShowLoginToast]);
  const handleClose = useCallback(() => {
    setShowLoginToast(false);
  }, []);

  return (
    <LoginToastContext.Provider
      value={{
        showLoginToast,
        handleOpenLoginToast: handleOpen,
        handleCloseLoginToast: handleClose
      }}
    >
      {children}
    </LoginToastContext.Provider>
  );
};

export default React.memo(LoginToastProvider);
