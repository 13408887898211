import React, { useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import { Row, Col, Alert, Ul, Li } from '@bootstrap-styled/v4';
import {
  AddPaymentMethodContainer,
  FormAddCard,
  FormItem,
  SelectInput,
  StateZipSection,
  SaveFormContainer,
  CancelButton,
  CardType,
  CardThumb,
  LockIcon,
  LabelCover,
  HelpLink,
  HelpImg,
  CVVCardImg,
  BackArrow,
  IconBox,
  Popupstap
} from './styles';
import './style.css';
import { CAN, states, USA } from '../DonorProfile/constants';
import { Text } from '../../components/Donee/components/common/Text/Text';
import Label from '../../components/Donee/components/common/Label';
import TextInput from '../../components/Donee/components/common/Input/TextInput';
import Icon from '../../components/Donee/components/common/Icon';
import { assetUrl, webConfig } from '../../utils/webConfig';
import SolidButton from '../../components/Donee/components/common/Button/SolidButton';
import Modal from '../../components/Donee/components/common/Modal';
import DonationStep from '../../components/DonationStep';
import ReCaptcha from '../../components/Wrappers/ReCaptcha';
import {
  cvvAmericanExpressMask,
  cvvMask,
  expiryDateMask,
  getCardsThumbFormat
} from '../../components/LoginModal/utils';

export default function DonorAddPaymentMethodView(props: any) {
  const {
    name,
    number,
    expiration,
    cvv,
    address,
    city,
    state,
    country,
    zip,
    paymentType,
    cardError,
    CVVInfoPopup,
    error,
    isZipCodeChanged,
    captchaError,
    cardThumb,
    cardTypeData,
    errorProp,
    errorCodeProp,
    onNewCardPopupClose,
    type,
    buttonLabel,
    setPaymentType,
    setCvvInfoPopup,
    setName,
    setNumber,
    setExpiration,
    setCvv,
    setCountry,
    setAddress,
    setCity,
    // function
    verifyCallback,
    resetRecaptcha,
    addNewCard,
    showState,
    moveCaretAtEnd,
    showCountry,
    showZipCode,
    invisible,
    cardDetailDone,
    allCountries,
    validCVV,
    checkCVV,
    validExpire,
    zipInvalid,
    isCardNumberValidated
  } = props;

  const expInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  useEffect(() => {
    if (
      isCardNumberValidated &&
      number.length &&
      expInputRef &&
      expInputRef.current
    ) {
      (expInputRef.current as any).inputElement.focus();
    }
  }, [isCardNumberValidated]);

  useEffect(() => {
    if (
      validExpire &&
      expiration.length &&
      cvvInputRef.current &&
      isCardNumberValidated
    ) {
      (cvvInputRef.current as any).inputElement.focus();
    }
  }, [validExpire]);

  return (
    <AddPaymentMethodContainer>
      <FormAddCard>
        {error !== undefined && error ? (
          <Text
            variant={'xSmall'}
            className={'text-left'}
          >
            <Alert
              color={'danger'}
              isOpen
            >
              {error}
            </Alert>
          </Text>
        ) : (
          ''
        )}

        {errorProp !== '' && errorCodeProp !== 1292 && (
          <Text
            variant={'xSmall'}
            className={'text-left'}
          >
            <Alert
              color={'danger'}
              isOpen
            >
              {errorProp}
            </Alert>
          </Text>
        )}

        {zipInvalid &&
          country === USA &&
          isZipCodeChanged &&
          paymentType === 'billing' && (
            <Text
              variant={'xSmall'}
              className={'text-left'}
            >
              <Alert
                color={'danger'}
                isOpen
              >
                {'Zip code must have 5 number'}
              </Alert>
            </Text>
          )}

        {zipInvalid &&
          country === CAN &&
          isZipCodeChanged &&
          paymentType === 'billing' && (
            <Text
              variant={'xSmall'}
              className={'text-left'}
            >
              <Alert
                color={'danger'}
                isOpen
              >
                {'Invalid Canadian postal code'}
              </Alert>
            </Text>
          )}

        {paymentType === 'card' && (
          <div>
            <center>
              <Text
                className={'popup-title mb-20'}
                color={'grayscaleBlack'}
              >
                Add New Card
                <Popupstap>
                  <DonationStep
                    step={1}
                    limit={2}
                  />
                </Popupstap>
              </Text>
            </center>

            <Row className={'mlr_-8'}>
              <Col
                xs={12}
                className={'plr-8'}
              >
                <FormItem>
                  <Label
                    size={'small'}
                    color={'primarySecondaryContent'}
                    weight={undefined}
                    value={undefined}
                  >
                    Full Name on Card
                  </Label>
                  <TextInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    maxLength={55}
                  />
                </FormItem>
              </Col>
              <Col
                xs={12}
                className={'plr-8'}
              >
                <FormItem>
                  <Label
                    size={'small'}
                    color={'primarySecondaryContent'}
                    weight={undefined}
                    value={undefined}
                  >
                    Card Number
                  </Label>
                  <CardType>
                    <MaskedInput
                      mask={getCardsThumbFormat(cardThumb)}
                      className={'formInput'}
                      guide={false}
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                    <CardThumb>
                      <Icon
                        name={
                          cardThumb !== ''
                            ? (`${cardThumb.replace(' ', '_')}_badge` as any)
                            : ('default_card' as any)
                        }
                        size={'large'}
                      />
                    </CardThumb>
                  </CardType>
                  <Text
                    color={'red'}
                    variant={'small'}
                  >
                    {cardError}
                  </Text>
                </FormItem>
              </Col>
              <Col
                xs={6}
                className={'plr-8'}
              >
                <FormItem>
                  <Label
                    size={'small'}
                    color={'primarySecondaryContent'}
                    weight={undefined}
                    value={undefined}
                  >
                    Exp. date (mm/yy)
                  </Label>
                  <MaskedInput
                    mask={expiryDateMask}
                    guide={false}
                    className={'formInput'}
                    value={expiration}
                    onChange={(e) => setExpiration(e.target.value)}
                    onFocus={moveCaretAtEnd}
                    ref={expInputRef}
                  />
                  {!validExpire ? (
                    <Text
                      color={'red'}
                      variant={'small'}
                    >
                      Expiration Date is not valid
                    </Text>
                  ) : (
                    ''
                  )}
                </FormItem>
              </Col>
              <Col
                xs={6}
                className={'plr-8'}
              >
                <FormItem>
                  <LabelCover>
                    <Label
                      size={'small'}
                      color={'primarySecondaryContent'}
                      weight={undefined}
                      value={undefined}
                    >
                      CVV
                    </Label>
                  </LabelCover>
                  <HelpLink
                    onClick={() => {
                      setCvvInfoPopup(true);
                      document.body.classList.add('cvv-help-open');
                    }}
                  >
                    <HelpImg src={assetUrl('/images/svg/help-icon.svg')} />
                  </HelpLink>
                  <MaskedInput
                    mask={
                      cardThumb === 'american express'
                        ? cvvAmericanExpressMask
                        : cvvMask
                    }
                    guide={false}
                    className={'formInput'}
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                    ref={cvvInputRef}
                  />
                  {checkCVV(validCVV, cardTypeData)}
                </FormItem>
              </Col>
              <br />
            </Row>
            <center>
              <Text
                variant={'xSmall'}
                color={'primarySecondaryContent'}
              >
                <LockIcon>
                  <Icon
                    size={'medium'}
                    name={'lock-icon'}
                  />
                </LockIcon>
                Your information will be stored securely
              </Text>
            </center>
          </div>
        )}

        {paymentType === 'billing' && (
          <div>
            <center className={'position-r'}>
              <BackArrow
                onClick={() => {
                  setPaymentType('card');
                }}
              >
                <IconBox>
                  <Icon
                    size={'medium'}
                    name={'back_arrow'}
                  />
                </IconBox>
              </BackArrow>
              <Text
                className={'popup-title mb-20'}
                color={'grayscaleBlack'}
              >
                Billing Address
                <Popupstap>
                  <DonationStep
                    step={2}
                    limit={2}
                  />
                </Popupstap>
              </Text>
            </center>
            <Row className={'mlr_-8'}>
              <Col
                xs={12}
                className={'plr-8'}
              >
                {showCountry() && (
                  <FormItem>
                    <Label
                      size={'small'}
                      color={'primarySecondaryContent'}
                      weight={undefined}
                      value={undefined}
                    >
                      Country
                    </Label>
                    <SelectInput
                      value={decodeURIComponent(country)}
                      onChange={(e) => setCountry(e.target.value)}
                      className={country === '' ? 'default' : ''}
                    >
                      <option value={''}>Select a country...</option>
                      {allCountries &&
                        allCountries.map((t: any) => (
                          <option
                            value={t.alpha3}
                            key={t.name}
                          >
                            {t.name}
                          </option>
                        ))}
                    </SelectInput>
                  </FormItem>
                )}
              </Col>
              <Col
                xs={12}
                className={'plr-8'}
              >
                <FormItem>
                  <Label
                    size={'small'}
                    color={'primarySecondaryContent'}
                    weight={undefined}
                    value={undefined}
                  >
                    Street address
                  </Label>
                  <TextInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    maxLength={255}
                  />
                </FormItem>
              </Col>
              <Col
                xs={12}
                className={'plr-8'}
              >
                {showZipCode(zip, country)}
              </Col>

              <Col
                xs={6}
                className={'plr-8'}
              >
                <FormItem>
                  <Label
                    size={'small'}
                    color={'primarySecondaryContent'}
                    weight={undefined}
                    value={undefined}
                  >
                    City
                  </Label>
                  <TextInput
                    value={decodeURIComponent(city)}
                    onChange={(e) => setCity(e.target.value)}
                    maxLength={55}
                  />
                </FormItem>
              </Col>
              <Col
                xs={6}
                className={'plr-8'}
              >
                <StateZipSection>
                  {showState(state, states, country)}
                </StateZipSection>
              </Col>
            </Row>
            <center>
              <Text
                variant={'xSmall'}
                color={'primarySecondaryContent'}
              >
                <LockIcon>
                  <Icon
                    size={'medium'}
                    name={'lock-icon'}
                  />
                </LockIcon>
                Your information will be stored securely
              </Text>
            </center>
          </div>
        )}
      </FormAddCard>

      {paymentType === 'card' && (
        <center>
          <SaveFormContainer>
            <SolidButton
              fluid
              size={'small'}
              width={'minwidth'}
              raised={cardDetailDone ? 'skyblue' : 'none'}
              color={cardDetailDone ? 'skyblue' : 'disable'}
              disabled={!cardDetailDone}
              onClick={() => {
                if (cardDetailDone) {
                  setPaymentType('billing');
                }
              }}
              value={undefined}
            >
              Continue
            </SolidButton>
            <CancelButton
              onClick={() => {
                onNewCardPopupClose();
              }}
            >
              <Text
                variant={'small'}
                color={'primaryGuidingViolet'}
              >
                Cancel
              </Text>
            </CancelButton>
          </SaveFormContainer>
        </center>
      )}

      {paymentType === 'billing' && (
        <center>
          <SaveFormContainer>
            <div className={'mb-20'}>
              <ReCaptcha
                size={'normal'}
                sitekey={webConfig.googleRecaptchaKey}
                onChange={verifyCallback}
                onExpired={resetRecaptcha}
                onErrored={resetRecaptcha}
              />
              {captchaError !== '' && <Text color={'red'}>{captchaError}</Text>}
            </div>
            <SolidButton
              fluid
              size={'small'}
              raised={invisible ? 'none' : 'primaryGenerosityOrange'}
              width={'minwidth'}
              value={type === 'checkout' ? buttonLabel : 'Add New Card'}
              color={invisible ? 'disable' : 'primaryGenerosityOrange'}
              onClick={() => addNewCard(props)}
              disabled={!!invisible}
              children={undefined}
            />
            <CancelButton
              onClick={() => {
                onNewCardPopupClose();
              }}
            >
              <Text
                variant={'small'}
                color={'primaryGuidingViolet'}
              >
                Cancel
              </Text>
            </CancelButton>
          </SaveFormContainer>
        </center>
      )}

      <Modal
        active={CVVInfoPopup}
        className={'success-login-popup'}
        onClose={function (): unknown {
          throw new Error('Function not implemented.');
        }}
      >
        <center>
          <Ul>
            <Li className={'cvv-icon'}>
              <CVVCardImg src={assetUrl('/images/svg/cvv-visa.svg')} />
            </Li>
            <Li className={'cvv-icon'}>
              <CVVCardImg src={assetUrl('/images/svg/cvv-amex.svg')} />
            </Li>
          </Ul>
          <Text
            className={'mb-10 w-100'}
            variant={'medium'}
            color={'grayscaleBlack'}
            weight={'regular'}
          >
            You can find the 3-digit security code on the back of your card. For
            Amex, the 4-digit code is on the front of the card.
          </Text>
        </center>
        <br />
        <SolidButton
          fluid
          size={'small'}
          width={'minwidth'}
          raised={'skyblue'}
          color={'skyblue'}
          onClick={() => {
            setCvvInfoPopup(false);
            document.body.classList.remove('cvv-help-open');
          }}
          value={'OK'}
        >
          OK
        </SolidButton>
      </Modal>
    </AddPaymentMethodContainer>
  );
}
