import React from 'react';
import { Address } from './pages/Address';
import AddressComplete from './pages/AddressComplete';
import { CreditCard } from './pages/CreditCard';
import { Landing } from './pages/Landing';
import { Login } from './pages/Login';
import { Name } from './pages/Name';
import { Password } from './pages/Password';
import { LoginPages, User } from './routes';

export interface ModalRouterProps {
  user: User;
  page: LoginPages;
  closeModal: () => unknown;
  onNext: (user: User, nextPage?: LoginPages) => unknown;
}

const Router: React.FC<ModalRouterProps> = (props) => {
  switch (props.page) {
    case 'LANDING':
      return <Landing {...props} />;
    case 'LOGIN':
      return <Login {...props} />;
    case 'NAME':
      return <Name {...props} />;
    case 'CREDIT':
      return <CreditCard {...props} />;
    case 'ADDRESS':
      return <Address {...props} />;
    case 'ADDRESS_COMPLETE':
      return <AddressComplete {...props} />;
    case 'PASSWORD':
      return <Password {...props} />;
  }
}

export default React.memo(Router);
