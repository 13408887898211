import React from 'react';
import Popover, {
  PopoverOrigin,
  PopoverProps
} from '@material-ui/core/Popover/Popover';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { historyLabel, links } from './constants';
import colors from '../theme/colors';
import { Text } from '../Donee/components/common/Text/Text';
import Icon from '../Donee/components/common/Icon';
import { HomeIcon } from '../icons';
import { GivelifyButton } from '../Donee/components/common/Button/GivelifyButton';
import { ROUTES } from '../../utils/routes';
import { usePushEvents } from '../../hooks/PushEvents';
import { profileLogoutSelected } from '../../hooks/PushEvents/clevertapEvents';

// const Transition = React.forwardRef(
// (
//     props: TransitionProps & { children?: React.ReactElement },
//     ref: React.Ref<unknown>
// ) => <Slide direction="down" ref={ref} {...props} />
// );

const DropdownContent = styled(Popover)`
  margin-top: 35px;
  .paper {
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  }
  .content {
    min-width: 180px;
    padding-top: 10px;
    padding-bottom: 10px;
    .get-the-app-button {
      margin-left: 8px;
    }
    .nav {
      display: flex;
      align-items: center;
      padding: 7px 15px;
      cursor: pointer;
      .text {
        cursor: pointer;
        margin-left: 10px;
      }
      .home-icon {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: ${colors.utilityGuidingVioletLight};
      }
    }
  }
`;

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};
const transformOrigin: PopoverOrigin = {
  vertical: 'center',
  horizontal: 'right'
};
const classes = {
  paper: 'paper'
};

interface ProfileDropdownContentProps {
  open: boolean;
  anchor: PopoverProps['anchorEl'];
  onLogout: () => unknown;
  onClose: () => unknown;
}

const ProfileDropdownContent: React.FC<ProfileDropdownContentProps> = ({
  open,
  onClose,
  onLogout,
  anchor
}) => {
  const { pushEvent } = usePushEvents();
  const push = useNavigate();

  const handleLogout = () => {
    onLogout();
    onClose();
    pushEvent(profileLogoutSelected);
    push(ROUTES.main);
  };
  const handleHomeClick = () => {
    onClose();
    push(ROUTES.main);
  };

  return (
    <DropdownContent
      // TransitionComponent={(props) => <Slide direction="down" ref={ref} {...props} />}
      anchorEl={anchor}
      open={open}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}
      classes={classes}
    >
      <div className={'content'}>
        <div
          className={'nav'}
          onClick={handleHomeClick}
        >
          <HomeIcon className={'home-icon'} />
          <Text
            variant={'small'}
            weight={'bold'}
            className={'text'}
          >
            Home
          </Text>
        </div>
        {links.authenticated.map((link, i) => {
          const handleClick = () => {
            onClose();
            push(link.path);
          };
          return (
            <div
              key={i}
              className={'nav'}
              onClick={handleClick}
            >
              <Icon
                name={link.icon as any}
                size={'medium'}
                fluid={undefined}
              />
              <Text
                variant={'small'}
                weight={'bold'}
                className={'text'}
              >
                {link.text}
              </Text>
              {link.text === historyLabel && (
                <GivelifyButton
                  variant={'transparent'}
                  className={'get-the-app-button'}
                >
                  GET THE APP
                </GivelifyButton>
              )}
            </div>
          );
        })}
        <div
          className={'nav'}
          onClick={handleLogout}
        >
          <Icon
            name={'logout-icon'}
            size={'small'}
            fluid={undefined}
          />
          <Text
            variant={'small'}
            weight={'bold'}
            className={'text'}
          >
            Logout
          </Text>
        </div>
      </div>
    </DropdownContent>
  );
};

export default ProfileDropdownContent;
