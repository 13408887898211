import { useMemo } from 'react';

export const useQueryString = () => {
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const setQueryString = (key: string, value: string | number) => {
  const url = new URL(window.location as unknown as URL);
  url.searchParams.set(key, value as string);
  window.history.pushState(null, '', url.toString());
};

export const removeQueryString = (key: string) => {
  const url = new URL(window.location as unknown as URL);
  url.searchParams.delete(key);
  window.history.pushState(null, '', url.toString());
};
