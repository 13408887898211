import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DonorProfileView from './DonorProfileView';
import { webConfig } from '../../utils/webConfig';
import { useDonor } from '../../providers/DonorProvider/useDonor';
import {
  detailOnProfile,
  profilePictureAdd,
  profilePictureAdded
} from '../../hooks/PushEvents/clevertapEvents';
import { Donor } from '../../providers/DonorProvider/interfaces';
import { usePushEvents } from '../../hooks/PushEvents';
import { googleZipLookup } from '../../components/LoginModal/pages/Landing/api';
import {
  emailPattern,
  phonePattern
} from '../../components/Donee/components/common/constants';
import { updateProfileRequest } from './api';
import { GivingApiResponse } from '../../utils/makeApiRequest';
import { useApiRequest } from '../../hooks/useGivingApiRequest';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import { ROUTES } from '../../utils/routes';
import { somethingWrongMsg } from '../../utils/messages';
import { HeartLoading } from '../../components/HeartLoading';

const getDonorProfilePictureUrl = (donor: Donor): string => {
  return donor &&
    donor.pictureUrl &&
    donor.pictureUrl !== '' &&
    donor.pictureUrl !== 'https://stage.givelify.com'
    ? donor.pictureUrl
    : '/images/svg/church-officer-placeholder.svg';
};

export default function Profile() {
  const { pushEvent } = usePushEvents();
  const { donor } = useDonor() || {};

  const [email, setEmail] = useState<string>(donor ? donor.email : '');
  const [phone, setPhone] = useState<string>(donor?.phone?.number || '');
  const [address, setAddress] = useState<string>(donor?.address?.street || '');
  const [city, setCity] = useState<string>(donor?.address?.city || '');
  const [state, setState] = useState<string>(donor?.address?.state || '');
  const [zip, setZip] = useState<string>(donor?.address?.zip || '');
  const [picture, setPicture] = useState<string | ArrayBuffer | undefined>(
    donor ? getDonorProfilePictureUrl(donor) : undefined
  );
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zipCodeError, setZipError] = useState('');
  const [oldZipCode, setOldZip] = useState('');
  const [uploadProcess, setUploadProcess] = useState(false);
  const [updateDetail, setUpdateDetail] = useState(false);
  const [error, setError] = useState<string | undefined>('');
  const [success, setSuccess] = useState('');
  const [userTempData, setUserTempData] = useState(donor);
  const [tempPicture, setTempPicture] = useState<string | ArrayBuffer | null>(
    null
  );
  const [tempFile, setTempFile] = useState(null);
  const [saveProcess, setSaveProcess] = useState(false);
  const [profileImageValidModal, setProfileImageValidModal] = useState(false);
  const [message, setMessage] = useState('');
  const [chooseFile, setChooseFile] = useState<HTMLInputElement | null>(null);

  const [requestState, makeRequest] = useApiRequest<GivingApiResponse<any>>();

  const { donorContext, loadDonor } = useDonorContext();
  const push = useNavigate();

  useEffect(() => {
    if (donorContext.donor.type === 'REQUEST_ERROR') {
      push(ROUTES.login);
    }
    if (donorContext.donor.type === 'REQUEST_SUCCESS' && !email) {
      const d = donorContext.donor.data.donor;
      setEmail(d.email);
      setPhone(d.phone.number);
      setAddress(d.address.street);
      setCity(d.address.city);
      setState(d.address.state);
      setZip(d.address.zip);
      d.pictureUrl && setPicture(d.pictureUrl);
    }
  }, [donorContext.donor.type]);

  useEffect(() => {
    switch (requestState.type) {
      case 'REQUEST_INIT':
      case 'REQUEST_START':
        return;
      case 'REQUEST_ERROR':
        setError(requestState.error.message || somethingWrongMsg);
        return;
      case 'REQUEST_SUCCESS':
        loadDonor(true);
        push(ROUTES.profile(true));
        return;
      default:
        break;
    }
  }, [requestState.type]);

  useEffect(() => {
    pushEvent(detailOnProfile);
  }, []);

  const onSetEmail = (newEmail: string) => {
    setEmail(newEmail);
    if (newEmail !== '') {
      if (validateEmail(newEmail)) {
        setEmailError('');
        setUpdateDetail(true);
      } else {
        setEmailError('Please check formatting on email address.');
        setUpdateDetail(false);
      }
    } else {
      setEmailError('Please enter your email address.');
      setUpdateDetail(false);
    }
  };

  const onSetPhone = (newPhone: string) => {
    setPhone(newPhone);
    if (newPhone !== '') {
      if (validatePhone(newPhone)) {
        setPhoneError('');
        setUpdateDetail(true);
      } else {
        setPhoneError('Please check formatting on phone number.');
        setUpdateDetail(false);
      }
    } else {
      setPhoneError('');
      setUpdateDetail(true);
    }
  };

  const onSetAddress = (newAddress: string) => {
    setAddress(newAddress);
    if (newAddress === '') {
      setAddressError('Please enter your address.');
      setUpdateDetail(false);
    } else {
      setAddressError('');
      setUpdateDetail(true);
    }
  };

  const onSetCity = (newCity: string) => {
    setCity(newCity);
    if (newCity === '') {
      setUpdateDetail(false);
      setCityError('Please enter your city.');
    } else {
      setUpdateDetail(true);
      setCityError('');
    }
  };

  const onSetState = (newState: string) => {
    setState(newState);
    if (newState === '') {
      setStateError('Please select your state.');
      setUpdateDetail(false);
    } else {
      setStateError('');
      setUpdateDetail(true);
    }
  };

  const onSetZip = (newZip: string) => {
    setZip(newZip);
    if (newZip !== '') {
      if (
        !Number(newZip) ||
        Number(newZip) < 0 ||
        (newZip.length > 0 && newZip.length < 5)
      ) {
        setZipError('Zip code should be 5 numbers.');
        return false;
      }

      if (oldZipCode !== newZip) {
        setOldZip(newZip);
        setZipError('');

        googleZipLookup(newZip)
          .then((response) => {
            if (response.success) {
              const results = response.data?.results[0];
              const addressComponents = results?.addressComponents;
              let newCity = '';
              let stateCode = '';
              addressComponents?.forEach((value) => {
                if (value.types.includes('locality')) {
                  newCity = value.longName;
                }
                if (value.types.includes('administrative_area_level_1')) {
                  stateCode = value.shortName;
                }
              });

              setCity(newCity);
              setCityError('');
              setState(stateCode);
              setStateError('');
              setUpdateDetail(true);
            }
          })
          .catch(() => {
            setZipError('Invalid zip code.');
          });
        return true;
      }
      setZipError('');
      return true;
    }
    setZipError('Please enter your zipcode.');
    return false;
  };

  useEffect(() => {
    if (
      message ===
        'Sorry but that profile image type is invalid. Supported image types are jpeg and png' ||
      message === 'Sorry but we accept image sizes 3MB or smaller.'
    ) {
      profileImageValidModalOpen();
      imageReset();
    }
  }, [message]);

  const onFileUploadHandle = (files: FileList | null) => {
    if (!files) return;
    pushEvent(profilePictureAdd);
    const file = files[0];

    if (file.size > 0) {
      let imageSize = file.size / 1024;
      imageSize /= 1024;
      if (imageSize > 3) {
        setMessage('Sorry but we accept image sizes 3MB or smaller.');
        return false;
      }
    }

    if (file.type !== '') {
      const imageType = file.type;
      const imageExtension = ['image/jpeg', 'image/png'];
      if (imageExtension.includes(imageType) === false) {
        setMessage(
          'Sorry but that profile image type is invalid. Supported image types are jpeg and png'
        );
        return false;
      }
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPicture(reader.result || undefined);
      setTempPicture(reader.result);
      pushEvent(profilePictureAdded);
    };
    setUpdateDetail(true);
    return true;
  };

  const handleClickChooseFile = () => {
    pushEvent(profilePictureAdd);
    if (!uploadProcess) {
      (chooseFile as any).click();
    }
    return false;
  };

  const handleError = () => {
    setPicture(webConfig.doneeAvatarDefault);
    userTempData &&
      setUserTempData({
        ...userTempData,
        pictureUrl: webConfig.doneeAvatarDefault
      });
  };

  const closeMessage = () => () => {
    setSuccess('');
    setError(undefined);
  };

  const updateProfile = (e: any) => {
    e.preventDefault();
    setError(undefined);
    const id = donorContext.donor.data?.donor.id;
    if (!id) {
      setError('Unable to save the changes. Please try again!');
      return;
    }

    if (zipCodeError !== '' || !updateDetail) {
      return false;
    }
    if (email === '') {
      return false;
    } else if (!validateEmail(email)) {
      return false;
    }

    if (address === '' || city === '' || state === '' || zip === '') {
      if (address === '') {
        setAddressError('Please enter your address.');
      }
      if (city === '') {
        setCityError('Please enter your city.');
      }
      if (state === '') {
        setStateError('Please enter your city.');
      }
      if (zip === '') {
        setZipError('Please enter your zipcode.');
      } else if (zip.length > 0 && zip.length < 5) {
        return false;
      }
      return false;
    }

    const data = {
      email,
      phone: {
        number: phone
      },
      address: {
        street: address,
        city,
        state,
        zip
      },
      picture: tempPicture || undefined,
      donorId: id,
      id: id
    };

    makeRequest(updateProfileRequest(data));
    return true;
  };

  const validateEmail = (email: string | undefined): boolean =>
    !!email && emailPattern.test(email);
  const validatePhone = (newPhone: string | undefined) =>
    !!newPhone && phonePattern.test(newPhone);

  const imageReset = () => {
    const newPicture = donor?.pictureUrl || webConfig.doneeAvatarDefault;
    setTempPicture('');
    setTempFile(null);
    setPicture(newPicture);
    if (typeof (chooseFile as any).value !== 'undefined') {
      (chooseFile as any).value = '';
    }
  };

  const profileImageValidModalOpen = () => {
    setProfileImageValidModal(true);
  };

  const profileImageValidModalClose = () => {
    setProfileImageValidModal(false);
  };

  if (
    donorContext.donor.type === 'REQUEST_INIT' ||
    donorContext.donor.type === 'REQUEST_START'
  ) {
    return <HeartLoading maxWidth={300} />;
  }

  return (
    <DonorProfileView
      picture={picture}
      name={donor?.name}
      email={email}
      phone={phone}
      address={address}
      city={city}
      state={state}
      zip={zip}
      saveProcess={saveProcess}
      success={success}
      error={error}
      emailError={emailError}
      phoneError={phoneError}
      addressError={addressError}
      cityError={cityError}
      stateError={stateError}
      zipCodeError={zipCodeError}
      tempPicture={tempPicture}
      updateDetail={updateDetail}
      profileImageValidModal={profileImageValidModal}
      message={message}
      closeMessage={closeMessage}
      updateProfile={updateProfile}
      handleError={handleError}
      handleClickChooseFile={handleClickChooseFile}
      imageReset={imageReset}
      onFileUploadHandle={onFileUploadHandle}
      profileImageValidModalClose={profileImageValidModalClose}
      setChooseFile={setChooseFile}
      setEmail={onSetEmail}
      setPhone={onSetPhone}
      setAddress={onSetAddress}
      setCity={onSetCity}
      setState={onSetState}
      setZip={onSetZip}
    />
  );
}
