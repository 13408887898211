import React, { useCallback, useState } from 'react';
import CardInfo from './CardInfo';
import styled from 'styled-components';
import ChangePaymentModal from '../ChangePayment/ChangePaymentModal';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { Text } from '../../../common/Text/Text';
import { usePushEvents } from '../../../../../../hooks/PushEvents';
import { paymentChangedOnGiftSummary } from '../../../../../../hooks/PushEvents/clevertapEvents';
import { useDonor } from '../../../../../../providers/DonorProvider/useDonor';

interface ChangePaymentMethodButtonProps {
  selectedCard: Wallet;
  wallet: Wallet[];
  setCard: (wallet: Wallet) => unknown;
  handleAddPaymentOpen: () => unknown;
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    text-align: left;
    flex: 1;
    margin-left: 26px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    .change-payment-button {
      cursor: pointer;
    }
  }
`;

const ChangePaymentMethodButton: React.FC<ChangePaymentMethodButtonProps> = ({
  handleAddPaymentOpen,
  selectedCard,
  wallet,
  setCard
}) => {
  const donor = useDonor();
  const { pushEvent } = usePushEvents();
  const [changePaymentOpen, setChangePaymentOpen] = useState<boolean>(false);

  const handleChangePaymentOpen = useCallback(() => {
    setChangePaymentOpen(true);
    pushEvent(paymentChangedOnGiftSummary, {
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort,
      paymentPresent: wallet.length ? 'Yes' : 'No',
      modalOpen: true,
      reason: 'Change Payment Modal Opened'
    });
  }, [setChangePaymentOpen, pushEvent]);

  const handleChangePaymentClose = useCallback(() => {
    setChangePaymentOpen(false);
    pushEvent(paymentChangedOnGiftSummary, {
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort,
      paymentPresent: wallet.length ? 'Yes' : 'No',
      modalOpen: false,
      reason: 'Change Payment Modal Closed'
    });
  }, [setChangePaymentOpen]);

  return (
    <>
      <StyledDiv>
        <CardInfo card={selectedCard} />
        <button>
          <Text
            color={'primaryGuidingViolet'}
            className={'change-payment-button'}
            onClick={handleChangePaymentOpen}
          >
            Change Payment
          </Text>
        </button>
      </StyledDiv>
      <ChangePaymentModal
        open={changePaymentOpen}
        wallet={wallet}
        selectedCard={selectedCard}
        setCard={setCard}
        handleModalClose={handleChangePaymentClose}
        handleAddPaymentOpen={handleAddPaymentOpen}
      />
    </>
  );
};

export default ChangePaymentMethodButton;
