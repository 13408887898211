import { axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';

const getSetPreferredWalletUrl = (id: string | number) =>
  `/donor/profile/wallets/${id}/setAsPreferred`;

export const setPreferredWallet = (walletId: number) => {
  const httpRequest = axiosClient.put(getSetPreferredWalletUrl(walletId));
  return makeApiRequest(httpRequest);
};

const getDeleteWalletUrl = (id: string | number) =>
  `/donor/profile/wallets/${id}`;
export const deleteWallet = (walletId: number) => {
  const httpRequest = axiosClient.delete(getDeleteWalletUrl(walletId));
  return makeApiRequest(httpRequest);
};
