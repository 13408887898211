export const shapes = {
  square: 'square',
  circle: 'circle',
  rounded: 'rounded'
};

export const sizes = {
  small: 48,
  medium: 64,
  large: 96
};

export const defaultSize = 'small';
export const defaultShape = 'square';
