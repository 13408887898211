import React, { useEffect } from 'react';
import AmountWizard from './AmountWizard';
import CartWizard from './CartWizard';
import EnvelopesWizard from './EnvelopesWizard';
import ThankYouWizard from './ThankYouWizard';
import { CheckoutWizardRouterProps } from './CheckoutWizardInterfaces';
import { RequestState } from '../../../../hooks/useRequestState';
import { Donee } from '../../../../providers/DoneeProvider';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { doneeNotFound } from '../../../../hooks/PushEvents/clevertapEvents';
import { HeartLoading } from '../../../HeartLoading';
import { useDonorContext } from '../../../../providers/DonorProvider/DonorProvider';

const CheckoutWizardRouter: React.FC<
  CheckoutWizardRouterProps & { donee: RequestState<Donee> }
> = (props) => {
  const { donorContext } = useDonorContext();
  const { pushEvent } = usePushEvents();
  const donee = props.donee;

  useEffect(() => {
    if (donee.type === 'REQUEST_ERROR') {
      pushEvent(doneeNotFound, { path: window.location.href });
    }
  }, [donee.type, pushEvent]);

  switch (donee.type) {
    case 'REQUEST_INIT':
    case 'REQUEST_START':
      return <HeartLoading className={'loading'} />;
    case 'REQUEST_ERROR':
      return (
        <div>{donee.error.message || 'Unable to load the organization.'}</div>
      );
    case 'REQUEST_SUCCESS':
      switch (props.route) {
        case 'amount':
          return (
            <AmountWizard
              {...props}
              donee={donee.data}
            />
          );
        case 'envelopes':
          // important check: updating here will affect EnvelopesWizard component
          if (props.cart.envelopes['new']) {
            return (
              <EnvelopesWizard
                {...props}
                donee={donee.data}
              />
            );
          }
          props.setRoute('amount');
          return null;
        case 'cart':
          return (
            <CartWizard
              {...props}
              donee={donee.data}
            />
          );
        case 'thank-you':
          return (
            <ThankYouWizard
              {...props}
              donee={donee.data}
              donorContext={donorContext}
            />
          );
      }
  }
};

export default CheckoutWizardRouter;
