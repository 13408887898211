import React from 'react';
import colors from '../theme/colors';
import styled from 'styled-components';
import makeStyles from '@material-ui/styles/makeStyles/makeStyles';
import { somethingWrongMsg } from '../../utils/messages';
import { SolidButtonV2 } from '../Donee/components/common';
import { SaveButton } from '../../pages/DonorProfile/styles';
import { webConfig } from '../../utils/webConfig';

const useStyles = makeStyles({
  SaveButton
});

const StyledDiv = styled.div`
  padding: 24px;
  text-align: center;
  .text {
    margin-bottom: 24px;
  }
  .MuiButton-root {
    border-radius: 50px;
    color: ${colors.white};
    background-color: ${colors.buttonsHoverViolet};
    &:hover {
      background-color: ${colors.buttonsHoverViolet};
    }
  }
`;

const handleClick = () => {
  window.location.href = webConfig.mainSearchSite;
};

export const SomethingWentWrong: React.FC = () => {
  const { SaveButton } = useStyles();
  return (
    <StyledDiv>
      <div className="text">{somethingWrongMsg}</div>
      <SolidButtonV2
        type={'submit'}
        className={SaveButton}
        onClick={handleClick}
      >
        Search an organization
      </SolidButtonV2>
    </StyledDiv>
  );
};

export default SomethingWentWrong;
