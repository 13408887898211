import React from 'react';
import WalletSectionView from './WalletSectionView';
import { Wallet } from '../../../providers/DonorProvider/interfaces';
import { deleteWallet, setPreferredWallet } from '../api';

interface WalletSectionProps {
  walletId: number;
  accountType: Wallet['type'];
  accountNumber: string;
  preferredAccount: boolean;
  deleteCardFromWallet: (
    newCardType: Wallet['type'],
    newCardNumber: string
  ) => void;
  wallets: Wallet[];
  openNewCardPopup: () => void;
  loadDonor: (forcePull?: boolean | undefined) => unknown;
}

const WalletSection: React.FC<WalletSectionProps> = ({
  walletId,
  accountType,
  preferredAccount,
  wallets,
  openNewCardPopup,
  accountNumber,
  deleteCardFromWallet,
  loadDonor
}) => {
  const [preferredCardDelete, setPreferredCardDelete] =
    React.useState<boolean>(false);
  const [recurringCardDelete, setRecurringCardDelete] =
    React.useState<boolean>(false);
  const [recurringCard] = React.useState<boolean>(false);
  const [accountId, setAccountId] = React.useState<number | undefined>(
    undefined
  );
  const [oldCardId, setOldCardId] = React.useState<number | undefined>(
    undefined
  );
  const [loader, setLoader] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [type, setType] = React.useState<'delete' | 'preferred' | 'default'>();

  const makeDefault = async (newAccountId: number | undefined) => {
    if (!newAccountId) return;
    const response = await setPreferredWallet(newAccountId);
    if (!response.success) return;
    loadDonor(true);
  };

  const doDeleteWallet = async (newAccountId: number | undefined) => {
    if (!newAccountId) return;
    const response = await deleteWallet(newAccountId);
    if (!response.success) return;

    const newAccountNumber = accountNumber.substr(accountNumber.length - 5);

    if (oldCardId) {
      deleteCardFromWallet(accountType, newAccountNumber);
    } else {
      setOldCardId(undefined);
    }
    setShow(false);
    loadDonor(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const openModal = (newType: 'delete' | 'preferred' | 'default') => {
    setShow(true);
    setType(newType);
  };

  const selectCardTemp = (account: any) => {
    setAccountId(account.id);
  };

  const cardSelectDone = () => {
    setLoader(true);
    doDeleteWallet(oldCardId);

    setTimeout(() => {
      makeDefault(accountId);
    }, 1000);
  };

  return (
    <WalletSectionView
      account_id={walletId}
      account_type={accountType}
      preferred_account={preferredAccount}
      wallets={wallets}
      openNewCardPopup={openNewCardPopup}
      account_number={accountNumber}
      preferredCardDelete={preferredCardDelete}
      recurringCardDelete={recurringCardDelete}
      recurringCard={recurringCard}
      show={show}
      type={type}
      accountId={accountId}
      loader={loader}
      setPreferredCardDelete={setPreferredCardDelete}
      setRecurringCardDelete={setRecurringCardDelete}
      setOldCardId={setOldCardId}
      openModal={openModal}
      closeModal={closeModal}
      makeDefault={makeDefault}
      deleteWallet={doDeleteWallet}
      selectCardTemp={selectCardTemp}
      cardSelectDone={cardSelectDone}
    />
  );
};

export default WalletSection;
