import React from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';
import { Text } from '../common/Text/Text';

export interface AmountCapsuleProps {
  amount: number | 'Other';
  className?: string;
  onClick: (amount: number | 'Other') => unknown;
}

export const CapsuleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0.5px 3px rgba(10, 0, 77, 0.04),
    0px 4px 15px rgba(10, 0, 77, 0.06),
    inset 0px 1px 0px rgba(115, 113, 127, 0.04);
  border-radius: 1000px;
  background: ${colors.white};
  border: none;
  &:active,
  &:hover,
  &:focus,
  &:active *,
  &:focus *,
  &:hover * {
    color: ${colors.primaryGuidingViolet};
    background: ${colors.utilityGuidingVioletLight};
  }
  &,
  & * {
    cursor: pointer;
    transition: color 0.5s, background 0.5s;
    word-break: break-all;
    max-width: 351px;
    border-radius: 1000px;
  }
`;

export const AmountCapsule: React.FC<AmountCapsuleProps> = ({
  amount,
  className,
  onClick
}) => {
  const handleClick = () => onClick(amount);
  return (
    <CapsuleButton
      onClick={handleClick}
      className={className}
    >
      <Text>{amount === 'Other' ? amount : `$${amount}`}</Text>
    </CapsuleButton>
  );
};
