import Axios from 'axios';
import ApiError from '../../../utils/ApiErrorOld';
import { webConfig } from '../../../utils/webConfig';

const RESET_PASSWORD_ENDPOINT = `${webConfig.baseAPIUrl}/access/password/reset`;

export const resetPasswordApi = async (email: string) => {
  const data = {
    email_address: email
  };

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'App-Install-Id': webConfig.appVersion,
      'Device-Name': window.navigator.appName,
      'App-Version': webConfig.appVersion
    }
  };

  const response = await Axios.post(
    RESET_PASSWORD_ENDPOINT,
    data,
    headers
  ).catch((err) => {
    throw new ApiError(
      err.response.data.response.code,
      err.response.data.response.error_message
    );
  });
  const { code, payload, error_message } = response.data.response;
  if (code !== 200 || !payload) {
    throw new ApiError(code, error_message);
  }

  return response.data.Response;
};
