import { createContext, useContext } from 'react';
import { axiosClient } from '../utils/axiosClient';
import { makeApiRequest } from '../utils/makeApiRequest';

export const ClientTokenContext = createContext<ClientAccessToken>({
  accessToken: '',
  expiresIn: 0,
  tokenType: 'Bearer'
});

export const useClientToken = () => useContext(ClientTokenContext);

export interface ClientAccessToken {
  accessToken: string;
  expiresIn: number;
  tokenType: 'Bearer';
}

const clientAccessUrl = '/online-giving/access/client';
export const getClientAccessToken = () => {
  const httpRequest = axiosClient.post(
    clientAccessUrl,
    {
      grantType: 'client_credentials'
    },
    {
      headers: {
        Authorization: '-'
      }
    }
  );
  return makeApiRequest<ClientAccessToken>(httpRequest);
};
