export const isEmailLoginPath = (query: string | null) =>
  query && query !== 'true';

const loginPath = '/?login=true';
export const ROUTES = {
  main: '/',
  activate: '/activate',
  activateEmail: '/activate-email',
  setPassword: '/account/set-password',
  login: loginPath,
  emailLogin: (email: string | undefined) =>
    email ? `/?login=${email}` : loginPath,
  editProfile: '/edit-profile',
  profile: (success?: true) => (success ? '/profile?success=true' : '/profile'),
  history: '/history',
  wallet: '/wallet',
  recurring: '/recurring',
  support: '/support'
};

export const applicationRoutes = [
  ROUTES.main,
  ROUTES.activate,
  ROUTES.editProfile,
  ROUTES.profile(),
  ROUTES.history,
  ROUTES.wallet,
  ROUTES.recurring,
  ROUTES.support
];
