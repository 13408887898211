import styled from 'styled-components';

import { backgroundColors, textColors, tagSizes } from './constants';
import fonts from '../../../../theme/fonts';
import colors from '../../../../theme/colors';
import { sizes } from '../Icon/constants';

export const Tag = styled.span<{
  color: keyof typeof colors;
  size: keyof typeof sizes;
}>`
  display: inline-block;
  font-family: ${fonts.fontfamily};
  color: ${(props) => textColors[props.color]};
  padding: ${(props) => tagSizes[props.size].vertical}px
    ${(props) => tagSizes[props.size].horizontal}px;
  font-size: ${(props) => tagSizes[props.size].text}px;
  min-width: ${(props) => tagSizes[props.size].container}px;
  min-height: ${(props) => tagSizes[props.size].container}px;
  border-radius: ${(props) => tagSizes[props.size].container}px;
  background: ${(props) => backgroundColors[props.color]};
`;
