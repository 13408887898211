import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme';

interface NameAvatarProps {
  name: string;
  className?: string;
}

const StyledDiv = styled.div`
  text-align: center;
  .initials {
    width: 75px;
    height: 75px;
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 3px solid ${colors.white};
    color: ${colors.primaryGuidingViolet};
    background-color: ${colors.utilityGuidingVioletLight};
  }
`;

const NameAvatar: React.FC<NameAvatarProps> = ({ name, className }) => {
  const names = name.split(' ');
  const initials = `${name[0]?.[0]?.toUpperCase() || ''}${names[
    names.length - 1
  ]?.[0]?.toUpperCase()}`;
  return (
    <StyledDiv className={className}>
      <div className="initials">{initials}</div>
    </StyledDiv>
  );
};

export default NameAvatar;
