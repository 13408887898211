import React from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';

const StyledDiv = styled.div`
  text-align: center;
  .dots-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.utilitySeparatorColor};
    border-radius: 100px;
    padding: 8px 16px;
  }
  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: ${colors.utilityInputColor};
  }
  .dash {
    display: inline-block;
    width: 11px;
    height: 1px;
    background-color: ${colors.utilityInputColor};
  }
  .selected {
    background-color: ${colors.primaryGuidingViolet};
  }
`;

export interface StepDotsProps {
  step: 1 | 2 | 3;
  className?: string;
}

const StepDots: React.FC<StepDotsProps> = ({ step, className }) => {
  return (
    <StyledDiv className={className}>
      <div className={'dots-container'}>
        <div className={'dot selected'} />
        <div className={`dash${step === 2 || step === 3 ? ' selected' : ''}`} />
        <div className={`dot${step === 2 || step === 3 ? ' selected' : ''}`} />
        <div className={`dash${step === 3 ? ' selected' : ''}`} />
        <div className={`dot${step === 3 ? ' selected' : ''}`} />
      </div>
    </StyledDiv>
  );
};

export default StepDots;
