import ClevertapReact from 'clevertap-react';
import { detect } from 'detect-browser';
import {
  authenticationSignInSelected,
  detailOnProfile,
  historyOnProfile,
  recurringOnProfile,
  supportOnProfile,
  walletOnProfile
} from './clevertapEvents';
import { logError } from '../../utils/logError';
import { webConfig } from '../../utils/webConfig';
import {
  signInLabel,
  profileDetailLabel,
  historyLabel,
  walletLabel,
  recurringLabel,
  supportLabel
} from '../../components/TopNavBar/constants';
import { Donor } from '../../providers/DonorProvider/interfaces';
import UAParser from 'ua-parser-js';

const { version } = require('../../../package.json');
const browser = detect();

const pathname = window.location.pathname;
const offset = new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)?.[1];
const systemInfo = new UAParser().getResult();

window.version = version;

export const getAppInfo = () => {
  const appVersion = version || 'OGA 4.0+';
  return {
    browserName: systemInfo.browser.name,
    browserVersion: systemInfo.browser.version,
    browserWidth: globalThis.innerWidth,
    browserHeight: globalThis.innerHeight,
    osName: systemInfo.os.name,
    osVersion: systemInfo.os.version,
    os: systemInfo.os.name,
    app: 'OGA',
    appVersion: appVersion,
    browser_name: browser?.name || 'unknown',
    timestamp: `${new Date().toLocaleString()} ${offset}`,
    siteUrl: webConfig.applicationBaseUrl,
    baseAPIUrl: webConfig.baseAPIUrl,
    mainSiteUrl: pathname
  };
};

export function pushEvent(eventName: string, properties: object = {}) {
  try {
    ClevertapReact.event(eventName, properties);
  } catch (error) {
    logError('Error pushing clevertap event', { error });
  }
}

export interface CleverTapDonorProfile {
  name: string;
  email: string;
  donor_id: string | number;
}

export function addProfile(donor: Donor | CleverTapDonorProfile) {
  try {
    ClevertapReact.profile({
      Site: {
        Name: donor.name,
        Email: donor.email,
        Identity: (donor as any).donor_id || (donor as any).id
      }
    });
  } catch (error) {
    logError('Error pushing clevertap profile', { error });
  }
}

export function extendProfile(gifts?: number, recurring?: boolean) {
  try {
    ClevertapReact.profile({
      Site:
        gifts !== undefined
          ? {
              gifts
            }
          : {
              recurring
            }
    });
  } catch (error) {
    logError('Error pushing clevertap extended profile', { error });
  }
}

export function trackLinks(textLabel: string, properties: object) {
  switch (textLabel) {
    case signInLabel:
      pushEvent(authenticationSignInSelected, properties);
      break;
    case profileDetailLabel:
      pushEvent(detailOnProfile, properties);
      break;
    case historyLabel:
      pushEvent(historyOnProfile, properties);
      break;
    case walletLabel:
      pushEvent(walletOnProfile, properties);
      break;
    case recurringLabel:
      pushEvent(recurringOnProfile, properties);
      break;
    case supportLabel:
      pushEvent(supportOnProfile, properties);
      break;
    default:
  }
}

export default {
  trackLinks,
  getAppInfo,
  addProfile,
  pushEvent
};
