import React, { useMemo } from 'react';
import styled from 'styled-components';
import MetaTags from '../../../MetaTags';
import { COMPONENT_VALUES, colors } from '../../../theme';
import GivingHistory from './GivingHistory';

interface DonationHistoryProps {
  className?: string;
}

const StyledDiv = styled.div`
  ${COMPONENT_VALUES.box}
  background-color: ${colors.white};
`;

const DonationHistory: React.FC<DonationHistoryProps> = ({ className }) => {
  const metaTags = useMemo(
    () => ({
      title: 'Donation History | Givelify',
      description: 'History of all donations through Givelify'
    }),
    []
  );
  return (
    <StyledDiv className={className}>
      <MetaTags {...metaTags} />
      <GivingHistory />
    </StyledDiv>
  );
};

export default DonationHistory;
