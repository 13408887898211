import React from 'react';
import styled from 'styled-components';
import CardLabel from './CardLabel';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import colors from '../../../../../theme/colors';
import { Text } from '../../../common/Text/Text';

interface CardInfoProps {
  card: Omit<Wallet, 'id' | 'isPreferred' | 'paymentInstrumentUuid'>;
  className?: string;
}

const StyledDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 26px;
  text-align: right;
  .card-label {
    margin-right: 8px;
  }
`;

const StyledDivNumber = styled.div`
  display: flex;
  align-items: center;
  .dots {
    display: flex;
    margin-top: 3px;
    align-items: center;
    .dot {
      background-color: ${colors['primaryPrimaryContent']};
      width: 4px;
      height: 4px;
      margin-right: 2px;
      border-radius: 50px;
    }
  }
  .last-digits {
    margin-top: 3px;
    margin-left: 3px;
  }
`;

export const CardNumbers: React.FC<CardInfoProps> = ({ card, className }) => {
  const lastFourDigits = card.number.replace(/X/g, '');
  return (
    <StyledDivNumber className={className}>
      <div className={'dots'}>
        <div className={'dot'} />
        <div className={'dot'} />
        <div className={'dot'} />
        <div className={'dot'} />
      </div>
      <Text className={'last-digits'}>{lastFourDigits}</Text>
    </StyledDivNumber>
  );
};

const CardInfo: React.FC<CardInfoProps> = ({ card, className }) => {
  return (
    <StyledDiv className={className}>
      <CardLabel
        cardType={card.type}
        className={'card-label'}
      />{' '}
      <CardNumbers card={card} />
    </StyledDiv>
  );
};

export default CardInfo;
