import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Textfield } from '../Textfield';
import { State, states } from './states';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { Text } from '../../../Donee/components/common/Text/Text';
import { Input } from '../../../Donee/components/common/Input/TextInput/styles';
import { mergeClassNames } from '../../../../utils/mergeClassNames';

const getOptionLabel = (option: State): string => option.state;

const getOptionSelected = (option: State, value: State): boolean =>
  option.stateCode === value.stateCode;

interface StateFieldProps {
  state: string;
  country?: string;
  className?: string;
  stateError: string | undefined;
  handleStateValue: (state: string) => unknown;
  handleState: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

const StateAutocomplete: React.FC<StateFieldProps> = ({
  state,
  className,
  stateError,
  handleStateValue
}) => {
  const initialInput = useMemo(
    () => states.find((x) => x.stateCode === state) || null,
    [state]
  );
  const [input, setInput] = useState<string>(initialInput?.state || '');
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<State | null>(initialInput);

  const handleInput = useCallback(
    (e: React.ChangeEvent<any>, v?: string) => {
      const value = v || e?.target.value || '';
      setInput(value);
      setOpen(true);
      setValue(null);
      handleStateValue('');
    },
    [setInput, setOpen, setValue, handleStateValue]
  );

  const handleSelect = useCallback(
    (_e: React.ChangeEvent<unknown>, v?: State | null) => {
      setValue(v || null);
      if (!v) {
        return;
      }
      setInput(v.state);
      setOpen(false);
      handleStateValue(v.stateCode);
    },
    [setValue, setOpen, setInput, handleStateValue]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Autocomplete
      id={'state'}
      noOptionsText={'No state found'}
      options={states}
      size={'small'}
      value={value}
      onChange={handleSelect}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      className={mergeClassNames('state', className)}
      open={open}
      inputValue={input}
      onBlur={handleClose}
      onInputChange={handleInput}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <div ref={params.InputProps.ref}>
          <Input
            {...params.inputProps}
            value={input}
            placeholder={'State/Province'}
            maxLength={255}
            onChange={handleInput}
          />
          {stateError && (
            <Text
              variant="xSmall"
              color={'red'}
            >
              {stateError}
            </Text>
          )}
        </div>
      )}
    />
  );
};

const StateField: React.FC<StateFieldProps> = ({
  state,
  country,
  className,
  stateError,
  handleState,
  handleStateValue
}) => {
  if (country !== 'US') {
    return (
      <Textfield
        id={'state'}
        placeholder={'State/Province'}
        value={state}
        maxLength={255}
        className={'state'}
        errorText={stateError}
        onChange={handleState}
      />
    );
  }
  return (
    <StateAutocomplete
      state={state}
      stateError={stateError}
      handleState={handleState}
      handleStateValue={handleStateValue}
      className={className}
    />
  );
};

export default StateField;
