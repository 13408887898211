import { RecurringTypes } from '../../../../utils';

export const recurringName = [
  { value: '1', name: 'Daily' },
  { value: '2', name: 'Weekly' },
  { value: '3', name: 'Biweekly (Every two weeks)' },
  { value: '4', name: 'Twice a month (1st and 16th)' },
  { value: '5', name: 'Monthly' }
];

export interface RecurringType<k extends RecurringTypes> {
  value: k;
  label: string;
  message: string;
}

export const recurringMapping: {
  [k in RecurringTypes]: RecurringType<k>;
} = {
  None: {
    value: 'None',
    label: 'Once',
    message: 'Once'
  },
  'FREQ=DAILY': {
    value: 'FREQ=DAILY',
    label: 'Daily',
    message: 'will be processed immediately and every day thereafter.'
  },
  'FREQ=WEEKLY': {
    value: 'FREQ=WEEKLY',
    label: 'Weekly',
    message: 'will be processed immediately and every week thereafter.'
  },
  'FREQ=MONTHLY': {
    value: 'FREQ=MONTHLY',
    label: 'Monthly',
    message: 'will be processed immediately and every month thereafter.'
  },
  'FREQ=WEEKLY;INTERVAL=2': {
    value: 'FREQ=WEEKLY;INTERVAL=2',
    label: 'Biweekly (Every two weeks)',
    message: 'will be processed immediately and every 2 weeks thereafter.'
  },
  'FREQ=MONTHLY;BYMONTHDAY=1,16': {
    value: 'FREQ=MONTHLY;BYMONTHDAY=1,16',
    label: 'Twice a month (1st and 16th)',
    message:
      'will be processed immediately and on the 1st and 16th day of each month thereafter.'
  }
};

export const recurringFrequencies = Object.values(recurringMapping);
