export const icons = {
  givelifyIcon: '/images/png/givelify-icon.png',
  'receipt-triangle': '/images/png/receipt-triangle.png',
  'receipt-triangle-full': '/images/png/receipt-triangle-full.png',
  'add-active': '/images/png/add_active@3x.png',
  'add-filled': '/images/png/add_filled@3x.png',
  'add-photo': '/images/png/add_photo@3x.png',
  'deep-link-hand': '/images/svg/deep-link-hand.svg',
  add: '/images/svg/add.svg?v=1',
  arrow: '/images/png/arrow@3x.png',
  'arrow-active': '/images/png/arrow_active@3x.png',
  'button-add': '/images/png/button_add@3x.png',
  close: '/images/png/close@3x.png',
  'close-circle': '/images/png/close-circle@3x.png',
  trash: '/images/png/trash@3x.png',
  'check-active': '/images/png/check_active@3x.png',
  'confirm-edit': '/images/png/confirm_edit@3x.png',
  'discover-badge': '/images/png/discover_badge@3x.png',
  'discover-logo': '/images/png/discover_logo@3x.png',
  'edit-active': '/images/png/edit_active@3x.png',
  edit: '/images/png/edit@3x.png',
  facebook: '/images/png/facebook@3x.png',
  'facebook-circle': '/images/png/facebook-circle@3x.png',
  'facebook-square': '/images/png/facebook-square@3x.png',
  'facebook-white-circle': '/images/svg/facebook-white-circle.svg',
  forward: '/images/png/forward@3x.png',
  backward: '/images/png/backward@3x.png',
  help: '/images/png/help@3x.png',
  'help-active': '/images/png/help_active@3x.png',
  lock: '/images/png/lock@3x.png',
  'lock-active': '/images/png/lock_active@3x.png',
  mail: '/images/png/mail@3x.png',
  'mail-active': '/images/png/mail_active@3x.png',
  'mastercard-badge': '/images/png/mastercard_badge@3x.png',
  'mastercard-logo': '/images/png/mastercard_logo@3x.png',
  'memo-active': '/images/svg/memo-active.svg?v=3',
  memo: '/images/svg/memo.svg?v=1',
  'name-active': '/images/png/name_active@3x.png',
  name: '/images/png/name@3x.png',
  'phone-active': '/images/png/phone_active@3x.png',
  phone: '/images/png/phone@3x.png',
  'recurring-active': '/images/svg/recurring-active.svg?v=3',
  recurring: '/images/svg/recurring.svg?v=1',
  remove: '/images/svg/remove.svg',
  twitter: '/images/png/twitter@3x.png',
  'visa-badge': '/images/png/visa_badge@3x.png',
  'visa-logo': '/images/png/visa_logo@3x.png?v=1',
  'pay-icon': '/images/svg/pay-icon.svg',
  'facebook-icon': '/images/svg/facebook-icon.svg?v=1',
  'twitter-icon': '/images/svg/twitter-icon.svg?v=1',
  'linkedin-icon': '/images/svg/linkedin-icon.svg?v=1',
  'mail-icon': '/images/svg/mail-icon.svg?v=1',
  'instagram-icon': '/images/svg/instagram-icon.svg?v=1',
  'edit-reccuring': '/images/svg/edit.svg',
  default_card: '/images/svg/default_badge.svg',
  email_icon: '/images/svg/email_icon.svg',
  empty_history_icon: '/images/svg/empty_history_icon.svg',
  cancel_recurrin_icon: '/images/svg/cancel_recurrin_icon.svg',
  'menu-recurring': '/images/svg/menu-recurring.svg',
  empty_recurring_icon: '/images/svg/empty_recurring_icon.svg',
  recurring_icon: '/images/svg/recurring_icon.svg',
  back_arrow: '/images/svg/back_arrow.svg?v=1',
  'history-list-arrow': '/images/svg/history-list-arrow.svg',
  'close-icon': '/images/svg/close-icon.svg',
  'profile-icon': '/images/svg/profile-icon.svg',
  'history-icon': '/images/svg/history-icon.svg',
  'wallet-icon': '/images/svg/wallet-icon.svg',
  'recurring-icon': '/images/svg/recurring-icon.svg',
  'support-icon': '/images/svg/support-icon.svg',
  'logout-icon': '/images/svg/logout-icon.svg',
  'give-icon': '/images/svg/give-icon.svg',
  mastercard_badge: '/images/svg/mastercard_badge.svg',
  visa_badge: '/images/svg/visa_badge.svg',
  discover_badge: '/images/svg/discover_badge.svg',
  american_express_badge: '/images/svg/american_express_badge.svg?v=1',
  diners_club_badge: '/images/svg/diner_club_badge.svg',
  'lock-icon': '/images/svg/lock-icon.svg',
  'help-icon': '/images/svg/help-icon.svg',
  follow_facebook_icon: '/images/svg/follow_facebook_icon.svg',
  'american_express-logo': '/images/svg/american_express_logo.svg',
  'american express-logo': '/images/svg/american_express_logo.svg',
  'diners_club-logo': '/images/svg/diners_club_logo.svg',
  'diners club-logo': '/images/svg/diners_club_logo.svg',
  empty_wallet_icon: '/images/svg/empty_wallet_icon.svg',
  pass_hide: '/images/svg/pass_hide.svg',
  pass_show: '/images/svg/pass_show.svg',
  'history-recurring': '/images/svg/history-recurring.svg',
  downloadAppBarCode: '/images/svg/downloadAppBarCode.svg',
  qrCode: '/images/png/qr-code.png',
  handHoldPhone: '/images/png/hand-hold-phone.png'
};

export const sizes = {
  xSmall: {
    icon: 16,
    container: 16
  },
  small: {
    icon: 20,
    container: 24
  },
  medium: {
    icon: 30,
    container: 36
  },
  large: {
    icon: 54,
    max_width: 54,
    container: 52
  },
  xLarge: {
    icon: 62,
    container: 72
  }
};

export const defaultSize = 'medium';
