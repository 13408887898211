import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Container, Alert, Ul, Li } from '@bootstrap-styled/v4';
import { makeStyles } from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import {
  FormProfile,
  ChangePassword,
  ForgotPasswordContainer,
  SendLinkContainer,
  UserMainContainer,
  ImgAvatarMain,
  UserName
} from './styles';
import colors from '../../components/theme/colors';
import { Text } from '../../components/Donee/components/common/Text/Text';
import { MainSection } from '../../components/Donee/components/common/Layout';
import { H2 } from '../../components/Donee/components/common/H2/styles';
import DonorAvatar from '../../components/LoginModal/components/DonorAvatar';
import { assetUrl, webConfig } from '../../utils/webConfig';
import {
  LinkButton,
  SolidButton
} from '../../components/Donee/components/common/Button';
import Divider from '../../components/Donee/components/common/Divider';
import Modal from '../../components/Donee/components/common/Modal';
import { Donor } from '../../providers/DonorProvider/interfaces';

const useStyles = makeStyles({
  CancelButton: {
    color: colors.primaryGuidingViolet
  }
});

interface DonorProfileDetailsViewProps {
  fullAddress: string;
  picture: string | null | undefined;
  name: string;
  email: string;
  phone: Donor['phone'];
  address: Donor['address'];
  city: string;
  state: string;
  zip: string;
  contributions: number;
  totalGiving: string;
  error: string;
  success: string;
  successChangePass: string;
  changePassModal: boolean;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setSuccess: React.Dispatch<React.SetStateAction<string>>;
  setChangePassModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeMessage: () => () => void;
  changePasswordAction: () => void;
}

const DonorProfileDetailsView: React.FC<DonorProfileDetailsViewProps> = ({
  fullAddress,
  picture,
  name,
  email,
  phone,
  contributions,
  totalGiving,
  error,
  success,
  successChangePass,
  changePassModal,
  setError,
  setSuccess,
  setChangePassModal,
  closeMessage,
  changePasswordAction
}) => {
  const classes = useStyles();
  const push = useNavigate();
  const { doneeId } = useParams();
  return (
    <div className={'mainpart'}>
      <Container>
        <center>
          {error !== '' && (
            <Alert
              className={'common-alert'}
              color={'danger'}
              isOpen
              onClick={() => {
                setError('');
              }}
            >
              <Text
                variant={'xSmall'}
                color={'red1'}
              >
                {error}
              </Text>
            </Alert>
          )}
          {success ? (
            <Alert
              className={'recurring-alert'}
              color={'success'}
              isOpen
              onClick={() => {
                setSuccess('');
              }}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {success}
              </Text>
            </Alert>
          ) : (
            ''
          )}

          {successChangePass && (
            <Alert
              className={'common-alert'}
              color={'success'}
              isOpen
              onClick={closeMessage()}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {successChangePass}
              </Text>
            </Alert>
          )}
        </center>

        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <FormProfile>
                <center>
                  <H2
                    size={'large'}
                    color={'grayscaleBlack'}
                    weight={'medium'}
                  >
                    Profile Details
                  </H2>
                </center>
                <Row className={'justify-content-center'}>
                  <Col
                    md={8}
                    sm={10}
                  >
                    <UserMainContainer className={'mb-30'}>
                      <ImgAvatarMain className={'mb-20'}>
                        <DonorAvatar
                          title={name}
                          src={
                            picture
                              ? assetUrl(picture)
                              : webConfig.doneeAvatarDefault
                          }
                        />
                      </ImgAvatarMain>
                      <UserName className={'mb-20'}>
                        <Text
                          variant={'large'}
                          color={'grey3'}
                          weight={'bold'}
                        >
                          {name}
                        </Text>
                      </UserName>
                      <Ul className={'donation-count'}>
                        <Li>
                          <Text
                            variant={'medium'}
                            color={'grey3'}
                            weight={'bold'}
                          >
                            {contributions > 0 ? contributions : '0'}
                          </Text>
                          <br />
                          <Text
                            variant={'small'}
                            color={'primarySecondaryContent'}
                          >
                            Gifts
                          </Text>
                        </Li>
                        <Li>
                          <Text
                            variant={'medium'}
                            color={'grey3'}
                            weight={'bold'}
                          >
                            {totalGiving}
                          </Text>
                          <br />
                          <Text
                            variant={'small'}
                            color={'primarySecondaryContent'}
                          >
                            Total Giving
                          </Text>
                        </Li>
                      </Ul>
                    </UserMainContainer>
                    <Ul>
                      {email !== '' && (
                        <Li>
                          <Text color={'grayscaleBlack'}>{email}</Text>
                          <br />
                        </Li>
                      )}
                      {phone.number && (
                        <Li>
                          <Text color={'grayscaleBlack'}>{phone.number}</Text>
                          <br />
                        </Li>
                      )}
                      {fullAddress !== '' && (
                        <Li>
                          <Text color={'grayscaleBlack'}>{fullAddress}</Text>
                        </Li>
                      )}
                    </Ul>
                    <ChangePassword>
                      <LinkButton onClick={() => push('/edit-profile')}>
                        <Text
                          variant={'medium'}
                          weight={'medium'}
                          color={'primaryGuidingViolet'}
                        >
                          Edit Profile Details
                        </Text>
                      </LinkButton>
                    </ChangePassword>
                    <Divider className={'mt-20'} />
                    <ChangePassword className={'mt-20'}>
                      <LinkButton
                        onClick={() => {
                          setChangePassModal(true);
                        }}
                      >
                        <Text
                          variant={'medium'}
                          weight={'medium'}
                          color={'primaryGuidingViolet'}
                        >
                          Change password
                        </Text>
                      </LinkButton>
                    </ChangePassword>
                  </Col>
                </Row>
              </FormProfile>
            </Col>
          </Row>
          <Modal
            active={changePassModal}
            onClose={() => setChangePassModal(false)}
          >
            <ForgotPasswordContainer>
              <center>
                <Text
                  className={'popup-title mb-30'}
                  color={'grayscaleBlack'}
                >
                  Do you want to change your password?
                </Text>
              </center>
              <SendLinkContainer>
                <SolidButton
                  fluid
                  size={'small'}
                  width={'default'}
                  raised={'skyblue'}
                  value={'Yes'}
                  color={'skyblue'}
                  onClick={changePasswordAction}
                  children={undefined}
                />
                <br />
                <Link
                  className={classes.CancelButton}
                  onClick={() => setChangePassModal(false)}
                >
                  Cancel
                </Link>
              </SendLinkContainer>
            </ForgotPasswordContainer>
          </Modal>
        </MainSection>
      </Container>
    </div>
  );
};

export default DonorProfileDetailsView;
