import React from 'react';
import colors from '../theme/colors';
import styled from 'styled-components';
import { ReactChildren } from '../../providers/DoneeProvider';

const StyledH2 = styled.h2`
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
`;

const StyledH3 = styled.h3`
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.grayscaleGray600};
`;

export interface HeadingProps extends ReactChildren {
  className?: string;
  variant: 'PrimaryContent' | 'SecondaryContent';
}

export const Heading: React.FC<HeadingProps> = ({
  children,
  variant,
  className
}) => {
  switch (variant) {
    case 'PrimaryContent':
      return <StyledH2 className={className}>{children}</StyledH2>;
    case 'SecondaryContent':
      return <StyledH3 className={className}>{children}</StyledH3>;
  }
};
