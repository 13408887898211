import styled from 'styled-components';
import fonts from '../../../../theme/fonts';
import colors from '../../../../theme/colors';

export const TextArea = styled.textarea`
  outline: 0;
  border: none;
  font-family: ${fonts.fontfamily};
  resize: none;
  width: 100%;
  font-size: ${fonts.fontSizing.medium}px;
  padding: 8px;
  color: ${colors.grayscaleBlack};
  '::placeholder' {
    color: ${colors.lightgrey};
    opacity: 1;
  }

  border: 1px solid #b1bac4;
  background-color: #f9f9f9 !important;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
`;
