import React, { useCallback } from 'react';
import styled from 'styled-components';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import { GetAppModalContent, getMobileAppLink } from '../../../../GetAppModal';
import { appDownloadEvent } from '../../../../../hooks/PushEvents/clevertapEvents';

interface ForceAppDownloadProps {
  giftCount: number;
}

const StyledDiv = styled.div``;

const ForceAppDownload: React.FC<ForceAppDownloadProps> = () => {
  const { pushEvent } = usePushEvents();

  const handleDownload = useCallback(() => {
    pushEvent(appDownloadEvent, {
      location: 'historymodal',
      action: 'clicked',
      name: 'force app download on history page'
    });
    getMobileAppLink();
  }, [pushEvent]);

  return (
    <StyledDiv>
      <GetAppModalContent handleDownload={handleDownload} />
      {/* <H2
        size={'large'}
        color={'grayscaleBlack'}
        weight={'medium'}
        className="page-heading"
      >
        Giving History
      </H2>
      <Text>
        Your giving history will be available on Givelify app. Please download
        it now!
      </Text>
      <DownloadAppButtons className="app-buttons" /> */}
    </StyledDiv>
  );
};

export default ForceAppDownload;
