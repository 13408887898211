import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Secure } from '../CreditCard/Secure';
import styled from 'styled-components';
import { ContentStyles } from '../../utils';
import colors from '../../../theme/colors';
import { ModalRouterProps } from '../../Router';
import { User } from '../../routes';
import { Text } from '../../../Donee/components/common/Text/Text';
import { AddressField } from '../../components/AddressField';
import ZipField from '../../components/ZipField';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { SelectInput } from '../../../theme';
import { countries } from '../../../../utils/statesConstant';
import { usePushEvents } from '../../../../hooks/PushEvents';
import {
  loginPopupAddressScreenNext,
  loginPopupAddressScreenShown
} from '../../../../hooks/PushEvents/clevertapEvents';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .info {
    margin-top: 8px;
    margin-bottom: 18px;
  }
  .street {
    margin-bottom: 8px;
  }
  .zip {
    margin-right: 4px;
    width: 100%;
  }
  .country {
    margin-left: 4px;
    width: 100%;
  }
  .fields-wrapper {
    display: flex;
  }
  .default {
    color: ${colors.lightgrey};
  }
  select {
    border: 1px solid #b1bac4;
  }
`;

const isValid = (street: string, zip: string, country: string): boolean => {
  return street.length > 2 && zip.length > 4 && country.length === 2;
};

export type AddressProps = ModalRouterProps & {
  questionText?: string;
  className?: string;
};

export const Address: React.FC<AddressProps> = ({
  user,
  questionText = 'Billing Address',
  className,
  onNext
}) => {
  const { pushEvent } = usePushEvents();
  const [street, setStreet] = useState<string>(user.address?.street || '');
  const [zip, setZip] = useState<string>(user.address?.zip || '');
  const [city, setCity] = useState<string>(user.address?.city || '');
  const [state, setState] = useState<string>(user.address?.state || '');
  // const [ country, setCountry ] = useState<string>(user.address?.country || '');
  const [countryCode, setCountryCode] = useState<string>(
    user.address?.countryCode || 'US'
  );

  const [streetError, setStreetError] = useState<string | undefined>();
  const [zipError, setZipError] = useState<string | undefined>();
  // const [ countryError ] = useState<string | undefined>();

  // const handleStreet = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const {value} = e.target;
  //   setStreet(value);
  // }, [ setStreet ]);
  const handleZip = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setZip(value);
    },
    [setZip]
  );
  // const handleCountry = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const {value} = e.target;
  //   setCountry(value);
  // }, [ setCountry ]);

  useEffect(() => {
    pushEvent(loginPopupAddressScreenShown);
  }, []);

  const handleSubmit = useCallback(() => {
    const address: User['address'] = {
      street,
      zip,
      city,
      state,
      countryCode
    };
    pushEvent(loginPopupAddressScreenNext);
    onNext({ ...user, address });
  }, [onNext, user, street, zip, countryCode, city, state]);

  return (
    <StyledDiv className={className}>
      <div>
        <Text
          variant={'medium'}
          weight={'extraBold'}
        >
          {questionText}
        </Text>
        <Secure className={'info'}>Your information is secure</Secure>
        <AddressField
          id={'street'}
          placeholder={'Street'}
          street={street}
          className={'street'}
          streetError={streetError}
          setStreetError={setStreetError}
          setStreet={setStreet}
          setZip={setZip}
          setCity={setCity}
          setState={setState}
          setCountryCode={setCountryCode}
        />
        <div className={'fields-wrapper'}>
          <ZipField
            zip={zip}
            className={'zip'}
            zipError={zipError}
            setZip={handleZip}
            setZipError={setZipError}
            // setCity={setCity}
            // setState={setState}
            // setCountry={setCountry}
            // setCountryCode={setCountryCode}
          />
          {/* <Textfield
            id={'country'}
            placeholder={'Country'}
            value={country}
            // onMouseLeave={() => onHover(email)}
            maxLength={20}
            className={'country'}
            errorText={countryError}
            onChange={handleCountry}
          /> */}
          <SelectInput
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            className={countryCode === '' ? 'default' : ''}
          >
            <option value={''}>Select a country...</option>
            {countries.map((t) => (
              <option
                value={t.alpha2}
                key={t.name}
              >
                {t.name}
              </option>
            ))}
          </SelectInput>
        </div>
      </div>
      <div className={'continue'}>
        <GivelifyButton
          onClick={handleSubmit}
          disabled={!isValid(street, zip, countryCode)}
        >
          Next
        </GivelifyButton>
      </div>
    </StyledDiv>
  );
};
