import styled from 'styled-components';
import layout from '../../../components/theme/layout';
import colors from '../../../components/theme/colors';
import ContentLayout from '../../../components/Donee/components/common/Layout/Content';
import { Text } from '../../../components/Donee/components/common/Text/Text';
import SolidButton from '../../../components/Donee/components/common/Button/SolidButton';
import {
  gdsLabelMedium,
  gdsLabelMediumLight
} from '../../../components/theme/fonts';

const { breakpoints } = layout;
const { mobile, mobile_sm } = breakpoints;

export const PreferredCardTitle = {
  ...gdsLabelMedium,
  color: colors.grayscaleBlack
};

export const MakePreferredLink = {
  ...gdsLabelMediumLight,
  color: colors.primaryGuidingViolet
};

export const DeleteLink = {
  ...gdsLabelMediumLight,
  paddingTop: '20px',
  color: colors.primaryGuidingViolet
};

export const CancelLink = {
  paddingTop: '20px',
  color: colors.primaryGuidingViolet
};

export const WalletContainer = styled(ContentLayout)`
  display: inline-block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: auto;
  @media screen and (max-width: ${mobile_sm.max}px) {
    padding: 0;
  }
`;

export const WalletVisa = styled.div`
  background-color: #2d3170;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 6px 12px 0 rgba(0, 64, 128, 0.19);
`;

export const WalletMaster = styled.div`
  background-color: #303134;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 6px 12px 0 rgba(0, 64, 128, 0.19);
`;

export const WalletDiscover = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 6px 12px 0 rgba(0, 64, 128, 0.19);
`;

export const WalletHandlerContainer = styled.div`
  margin-bottom: 10px;
`;

export const WalletMainImage = styled.div``;

export const WalletMainPart = styled.div`
  position: relative;
`;

export const CardIcon = styled.div`
  position: absolute;
  max-height: 15px;
  top: 20px;
  left: 20px;
`;

export const CardNumber = styled.div`
  position: absolute;
  bottom: 20%;
  left: 20px;
`;

export const CardIcon2 = styled.div`
  max-height: 15px;
  float: left;
  width: 70px;
  text-align: left;
`;

export const CardNumber2 = styled.div`
  display: inline-block;
  margin-left: 70px;
  @media screen and (max-width: ${mobile.max}px) {
    margin-left: 20px;
  }
`;

export const PaymentMethodLabelContainer = styled.div`
  padding: 10px 0px;
  position: relative;
  border-bottom: 2px solid #ececec;
  text-align: left;
`;

export const PaymentMethodLabel = styled(Text)``;

export const OffsetDismissButton = styled(SolidButton)`
  margin-top: 15px;
`;

export const PaymentModalActionDivider = styled.div`
  height: 1px;
  margin-bottom: 16px;
  background-color: #e1e3e6;
`;

export const PaymentModalActionTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddNewCardContainer = styled.div`
  text-align: right;
  margin-bottom: 20px;
  margin-top: 20px;
`;
