import { Cart } from '../Capsules/Envelopes';

// const OLD_CART_LOCAL_STORAGE = 'oga-cart'; v4.1.26
const CART_LOCAL_STORAGE = 'new-oga-cart';

const defaultCart = (): Cart => {
  return {
    envelopes: {},
    recurring: 'None',
    wallet: undefined
  };
};

export type LocalStorageCart =
  | {
      [id: string]: Cart | undefined;
    }
  | undefined;

const getCart = (): LocalStorageCart => {
  const result = window.localStorage.getItem(CART_LOCAL_STORAGE);
  const cart = result ? JSON.parse(result) : undefined;
  window.cart = cart;
  return cart;
};

export const deleteCartLocal = () =>
  window.localStorage.removeItem(CART_LOCAL_STORAGE);

export const getCartLocal = (
  doneeId: string | undefined,
  amount: number | undefined
): { cart: Cart; isEmpty: boolean } => {
  const emptyCart = defaultCart();
  if (!doneeId) return { cart: emptyCart, isEmpty: true };
  const local = getCart()?.[doneeId];
  const cart = local || emptyCart;
  const envelopes = Object.keys(cart.envelopes);
  const isEmpty =
    !local ||
    envelopes.length === 0 ||
    (envelopes.length === 1 && cart.envelopes['new'] !== undefined);
  cart.envelopes.new = amount;
  cart.recurring = 'None';
  return { cart, isEmpty };
};

export const saveCartLocal = (doneeId: string | undefined, cart: Cart) => {
  if (!doneeId) return;
  window.localStorage.setItem(
    CART_LOCAL_STORAGE,
    JSON.stringify(
      window.cart ? { ...window.cart, [doneeId]: cart } : { [doneeId]: cart }
    )
  );
};
