export interface LoggerConfig {
  service: string;
  userType: UserType;
  logUnhandledExceptions?: boolean;
}

export enum UserType {
  GIVING_PARTNER = 'giving-partner',
  DONOR = 'donor',
  ADMIN = 'admin'
}

export interface TraceItem {
  line?: number;
  column?: number;
  file?: string;
  function?: string;
}

export type CustomField = string | number | boolean | object | undefined;

export enum LogLevel {
  EMERGENCY = 'EMERGENCY',
  ALERT = 'ALERT',
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  NOTICE = 'NOTICE',
  INFO = 'INFO',
  DEBUG = 'DEBUG'
}

export enum LogEntityType {
  GENERIC = 'generic',
  WEB_UI_EXCEPTION = 'web-ui-exception'
}

export interface LogEntity<T> {
  type: LogEntityType;
  value: T;
}

export interface AddLogRequest<T> {
  givelifyEventId: string;
  service: string;
  logLevel: LogLevel;
  message?: string;
  entity: LogEntity<T>;
}

export interface BrowserInfo {
  browserName?: string;
  browserVersion?: string;
  browserWidth?: number;
  browserHeight?: number;
}

export interface OSInfo {
  osName?: string;
  osVersion?: string;
}

export interface AdditionalInfo {
  browserInfo?: BrowserInfo;
  osInfo?: OSInfo;
  // this will need to be updated with the correct type when functionality if merged
  breadcrumbs?: any[];
  location?: string;
}

export interface ExceptionLog extends AdditionalInfo {
  file: string;
  sourceFileUrl?: string;
  trace: TraceItem[];
  column?: number;
  line?: number;
  message: string;
}

export interface GenericLog extends AdditionalInfo {
  data: { [key: string]: CustomField };
}
