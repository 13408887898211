export default {
  black: '#000000',
  white: '#FFFFFF',
  gray200: '#F0F2F5',
  grey3: '#3a4c63',
  grey4: '#d5d7da',
  grey5: '#EDEFF2',
  grey6: '#F5F7FA',
  lightgrey: '#aaaaaa',
  // please use utilityInputColor over this
  disable: '#d0cfd9',
  red: '#FF3B5B',
  red1: '#a9445a',
  darkBlue: '#3b5998',
  darkBluetxt: '#2a2f46',
  lightBlue: '#00CDFF',
  lightBrown: '#fff1e8',
  primaryBlue: '#0075F7',
  darkOrange: '#FF8D00',
  lightOrange: '#FFBE00',
  primaryOrange: '#FFA700',
  transparent: 'transparent',
  brandDarkGray: '#3A4C63',
  primaryGenerosityOrange: '#f85520',
  buttonsHoverOrange: '#E34612',
  buttonsPressedOrange: '#D93B07',
  primaryGuidingViolet: '#715aff',
  buttonsHoverViolet: '#5F48F0',
  grayscaleBlack: '#282636',
  grayscaleGray700: '#555266',
  grayscaleGray600: '#73717f',
  grayscaleGray500: '#94939e',
  grayscaleGray200: '#d0cfd9',
  grayscaleGray100: '#eeedf3',
  grayscaleGray50: '#fafafc',
  grayscaleWhite: '#ffffff',
  warmColorOrange75: '#fdddc7',
  utilitySuccess: '#00ac31',
  utilityWarning: '#cc7e00',
  utilityError: '#e42727',
  primaryPrimaryContent: '#282636',
  primarySecondaryContent: '#73717f',
  utilitySeparatorColor: '#eeedf3',
  utilityBackgroundLightColor: '#fafafc',
  utilityTabColor: '#95939e',
  utilityInputColor: '#d0cfd9',
  utilityGuidingVioletLight: '#f4f2ff',
  utilityGuidingVioletDark: '#ece8ff',
  externalFacebookColor: '#1877f2',
  utilitySuccessLightColor: '#ebf9ef',
  utilityWarningLightColor: '#fef4ec',
  utilityErrorLightColor: '#fdeeee',
  externalAppleColor: '#06070a',
  utilityBackgroundDefaultColor: '#f3f3f7',
  grayscaleGray75: '#f3f3f7',
  grayscaleGray400: '#a2a1ad',
  grayscaleGray300: '#bcbac4'
};
