import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ProfileDropdownContent from './ProfileDropdownContent';
import DonorAvatar from '../LoginModal/components/DonorAvatar';
import { Text } from '../Donee/components/common/Text/Text';
import { RightArrowIcon } from '../icons';
import { Donor } from '../../providers/DonorProvider/interfaces';

const StyledButton = styled.button<{ open: boolean }>`
  border: none;
  background-color: inherit;
  .dropdown-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    .text {
      cursor: pointer;
    }
    .profile-pic {
      margin-right: 10px;
      width: 36px;
      height: 36px;
      border-radius: 360px;
    }
    .icon {
      margin-left: 10px;
      margin-top: -2px;
      transition: transform 0.3s ease-in-out;
      transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
    }
  }
`;

interface ProfileDropdownProps {
  donor: Donor;
  onLogout: () => unknown;
  className?: string;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  donor,
  onLogout,
  className
}) => {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = useCallback(() => {
    setOpen((o) => !o);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const donorName = donor.name;

  return (
    <>
      <StyledButton
        className={className}
        open={open}
        ref={ref}
      >
        <div
          className={'dropdown-button'}
          onClick={toggleOpen}
        >
          <DonorAvatar
            className={'profile-pic'}
            src={donor.pictureUrl}
            title={donorName}
          />
          <Text
            variant={'small'}
            weight={'bold'}
            className={'text'}
          >
            {donorName}
          </Text>
          <RightArrowIcon className={'icon'} />
        </div>
        <ProfileDropdownContent
          onLogout={onLogout}
          open={open}
          onClose={onClose}
          anchor={ref.current}
        />
      </StyledButton>
    </>
  );
};

export default ProfileDropdown;
