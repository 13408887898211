import React, { useState } from 'react';
import styled from 'styled-components';
import { DonationAddonProps, getElementHeight } from '../../utils';
import colors from '../../../theme/colors';

export interface CardOpenProps {
  open: boolean;
  setOpen: (open: boolean) => unknown;
}

export type CardBottomComponentProps = DonationAddonProps & CardOpenProps;

type CursorType = 'pointer' | 'inherit';

interface CardRowProps<T> {
  initialState?: boolean;
  LeftSideComponent: React.FC<CardOpenProps>;
  RightSideComponent: React.FC<CardOpenProps>;
  BottomComponent?: {
    SecondRightComment: React.FC<T & CardOpenProps> | undefined;
    Component: React.FC<T & CardOpenProps>;
    props: T;
  };
  bottomBorder?: boolean;
  cursorType?: CursorType;
}

const hideCSS = `
  height: 0;
  visibility: hidden;
`;
const visibleCSS = (height: string) => `
  height: ${height};
  visibility: visible;
`;
const secondRightMargin = 'margin-left: 8px;margin-right: 16px;';

const StyledDiv = styled.div<{
  open: boolean;
  bottomElementHeight: string;
  bottomBorder: boolean;
  isSecondRight: boolean;
  cursorType: CursorType;
}>`
  border-bottom: ${({ bottomBorder }) =>
    bottomBorder ? `1px solid ${colors.utilitySeparatorColor}` : 'none'};
  .row {
    cursor: ${({ cursorType }) => cursorType};
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .left-side {
      word-break: break-all;
      margin-right: 8px;
    }
    .right-side {
      display: flex;
    }
    .second-right-side {
      ${({ isSecondRight }) => (isSecondRight ? secondRightMargin : '')}
    }
    div {
      cursor: ${({ cursorType }) => cursorType};
    }
  }
  .bottom {
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    ${({ open, bottomElementHeight }) =>
      open ? visibleCSS(bottomElementHeight) : hideCSS}
  }
`;

const CardRow = <T,>({
  LeftSideComponent,
  RightSideComponent,
  initialState,
  bottomBorder = true,
  cursorType = 'inherit',
  BottomComponent
}: CardRowProps<T>) => {
  const [open, setOpen] = useState<boolean>(initialState || false);
  const handleClick = () => setOpen((o) => !o);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const height = `${getElementHeight(elementRef.current, parentRef.current)}px`;
  return (
    <StyledDiv
      ref={parentRef}
      open={open}
      bottomElementHeight={height}
      cursorType={cursorType}
      bottomBorder={bottomBorder}
      isSecondRight={
        BottomComponent && BottomComponent.SecondRightComment ? true : false
      }
    >
      <div
        className={'row'}
        onClick={BottomComponent ? handleClick : undefined}
      >
        <div className={'left-side'}>
          <LeftSideComponent
            open={open}
            setOpen={setOpen}
          />
        </div>
        <div className={'right-side'}>
          <div className={'second-right-side'}>
            {BottomComponent && BottomComponent.SecondRightComment && (
              <BottomComponent.SecondRightComment
                open={open}
                setOpen={setOpen}
                {...BottomComponent.props}
              />
            )}
          </div>
          <RightSideComponent
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
      {BottomComponent && (
        <div
          ref={elementRef}
          className={'bottom'}
        >
          <BottomComponent.Component
            open={open}
            setOpen={setOpen}
            {...BottomComponent.props}
          />
        </div>
      )}
    </StyledDiv>
  );
};

export default CardRow;
