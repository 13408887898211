import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import DonorAvatar from './DonorAvatar';
import { Text } from '../../Donee/components/common/Text/Text';
import { useDonor } from '../../../providers/DonorProvider/useDonor';
import colors from '../../theme/colors';
import IconButton from '../../Donee/components/common/Button/IconButton';
import { useLoginToastContext } from '../../../providers/LoginToastProvider';

const sideInAnimation = keyframes`
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;

const StyledDiv = styled.div`
  z-index: 999;
  top: 8px;
  left: 50%;
  position: fixed;
  min-width: 280px;
  text-align: center;
  transform: translateX(-50%);
  animation: ${sideInAnimation} 0.3s ease-in;
  .toast {
    display: inline-flex;
    align-items: center;
    padding: 6px;
    border-radius: 90px;
    margin: auto;
    background-color: ${colors.white};
    box-shadow: 0px 1px 6px rgba(10, 0, 77, 0.04),
      0px 5.5px 30px rgba(10, 0, 77, 0.05);
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      margin-right: 12px;
    }
    .inline {
      display: inline-block;
    }
    .name {
      padding-left: 4px;
    }
    .close-icon {
      cursor: pointer;
      margin-left: 21px;
      margin-right: 15px;
    }
  }
`;

export const LoginToast: React.FC = () => {
  const { donor } = useDonor() || {};
  const { showLoginToast, handleCloseLoginToast } = useLoginToastContext();

  useEffect(() => {
    if (showLoginToast) {
      setTimeout(handleCloseLoginToast, 5000);
    }
  }, [showLoginToast, handleCloseLoginToast]);

  if (!showLoginToast) return null;

  return (
    <StyledDiv>
      <div className={'toast'}>
        <DonorAvatar
          src={donor?.pictureUrl}
          className={'avatar'}
        />
        <div>
          <Text
            weight={'extraBold'}
            color={'primarySecondaryContent'}
            className={'inline'}
          >
            Welcome,
          </Text>
          <Text
            weight={'extraBold'}
            className={'inline name'}
          >
            {donor?.name}
          </Text>
        </div>
        <IconButton
          name={'close-icon'}
          size={'xSmall'}
          width={'link'}
          raised={'none'}
          color={'transparent'}
          onClick={handleCloseLoginToast}
          className={'close-icon'}
          iconProps={undefined}
        />
      </div>
    </StyledDiv>
  );
};
