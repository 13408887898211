import parse from 'html-react-parser';

export const numberWithCommas = (value: string) => {
  if (value !== '') {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const currencySign = () => '$';

export const amountFormat = (amount: number | string) =>
  currencySign() + amount;

export const decodeTextContent = (text: string | undefined) => {
  if (!text) return text;
  try {
    const decodeData = parse(decodeURIComponent(text));
    if (
      decodeData &&
      (typeof decodeData === 'string' || decodeData instanceof String)
    ) {
      return decodeData;
    }
  } catch (e) {
    return text;
  }
  return undefined;
};

export const amountNumberFormat = (amount?: number | string): string => {
  if (!amount) return '0';
  if (typeof amount === 'string') {
    amount = parseInt(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(amount);
};
