import styled from 'styled-components';
import { SolidButton } from '../../Donee/components/common';
import { layout } from '../../theme';
import React from 'react';

const { breakpoints } = layout;
const { mobile, desktop } = breakpoints;

export const ContentContainer = styled.div`
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: ${mobile.max}px) {
    padding-left: 18px;
    padding-right: 18px;
  }
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: inherit;
  min-width: inherit;
  height: inherit;
  min-height: inherit;
  margin: 0 auto;
  max-width: ${desktop.min}px;
`;

const ContentLayout: React.FC<any> = ({ children, ...props }) => (
  <ContentContainer {...props}>
    <Content>{children}</Content>
  </ContentContainer>
);

export const ForgotPasswordContainer = styled(ContentLayout)`
  text-align: center;
  margin-top: 24px;
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

export const ForgotHeader = styled.div`
  font-size: 18px;
`;

export const ForgotBody = styled.div`
  text-align: left;
`;

export const FormItem = styled.div`
  padding: 32px 0 50px 0;
`;

export const SendLinkContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
`;

export const SendButton = styled(SolidButton).attrs({
  fluid: true,
  color: 'skyblue',
  size: 'medium',
  raised: 'skyblue',
  weight: 'regular'
})``;

export const ForgotPasswordMessageError = styled.div`
  text-align: left;
  color: #a9445a;
`;

export const MessageError = styled.div`
  background-color: #f2dede;
  padding: 16px;
  color: #a9445a;
`;

export const ForgotPasswordMessageSuccess = styled.div`
  text-align: left;
  color: #3c763d;
`;

export const MessageSuccess = styled.div`
  background-color: #dff0d8;
  padding: 16px;
  color: #3c763d;
`;

export const BtnVisible = styled(SolidButton).attrs({
  fluid: true,
  color: 'disable',
  size: 'medium',
  raised: 'disable',
  weight: 'regular'
})``;
