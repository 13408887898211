import React, { useEffect, useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';
import styled from 'styled-components';
import colors from '../../../../theme/colors';
import { Text } from '../../common/Text/Text';
import SparkleLoop from '../../../../HeartLoading/animations/SparkleLoop.json';
import ConfirmCheck from '../../../../HeartLoading/animations/SparkleLoop.json';

interface DonationAmountAnimationProps {
  amount: number | undefined;
}

const Celebration = styled.div`
  position: relative;
  .animations-container {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .amount-container {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translateX(-50%);
    .dollar-sign {
      font-size: 21px;
      margin-right: 2px;
      color: ${colors.primarySecondaryContent};
    }
    .amount {
      font-size: 34px;
    }
  }
`;

const numberWithCommas = (numOrString?: string | number) => {
  if (!numOrString) {
    return numOrString;
  }
  let value = numOrString.toString();
  if (value.includes('.')) {
    value = Number(numOrString).toFixed(2);
  }
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const DonationAmountAnimation: React.FC<DonationAmountAnimationProps> = ({
  amount
}) => {
  const [showAmount, setShowAmount] = useState<boolean>(false);
  const [lottieProps, setLottieProps] = useState<LottieProps>();

  useEffect(() => {
    setLottieProps({
      isClickToPauseDisabled: true,
      ariaRole: 'figure',
      options: {
        animationData: ConfirmCheck,
        loop: true,
        autoplay: true
      },
      eventListeners: [
        {
          eventName: 'complete',
          callback: startSparkleLoopAnimation
        }
      ]
    });
    window.setTimeout(() => {
      setShowAmount(true);
    }, 800);
  }, []);

  const startSparkleLoopAnimation = () => {
    setLottieProps({
      isClickToPauseDisabled: true,
      ariaRole: 'figure',
      options: {
        animationData: SparkleLoop,
        loop: true,
        autoplay: true
      }
    });
  };

  return (
    <Celebration className={`celebration${''}`}>
      <div className={'animations-container'}>
        {lottieProps && <Lottie {...lottieProps} />}
      </div>
      {amount && showAmount && (
        <div className={'amount-container'}>
          <Text
            variant={'xlarge'}
            color={'grey3'}
            weight={'bold'}
            className={'amount'}
          >
            <sup className={'dollar-sign'}>$</sup>
            {numberWithCommas(amount)}
          </Text>
        </div>
      )}
    </Celebration>
  );
};

export default DonationAmountAnimation;
