import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Email from './Email';
import { DISABLE_CAPTCHA, getLoginTypes, LoginTypesResponse } from './api';
import { ContentStyles } from '../../utils';
import { ModalRouterProps } from '../../Router';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { GivingApiResponse } from '../../../../utils/makeApiRequest';
import { useApiRequest } from '../../../../hooks/useGivingApiRequest';
import {
  emailOnMainAuth,
  loginPopup,
  loginPopupLoginScreen,
  loginPopupSignupScreen
} from '../../../../hooks/PushEvents/clevertapEvents';
import { Text } from '../../../Donee/components/common/Text/Text';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { isLoading } from '../../../../hooks/useRequestState';
import { FacebookButton } from '../../../FacebookButton';
import { Divider } from '../../../Donee/components/common/Divider/Divider';
import ReCaptchaV3, {
  requestRecaptchaV3Token
} from '../../../Wrappers/ReCaptchaV3';

const StyledLogin = styled(ContentStyles)`
  .text-left {
    text-align: left;
    margin-bottom: 8px;
  }
  .divider {
    margin-top: 24px;
  }
  .facebook-button {
    border: none;
    margin-bottom: 12px;
  }
  .join-email,
  .join-facebook {
    margin-bottom: 24px;
  }
  .join-email {
    margin-top: 20px;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LandingProps extends ModalRouterProps {}

export const Landing: React.FC<LandingProps> = ({ user, onNext }) => {
  const { pushEvent } = usePushEvents();
  const [loginTypes, makeLoginTypesRequest] =
    useApiRequest<GivingApiResponse<LoginTypesResponse>>();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [emailError, setEmailError] = useState<string>('');
  const handleSubmit = () => {
    const email = emailInputRef.current?.value;
    if (!email) {
      setEmailError('Please enter a valid email address');
      return;
    }
    emailError !== '' && setEmailError('');
    requestRecaptchaV3Token(
      (token: string | undefined) => {
        makeLoginTypesRequest(getLoginTypes(email, token));
      },
      undefined,
      'LoginType'
    );
    pushEvent(emailOnMainAuth);
  };

  useEffect(() => {
    pushEvent(loginPopup);
  }, []);

  useEffect(() => {
    if (loginTypes.type === 'REQUEST_ERROR') {
      setEmailError(loginTypes.error.message);
    } else if (loginTypes.type === 'REQUEST_SUCCESS') {
      const email = emailInputRef.current?.value;
      const isUserNotFound =
        loginTypes.data.response.payload.loginTypes.length === 0;
      pushEvent(
        isUserNotFound ? loginPopupSignupScreen : loginPopupLoginScreen,
        { email }
      );
      onNext({ ...user, email }, isUserNotFound ? 'NAME' : 'LOGIN');
    }
  }, [loginTypes.type, setEmailError]);

  return (
    <StyledLogin>
      <div>
        <Text
          variant={'large'}
          weight={'extraBold'}
          className={'join-facebook'}
        >
          Join or Sign in with Facebook
        </Text>
        <FacebookButton className={'facebook-button'} />
        <Text
          variant={'xSmall'}
          weight={'light'}
          color={'grayscaleGray600'}
        >
          Fast and easy. No password required.
          <br />
          No posts on Facebook without permission.
        </Text>
        <Divider
          text={'Or'}
          className={'divider'}
        />
        <Text
          variant={'large'}
          weight={'extraBold'}
          className={'join-email'}
        >
          Join or Sign in with your email
        </Text>
        <Email
          defaultValue={user.email}
          inputRef={emailInputRef}
          emailError={emailError}
        />
      </div>
      {!DISABLE_CAPTCHA && <ReCaptchaV3 hideText={true} />}
      <div className={'continue'}>
        <GivelifyButton
          onClick={handleSubmit}
          isLoading={isLoading(loginTypes)}
        >
          Continue
        </GivelifyButton>
      </div>
    </StyledLogin>
  );
};
