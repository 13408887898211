import React from 'react';
import ClevertapReact from 'clevertap-react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { Logger } from './utils/logger';
import { webConfig } from './utils/webConfig';
import TagManager from 'react-gtm-module';
import { logInfo } from './utils/logError';
import { ReportHandler } from 'web-vitals';
import ErrorBoundary from './utils/Errors';
import { serviceWorkerUnregister } from './serviceWorkerRegistration';

// eslint-disable-next-line
console.log(
  '%cStop!',
  'color: #ff0000; font-size: 50px; font-weight: bold; text-shadow: 1px 1px 1px #000;'
);
// eslint-disable-next-line
console.log(
  '%cThis is a browser feature intended for developers. If someone told you to copy-paste something here to enable a "Hack", it is a scam and will give them access to your account.',
  'font-size: 20px; font-weight: bold; text-shadow: 0 0 0 #000;'
);

if (webConfig.isProduction) {
  Logger.configure({
    service: 'online-giving',
    logUnhandledExceptions: true,
    userType: Logger.UserType.DONOR
  });
}

if (!webConfig.isPrerenderBot) {
  ClevertapReact.initialize(webConfig.clevertapAccountId);
}

webConfig.googleTagManagerId &&
  TagManager.initialize({
    gtmId: webConfig.googleTagManagerId,
    dataLayer: {
      dataLayerName: 'Online-Giving'
    }
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Logger.BasicErrorBoundary fallback={<ErrorBoundary />}>
      <App />
    </Logger.BasicErrorBoundary>
  </React.StrictMode>
);

const reportHandler: ReportHandler = (metric) => {
  logInfo('Logging Metric', metric);
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(reportHandler);

serviceWorkerUnregister();
