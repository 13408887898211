import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { assetUrl } from '../../utils/webConfig';
import layout from '../../components/theme/layout';
import ContentLayout from '../../components/Donee/components/common/Layout/Content';
import SolidButton from '../../components/Donee/components/common/Button/SolidButton';

const { breakpoints } = layout;
const { desktop, tablet, laptop, mobile, mobile_sm } = breakpoints;

export const WalletSectionMain = styled.div`
  padding: 0 60px;
  text-align: center;
  @media screen and (max-width: ${mobile_sm.max}px) {
    padding: 0;
  }
`;

export const AddNewCardContainer = styled(ContentLayout)`
  display: block;
  text-align: center;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
  @media screen and (min-width: ${tablet.min}px) and (max-width: ${tablet.max}px) {
    width: 75%;
  }
`;

export const AddNewCardButton = styled(SolidButton).attrs({
  color: 'skyblue',
  raised: 'skyblue',
  size: 'medium',
  width: 'big'
});

export const PaymentLink = styled(Link)`
  text-decoration: none;
`;

export const PageLoading = styled.div`
  position: relative;
  min-height: 200px;
  :after {
    background: url(${assetUrl('/images/history-loading.gif?v=1')}) no-repeat
      scroll center 70px #fff;
    position: absolute;
    width: 100%;
    min-height: 200px;
    content: '';
    z-index: 1;
    top: 0px;
    height: 100%;
    left: 0;
  }
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;
