import React, { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import ButtonsWrapper, {
  ButtonsWrapperProps,
  modalMarginValue,
  modalMarginValueMobile
} from './ButtonsWrapper';
import {
  LoginPages,
  LOGIN_ROUTES,
  User,
  LOGIN_ROUTES_SKIP_SIGNUP,
  SIGNUP_ROUTES
} from './routes';
import Router from './Router';
import colors from '../theme/colors';
import { modalBreakPoint } from '../Donee/components/common/Modal/styles';
import { Heading } from '../Heading';
import { useQueryString } from '../../hooks/useQueryString';
import { isEmailLoginPath } from '../../utils/routes';

interface TopHeadingProps {
  donation: string;
  doneeName: string;
}

const TopHeading: FC<TopHeadingProps> = memo(({ donation, doneeName }) => {
  return (
    <div className={'heading'}>
      <Heading variant={'PrimaryContent'}>Give ${donation} to</Heading>
      <Heading variant={'SecondaryContent'}>{doneeName}</Heading>
    </div>
  );
});

TopHeading.displayName = 'TopHeading';

export const SignupModalContentHeight = '468px';

const ModalStyles = styled.div`
  .heading {
    padding-bottom: 32px;
    padding-left: ${modalMarginValue}px;
    padding-right: ${modalMarginValue}px;
    border-bottom: 1px solid ${colors.utilitySeparatorColor};
    h2 {
      margin-top: 24px;
      margin-bottom: 0;
    }
    h3 {
      margin-top: 0px;
      margin-bottom: 7px;
    }
  }
  .content {
    padding-top: 32px;
    padding-bottom: 0px;
    padding-left: ${modalMarginValue}px;
    padding-right: ${modalMarginValue}px;
    height: ${SignupModalContentHeight};
    overflow: auto;
  }
  @media only screen and (max-width: ${modalBreakPoint}px) {
    .heading {
      padding-bottom: 24px;
      padding-left: ${modalMarginValueMobile}px;
      padding-right: ${modalMarginValueMobile}px;
      h2 {
        margin-top: 0;
      }
      h3 {
        margin-bottom: 0;
      }
    }
    .content {
      padding-top: 24px;
      padding-left: ${modalMarginValueMobile}px;
      padding-right: ${modalMarginValueMobile}px;
    }
  }
`;

export interface MainWrapperProps extends Omit<ButtonsWrapperProps, 'onBack'> {
  donation: string | undefined;
  skipsCreditCard: boolean;
}

const getNextPath = (page: LoginPages, user: User) => {
  const newPage: LoginPages =
    // skip city and state step if they already exist
    page === 'ADDRESS'
      ? user.skipsSignup
        ? page
        : user.address?.city && user.address.state
        ? 'ADDRESS_COMPLETE'
        : page
      : page;

  if (user.skipsSignup) {
    return LOGIN_ROUTES_SKIP_SIGNUP[newPage].next;
  }
  if (user.skipsCreditCard) {
    return SIGNUP_ROUTES[newPage].next;
  }
  return LOGIN_ROUTES[newPage].next;
};

const getPreviousPath = (
  page: LoginPages,
  skipsSignup?: boolean,
  skipsCreditCard?: boolean
) =>
  (skipsSignup
    ? LOGIN_ROUTES_SKIP_SIGNUP
    : skipsCreditCard
    ? SIGNUP_ROUTES
    : LOGIN_ROUTES)[page].previous;

export const MainWrapper: React.FC<MainWrapperProps> = ({
  donation,
  skipsCreditCard,
  doneeName,
  doneeUrl,
  onClose
}) => {
  const query = useQueryString();
  const [page, setPage] = useState<LoginPages>(
    isEmailLoginPath(query.get('login')) ? 'LOGIN' : 'LANDING'
  );
  const [user, setUser] = useState<User>({
    skipsCreditCard
  });

  const handleNext = useCallback(
    (user: User, nextPage?: LoginPages) => {
      const next = nextPage || getNextPath(page, user);
      if (!next) {
        return;
      }
      setUser(user);
      setPage(next);
    },
    [page, setPage]
  );
  const handlePrevious = useCallback(() => {
    const previous = getPreviousPath(
      page,
      user.skipsSignup,
      user.skipsCreditCard
    );
    previous && setPage(previous);
  }, [page, user.skipsSignup, user.skipsCreditCard, setPage]);

  return (
    <ModalStyles>
      <ButtonsWrapper
        doneeUrl={donation ? doneeUrl : undefined}
        doneeName={doneeName}
        onBack={LOGIN_ROUTES[page].previous ? handlePrevious : undefined}
        onClose={onClose}
      />
      {donation && (
        <TopHeading
          donation={donation}
          doneeName={doneeName}
        />
      )}
      <div className={'content'}>
        <Router
          user={user}
          page={page}
          onNext={handleNext}
          closeModal={onClose}
        />
      </div>
    </ModalStyles>
  );
};
