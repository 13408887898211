import React, {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useState
} from 'react';
import { Link, makeStyles } from '@material-ui/core';
import { emailPattern } from '../../Donee/components/common/constants';
import colors from '../../theme/colors';
import { resetPasswordApi } from './apiOld';
import {
  ForgotBody,
  ForgotPasswordContainer,
  FormItem,
  SendLinkContainer
} from './styles';
import { Text } from '../../Donee/components/common/Text/Text';
import TextInput from '../../Donee/components/common/Input/TextInput';
import SolidButton from '../../Donee/components/common/Button/SolidButton';
import GivelifyModal from '../../Donee/components/common/Modal/GivelifyModal';

const center: CSSProperties = {
  textAlign: 'center'
};

const CancelLink = {
  paddingTop: '20px',
  color: colors.primaryGuidingViolet
};

const useStyles = makeStyles({
  CancelLink
});

export interface ForgotPasswordModalProps {
  email?: string;
  open: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  open,
  email = '',
  onClose,
  onSuccess
}) => {
  const { CancelLink } = useStyles();
  const [forgetEmail, setForgetEmail] = useState<string>(email);
  const [forgetError, setForgetError] = useState<string>('');

  const resetPassword = useCallback(() => {
    if (!forgetEmail || !emailPattern.test(forgetEmail)) {
      return;
    }
    resetPasswordApi(forgetEmail)
      .then(() => onSuccess())
      .catch((err) => setForgetError(err.message));
  }, [forgetEmail, onSuccess, setForgetError]);

  const onHover = useCallback(
    (email: string) => () => {
      if (emailPattern.test(email)) {
        setForgetError('');
      } else {
        setForgetError('This field must be an email');
      }
    },
    [setForgetError]
  );

  const handleEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setForgetEmail(e.target.value),
    [setForgetEmail]
  );
  return (
    <GivelifyModal
      open={open}
      onClose={onClose}
    >
      <ForgotPasswordContainer>
        <div style={center}>
          <Text
            className={'popup-title mb-30'}
            color={'grayscaleBlack'}
          >
            Recover your password
          </Text>
        </div>
        <ForgotBody>
          <Text
            className={'mb-10 dib'}
            variant={'small'}
          >
            Enter the Email address you used to sign up
          </Text>
          <FormItem onSubmit={resetPassword}>
            <TextInput
              placeholder={'Email'}
              value={forgetEmail}
              type={'email'}
              onChange={handleEmail}
              onBlur={onHover(forgetEmail)}
            />
            <Text
              variant={'xSmall'}
              color={'red'}
            >
              {forgetError}
            </Text>
            <Text
              className={'mt-10 dib'}
              variant={'small'}
            >
              We will send you instruction to reset your password
            </Text>
          </FormItem>
        </ForgotBody>

        <SendLinkContainer>
          <SolidButton
            fluid={true}
            size={'small'}
            width={'default'}
            raised={forgetEmail ? 'skyblue' : 'none'}
            color={forgetEmail ? 'skyblue' : 'disable'}
            value={'Reset Password'}
            onClick={() => resetPassword()}
            disabled={!forgetEmail}
          >
            Reset Password
          </SolidButton>
        </SendLinkContainer>
      </ForgotPasswordContainer>
    </GivelifyModal>
  );
};
