export const scrollbarStyles = `
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }
`;
