export interface ErrorResponse extends Error {
  isAxiosError: boolean;
  response: {
    status: number;
    data?: {
      message?: string;
    };
  };
}

interface ErrorProps {
  status: number;
  message: string;
  isAxiosError?: boolean;
  name?: string
}

export const setErrorResponse = ({
  status,
  message,
  isAxiosError = true,
  name = 'Custom Created Error - OGA 2.0'
}: ErrorProps): ErrorResponse => {
  return {
    name,
    message,
    isAxiosError,
    response: {
      status,
      data: {
        message
      }
    }
  }
}
