import axios from 'axios';
import { logInfo } from '../../utils/logError';
import { FacebookLoginResponse } from './api';

export const getFacebookProfilePicture = async (
  facebookUser: FacebookLoginResponse
): Promise<string | undefined> => {
  const fallbackProfileUrl = facebookUser?.picture?.data?.url || undefined;

  try {
    const response = await axios.get(
      `https://graph.facebook.com/v5.0/${
        facebookUser.userID || facebookUser.id
      }/picture?type=large&redirect=0`
    );
    const facebookPicture = response.data?.data?.url;
    logInfo('Successfully loaded facebook profile picture (facebookPicture).', {
      facebookUser,
      facebookPicture
    });
    return facebookPicture || fallbackProfileUrl;
  } catch (e) {
    logInfo('Unable to load facebook profile picture', {
      facebookUser,
      id: facebookUser.id,
      userID: facebookUser.userID,
      fallbackProfileUrl,
      exception: e
    });
    return fallbackProfileUrl;
  }
};
