const applicationBaseUrl =
  process.env.REACT_APP_BASE_APPLICATION_URL ||
  'https://www.givelify.com/donate';

const isStorybook = process.env.STORYBOOK;
const nodeEnvironment = process.env.NODE_ENV;

const getApplicationBaseUrl = () => {
  if (isStorybook) {
    return nodeEnvironment === 'production' ? '/online-giving-app' : undefined;
  }
  return applicationBaseUrl;
};

export const getNewDoneePath = (path: string) => {
  const d = sessionStorage.getItem('doneeIdStr');
  const d2 = sessionStorage.getItem('doneeId');
  const doneeIdStr = d && JSON.parse(d);
  const doneeId = d2 && JSON.parse(d2);

  if (doneeId && doneeIdStr) {
    const oldPartialDoneeIdUrl = `/${doneeId}`;
    const isOldUrl = path.includes(oldPartialDoneeIdUrl);
    if (isOldUrl) {
      const newPartialDoneeIdUrl = `/${doneeIdStr}`;
      return path.replace(oldPartialDoneeIdUrl, newPartialDoneeIdUrl);
    }
  }

  return path;
};

export const assetUrl = (path: string) => {
  if (path?.substring(0, 4) !== 'http') {
    return `${getApplicationBaseUrl() || ''}${path || ''}`;
  }
  return path;
};

const baseAPIUrl = process.env.REACT_APP_BASE_API_URL;
if (!baseAPIUrl) throw new Error('REACT_APP_BASE_API_URL is not set.');

const baseLoggerAPIUrl = process.env.REACT_APP_LOGGER_BASE_URL;
if (!baseLoggerAPIUrl) throw new Error('REACT_APP_LOGGER_BASE_URL is not set.');

export const webConfig = {
  appVersion: process.env.REACT_APP_VERSION || '2.0',
  baseAPIUrl,
  baseLoggerAPIUrl,
  doneeIdHashPrefix: process.env.REACT_APP_DONEE_HASH_PREFIX || '806b14214940f',
  applicationBaseUrl,
  isPrerenderBot: window.navigator.userAgent.match(/prerender/gi),
  googleRecaptchaKey: '6LdLBq4UAAAAABoNi51QxVRBmjQF3i8o7--6QQ92',
  isProduction: nodeEnvironment === 'production',
  disableCaptcha:
    process.env.REACT_APP_ENVIRONMENT !== 'prod' &&
    process.env.REACT_APP_DISABLE_RECAPTCHA === 'true',
  refreshTokenBaseUrl: '/online-giving/access/refresh',

  recaptchaV3Key: process.env.REACT_APP_RECAPTCHA_V3_KEY,
  clevertapAccountId: process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID,
  fullStoryOrganizationId: '15YDMD',
  facebookAppId: '356550661098841',
  appStoreUrl: 'https://itunes.apple.com/us/app/givelify/id725815127',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify',
  googlePlaceApiKey: 'AIzaSyD3ydRyHEQGwGg4DYCcwiB_kZKo9Br7gHk',
  googleAutocompleteApiKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API,
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,

  doneeAvatarDefault: assetUrl('/images/svg/church-officer-placeholder.svg'),
  nonProfitBannerDefault: assetUrl(
    '/images/svg/non-profit-banner-placeholder.svg'
  ),
  churchBannerDefault: assetUrl('/images/svg/banner-placeholder.svg'),
  churchBannerVerifiedDefault: assetUrl('/images/svg/banner/worship.svg'),
  churchBannerUnverifiedDefault: assetUrl(
    '/images/svg/banner/worship-unverified.svg'
  ),
  nonProfitBannerVerifiedDefault: assetUrl('/images/svg/banner/nonprofit.svg'),
  nonProfitBannerUnverifiedDefault: assetUrl(
    '/images/svg/banner/nonprofit-unverified.svg'
  ),
  getAppMobileUrl: assetUrl('/images/svg/get-mobile-app.svg'),
  qrCodeUrl: assetUrl('/images/svg/qr-code.svg'),

  followFacebookURL: 'https://www.facebook.com/givelify',
  followTwitterURL: 'https://twitter.com/givelify',
  followInstagramURL: 'https://www.instagram.com/givelify/',
  followLinkedinURL: 'https://www.linkedin.com/company/givelify/',
  faqURL: 'https://support.givelify.com/hc/en-us',
  termsServiceURL: 'https://www.givelify.com/about/terms-of-use/',
  supportContactUrl: 'https://support.givelify.com/hc/en-us/requests/new',

  mainSearchSite: 'https://www.givelify.com/donors',

  doneeWithInternationalBillingAddressOption:
    process.env.REACT_APP_DONEE_WITH_INTERNATIONAL_BILLING_ADDRESS || 'all'
};

export const appLinks: { [id: string]: { apple: string; android: string } } = {
  footer: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_footer&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_footer'
  },
  thankYouBanner: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_thankyoubanner&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_thankyoubanner'
  },
  historyModal: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_historymodal&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_historymodal'
  },
  receiptBottomSheet: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_receiptbottomsheet&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_receiptbottomsheet'
  },
  receiptThankYou: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_receiptthankyou&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_receiptthankyou'
  },
  giftAmountBanner: {
    apple:
      'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_giftamounts&mt=8',
    android:
      'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_giftmountsbanner'
  }
};
