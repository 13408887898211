import styled from 'styled-components';
import { colors, layout } from '../../../../../theme';
import { SolidButtonStyle } from '../../../common/Button/SolidButton/styles';
import ContentLayout from '../../../common/Layout/Content';

const { breakpoints } = layout;
const { desktop, tablet, laptop, mobile, mobile_sm } = breakpoints;

export const AddButton = {
  ...SolidButtonStyle,
  background: colors.primaryGuidingViolet,
  color: colors.white,
  width: '240px'
};

export const CancelLink = {
  color: colors.primaryGuidingViolet
};

export const HistoryContainer = styled(ContentLayout)`
  display: inline-block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media screen and (max-width: ${mobile_sm.max}px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const DonationListMain = styled.div``;

export const DonationListItem = styled.div`
  align-items: center;
  overflow: hidden;
  padding: 6px 0;
  border-top: 1px solid #d8dce0;
`;

export const DonationDescriptionContainer = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  left: ${(props) => (props.active ? '36px' : '0px')};
  transition: left 0.2s ease-in;
`;

export const MemberIdLink = styled.a``;

export const ForgotPasswordContainer = styled(ContentLayout)`
  text-align: center;
  display: block;
  width: 100%;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

export const SendLinkContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
`;

export const ForgotBody = styled.div`
  text-align: left;
`;

export const FormItem = styled.div`
  padding: 2px 0 30px 0;
`;

export const MemoContant = styled.div`
  text-align: left;
`;

export const BackArrow = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  div {
    cursor: pointer;
  }
  img {
    margin-top: -2px;
  }
`;

export const IconBox = styled.div`
  display: inline-block;
  margin-right: 6px
  float: left;
  margin-top: 5px;
`;

export const CardDetail = styled.div`
  display: flex;
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;
