import React, { useState } from 'react';
import styled from 'styled-components';
import AddMessageField from './AddMessageField';
import { DonationAddonProps } from '../../../../utils';
import colors from '../../../../../theme/colors';
import { MessageIcon } from '../../../../../icons';
import { Text } from '../../../common/Text/Text';
import GivelifyModal from '../../../common/Modal/GivelifyModal';

interface AddMessageProps extends DonationAddonProps {
  className?: string;
}

const StyledDiv = styled.div`
  display: flex;
  padding: 15px 24px;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  transition: background 0.5s ease-in-out;
  &:hover {
    background-color: ${colors.utilitySeparatorColor};
  }
  & * {
    cursor: pointer;
  }
  .icon {
    margin-top: -2px;
    margin-right: 14px;
  }
  .message-text {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
  }
  .edit-text {
    margin-left: 14px;
  }
`;

const AddMessage: React.FC<AddMessageProps> = ({
  message,
  className,
  setMessage
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <StyledDiv
        onClick={handleOpen}
        className={className}
      >
        <MessageIcon className={'icon'} />
        <Text className={'message-text'}>{message || 'Add a message'}</Text>
        {message && (
          <Text
            color={'primaryGuidingViolet'}
            className={'edit-text'}
          >
            Edit
          </Text>
        )}
      </StyledDiv>
      <GivelifyModal
        open={open}
        onClose={handleClose}
        mainTitle={'Add Message'}
      >
        <AddMessageField
          message={message}
          setMessage={setMessage}
          handleDone={handleClose}
        />
      </GivelifyModal>
    </>
  );
};

export default AddMessage;
