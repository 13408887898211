export interface State {
  state: string;
  stateCode: string;
}

// TODO: remove duplication src/utils/statesConstant.ts
export const states: State[] = [
  {
    state: 'Alabama',
    stateCode: 'AL'
  },
  {
    state: 'Alaska',
    stateCode: 'AK'
  },
  {
    state: 'Arizona',
    stateCode: 'AZ'
  },
  {
    state: 'Arkansas',
    stateCode: 'AR'
  },
  {
    state: 'California',
    stateCode: 'CA'
  },
  {
    state: 'Colorado',
    stateCode: 'CO'
  },
  {
    state: 'Connecticut',
    stateCode: 'CT'
  },
  {
    state: 'Delaware',
    stateCode: 'DE'
  },
  {
    state: 'Florida',
    stateCode: 'FL'
  },
  {
    state: 'Georgia',
    stateCode: 'GA'
  },
  {
    state: 'Hawaii',
    stateCode: 'HI'
  },
  {
    state: 'Idaho',
    stateCode: 'ID'
  },
  {
    state: 'Illinois',
    stateCode: 'IL'
  },
  {
    state: 'Indiana',
    stateCode: 'IN'
  },
  {
    state: 'Iowa',
    stateCode: 'IA'
  },
  {
    state: 'Kansas',
    stateCode: 'KS'
  },
  {
    state: 'Kentucky',
    stateCode: 'KY'
  },
  {
    state: 'Louisiana',
    stateCode: 'LA'
  },
  {
    state: 'Maine',
    stateCode: 'ME'
  },
  {
    state: 'Maryland',
    stateCode: 'MD'
  },
  {
    state: 'Massachusetts',
    stateCode: 'MA'
  },
  {
    state: 'Michigan',
    stateCode: 'MI'
  },
  {
    state: 'Minnesota',
    stateCode: 'MN'
  },
  {
    state: 'Mississippi',
    stateCode: 'MS'
  },
  {
    state: 'Missouri',
    stateCode: 'MO'
  },
  {
    state: 'Montana',
    stateCode: 'MT'
  },
  {
    state: 'Nebraska',
    stateCode: 'NE'
  },
  {
    state: 'Nevada',
    stateCode: 'NV'
  },
  {
    state: 'New Hampshire',
    stateCode: 'NH'
  },
  {
    state: 'New Jersey',
    stateCode: 'NJ'
  },
  {
    state: 'New Mexico',
    stateCode: 'NM'
  },
  {
    state: 'New York',
    stateCode: 'NY'
  },
  {
    state: 'North Carolina',
    stateCode: 'NC'
  },
  {
    state: 'North Dakota',
    stateCode: 'ND'
  },
  {
    state: 'Ohio',
    stateCode: 'OH'
  },
  {
    state: 'Oklahoma',
    stateCode: 'OK'
  },
  {
    state: 'Oregon',
    stateCode: 'OR'
  },
  {
    state: 'Pennsylvania',
    stateCode: 'PA'
  },
  {
    state: 'Rhode Island',
    stateCode: 'RI'
  },
  {
    state: 'South Carolina',
    stateCode: 'SC'
  },
  {
    state: 'South Dakota',
    stateCode: 'SD'
  },
  {
    state: 'Tennessee',
    stateCode: 'TN'
  },
  {
    state: 'Texas',
    stateCode: 'TX'
  },
  {
    state: 'Utah',
    stateCode: 'UT'
  },
  {
    state: 'Vermont',
    stateCode: 'VT'
  },
  {
    state: 'Virginia',
    stateCode: 'VA'
  },
  {
    state: 'Washington',
    stateCode: 'WA'
  },
  {
    state: 'West Virginia',
    stateCode: 'WV'
  },
  {
    state: 'Wisconsin',
    stateCode: 'WI'
  },
  {
    state: 'Wyoming',
    stateCode: 'WY'
  },
  { state: 'Armed Forces Americas (AA)', stateCode: 'AA' },
  { state: 'Armed Forces Europe (AE)', stateCode: 'AE' },
  { state: 'Armed Forces Pacific (AP)', stateCode: 'AP' }
];
