import { useCallback } from 'react';
import {
  RequestState,
  requestError,
  requestStart,
  requestSuccess,
  useRequestState
} from './useRequestState';
import { ApiResponse, GivingApiResponse } from '../utils/makeApiRequest';
import { setErrorResponse } from '../utils/ErrorResponse';

const defaultErrorMessage =
  "Something went wrong, and it's not you, it's on us. Please try again.";
export const useApiRequest = <T>() => {
  const [requestState, setRequestState] = useRequestState<T>();
  const makeRequest = useCallback(
    async (request: Promise<ApiResponse<T>>) => {
      setRequestState(requestStart());
      try {
        const response = await request;
        if (response.success) {
          setRequestState(requestSuccess<T>(response.data!));
        } else {
          setRequestState(requestError(response.error!));
        }
      } catch (e) {
        setRequestState(
          requestError(
            setErrorResponse({
              message: defaultErrorMessage,
              status: 0,
              isAxiosError: false
            })
          )
        );
      }
    },
    //eslint-disable-next-line
    [setRequestState]
  );
  return [requestState, makeRequest, setRequestState] as const;
};

export const useGivingApiRequest = <T>(state?: RequestState<T>) => {
  const [requestState, setRequestState] = useRequestState<T>(state);
  const makeRequest = useCallback(
    async (request: Promise<ApiResponse<GivingApiResponse<T>>>) => {
      setRequestState(requestStart());
      const response = await request;
      if (response.success) {
        setRequestState(requestSuccess<T>(response.data!.response.payload));
      } else {
        setRequestState(requestError(response.error!));
      }
    },
    //eslint-disable-next-line
    [setRequestState]
  );
  return [requestState, makeRequest, setRequestState] as const;
};
