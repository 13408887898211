export default {
  default: {
    width: 'auto',
    padding: '13px 50px'
  },
  full: {
    width: '100%',
    padding: '13px 20px'
  },
  big: {
    padding: '20px 20px'
  },
  minwidth: {
    width: 'auto',
    minwidth: '225px'
  },
  link: {
    padding: '0 !important'
  }
};
