const fonts = {
  fontfamily: 'Area Normal',
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800
  },
  fontSizing: {
    xxLarge: 38,
    xLarge: 30,
    large: 24,
    medium3: 20,
    medium2: 18,
    medium: 16,
    small: 14,
    xSmall: 12,
    smallest: 10
  },
  fontLeading: {
    xxLarge: 40,
    xLarge: 36,
    large: 30,
    medium3: 30,
    medium2: 28,
    medium: 26,
    small: 20,
    xSmall: 18,
    smallest: 16
  }
};

export const gdsHeadingXxlarge = {
  fontFamily: 'Area Normal',
  fontSize: '40px',
  fontWeight: '700',
  lineHeight: '52px',
  letterSpacing: '0px'
};
export const gdsHeadingXlarge = {
  fontFamily: 'Area Normal',
  fontSize: '34px',
  fontWeight: '700',
  lineHeight: '42px',
  letterSpacing: '0px'
};
export const gdsHeadingLarge = {
  fontFamily: 'Area Normal',
  fontSize: '28px',
  fontWeight: '700',
  lineHeight: '36px',
  letterSpacing: '0px'
};
export const gdsHeadingMedium = {
  fontFamily: 'Area Normal',
  fontSize: '22px',
  fontWeight: '800',
  lineHeight: '30px',
  letterSpacing: '0px'
};
export const gdsHeadingSmall = {
  fontFamily: 'Area Normal',
  fontSize: '20px',
  fontWeight: '800',
  lineHeight: '28px',
  letterSpacing: '0px'
};
export const gdsLabelLarge = {
  fontFamily: 'Area Normal',
  fontSize: '18px',
  fontWeight: '800',
  lineHeight: '24px',
  letterSpacing: '0px'
};
export const gdsLabelMedium = {
  fontFamily: 'Area Normal',
  fontSize: '16px',
  fontWeight: 800,
  lineHeight: '20px',
  letterSpacing: '0px'
  // textTransform: 'none'
};
export const gdsLabelMediumLight = {
  fontFamily: 'Area Normal',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '20px',
  letterSpacing: '0px'
};
export const gdsLabelSmall = {
  fontFamily: 'Area Normal',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '0.25px'
};
export const gdsLabelXsmall = {
  fontFamily: 'Area Normal',
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '18px',
  letterSpacing: '0.25px'
};
export const gdsBodyLarge = {
  fontFamily: 'Area Normal',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '28px',
  letterSpacing: '0px'
};
export const gdsBodyMedium = {
  fontFamily: 'Area Normal',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0px'
};
export const gdsBodySmall = {
  fontFamily: 'Area Normal',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0.25px'
};
export const gdsBodyXsmall = {
  fontFamily: 'Area Normal',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.25px'
};
export const gdsHeadingMediumLight = {
  fontFamily: 'Area Normal',
  fontSize: '22px',
  fontWeight: '700',
  lineHeight: '30px',
  letterSpacing: '0px'
};
export const gdsSpecialPastorSignature = {
  fontFamily: 'Caveat',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0px'
};

export default fonts;
