import React from 'react';
import { Text as StyledText, TextProps } from './styles';
import {
  defaultTextColor,
  defaultTextSize,
  defaultTextWeight
} from './constants';

interface StyledTextProps extends Partial<TextProps> {
  value?: string | number;
  children?: React.ReactElement | string | number;
}

const Text: React.FC<StyledTextProps> = ({
  size = defaultTextSize,
  color = defaultTextColor,
  weight = defaultTextWeight,
  value,
  children,
  ...props
}) => (
  <StyledText
    size={size}
    color={color}
    weight={weight}
    {...props}
  >
    {children || value}
  </StyledText>
);

export default Text;
