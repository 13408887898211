import React, { RefObject } from 'react';
import { Textfield } from '../Textfield';

interface EmailProps {
  defaultValue?: string;
  inputRef: RefObject<HTMLInputElement>;
  emailError?: string;
  className?: string;
}

const Email: React.FC<EmailProps> = ({inputRef, emailError, className, defaultValue}) => {
  return (
    <Textfield
      errorText={emailError}
      className={className}
      inputRef={inputRef}
      defaultValue={defaultValue}
      id={'email'}
      type={'email'}
      placeholder={'Email Address'}
      // onMouseLeave={() => onHover(email)} (validation)
      maxLength={255}
    />
  );
}

export default Email;
