import styled from 'styled-components';
import colors from '../../../../../theme/colors';
import fonts from '../../../../../theme/fonts';
import Icon from '../../Icon';

interface FocusedProps {
  focused?: boolean;
}

export const getInputContainerBorderColor = ({ focused }: FocusedProps) =>
  focused ? colors.primaryBlue : colors.disable;

export const getInputIconColor = ({ focused }: FocusedProps) =>
  focused ? colors.primaryBlue : colors.grey3;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const InputIcon = styled(Icon)``;

export const InputIconContainer = styled.div`
  padding-right: 12px;
  position: absolute;
  right: 10px;
`;

export const Input = styled.input`
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  padding: 8px 15px;
  color: ${colors.grey3};
  caret-color: ${colors.grayscaleBlack};
  font-family: ${fonts.fontfamily};
  outline: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.disable};
  background-color: #fff !important;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  ::placeholder {
    color: ${colors.lightgrey};
    opacity: 1;
  }
  ::-ms-reveal {
    display: none;
  }
`;
