import styled, { css } from 'styled-components';

import { sizes } from './constants';

const fluidSize = css`
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
`;

export interface IconProps {
  size: keyof typeof sizes;
  fluid?: boolean;
  maxHeight?: number;
  height?: string | number;
}

export const Icon = styled.img<IconProps>`
  max-width: ${(props) => sizes[props.size].icon}px !important;
  max-height: ${(props) =>
    props.maxHeight ? props.maxHeight : sizes[props.size].icon}px !important;
  ${(props) => props.fluid && fluidSize};
`;

// width: ${(props) => sizes[props.size].max_width}px !important;
export const Container = styled.div<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) =>
    props.height !== '' ? 'auto' : `${sizes[props.size].icon}px !important `};

  ${(props) => props.fluid && fluidSize};
`;
