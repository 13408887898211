import React from 'react';
import { Label as StyledText } from './styles';
import {
  textColors,
  textWeights,
  lineHeights,
  defaultTextSize,
  defaultTextColor,
  defaultTextWeight
} from './constants';

interface LabelProps {
  color?: keyof typeof textColors;
  size?: keyof typeof lineHeights;
  weight?: keyof typeof textWeights;
  selectable?: boolean;
  htmlFor?: string;
  children?: React.ReactElement | string | number;
  value?: React.ReactElement | string | number;
}

const Label: React.FC<LabelProps> = ({
  size = defaultTextSize,
  color = defaultTextColor,
  weight = defaultTextWeight,
  value,
  children,
  ...props
}) => (
  <div className={'text-left'}>
    <StyledText
      size={size}
      color={color}
      weight={weight}
      {...props}
    >
      {children || value}
    </StyledText>
  </div>
);

export default Label;
