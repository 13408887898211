import styled from 'styled-components';

import Image from '../Image';
import { assetUrl } from '../../../../../../utils/webConfig';
import layout from '../../../../../theme/layout';

const { breakpoints } = layout;
const { mobile, tablet, desktop } = breakpoints;

export const HeaderImage = styled(Image)<{ source: string }>`
  width: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => assetUrl(props.source)});

  @media screen and (max-width: ${mobile.max}px) {
    min-height: 208px;
  }

  @media screen and (min-width: ${tablet.min}px) and (max-width: ${tablet.max}px) {
    min-height: 208px;
  }

  @media screen and (min-width: ${desktop.min}px) {
    min-height: 272px;
  }
`;
