import { AxiosPromise } from 'axios';
import toCamelCase from 'camelcase-keys';
import { ErrorResponse } from './ErrorResponse';
import { logError } from './logError';
import { somethingWrongMsg } from './messages';

export interface GivingApiResponse<T, U extends number = number> {
  response: {
    // you can add `code` specific interfaces
    // such as SomeInterface<T, 200> | SomeInterface<V, 201> | SomeInterface<Rest, number>
    code: U;
    payload: T;
  };
}

export type ApiResponse<T> =
  | {
      success: true;
      data?: T;
    }
  | {
      success: false;
      error?: ErrorResponse;
    };

const getMessage = (resp: any) => {
  let message: string =
    resp && resp.data && resp.data.response
      ? resp.data.response.error_message || resp.data.response.errorMessage
      : somethingWrongMsg;
  if (resp.data.response.errors) {
    message = resp.data.response.errors;
  }
  if (resp.status === 500 || resp.status === 401) {
    if (resp.status === 500) {
      logError('API Request Error: 500', resp);
    }
    message = somethingWrongMsg;
  }
  if (message.includes('SQL')) {
    message = somethingWrongMsg;
  }
  return message;
};

export const makeGoogleRequest = async <T>(
  httpPromise: AxiosPromise
): Promise<ApiResponse<T>> => {
  const response = await httpPromise;
  if (response.status === 200) {
    return {
      success: true,
      data: toCamelCase(response.data, { deep: true }) as T
    };
  }
  return {
    success: false,
    error: {
      isAxiosError: true,
      response: {
        status: 500,
        data: {
          message: 'Possible network problem'
        }
      },
      name: '500',
      message: 'Possible network problem'
    }
  };
};

export const makeApiRequest = async <T = any>(
  httpPromise: AxiosPromise<any>
): Promise<ApiResponse<GivingApiResponse<T>>> => {
  try {
    const response = await httpPromise;
    if (
      response.status === 200 ||
      response.status === 204 ||
      response.status === 201
    ) {
      const body = response.data;
      if (
        body.response.code === 200 ||
        body.response.code === 204 ||
        body.response.code === 201
      ) {
        return {
          success: true,
          data: body
        };
      }
      return {
        success: false,
        error: {
          isAxiosError: false,
          response: {
            status: body.response.code,
            data: {
              message: body.response.error_message
            }
          },
          name: body.response.code.toString(),
          message: body.response.error_message
        }
      };
    }

    const resp = response;
    const message = getMessage(resp);
    return {
      success: false,
      error: {
        isAxiosError: false,
        response: {
          status: response.status,
          data: {
            message
          }
        },
        name: response.status.toString(),
        message
      }
    };
  } catch (err: any) {
    if (err.response) {
      const { response: resp } = err;
      const message = getMessage(resp);
      return {
        success: false,
        error: {
          isAxiosError: false,
          response: {
            status: err.response.data.response.code,
            data: {
              message
            }
          },
          name: err.response.status.toString(),
          message
        }
      };
    }
    return {
      success: false,
      error: {
        isAxiosError: true,
        response: {
          status: 500,
          data: {
            message: 'Possible network problem'
          }
        },
        name: '500',
        message: 'Possible network problem'
      }
    };
  }
};
