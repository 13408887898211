import styled from 'styled-components';
import layout from '../../components/theme/layout';
import ContentLayout from '../../components/Donee/components/common/Layout/Content';

const { breakpoints } = layout;
const { desktop, tablet, laptop, mobile } = breakpoints;

export const FormProfile = styled.div``;

export const FormProfileHeaderContainer = styled.div`
  text-align: left;
`;

export const ChangePassword = styled.div`
  text-align: left;
  color: #0678fe;
`;

export const ForgotPasswordContainer = styled(ContentLayout)`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

export const SendLinkContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;

export const UserMainContainer = styled.div`
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1),
    0px -1px 4px rgba(132, 151, 178, 0.1);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  margin-top: 60px;
`;

export const ImgAvatarMain = styled.div`
  border-radius: 50%;
  height: 86px;
  width: 86px;
  overflow: hidden;
  display: inline-block;
  margin-top: -75px;
`;

export const ImgAvatar = styled.img`
  width: 100%;
  height: 100%;
`;

export const UserName = styled.div`
  width: 100%;
  display: inline-block;
`;
