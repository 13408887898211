import React, { useEffect } from 'react';
import WalletSection from './WalletSection';
import { Row, Col, Container, Alert } from '@bootstrap-styled/v4';
import { Link, useNavigate } from 'react-router-dom';
import { WalletSectionMain } from './styles';
import { usePushEvents } from '../../hooks/PushEvents';
import { walletShown } from '../../hooks/PushEvents/clevertapEvents';
import { Text } from '../../components/Donee/components/common/Text/Text';
import { MainSection } from '../../components/Donee/components/common/Layout';
import H2 from '../../components/Donee/components/common/H2';
import Icon from '../../components/Donee/components/common/Icon';
import Modal from '../../components/Donee/components/common/Modal';
import DonorAddPaymentMethod from '../DonorAddPaymentMethod';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import { ROUTES } from '../../utils/routes';
import { HeartLoading } from '../../components/HeartLoading';
import { somethingWrongMsg } from '../../utils/messages';

const WalletScreen: React.FC = () => {
  const push = useNavigate();
  const { pushEvent } = usePushEvents();
  const [addNewCard, setAddNewCard] = React.useState(false);
  const [actionType, setActionType] = React.useState<
    'delete' | 'preferred' | undefined
  >('preferred');
  const [alertMsg, setAlertMsg] = React.useState(false);
  const [cardType, setCardType] = React.useState('');
  const [cardNumber, setCardNumber] = React.useState('');
  const [serverError, setServerError] = React.useState('');

  const { donorContext, loadDonor } = useDonorContext();
  const donor = donorContext.donor;

  useEffect(() => {
    if (donorContext.donor.type === 'REQUEST_ERROR') {
      push(ROUTES.login);
    }
  }, [donorContext.donor.type]);

  useEffect(() => {
    pushEvent(walletShown);
  }, []);

  const getWalletAccountNumber = (number: string) =>
    number
      .replace(/\W/gi, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();

  const setAlertMessage = (
    newActionType: 'delete' | 'preferred' | undefined
  ) => {
    if (newActionType === 'delete' || newActionType === 'preferred') {
      setAlertMsg(true);
      setActionType(newActionType);
    } else {
      setActionType(undefined);
    }
  };

  const deleteCardFromWallet = (newCardType: any, newCardNumber: any) => {
    setCardType(newCardType);
    setCardNumber(newCardNumber);
    setAlertMessage('delete');
  };

  const closeAlertMsg = () => {
    setAlertMsg(false);
    setCardType('');
    setCardNumber('');
  };

  const openNewCardPopup = () => {
    setAddNewCard(true);
  };

  const newCardDataCallback = () => null;

  return (
    <div className={'mainpart'}>
      <Container>
        {actionType !== undefined && (
          <center>
            <Alert
              className={'common-alert'}
              color={'success'}
              isOpen={alertMsg}
              onClick={closeAlertMsg}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {actionType === 'preferred' &&
                  'Your preferred card was successfully updated'}
              </Text>
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {actionType === 'delete' &&
                  `${cardType} ending in ${cardNumber} removed successfully`}
              </Text>
            </Alert>
          </center>
        )}

        {serverError !== '' && (
          <center>
            <Alert
              className={'common-alert'}
              color={'danger'}
              isOpen
              onClick={() => setServerError('')}
            >
              <Text
                variant={'xSmall'}
                color={'red1'}
              >
                {serverError}
              </Text>
            </Alert>
          </center>
        )}
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <center>
                <H2
                  size={'large'}
                  color={'grayscaleBlack'}
                  weight={'medium'}
                  value={undefined}
                >
                  Wallet
                </H2>
              </center>

              {donor.type === 'REQUEST_ERROR' && (
                <center className={'mt-30'}>
                  <br />
                  <Icon
                    size={'xLarge'}
                    name={'empty_wallet_icon'}
                  />
                  <Row className={'justify-content-center mt-15'}>
                    <Col lg={10}>
                      <Text
                        color={'grey3'}
                        variant={'medium'}
                      >
                        {donor.error.message || somethingWrongMsg}
                      </Text>
                    </Col>
                  </Row>
                </center>
              )}
            </Col>
          </Row>
          <WalletSectionMain>
            {(donor.type === 'REQUEST_INIT' ||
              donor.type === 'REQUEST_START') && (
              <Row className={'justify-content-center'}>
                <Col lg={12}>
                  <HeartLoading maxWidth={300} />
                </Col>
              </Row>
            )}

            <Row
              className={'justify-content-center'}
              style={
                donor.type === 'REQUEST_INIT' || donor.type === 'REQUEST_START'
                  ? { display: 'none' }
                  : {}
              }
            >
              {donor.data?.wallets.map(
                (t) =>
                  t.isPreferred && (
                    <Col
                      lg={6}
                      key={t.id}
                    >
                      <WalletSection
                        walletId={t.id}
                        accountType={t.type}
                        accountNumber={getWalletAccountNumber(t.number)}
                        preferredAccount={t.isPreferred}
                        deleteCardFromWallet={deleteCardFromWallet}
                        wallets={donor.data?.wallets}
                        openNewCardPopup={openNewCardPopup}
                        loadDonor={loadDonor}
                      />
                    </Col>
                  )
              )}
            </Row>
            <br />

            <Row className={'justify-content-center'}>
              {donor.type === 'REQUEST_INIT' ||
              donor.type === 'REQUEST_START' ||
              donor.data?.wallets.length ? (
                donor.data?.wallets.map(
                  (t) =>
                    !t.isPreferred && (
                      <Col
                        lg={5}
                        key={t.id}
                      >
                        <WalletSection
                          walletId={t.id}
                          accountType={t.type}
                          accountNumber={getWalletAccountNumber(t.number)}
                          preferredAccount={t.isPreferred}
                          deleteCardFromWallet={deleteCardFromWallet}
                          wallets={[]}
                          openNewCardPopup={openNewCardPopup}
                          loadDonor={loadDonor}
                        />
                      </Col>
                    )
                )
              ) : (
                <div>
                  No cards available in your wallet. You can add a card to your
                  wallet while donating <Link to="/">here</Link>.
                </div>
              )}
            </Row>

            <Modal
              active={addNewCard}
              className={'addnewcard-main'}
              onClose={() => setAddNewCard(false)}
            >
              <DonorAddPaymentMethod
                type={'wallet'}
                onNewCardDataCallback={newCardDataCallback}
                onNewCardPopupClose={() => setAddNewCard(false)}
              />
            </Modal>
          </WalletSectionMain>
        </MainSection>
      </Container>
    </div>
  );
};

export default WalletScreen;
