import React from 'react';
import parse from 'html-react-parser';
import { Row, Col, Container, Alert } from '@bootstrap-styled/v4';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  HistoryContainer,
  DonationListMain,
  DonationListItem,
  DonationDescriptionContainer,
  MemberIdLink,
  ForgotPasswordContainer,
  SendLinkContainer,
  ForgotBody,
  FormItem,
  MemoContant,
  BackArrow,
  IconBox,
  CardDetail,
  AddButton,
  CancelLink
} from './styles';
import { Text } from '../../../common/Text/Text';
import { MainSection, RightSide } from '../../../common/Layout/styles';
import TextInput from '../../../common/Input/TextInput';
import Icon from '../../../common/Icon';
import {
  amountNumberFormat,
  decodeTextContent
} from '../../../../../../utils/functions';
import { H2 } from '../../../common/H2/styles';
import SolidButton from '../../../common/Button/SolidButton';
import Divider from '../../../common/Divider';
import { GiftHistoryResponse } from '../api';
import GivelifyModal from '../../../common/Modal/GivelifyModal';

const useStyles = makeStyles({
  AddButton,
  CancelLink
});

interface HistoryDetailDesignProps {
  success?: string;
  error?: string;
  closeMessage: () => () => void;
  exitDetail: () => void;
  memberId?: string;
  memberIdPopup: boolean;
  memberIdInput?: string;
  updateMemberIdAction: () => boolean;
  memberIdDelete: () => void;
  memberIdModalOpen: () => void;
  memberIdModalClose: () => void;
  apiError?: string;
  onMemberIdInput: (value: string) => boolean;
  loading: boolean;
  details: GiftHistoryResponse['data'][number] & { detailType: 'history' };
  memo?: string;
  creditNo: string;
}

const HistoryDetailDesign: React.FC<HistoryDetailDesignProps> = ({
  success,
  error,
  closeMessage,
  exitDetail,
  memberId,
  memberIdPopup,
  memberIdInput,
  updateMemberIdAction,
  memberIdDelete,
  memberIdModalOpen,
  memberIdModalClose,
  apiError,
  onMemberIdInput,
  loading,
  details,
  memo,
  creditNo
}) => {
  const classes = useStyles();

  return (
    <div className={'mainpart'}>
      <Container>
        {(success !== undefined || error !== undefined) && (
          <center>
            {success !== undefined && (
              <Alert
                className={'common-alert m-0'}
                color={'success'}
                isOpen
                onClick={closeMessage()}
              >
                <Text
                  variant={'xSmall'}
                  color={'grey3'}
                >
                  {success}
                </Text>
              </Alert>
            )}
            {error !== undefined && (
              <Alert
                className={'common-alert'}
                color={'danger'}
                isOpen
                onClick={closeMessage()}
              >
                <Text
                  variant={'xSmall'}
                  color={'grey3'}
                >
                  {error}
                </Text>
              </Alert>
            )}
          </center>
        )}
        <MainSection className={'position-r'}>
          <BackArrow onClick={() => exitDetail()}>
            <IconBox>
              <Icon
                size={'medium'}
                name={'back_arrow'}
              />
            </IconBox>
            <Text color={'primaryGuidingViolet'}> Back </Text>
          </BackArrow>
          {details && details.donee && details.gift && details.envelopes && (
            <Row className={'justify-content-center'}>
              <Col
                lg={6}
                md={8}
                sm={10}
              >
                <HistoryContainer>
                  <center>
                    <H2
                      size={'large'}
                      color={'grayscaleBlack'}
                      weight={'medium'}
                    >
                      Past Gift
                    </H2>
                    <br />
                    <H2
                      size={'large'}
                      color={'grayscaleBlack'}
                      weight={'medium'}
                    >
                      {details.donee.name}
                    </H2>
                    <Text
                      variant={'medium'}
                      color={'primarySecondaryContent'}
                    >
                      {' '}
                      {details.gift.date}{' '}
                    </Text>
                  </center>
                  <br />
                  <DonationListMain>
                    {details.detailType === 'history'
                      ? details.envelopes.map((item: any) => (
                          <DonationListItem
                            className={'donationlist'}
                            key={item.id}
                          >
                            <Row>
                              <Col xs={8}>
                                <DonationDescriptionContainer
                                  className={'donationlistname'}
                                >
                                  <Text color={'primarySecondaryContent'}>
                                    {' '}
                                    {item.name}{' '}
                                  </Text>
                                </DonationDescriptionContainer>
                              </Col>
                              <Col xs={4}>
                                <RightSide>
                                  <Text color={'grey3'}>
                                    {' '}
                                    {amountNumberFormat(item.amount)}{' '}
                                  </Text>
                                </RightSide>
                              </Col>
                            </Row>
                          </DonationListItem>
                        ))
                      : details.envelopes.map((item: any) => (
                          <DonationListItem
                            className={'donationlist'}
                            key={item.id}
                          >
                            <Row>
                              <Col xs={8}>
                                <DonationDescriptionContainer
                                  className={'donationlistname'}
                                >
                                  <Text color={'primarySecondaryContent'}>
                                    {' '}
                                    {item.name}{' '}
                                  </Text>
                                </DonationDescriptionContainer>
                              </Col>
                              <Col xs={4}>
                                <RightSide>
                                  <Text color={'grey3'}>
                                    {' '}
                                    {amountNumberFormat(item.amount)}{' '}
                                  </Text>
                                </RightSide>
                              </Col>
                            </Row>
                          </DonationListItem>
                        ))}
                    <Divider className={'mb-5'} />
                    <Row>
                      <Col xs={12}>
                        <RightSide>
                          <Text
                            color={'grey3'}
                            variant={'medium'}
                            weight={'medium'}
                          >
                            {' '}
                            Total: {amountNumberFormat(details.gift.amount)}
                          </Text>
                        </RightSide>
                      </Col>
                    </Row>
                    <br />
                  </DonationListMain>

                  {memo && (
                    <MemoContant>
                      <Text color={'primarySecondaryContent'}>Message</Text>
                      <br />
                      <Text
                        color={'grey3'}
                        variant={'small'}
                      >
                        {parse(memo)}{' '}
                      </Text>
                    </MemoContant>
                  )}
                  <br />
                  <DonationListMain>
                    <Row>
                      <Col xs={8}>
                        <DonationDescriptionContainer
                          className={'donationlistname'}
                        >
                          <MemberIdLink>
                            <Text
                              color={'primaryGuidingViolet'}
                              weight={'medium'}
                              onClick={memberIdModalOpen}
                            >
                              {' '}
                              {memberId === undefined ? 'Add' : 'Edit'} Member
                              ID/Envelope#
                            </Text>
                          </MemberIdLink>
                        </DonationDescriptionContainer>
                      </Col>
                      <Col xs={4}>
                        <RightSide>
                          <Text
                            color={'primaryGuidingViolet'}
                            weight={'medium'}
                          >
                            {decodeTextContent(memberId)}
                          </Text>
                        </RightSide>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <DonationDescriptionContainer>
                          <Text
                            variant={'small'}
                            color={'primarySecondaryContent'}
                          >
                            Assigned to you by the organization
                          </Text>
                        </DonationDescriptionContainer>
                      </Col>
                    </Row>
                  </DonationListMain>
                  <br />
                  <DonationListMain>
                    <Row className={'mt-5'}>
                      <Col xs={5}>
                        <DonationDescriptionContainer
                          className={'donationlistname'}
                        >
                          <Text color={'primarySecondaryContent'}>
                            {' '}
                            Credit card{' '}
                          </Text>
                        </DonationDescriptionContainer>
                      </Col>
                      <Col xs={7}>
                        <RightSide>
                          <CardDetail>
                            {typeof details.gift.accountType !==
                              'undefined' && (
                              <Icon
                                size={'large'}
                                name={
                                  `${details.gift.accountType.toLowerCase()}-logo` as any
                                }
                                className={'recurring-card'}
                              />
                            )}
                            <Text
                              className={'card_no'}
                              color={'grey3'}
                            >
                              {' '}
                              {creditNo}{' '}
                            </Text>
                          </CardDetail>
                        </RightSide>
                      </Col>
                    </Row>
                    <Row className={'mt-5'}>
                      <Col xs={5}>
                        <DonationDescriptionContainer
                          className={'donationlistname'}
                        >
                          <Text color={'primarySecondaryContent'}>
                            {' '}
                            Receipt #{' '}
                          </Text>
                        </DonationDescriptionContainer>
                      </Col>
                      <Col xs={7}>
                        <RightSide>
                          <Text color={'grey3'}>
                            {' '}
                            {details.gift.receiptNumber}{' '}
                          </Text>
                        </RightSide>
                      </Col>
                    </Row>
                  </DonationListMain>
                </HistoryContainer>
              </Col>
            </Row>
          )}
        </MainSection>
      </Container>

      <GivelifyModal
        open={memberIdPopup}
        onClose={memberIdModalClose}
      >
        <ForgotPasswordContainer>
          <center>
            <Text
              className={'popup-title mb-10'}
              color={'grayscaleBlack'}
            >
              {memberId === undefined ? 'Add' : 'Edit'} Member ID/Envelope#
            </Text>
          </center>
          <ForgotBody>
            <FormItem>
              <TextInput
                placeholder={'Member Id/Envelope#'}
                value={decodeTextContent(memberIdInput) as string}
                type={'text'}
                onChange={(e) => onMemberIdInput(e.target.value)}
                maxLength={16}
              />
              {apiError !== undefined && <Text color={'red'}>{apiError}</Text>}
            </FormItem>
          </ForgotBody>
          <SendLinkContainer>
            <SolidButton
              className={classes.AddButton}
              onClick={updateMemberIdAction}
              fluid={undefined}
              value={undefined}
            >
              {loading ? 'Saving...' : 'Save'}
            </SolidButton>
            {memberId !== undefined && (
              <div>
                <br />
                <Link
                  className={classes.CancelLink}
                  onClick={() => {
                    memberIdDelete();
                  }}
                >
                  Delete Member ID/Envelope#
                </Link>
              </div>
            )}
          </SendLinkContainer>
        </ForgotPasswordContainer>
      </GivelifyModal>
    </div>
  );
};

export default HistoryDetailDesign;
