import React from 'react';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Donor, DonorContextProps } from './interfaces';
import { defaultDonorContext, deleteAccessTokens } from './donorLocalStorage';
import { ReactChildren } from '../DoneeProvider';
import { useLoadDonor } from './useLoadDonor';
import { logoutFullStory } from '../../components/Wrappers/FullStory';

export const DonorContext = createContext<{
  donorContext: DonorContextProps;
  setDonorContext: Dispatch<SetStateAction<DonorContextProps>>;
  loadDonor: (forcePull?: boolean | undefined) => unknown;
  logout: () => unknown;
}>({
  donorContext: defaultDonorContext(),
  setDonorContext: () => null,
  loadDonor: () => null,
  logout: () => null
});

export const useDonorContext = () => useContext(DonorContext);
export const isDonorLoggedIn = (d: DonorContextProps) =>
  d.donor.type === 'REQUEST_SUCCESS';

const DonorProvider: React.FC<
  ReactChildren & { donorEmailRef: React.MutableRefObject<Donor | undefined> }
> = ({ donorEmailRef, children }) => {
  const [donorContext, setDonorContext] = useState<DonorContextProps>(
    defaultDonorContext()
  );
  const loadDonor = useLoadDonor(donorContext, setDonorContext);

  useEffect(() => {
    loadDonor();
  }, []);

  useEffect(() => {
    if (donorContext.donor.type !== 'REQUEST_SUCCESS') return;
    donorEmailRef.current = donorContext.donor.data.donor;
  }, [donorContext.donor, donorEmailRef]);

  const logout = useCallback(() => {
    window.accessToken = undefined;
    deleteAccessTokens();
    setDonorContext(defaultDonorContext());
    logoutFullStory();
  }, [setDonorContext]);

  return (
    <DonorContext.Provider
      value={{ donorContext, setDonorContext, loadDonor, logout }}
    >
      {children}
    </DonorContext.Provider>
  );
};

export default React.memo(DonorProvider);
