import React from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';
import { webConfig } from '../../../../utils/webConfig';
import { COMPONENT_VALUES } from '../../../theme';
import { HeartLoading } from '../../../HeartLoading';
import { useDonee } from '../../../../providers/DoneeProvider';
import { DoneeDialogCard } from './DoneeDialogCard';

interface StyleProps {
  doneeType: 'church' | 'nonprofit';
  isLoading: boolean;
  coverUrl: string | undefined;
  profileUrl: string | undefined | null;
}

export const StyledDiv = styled.div<StyleProps>`
  background-color: ${colors.white};
  .donee-name {
    display: inline;
    margin-top: 10px;
    line-height: 36px;
  }
  .cover-photo {
    width: 100%;
    height: 202px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .profile-photo {
    width: 72px;
    height: 72px;
    margin-top: -36px;
    margin-bottom: 8px;
    margin-left: 28px;
    border-radius: 100px;
    background-size: cover !important;
    border: 4px solid ${colors.white};
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    background: url(${webConfig.doneeAvatarDefault});
  }
  .content {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .donee-info {
    margin-top: 12px;
    margin-left: 12px;
  }
  .donee {
    margin-bottom: 12px;
  }
  .pastor {
    margin-bottom: 32px;
  }
  .verified {
    display: inline;
    margin-left: 8px;
  }
  .address,
  .phone {
    display: flex;
    align-items: center;
  }
  .address {
    margin-bottom: 18px;
  }
  .phone {
    margin-bottom: 42px;
  }
  .address-icon {
    margin-right: 15px;
  }
  .phone-icon {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    margin-left: 1px;
  }
  .taxable {
    padding: 12px;
    text-align: center;
    border-radius: 24px;
    background-color: ${colors.utilityBackgroundLightColor};
  }
  ${COMPONENT_VALUES.box};
  max-width: 360px;
  max-height: ${({ isLoading: loading }) => (loading ? 365 : 700)}px;
  .error-message {
    padding: 30px 20px;
  }
`;

const DoneeDialog: React.FC = () => {
  const { donee } = useDonee();
  switch (donee.type) {
    case 'REQUEST_INIT':
    case 'REQUEST_START':
      return (
        <StyledDiv
          doneeType={'church'}
          isLoading={true}
          coverUrl={''}
          profileUrl={''}
        >
          <HeartLoading />
        </StyledDiv>
      );
    case 'REQUEST_SUCCESS':
      const organization = donee.data.organization;
      return (
        <StyledDiv
          doneeType={organization.type}
          isLoading={false}
          coverUrl={organization.banner}
          profileUrl={organization.official?.picture}
        >
          <DoneeDialogCard
            doneeType={organization.type}
            coverUrl={organization.banner}
            profileUrl={organization.official?.picture}
            address={{
              street: organization.streetAddress,
              city: organization.city,
              state: organization.state,
              zip: organization.zip
            }}
            doneeName={organization.name}
            pastorName={organization.official?.name || ''}
            phone={organization.phone}
            isTaxableOrg={organization.isTaxDeductible}
            isVerified={organization.isPaymentVerified}
          />
        </StyledDiv>
      );

    default:
      return (
        <StyledDiv
          doneeType={'church'}
          isLoading={false}
          coverUrl={''}
          profileUrl={''}
        >
          <div className={'error-message'}>
            {donee.error.message || 'Unable to load the organization.'}
          </div>
        </StyledDiv>
      );
  }
};

export default DoneeDialog;
