import { LoginResponse, DonorWithWallet } from './interfaces';
import { axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';
import { webConfig } from '../../utils/webConfig';

export interface FacebookLoginResponse {
  status: undefined;
  id: string;
  userID: string;
  accessToken: string;
  name?: string;
  email?: string;
  picture?: {
    data: {
      height?: number;
      is_silhouette?: boolean;
      url?: string;
      width?: number;
    };
  };
}

const loginUrl = '/online-giving/access/email/signin';
export const login = (username: string, password: string) => {
  const httpRequest = axiosClient.post(loginUrl, {
    emailAddress: username,
    password
  });
  return makeApiRequest<LoginResponse>(httpRequest);
};

const getProfileUrl = '/donor/profile?expand=wallets';
export const getProfile = () => {
  const httpRequest = axiosClient.get(getProfileUrl);
  return makeApiRequest<DonorWithWallet>(httpRequest);
};

interface ActivateRequestBody {
  expiryTime: number | string | undefined | null;
  hash: number | string | undefined | null;
  email: number | string | undefined | null;
  id: number | string | undefined | null;
  signature: number | string | undefined | null;
}

const activateDonorProfileUrl = '/online-giving/access/email/activate';
export const activateDonorProfile = (body: ActivateRequestBody) => {
  const httpRequest = axiosClient.post(activateDonorProfileUrl, body);
  return makeApiRequest<DonorWithWallet>(httpRequest);
};

const resendActivateEmailUrl = '/online-giving/access/email/verify';
export const resendActivateEmail = (body: { emailAddress: string }) => {
  const httpRequest = axiosClient.post(resendActivateEmailUrl, body);
  return makeApiRequest<DonorWithWallet>(httpRequest);
};

interface ActivateEmailRequestBody {
  token: string | undefined | null;
  resend: boolean;
}

const activateDonorEmailUrl = '/online-giving/access/email/change';
export const activateDonorEmail = (body: ActivateEmailRequestBody) => {
  const httpRequest = axiosClient.post(activateDonorEmailUrl, body);
  return makeApiRequest<DonorWithWallet>(httpRequest);
};

export const getTokenWithRefresh = (refreshToken: string) => {
  const httpRequest = axiosClient.post(webConfig.refreshTokenBaseUrl, {
    refreshToken
  });
  return makeApiRequest<LoginResponse>(httpRequest);
};

export interface FacebookLoginRequestBody {
  facebookId: string;
  facebookToken: string;
  emailAddress: string | undefined;
}

const FACEBOOK_SIGN_IN_AND_SIGNUP_URL = '/online-giving/access/facebook';
export const facebookLoginRequest = (body: FacebookLoginRequestBody) => {
  const httpRequest = axiosClient.post(FACEBOOK_SIGN_IN_AND_SIGNUP_URL, body);
  return makeApiRequest<LoginResponse>(httpRequest);
};
