import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Email from '../Landing/Email';
import Alert from '@material-ui/lab/Alert/Alert';
import { Textfield } from '../Textfield';
import { Text } from '../../../Donee/components/common/Text/Text';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { ModalRouterProps } from '../../Router';
import { ContentStyles } from '../../utils';
import { ForgotPasswordModal } from '../../components/ForgotPasswordModal';
import { useDonorLogin } from '../../../../providers/DonorProvider/useDonorLogin';
import { somethingWrongMsg } from '../../../../utils/messages';
import { useLoginToastContext } from '../../../../providers/LoginToastProvider';
import { setLoginTypeLocalStorage } from '../../../../providers/DonorProvider/donorLocalStorage';
import { useQueryString } from '../../../../hooks/useQueryString';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .main-text {
    margin-top: 4px;
    margin-bottom: 24px;
  }
  .password-field {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .alert {
    margin-bottom: 18px;
  }
  .password-field-icon {
    cursor: pointer;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginProps extends ModalRouterProps {}

export const Login: React.FC<LoginProps> = ({ user }) => {
  const query = useQueryString();
  const email = query.get('login')?.replace(/ /g, '+');
  const { handleOpenLoginToast } = useLoginToastContext();
  const { state, login } = useDonorLogin();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [showForgotPasswordSuccess, setForgotPasswordSuccess] =
    useState<boolean>(false);
  const [openForgotPassword, setOpenForgotPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = useCallback(() => {
    setShowPassword((s) => !s);
  }, [setShowPassword]);

  useEffect(() => {
    if (state.type === 'REQUEST_SUCCESS') {
      handleOpenLoginToast();
      setLoginTypeLocalStorage(undefined, undefined, 'password');
    }
  }, [state, handleOpenLoginToast]);

  const handleForgotPasswordMessageClose = useCallback(() => {
    setForgotPasswordSuccess(false);
  }, [setForgotPasswordSuccess]);

  const handleForgotPasswordSuccess = useCallback(() => {
    setForgotPasswordSuccess(true);
    setOpenForgotPassword(false);
  }, [setForgotPasswordSuccess]);

  const handleForgotPasswordClose = useCallback(
    () => setOpenForgotPassword(false),
    [setOpenForgotPassword]
  );

  const handleForgotPassword = useCallback(() => {
    setOpenForgotPassword(true);
  }, [setOpenForgotPassword]);

  const handleSubmit = () => {
    const email = emailInputRef.current?.value;
    const password = passwordInputRef.current?.value;
    if (!email) {
      setEmailError('*This field must be a valid email address');
      return;
    }
    emailError !== '' && setEmailError('');
    if (!password) {
      setPasswordError('*Password is required');
      return;
    }
    passwordError !== '' && setPasswordError('');
    login(email, password);
  };

  return (
    <>
      <StyledDiv>
        <div>
          {showForgotPasswordSuccess && (
            <Alert
              color={'success'}
              className={'alert'}
              onClick={handleForgotPasswordMessageClose}
            >
              We emailed you the reset link.
            </Alert>
          )}
          <Text
            variant={'medium'}
            weight={'extraBold'}
            color={'primarySecondaryContent'}
          >
            We remember you!
          </Text>
          <Text
            variant={'large'}
            weight={'extraBold'}
            className={'main-text'}
          >
            Let&#39;s get you signed in
          </Text>
          <Email
            defaultValue={
              user.email || (email && email !== 'true' ? email : undefined)
            }
            inputRef={emailInputRef}
            emailError={emailError}
          />
          <Textfield
            inputRef={passwordInputRef}
            id={'password'}
            type={showPassword ? 'text' : 'password'}
            icon={showPassword ? 'pass_hide' : 'pass_show'}
            onIconClick={handleShowPassword}
            iconClassName={'password-field-icon'}
            placeholder={'Password'}
            maxLength={255}
            className={'password-field'}
            errorText={passwordError}
          />
          {state.type === 'REQUEST_ERROR' && (
            <Text
              variant={'xSmall'}
              color={'red'}
              weight={undefined}
            >
              {state.error.message || somethingWrongMsg}
            </Text>
          )}
          <Text
            variant={'small'}
            weight={'semiBold'}
            onClick={handleForgotPassword}
            color={'primaryGuidingViolet'}
            className={'forgot-password'}
          >
            I forgot my password
          </Text>
        </div>
        <div className={'continue'}>
          <GivelifyButton
            onClick={handleSubmit}
            isLoading={state.type === 'REQUEST_START'}
          >
            Continue
          </GivelifyButton>
        </div>
      </StyledDiv>
      <ForgotPasswordModal
        email={user.email}
        open={openForgotPassword}
        onClose={handleForgotPasswordClose}
        onSuccess={handleForgotPasswordSuccess}
      />
    </>
  );
};
