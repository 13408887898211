import React from 'react';
import CreditCardForm, {
  PaymentFormProps,
  PaymentRoutes
} from './CreditCardForm';
import AddressForm from './AddressForm';
import AddressCompleteForm from './AddressCompleteForm';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';

interface CardDetailsFormProps extends Omit<PaymentFormProps, 'setRoute'> {
  route: PaymentRoutes;
  setRoute: React.Dispatch<React.SetStateAction<PaymentRoutes>>;
  addCard: (card: Wallet) => unknown;
  handlePaymentModalClose: () => unknown;
}

const CardDetailsForm: React.FC<CardDetailsFormProps> = ({
  route,
  user,
  setRoute,
  setUser,
  addCard,
  handlePaymentModalClose
}) => {
  switch (route) {
    case 'CREDIT':
      return (
        <CreditCardForm
          user={user}
          setUser={setUser}
          setRoute={setRoute}
        />
      );
    case 'ADDRESS':
      return (
        <AddressForm
          user={user}
          setUser={setUser}
          setRoute={setRoute}
        />
      );
    case 'ADDRESS_COMPLETE':
      return (
        <AddressCompleteForm
          user={user}
          setUser={setUser}
          setRoute={setRoute}
          addCard={addCard}
          handlePaymentModalClose={handlePaymentModalClose}
        />
      );

    default:
      return null;
  }
};

export default CardDetailsForm;
