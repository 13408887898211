import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../theme/colors';
import { noSelect } from '../../../common/Text/styles';

interface MessageCapsuleButtonsProps {
  setMessage: (text: string) => unknown;
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 36px;
  margin-bottom: 24px;
  .capsule {
    ${noSelect}
    cursor: pointer;
    margin-right: 8px;
    padding: 12px 16px;
    border-radius: 24px;
    color: ${colors.primarySecondaryContent};
    border: 1px solid ${colors.utilitySeparatorColor};
    background-color: ${colors.utilityBackgroundLightColor};
  }
`;

const MessageCapsuleButtons: React.FC<MessageCapsuleButtonsProps> = ({
  setMessage
}) => {
  return (
    <StyledDiv>
      <div
        className={'capsule'}
        onClick={() => setMessage('Thankful for ')}
      >
        Thankful for...
      </div>
      <div
        className={'capsule'}
        onClick={() => setMessage('In memory of ')}
      >
        In memory of...
      </div>
    </StyledDiv>
  );
};

export default React.memo(MessageCapsuleButtons);
