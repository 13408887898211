import styled from 'styled-components';
import { assetUrl } from '../../utils/webConfig';
import { Link } from 'react-router-dom';
import layout from '../../components/theme/layout';
import ContentLayout from '../../components/Donee/components/common/Layout/Content';
import SolidButton from '../../components/Donee/components/common/Button/SolidButton';
import WrapperButton from '../../components/Donee/components/common/Button/WrapperButton';

const { breakpoints } = layout;
const { desktop, laptop, tablet, mobile } = breakpoints;

export const RecurringContainer = styled(ContentLayout)`
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: ${mobile.max}px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const CancelRecurringLink = styled.div`
  cursor: pointer;
`;

export const DonationsDataLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DonationsDatavalue = styled.div`
  float: right;
  text-align: right;
`;

export const IconBox = styled.div`
  display: inline-block;
  margin-right: 3px
  float: left;
  margin-top: 2px;
`;

export const ModalSection = styled.div`
  margin-top: 24px;
  text-align: center;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #ababab;
  font-size: 20px;
  padding: 16px;
`;

export const ModalBody = styled.div``;

export const ModalFooter = styled.div`
  justify-content: flex-end;
  padding-top: 16px;
`;

export const ConfirmButton = styled(SolidButton).attrs({
  color: 'skyblue',
  size: 'small',
  raised: 'skyblue'
})``;

export const CancelButton = styled(SolidButton).attrs({
  color: 'transparent',
  size: 'medium',
  width: 'link',
  raised: 'transparent',
  weight: 'regular'
})`
  margin-top: 15px;
`;

export const TooltipAction = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: absolute;
  top: 100%;
  right: 10px;
  padding: 10px 15px;
  -webkit-box-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  min-width: 205px;
  z-index: 2;
  & * {
    cursor: pointer;
  }
`;

export const TooltipOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const PageLoading = styled.div`
  position: relative;
  min-height: 200px;
  :after {
    background: url(${assetUrl('/images/history-loading.gif?v=1')}) no-repeat
      scroll center 70px #fff;
    position: absolute;
    width: 100%;
    min-height: 200px;
    content: '';
    z-index: 1;
    top: 0px;
    height: 100%;
  }
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;

export const PaymentInfoContainer = styled.div`
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  transition: all 0.1s linear;
  text-align: center;
`;

export const PaymentInfoPreview = styled.div`
  display: flex;
  align-items: center;
`;

export const PaymentInfoCardPreview = styled.div`
  padding: 0 12px;
`;

export const PaymentLink = styled(Link)`
  text-decoration: none;
  opacity: 0.9;
`;

export const PaymentButtonLink = styled(WrapperButton)`
  text-decoration: none;
`;
