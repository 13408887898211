import { BasicErrorBoundary } from './BasicErrorBoundary';
import { log, configure, configureUserData } from './logger';
import { UserType, LogLevel } from './models';

export const Logger = {
  configure,
  configureUserData,
  LogLevel,
  UserType,
  BasicErrorBoundary,
  log
};
