import styled, { css } from 'styled-components';
import { textSizes, textColors, textWeights, lineHeights } from './constants';
import colors from '../../../../theme/colors';
import layout from '../../../../theme/layout';

const { breakpoints } = layout;
const { mobile } = breakpoints;

const noSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const H2 = styled.h2<{
  color: keyof typeof colors;
  size: keyof typeof textSizes;
  weight: keyof typeof textWeights;
  selectable?: boolean;
}>`
  color: ${(props) => textColors[props.color]};
  font-size: ${(props) => textSizes[props.size]}px;
  font-weight: ${(props) => textWeights[props.weight]};
  line-height: ${(props) => lineHeights[props.size]}px;
  ${(props) => (props.selectable ? '' : noSelect)};
  @media screen and (max-width: ${mobile.max}px) {
    font-size: 20px;
  }
  margin-bottom: 16px;
`;
