import React from 'react';
import {
  sizes,
  shadowTypes,
  backgroundColors,
  defaultShadowType,
  defaultSize,
  defaultColor
} from './constants';
import Icon, { IconProps } from '../../Icon';
import { StyledIconButton } from './styles';

interface IconButtonProps {
  size?: keyof typeof sizes;
  color?: keyof typeof backgroundColors;
  raised?: keyof typeof shadowTypes;
  name: IconProps['name'];
  iconProps?: IconProps;
  width?: any;
  onClick?: any;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  name,
  size = defaultSize,
  color = defaultColor,
  raised = defaultShadowType,
  iconProps,
  ...props
}) => {
  return (
    <StyledIconButton
      raised={raised}
      color={color}
      size={size}
      {...props}
    >
      <Icon
        size={size}
        {...iconProps}
        name={name}
      />
    </StyledIconButton>
  );
};

export default IconButton;
