import { axiosClient } from '../../../../../utils/axiosClient';
import { makeApiRequest } from '../../../../../utils/makeApiRequest';

export interface GiftHistoryResponse {
  data: {
    donee: {
      id: string; // '1071126600880962'
      name: string; // 'Anointed City of the Lord'
      address: string; // '47 S Pennsylvania St, Indianapolis, IN, 46204'
      canAcceptDonation: boolean;
    };
    envelopes: {
      amount: number;
      id: number;
      name: string; // 'Tithe'
    }[];
    gift: {
      accountType: 'Visa';
      amount: number;
      approvalCode: string; // '000058'
      date: string; // 'Dec 12 2023 @ 2:58 PM EST'
      isoDate: string; // '2023-12-12T19:58:42.000000Z'
      maskedPan: string; // 'XXXXXXXXXXXX1111'
      receiptNumber: number; // 231244097916
      recurringId: number; // 0
      txnId: number; // 44097916
      uuid: string; // 'bacadf01-787a-4590-9f3c-45293d53516a'
    };
    memos: [];
  }[];
  links: {
    first: string | null; // 'https://donor.qa.givelify.com/donor/profile/gifts-history?year=2023&page=1'
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    currentPage: number;
    from: number;
    giftsTotal: number;
    giftsYears: number[];
    lastPage: number;
    path: string; // 'https://donor.qa.givelify.com/donor/profile/gifts-history'
    perPage: number;
    to: number;
    total: number;
  };
}

interface GiftHistoryRequestProps {
  year?: number | string;
  page?: number | string;
  perPage?: number | string;
}

const GIFTS_HISTORY_URL = '/donor/profile/gifts-history';
export const getGiftHistoryRequest = (data: GiftHistoryRequestProps) => {
  let params = '';
  if (data.year) {
    params = `year=${data.year}`;
    if (data.page) {
      params = `${params}&page=${data.page}`;
    }
    if (data.perPage) {
      params = `${params}&per_page=${data.perPage}`;
    }
  }

  const httpRequest = axiosClient.get(`${GIFTS_HISTORY_URL}?${params}`);
  return makeApiRequest(httpRequest);
};

const getGiftDetailsUrl = (id: string | number) => `/donor/profile/gifts/${id}`;
export function getGiftDetails(giftId: string) {
  const httpRequest = axiosClient.get(getGiftDetailsUrl(giftId));
  return makeApiRequest(httpRequest);
}
