import { ROUTES } from '../../utils/routes';

export const signInLabel = 'Sign In';
export const profileDetailLabel = 'Profile Details';
export const historyLabel = 'History';
export const walletLabel = 'Wallet';
export const recurringLabel = 'Recurring';
export const supportLabel = 'Support, etc.';

export const links = {
  guest: [
    {
      text: signInLabel,
      path: ROUTES.login
    }
  ],
  authenticated: [
    {
      text: profileDetailLabel,
      path: ROUTES.profile(),
      icon: 'profile-icon'
    },
    {
      text: historyLabel,
      path: ROUTES.history,
      icon: 'history-icon'
    },
    {
      text: walletLabel,
      path: ROUTES.wallet,
      icon: 'wallet-icon'
    },
    {
      text: recurringLabel,
      path: ROUTES.recurring,
      icon: 'recurring-icon'
    },
    {
      text: supportLabel,
      path: ROUTES.support,
      icon: 'support-icon'
    }
  ]
};
