import React from 'react';
import { Row, Col, Container } from '@bootstrap-styled/v4';
import { Footer, GivingAppsLinks } from './styles';
import { Text } from '../Donee/components/common/Text/Text';
import {
  FooterLogo,
  Footerlink,
  LeftSide,
  LogoImg,
  RightSide
} from '../Donee/components/common/Layout/styles';
import { assetUrl } from '../../utils/webConfig';
import DownloadAppButtons from './DownloadAppButtons';

const FooterComponent: React.FC = () => (
  <Footer className={'footer-main'}>
    <Container className={'footer-content'}>
      <Row>
        <Col
          md={3}
          className={'footer-logo'}
        >
          <FooterLogo>
            <Text
              className={'footer-logo-p'}
              variant="xSmall"
              weight={'light'}
              color={undefined}
            >
              Online giving app by
            </Text>
            <Footerlink
              href="https://www.givelify.com/donors"
              target={'_blank'}
            >
              <LogoImg
                alt={'Givelify Logo'}
                src={assetUrl('/images/svg/footer-logo.svg')}
              />
            </Footerlink>
          </FooterLogo>
        </Col>
        <Col md={9}>
          <RightSide className={'footer-right-part'}>
            <LeftSide className={'footer-inner-left-part'}>
              <Text
                className={'footer-p'}
                variant="xxSmall"
                weight={'light'}
                color={undefined}
              >
                For the most beautiful experience, get the Givelify mobile
                giving app.
              </Text>
            </LeftSide>
            <LeftSide className={'footer-inner-left-part'}>
              <GivingAppsLinks>
                <DownloadAppButtons />
              </GivingAppsLinks>
            </LeftSide>
          </RightSide>
        </Col>
      </Row>
    </Container>
  </Footer>
);

export default FooterComponent;
