import ReactModal from 'react-modal';

if (!ReactModal.defaultStyles.overlay) {
  ReactModal.defaultStyles.overlay = {};
}

ReactModal.defaultStyles.overlay.backgroundColor = '#F1F0F7CC';

export const defaults = {
  ariaHideApp: false,
  closeTimeoutMS: 400,
  parentSelector: () => document.body
};
