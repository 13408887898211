import React, { useEffect, useMemo, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';
import HistoryDetail from './HistoryDetail';
import HistoryList from './HistoryList';
import { useDonor } from '../../../../../providers/DonorProvider/useDonor';
import { ById, axiosClient } from '../../../../../utils/axiosClient';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import { historyShown } from '../../../../../hooks/PushEvents/clevertapEvents';
import { webConfig } from '../../../../../utils/webConfig';
import GetAppModal, { isMobile } from '../../../../GetAppModal';
import { GiftHistoryResponse, getGiftHistoryRequest } from './api';
import { useApiRequest } from '../../../../../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../../../../../utils/makeApiRequest';
import { useDonorContext } from '../../../../../providers/DonorProvider/DonorProvider';
import { ROUTES } from '../../../../../utils/routes';
import { HeartLoading } from '../../../../HeartLoading';
import {
  GiftSummaryResponse,
  getGiftSummaryRequest
} from '../../../../../pages/DonorProfileDetails/api';
import ForceAppDownload from './ForceAppDownload';
import { useEnabledFeatures } from '../../../../../hooks/EnabledFeatures';

const MAX_GIFT_COUNT = 3;
const countPerPage = 50;
const calendarYear = new Date().getFullYear();

export default function History() {
  const { isFeatureEnabled } = useEnabledFeatures();
  const [open, setOpen] = useState<boolean>(true);
  const { pushEvent } = usePushEvents();
  const [giftSummaryRequest, makeGiftSummaryRequest] =
    useApiRequest<GivingApiResponse<GiftSummaryResponse>>();
  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<GiftHistoryResponse>>();
  const [error, setError] = useState('');
  const [yearArr, setYearArr] = useState<number[]>([]);
  const [yearMetas, setYearMetas] = useState<ById<GiftHistoryResponse['meta']>>(
    {}
  );
  const [historyItems, setHistoryItems] = useState<any>([]);
  const [currentYear, setCurrentYear] = useState<number>(calendarYear);
  const [exportPopup, setExportPopup] = useState(false);
  const [total, setTotal] = useState<ById<string> | undefined>();
  const [exporter, setExporter] = useState(false);
  const [exportError, setExportError] = useState<string | undefined>('');
  const [historyDetailInfo, setHistoryDetailInfo] = useState<
    | (GiftHistoryResponse['data'][number] & { detailType: 'history' })
    | undefined
  >(undefined);
  const [serverError, setServerError] = useState('');
  const [startPosition, setStartPosition] = useState(0);
  const [success, setSuccess] = useState<string | undefined>();

  const { donorContext } = useDonorContext();
  const { donor } = useDonor() || {};
  const push = useNavigate();

  const isLoadMore = useMemo(() => {
    if (yearMetas) {
      const metaInfo = yearMetas[currentYear];
      if (metaInfo) {
        return metaInfo.currentPage < metaInfo.lastPage;
      }
    }
    return false;
  }, [yearMetas, currentYear]);

  useEffect(() => {
    if (donorContext.donor.type === 'REQUEST_ERROR') {
      push(ROUTES.login);
    }
  }, [donorContext.donor.type]);

  useEffect(() => {
    makeGiftSummaryRequest(getGiftSummaryRequest());
    pushEvent(historyShown);
  }, []);

  useEffect(() => {
    if (requestState.type === 'REQUEST_INIT') {
      makeRequest(getGiftHistoryRequest({ year: calendarYear }));
      return;
    }
    if (requestState.type === 'REQUEST_ERROR') {
      setServerError(requestState.error.message);
    }
    if (requestState.type !== 'REQUEST_SUCCESS') return;
    const payload = requestState.data.response.payload;
    const yearList = payload.meta.giftsYears;
    if (!yearList.includes(currentYear)) {
      yearList.unshift(currentYear);
    }
    const items: ById<GiftHistoryResponse['data']> = {};
    const totalInfos: ById<string> = {};
    const metaInfos: ById<GiftHistoryResponse['meta']> = {};
    yearList.forEach((year) => {
      items[year] = [];
      totalInfos[year] = '0';
      metaInfos[year] = undefined;
    });
    totalInfos[currentYear] = parseFloat(payload.meta.giftsTotal.toString())
      .toFixed(2)
      .replace('.00', '');
    items[currentYear] = [...payload.data];
    metaInfos[currentYear] = payload.meta;
    setYearArr(yearList);
    setTotal(totalInfos);
    setYearMetas(metaInfos);
    setHistoryItems(items);
    setExporter(true);
  }, [requestState]);

  useEffect(() => {
    if (!currentYear) {
      return;
    }
    if (!historyItems || !historyItems[currentYear]?.length) {
      getYearItems(1);
    } else {
      const newYearArr = [...yearArr];
      setYearArr(newYearArr);
    }
  }, [currentYear]);

  const getYearItems = (pageNum: number) => {
    const data = {
      year: currentYear,
      page: pageNum,
      perPage: countPerPage
    };
    makeRequest(getGiftHistoryRequest(data));
  };

  const loadMoreItems = () => {
    const metaInfo = yearMetas[currentYear];
    if (metaInfo && metaInfo.currentPage < metaInfo.lastPage) {
      getYearItems(metaInfo.currentPage + 1);
    }
  };

  const closeMessage = () => () => {
    setExportError(undefined);
    setSuccess('');
  };

  const exitDetail = () => {
    setHistoryDetailInfo(undefined);
  };

  const handleHistoryDetailInfo = (
    data: GiftHistoryResponse['data'][number]
  ) => {
    setHistoryDetailInfo({
      ...data,
      detailType: 'history'
    });
  };

  const changeType = (type: number) => {
    setCurrentYear(type);
  };

  const dateFormat = (value: string) => {
    const timestamp = value.split(' ');
    const month = timestamp['0'];
    const date = timestamp['1'];
    const year = timestamp['2'];
    return `${month} ${date}, ${year}`;
  };

  const exportData = () => {
    setExportError(undefined);
    if (!donor) return;
    const data = {
      donor_id: donor.id,
      email: donor.email,
      year: currentYear
    };

    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    axiosClient
      .post(
        webConfig.baseAPIUrl +
          `/donor/profile/gifts-export?year=${currentYear}`,
        data,
        headers
      )
      .then((response: any) => {
        if (response.status === 200) {
          setSuccess(response.data.response.payload.message);
          setExportPopup(false);
        } else {
          setExportError(response.data.Response.Error.message);
        }
      })
      .catch((e) => {
        setExportError(
          e.response?.data?.response?.errorMessage ||
            'We are unable to email you at the moment, please try again!'
        );
      });
  };

  const exportPopupModalClose = () => {
    setExportPopup(false);
  };

  const exportPopupModalOpen = () => {
    setExportPopup(true);
  };

  const handleOnChanged = (value: any) => {
    if (typeof value.item !== 'undefined') {
      if (typeof value.item.index !== 'undefined') {
        if (value.item.index !== null && startPosition !== value.item.index) {
          setStartPosition(value.item.index);
        }
      }
    }
  };

  if (
    giftSummaryRequest.type === 'REQUEST_INIT' ||
    giftSummaryRequest.type === 'REQUEST_START' ||
    donorContext.donor.type === 'REQUEST_INIT' ||
    donorContext.donor.type === 'REQUEST_START'
  ) {
    return <HeartLoading maxWidth={300} />;
  }

  if (
    isMobile &&
    isFeatureEnabled('hide-giving-history') &&
    giftSummaryRequest.type === 'REQUEST_SUCCESS'
  ) {
    const giftCount = giftSummaryRequest.data?.response.payload.all.count;
    if (giftCount && giftCount < MAX_GIFT_COUNT) {
      return <ForceAppDownload giftCount={giftCount} />;
    }
  }

  if (historyDetailInfo) {
    return (
      <HistoryDetail
        details={historyDetailInfo}
        exitDetail={exitDetail}
      />
    );
  }

  return (
    <>
      <GetAppModal
        open={open}
        setOpen={setOpen}
      />
      <HistoryList
        email={donor?.email}
        exporter={exporter}
        exportError={exportError}
        exportPopup={exportPopup}
        exportPopupModalOpen={exportPopupModalOpen}
        exportPopupModalClose={exportPopupModalClose}
        exportData={exportData}
        currentYear={currentYear}
        closeMessage={closeMessage}
        success={success}
        serverError={serverError}
        setServerError={setServerError}
        handleOnChanged={handleOnChanged}
        startPosition={startPosition}
        changeType={changeType}
        loader={
          requestState.type === 'REQUEST_INIT' ||
          requestState.type === 'REQUEST_START'
        }
        historyItems={historyItems}
        total={total}
        error={error}
        handleHistoryDetailInfo={handleHistoryDetailInfo}
        dateFormat={dateFormat}
        yearArr={yearArr}
        loadMoreItems={loadMoreItems}
        isLoadMore={isLoadMore}
      />
    </>
  );
}
