import React from 'react';
import { useSolidButtonV2Styles } from '../SolidButton';
import { Button, CircularProgress, PropTypes } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../../theme/colors';

interface ButtonStyleProps {
  isLoading?: boolean;
}

const StyledTransparentButton = styled.div`
  & .MuiButtonBase-root {
    border-radius: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    box-shadow: none;
    color: ${colors.primaryGuidingViolet};
    background-color: ${colors.utilityGuidingVioletLight};
  }
  & .MuiButtonBase-root:hover {
    box-shadow: none;
    background-color: ${colors.utilityGuidingVioletDark};
  }
`;

const StyledButton = styled.div<ButtonStyleProps>`
  position: relative;
  & .MuiButtonBase-root:hover {
    color: ${colors.white};
    background-color: ${colors.buttonsHoverViolet};
  }
  & .MuiButtonBase-root {
    font-size: 16px;
    color: ${colors.white};
    margin-top: 0;
    margin-bottom: 0;
    height: 52px;
    min-height: 52px;
    max-height: 52px;
    max-width: 100%;
    cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
    pointer-events: ${({ isLoading }) => (isLoading ? 'none' : undefined)};
    background-color: ${({ isLoading }) =>
      isLoading
        ? `${colors.utilityTabColor} !important`
        : colors.primaryGuidingViolet};
  }
  & .loader {
    position: absolute;
    top: 50%;
    right: 14px;
    margin-top: -12px;
    margin-left: -12px;
    color: ${colors.white};
  }
`;

export interface ButtonProps {
  id?: string;
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  color?: PropTypes.Color;
  variant?: 'button' | 'transparent';
  onHover?: () => unknown; // same as onMouseEnter
  onMouseEnter?: () => unknown;
  children: React.ReactNode;
  onClick?: () => unknown;
}

export const GivelifyButton: React.FC<ButtonProps> = ({
  id,
  isLoading,
  className,
  disabled,
  onHover,
  onMouseEnter,
  onClick,
  color = 'primary',
  variant = 'button',
  children
}) => {
  const { solidButton, solidOrangeButton } = useSolidButtonV2Styles();
  if (variant === 'transparent') {
    return (
      <StyledTransparentButton className={className}>
        <Button
          id={id}
          fullWidth
          disabled={disabled}
          color={color}
          variant={'contained'}
          onClick={onClick}
          onMouseEnter={onMouseEnter || onHover}
        >
          {children}
        </Button>
      </StyledTransparentButton>
    );
  }
  return (
    <StyledButton
      isLoading={isLoading}
      className={className}
    >
      <Button
        id={id}
        fullWidth
        disabled={disabled}
        color={color}
        variant={'contained'}
        onClick={onClick}
        onMouseEnter={onMouseEnter || onHover}
        className={color === 'secondary' ? solidOrangeButton : solidButton}
      >
        {children}
      </Button>
      {isLoading && (
        <CircularProgress
          size={24}
          thickness={5}
          className={'loader'}
        />
      )}
    </StyledButton>
  );
};
