import React, { useCallback } from 'react';
import {
  isAndroidDevice,
  isAppleDevice
} from '../Donee/components/common/utils';
import { assetUrl } from '../../utils/webConfig';
import { GivingAppPlatformStoreImage } from './styles';
import { usePushEvents } from '../../hooks/PushEvents';
import { appDownloadEvent } from '../../hooks/PushEvents/clevertapEvents';

const appStoreUrl =
  'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_footer&mt=8';
const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_footer';

interface DownloadAppButtonsProps {
  className?: string;
}

const DownloadAppButtons: React.FC<DownloadAppButtonsProps> = ({
  className
}) => {
  const { pushEvent } = usePushEvents();

  const handleAndroid = useCallback(() => {
    pushEvent(appDownloadEvent, {
      location: 'Footer',
      platform: 'android',
      action: 'clicked'
    });
    // copyDoneeIdToClipboard();
  }, [pushEvent]);
  const handleIos = useCallback(() => {
    pushEvent(appDownloadEvent, {
      location: 'Footer',
      platform: 'iOS',
      action: 'clicked'
    });
    // copyDoneeIdToClipboard();
  }, [pushEvent]);

  return (
    <div className={className}>
      {isAndroidDevice() ? null : (
        <a
          target={'_blank'}
          href={appStoreUrl}
          rel={'noreferrer'}
          onClick={handleIos}
        >
          <GivingAppPlatformStoreImage
            src={assetUrl('/images/svg/app-store-badge.svg')}
          />
        </a>
      )}
      {isAppleDevice() ? null : (
        <a
          target={'_blank'}
          href={googlePlayUrl}
          rel={'noreferrer'}
          onClick={handleAndroid}
        >
          <GivingAppPlatformStoreImage
            src={assetUrl('/images/svg/google-play-badge.svg')}
          />
        </a>
      )}
    </div>
  );
};

export default DownloadAppButtons;
