import styled from 'styled-components';
import colors from '../../../../../theme/colors';
import { assetUrl } from '../../../../../../utils/webConfig';

export const RadioOptionRow = styled.div`
  opacity: 1;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:active {
    opacity: 0.8;
    transition: all 0.1s ease-in-out;
  }
  position: relative;
`;

export const RadioOptionButtonContainer = styled.div<{ selected?: boolean }>`
  align-items: center;
  justify-content: center;
  border-color: ${(props) =>
    props.selected ? colors.primaryBlue : colors.grey3};
`;

export const RadioOptionButton = styled.div<{ selected?: boolean }>`
  max-width: 20px;
  max-height: 20px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? colors.primaryBlue : colors.transparent};
  transition: all 0.15s ease-in-out;
`;

export const CardRadioOptionButton = styled.div<{ selected?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${(props) => {
    if (props.selected) {
      const radioIconImage = assetUrl('/images/svg/radio-icon.svg');
      return `url(${radioIconImage}) no-repeat ${colors.white} -1px -1px`;
    }
    return `${colors.white} -1px -1px`;
  }};
  transition: all 0.15s ease-in-out;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.selected ? colors.primaryBlue : colors.grey3};
  position: absolute;
  top: 13px;
  right: 0px;
`;
