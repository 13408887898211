// export const getV3CaptchaScript = (GOOGLE_SITE_SECRET_KEY) => {
//   const script = document.createElement('script');
//   script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_SITE_SECRET_KEY}`;
//   script.async = true;
//   script.defer = true;
//   document.body.appendChild(script);
//   return script;
// };

export const appStoreLink =
  'https://apps.apple.com/us/app/givelify-mobile-giving-app/id725815127';
export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&hl=en_US&gl=US';

const userAgent = navigator.userAgent || navigator.vendor || window.opera;
export const isAndroidDevice = () => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return false;
  }
  if (/android/i.test(userAgent)) {
    return true;
  }
  return false;
};

export const isAppleDevice = () => {
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }
  return false;
};
