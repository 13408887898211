import React from 'react';
import { Row, Col, Container, Ul, Li } from '@bootstrap-styled/v4';
import { MainSection, Text, H2, IconButton } from '../common';
import { SupportLink, PreviousArrow, SocialLink } from './styles';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { assetUrl, webConfig } from '../../../../utils/webConfig';
import { supportShown } from '../../../../hooks/PushEvents/clevertapEvents';

const DonorSupport: React.FC = () => {
  const { pushEvent } = usePushEvents();

  React.useEffect(() => {
    pushEvent(supportShown);
  }, []);

  return (
    <div className={'mainpart'}>
      <Container>
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <center>
                <H2
                  size={'large'}
                  color={'grayscaleBlack'}
                  weight={'medium'}
                  value={undefined}
                >
                  Support
                </H2>
              </center>
              <br />
              <Row className={'justify-content-center'}>
                <Col
                  md={8}
                  sm={10}
                >
                  <Ul>
                    <Li>
                      <SupportLink
                        href={webConfig.faqURL}
                        target={'_blank'}
                      >
                        <Text
                          size={'medium2'}
                          color={'grey3'}
                          weight={undefined}
                          value={undefined}
                        >
                          FAQ
                        </Text>
                        <PreviousArrow
                          src={assetUrl('/images/svg/previous_arrow.svg')}
                        />
                      </SupportLink>
                    </Li>
                    <Li>
                      <SupportLink
                        href={webConfig.supportContactUrl}
                        target={'_blank'}
                      >
                        <Text
                          size={'medium2'}
                          color={'grey3'}
                          weight={undefined}
                          value={undefined}
                        >
                          Contact Us
                        </Text>
                        <PreviousArrow
                          src={assetUrl('/images/svg/previous_arrow.svg')}
                        />
                      </SupportLink>
                    </Li>
                    <Li>
                      <SupportLink
                        href={webConfig.termsServiceURL}
                        target={'_blank'}
                        last
                      >
                        <Text
                          size={'medium2'}
                          color={'grey3'}
                          weight={undefined}
                          value={undefined}
                        >
                          Terms of Service
                        </Text>
                        <PreviousArrow
                          src={assetUrl('/images/svg/previous_arrow.svg')}
                        />
                      </SupportLink>
                    </Li>
                  </Ul>
                  <br />
                  <br />
                  <center>
                    <Text
                      size={'medium'}
                      color={'grey3'}
                      weight={'medium'}
                      value={undefined}
                    >
                      Follow Us
                    </Text>
                    <Ul className={'social-group'}>
                      <Li inline>
                        <SocialLink
                          href={webConfig.followFacebookURL}
                          target={'_blank'}
                        >
                          <IconButton
                            className={'social-icon'}
                            name={'facebook-icon'}
                            size={'large'}
                            color={'grey3'}
                            raised={undefined}
                            iconProps={undefined}
                          />
                        </SocialLink>
                      </Li>
                      <Li inline>
                        <SocialLink
                          href={webConfig.followTwitterURL}
                          target={'_blank'}
                        >
                          <IconButton
                            className={'social-icon'}
                            name={'twitter-icon'}
                            size={'large'}
                            color={'grey3'}
                            raised={undefined}
                            iconProps={undefined}
                          />
                        </SocialLink>
                      </Li>
                      <Li inline>
                        <SocialLink
                          href={webConfig.followInstagramURL}
                          target={'_blank'}
                        >
                          <IconButton
                            className={'social-icon'}
                            name={'instagram-icon'}
                            size={'large'}
                            color={'grey3'}
                            raised={undefined}
                            iconProps={undefined}
                          />
                        </SocialLink>
                      </Li>
                      <Li inline>
                        <SocialLink
                          href={webConfig.followLinkedinURL}
                          target={'_blank'}
                        >
                          <IconButton
                            className={'social-icon'}
                            name={'linkedin-icon'}
                            size={'large'}
                            color={'grey3'}
                          />
                        </SocialLink>
                      </Li>
                    </Ul>
                  </center>
                </Col>
              </Row>
            </Col>
          </Row>
        </MainSection>
      </Container>
    </div>
  );
};

export default DonorSupport;
