import React, { useState } from 'react';
import styled from 'styled-components';
import { GivelifyButton } from '../../common/Button/GivelifyButton';
import GivelifyModal from '../../common/Modal/GivelifyModal';
import { Textfield } from '../../../../LoginModal/pages/Textfield';
import { Text } from '../../common/Text/Text';
import colors from '../../../../theme/colors';

const MINIMUM_AMOUNT = 0.5;

interface OtherAmountModalProps {
  open: boolean;
  onSubmit: (value: number) => unknown;
  onClose: () => unknown;
}

const StyledModalContent = styled.div`
  padding-top: 36px;
  .amount-wrapper {
    position: relative;
    .dollar-symbol {
      top: 13px;
      left: 16px;
      z-index: 10;
      position: absolute;
    }
    .other-amount-text-field {
      margin-top: 12px;
      margin-bottom: 12px;
      input {
        border-width: 2px;
        padding-left: 30px;
        &:focus {
          border-color: ${colors.primaryGuidingViolet};
        }
      }
    }
  }
`;

const OtherAmountModal: React.FC<OtherAmountModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  const [amount, setAmount] = useState<number | undefined>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAmount(parseFloat(value) || undefined);
  };

  const handleSubmit = () =>
    amount && amount >= MINIMUM_AMOUNT && onSubmit(amount);

  return (
    <GivelifyModal
      open={open}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
    >
      <StyledModalContent>
        <Text
          variant={'medium'}
          weight={'bold'}
          className={'other-amount-text'}
        >
          Enter dollar amount
        </Text>
        <div className={'amount-wrapper'}>
          <div className={'dollar-symbol'}>$</div>
          <Textfield
            errorText={undefined}
            value={amount}
            onChange={handleChange}
            id={'other-amount-number'}
            autoFocus={true}
            type={'number'}
            placeholder={'Amount'}
            maxLength={7}
            className={'other-amount-text-field'}
          />
        </div>
        <GivelifyButton
          disabled={amount === undefined || amount < MINIMUM_AMOUNT}
          onClick={handleSubmit}
        >
          OK
        </GivelifyButton>
      </StyledModalContent>
    </GivelifyModal>
  );
};

export default OtherAmountModal;
