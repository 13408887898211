import React, { useCallback } from 'react';
import styled from 'styled-components';
import PaymentCard from './PaymentCard';
import GivelifyModal from '../../../common/Modal/GivelifyModal';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { Text } from '../../../common/Text/Text';
import { Secure } from '../../../../../LoginModal/pages/CreditCard/Secure';
import { AddIcon } from '../../../../../icons';

interface ChangePaymentModalProps {
  open: boolean;
  selectedCard: Wallet;
  wallet: Wallet[];
  setCard: (wallet: Wallet) => unknown;
  handleModalClose: () => unknown;
  handleAddPaymentOpen: () => unknown;
}

const StyledDiv = styled.div`
  .heading {
    margin-top: 16px;
  }
  .info {
    margin-top: 6px;
    margin-bottom: 18px;
  }
  .add-payment-button {
    width: 100%;
    border: inherit;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-icon {
      margin-right: 11px;
    }
    & * {
      cursor: pointer;
    }
  }
`;

const ChangePaymentModal: React.FC<ChangePaymentModalProps> = ({
  open,
  selectedCard,
  wallet,
  setCard,
  handleModalClose,
  handleAddPaymentOpen
}) => {
  const onAddPaymentMethod = useCallback(() => {
    handleAddPaymentOpen();
    handleModalClose();
  }, [handleAddPaymentOpen, handleModalClose]);

  const handleCardClick = useCallback(
    (card: Wallet) => {
      setCard(card);
      handleModalClose();
    },
    [setCard, handleModalClose]
  );

  if (!open) {
    return null;
  }
  return (
    <GivelifyModal
      open={true}
      onClose={handleModalClose}
      contentStyles={'height: 475px; overflow: auto;'}
    >
      <StyledDiv>
        <Text
          variant={'large'}
          className={'heading'}
        >
          Change Payment
        </Text>
        <Secure className={'info'}>Your information is secure</Secure>
        <div className={'wallet'}>
          {wallet.map((card) => (
            <PaymentCard
              card={card}
              key={card.id}
              isSelected={selectedCard.id === card.id}
              onClick={handleCardClick}
            />
          ))}
        </div>
        <button
          className={'add-payment-button'}
          onClick={onAddPaymentMethod}
        >
          <AddIcon className={'add-icon'} />
          <Text color={'primarySecondaryContent'}>Add Payment Method</Text>
        </button>
      </StyledDiv>
    </GivelifyModal>
  );
};

export default ChangePaymentModal;
