export default {
  horizontal: {
    xLarge: 40,
    large: 32,
    medium: 24,
    small: 16,
    xSmall: 8
  },

  vertical: {
    xLarge: 40,
    large: 32,
    medium: 24,
    small: 16,
    xSmall: 8
  },
  breakpoints: {
    mobile_sm: {
      max: 575
    },
    mobile: {
      min: 576,
      max: 767
    },
    tablet: {
      min: 768,
      max: 991
    },
    laptop: {
      min: 992,
      max: 1069
    },
    desktop: {
      min: 1070,
      max: 1199
    }
  }
};
