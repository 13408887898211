// Sign In screen events
export const signInScreen = '_signIn_shown';
export const signInOnSignIn = 'SignIn_SignIn_Selected';
export const signInSuccessful = '_signIn_successful';
export const joinOnSignIn = 'SignIn_Join_Selected';
export const errorOnSignIn = '_signIn_failed';
export const forgotPasswordOnSignin = 'SignIn_Forgot_Password';
export const errorOnSignInGetProfile = '_signIn_getprofile_failed';
export const errorOnSignUpGetProfile = '_signUp_getprofile_failed';

// Sign Out event
export const profileLogoutSelected = 'Profile_Logout_Selected';

// Join screen events
export const joinScreen = '_join_shown';
export const joinOnJoin = 'Join_Join_Selected';
export const signInOnJoin = 'Join_SignIn_Selected';
export const joinSuccessful = '_join_successful';
export const errorOnJoin = '_join_failed';
export const tosOnJoin = 'Join_TOS_Selected';

// Main Auth screen events
export const emailOnMainAuth = 'Authentication_Email_Selected';
export const facebookOnMainAuth = 'Authentication_Facebook_Selected';
export const facebookLoginSuccess = '_authentication_fb_successful';
export const errorFacebookLogin = '_authentication_fb_failed';
export const authenticationSignInSelected = 'Authentication_SignIn_Selected';

// Amount Selection screen events
export const giftAmountScreen = '_giftAmounts_shown';
export const amountSelected = 'GiftAmounts_Amount_Selected';
export const otherAmountSelected = 'GiftAmounts_Other_Selected';
export const numberPadOkSelected = 'GiftAmounts_NumberPad_Ok';

// Gift Summary screen
export const giftSummaryScreen = '_giftSummary_shown';
export const addDonationOnGiftSummary = 'GiftSummary_Add_Gift';
export const deleteDonationOnGiftSummary = 'GiftSummary_Remove_Gift';
export const giveNowOnGiftSummary = 'GiftSummary_Give_Selected';
export const errorOnGiftSummary = '_gift_failed';
export const addMemoGiftSummary = 'GiftSummary_Add_Memo';
export const giftDonationsScreen = '_giftSummary_more_shown';
export const removeMemoGiftSummary = 'AddMemo_Clear_Selected';
export const giftSuccessfullySubmitted = '_gift_successful';
export const doneeNotFound = '_donee_not_found';

// Cause Selection screen events
export const giftCauseScreen = '_giftCauses_shown';
export const selectOnGiftCause = 'GiftCauses_Cause_Selected';

// CC Payment
export const giftPaymentScreen = '_choosePayment_shown';
export const paymentChangedOnGiftSummary = 'GiftSummary_Change_Payment';
export const paymentSelectExisting = 'ChoosePayment_ExistingCard_Selected';
export const paymentSelectNew = 'ChoosePayment_NewCard_Selected';

// Gift Recurring screen events
export const giftRecurringScreen = '_recurring_shown';
export const recurringSetOnGiftSummary = 'GiftSummary_Set_Recurring';

// Profile screen events
export const detailOnProfile = 'Profile_ProfileDetails_Selected';
export const historyOnProfile = 'Profile_History_Selected';
export const walletOnProfile = 'Profile_Wallet_Selected';
export const recurringOnProfile = 'Profile_Recurring_Selected';
export const supportOnProfile = 'Profile_Support_Selected';
export const profilePictureAdd = 'Profile_Picture_Add';
export const profilePictureAdded = '_profile_picture_added';
export const profileDetailsShown = '_profileDetails_shown';
export const historyShown = '_history_shown';
export const walletShown = '_wallet_shown';
export const recurringShown = '_recurring_shown';
export const supportShown = '_support_shown';

// Download Banner
export const downloadBannerDownloadClicked = 'DownloadBanner_DownloadApp';
export const downloadBannerDismissedClicked = 'DownloadBanner_Dismiss';

export const thankyouScreenShown = '_thankyouscreen_shown';
export const viewReceiptButtonShown = '_viewReceiptModal_shown';
export const viewReceiptDismissedClicked = 'ViewReceiptModal_Dismiss';
export const appDownloadEvent = 'AppDownload';

// New Login/Signup Flow
export const loginPopup = 'Login_popup_init';
export const loginPopupLoginScreen = 'Login_popup_login_screen';
export const loginPopupSignupScreen = 'Login_popup_signup_screen_init';
export const loginPopupSuccessfulSignup = 'Login_popup_successful_signup';
export const loginPopupSuccessfulSignupAfterFailure =
  'Login_popup_successful_signup_after_card_failure';

export const loginPopupNameScreenShown = '_auth_name_shown';
export const loginPopupNameScreenNext = 'Auth_Name_Next';

export const loginPopupCardScreenShown = '_auth_cardinfo_shown';
export const loginPopupCardScreenNext = 'Auth_CardInfo_Next';

export const loginPopupAddressScreenShown = '_auth_billinaddress_shown';
export const loginPopupAddressScreenNext = 'Auth_BillingAdress_Next';

export const loginPopupAddressCityScreenShown = '_auth_billinaddresscity_shown';
export const loginPopupAddressCityScreenNext = 'Auth_BillingAdressCity_Next';

export const loginPopupPasswordScreenShown = '_auth_password_shown';
export const loginPopupPasswordScreenNext = 'Auth_Password_Next';

export const lastLoginBannerShown = '_auth_rememberlogin_shown';
export const lastLoginBannerDismiss = 'Last_Login_Banner_Dismiss';
