import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CheckoutWizardRouter from './CheckoutWizardRouter';
import { CheckoutWizardRouterProps } from './CheckoutWizardInterfaces';
import WizardSteps from '../Step/WizardSteps';
import { Cart, SelectedEnvelope } from '../Capsules/Envelopes';
import StepsAndDonee from '../Step/StepsAndDonee';
import GiveButton from './CartWizard/Payment/GiveButton';
import {
  DonationResponse,
  getTotalCartAmount,
  sendDonationRequest
} from './CartWizard/Payment/api';
import {
  deleteCartLocal,
  getCartLocal,
  saveCartLocal
} from './CartLocalStorage';
import { COMPONENT_VALUES, colors } from '../../../theme';
import { PHONE_WIDTH, RecurringTypes, TABLET_WIDTH } from '../../utils';
import { useDonee } from '../../../../providers/DoneeProvider';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { useQueryString } from '../../../../hooks/useQueryString';
import { GivingApiResponse } from '../../../../utils/makeApiRequest';
import { useApiRequest } from '../../../../hooks/useGivingApiRequest';
import { logInfo, logging } from '../../../../utils/logError';
import {
  deleteDonationOnGiftSummary,
  errorOnGiftSummary,
  giftSuccessfullySubmitted
} from '../../../../hooks/PushEvents/clevertapEvents';
import { requestInit } from '../../../../hooks/useRequestState';
import { requestRecaptchaV3Token } from '../../../Wrappers/ReCaptchaV3';
import { Wallet } from '../../../../providers/DonorProvider/interfaces';
import MetaTags from '../../../MetaTags';
import { HeartLoading } from '../../../HeartLoading';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../utils/routes';
import { LogLevel } from '../../../../utils/logger/models';
import { useDonorContext } from '../../../../providers/DonorProvider/DonorProvider';
import { useDonor } from '../../../../providers/DonorProvider/useDonor';

const duplicateDonationErrorCode = 1292;

const StyledDiv = styled.div<{ isCart: boolean }>`
  ${COMPONENT_VALUES.box}
  background-color: ${colors.white};
  .wizard-content {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: ${({ isCart }) => (isCart ? 44 : 80)}px;
    .loading {
      svg {
        max-height: 300px;
      }
    }
  }
  @media only screen and (max-width: ${TABLET_WIDTH}) {
    .wizard-content {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
  @media only screen and (max-width: ${PHONE_WIDTH}) {
    .wizard-content {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
`;

let firstRender: number = 0;
const firstRenderRoute = (): CheckoutWizardRouterProps['route'] => {
  if (firstRender < 2) {
    firstRender++;
    return 'cart';
  }
  return 'amount';
};

const CheckoutWizard: React.FC<{ className?: string }> = ({ className }) => {
  const { donor } = useDonor() || {};
  const push = useNavigate();
  const { donee, doneeId } = useDonee();
  const { pushEvent, extendProfile } = usePushEvents();
  const query = useQueryString();
  const { setDonorContext } = useDonorContext();

  const { queryAmount, localCart, isDefaultEmptyCart } = useMemo(() => {
    const queryAmount = parseInt(query.get('amount') || '', 10) || 0;
    const { cart, isEmpty } = getCartLocal(doneeId, queryAmount);
    return { queryAmount, localCart: cart, isDefaultEmptyCart: isEmpty };
  }, [query, doneeId]);

  const [forceDuplicateDonation, setForceDuplicateDonation] =
    useState<boolean>(false);
  const [requestState, makeRequest, setRequestState] =
    useApiRequest<GivingApiResponse<DonationResponse>>();
  const [route, setRoute] = useState<CheckoutWizardRouterProps['route']>(
    isDefaultEmptyCart
      ? queryAmount
        ? 'envelopes'
        : 'amount'
      : firstRenderRoute()
  );
  const [cart, setCart] = useState<Cart>(localCart);

  useEffect(() => {
    if (requestState.type !== 'REQUEST_ERROR') return;
    const { amount, envelopes } = getTotalCartAmount(cart.envelopes);
    pushEvent(errorOnGiftSummary, {
      amount,
      totalAmount: amount,
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort,
      envelopeCount: envelopes.length,
      errorMessage: requestState.error.message,
      envelope: envelopes.length === 1 ? envelopes[0].name : 'Multiple'
    });
  }, [pushEvent, requestState]);

  useEffect(() => {
    if (route === 'amount') {
      // window.onpopstate = () => {
      //   const pathname = window.location.pathname;
      //   if (applicationRoutes.includes(pathname)) return;
      //   window.location.href = webConfig.mainSearchSite;
      // };
      return;
    }
    window.onpopstate = () => {
      push(ROUTES.main);
    };
    return () => {
      window.onpopstate = null;
    };
  }, [route]);

  useEffect(() => {
    doneeId && saveCartLocal(doneeId, cart);
  }, [cart]);

  useEffect(() => {
    if (queryAmount) {
      setRoute('envelopes');
    } else if (getTotalCartAmount(cart.envelopes).amount === 0) {
      setRoute('amount');
    }
  }, [queryAmount]);

  useEffect(() => {
    if (requestState.type === 'REQUEST_SUCCESS') {
      const totalAmountFromApi = requestState.data?.response?.payload?.amount;
      const cartInfo = getTotalCartAmount(cart.envelopes);
      const totalAmount = totalAmountFromApi || cartInfo.amount;
      const cartForEvent = {
        cart: { ...cart, wallet: undefined },
        totalAmountFromApi,
        totalAmount,
        ogaVersions: '4.0'
      };
      if (!totalAmountFromApi) {
        logging(
          'Unable to get total from API',
          cartForEvent,
          LogLevel.CRITICAL
        );
      }
      setForceDuplicateDonation(false);
      logInfo('Successful donation.', cartForEvent);
      pushEvent(giftSuccessfullySubmitted, {
        totalAmount,
        loggedIn: Boolean(donor),
        doneeId: window.doneeIdShort,
        amount: totalAmount,
        envelopeCount: cartInfo.envelopes.length,
        envelope:
          cartInfo.envelopes.length === 1
            ? cartInfo.envelopes[0].name
            : 'Multiple'
      });
      setRequestState(requestInit());
      setRoute('thank-you');
      deleteCartLocal();
      setCart({
        envelopes: {},
        recurring: 'None',
        wallet: undefined,
        receipt: {
          totalAmount,
          receiptId: requestState.data.response.payload.transactionId
        }
      });
      setDonorContext((s) => {
        const totalGifts = s.totalGifts + 1;
        extendProfile(totalGifts);
        return { ...s, totalGifts };
      });
    }
    if (
      requestState.type === 'REQUEST_ERROR' &&
      requestState.error.response.status === duplicateDonationErrorCode
    ) {
      setForceDuplicateDonation(true);
    }
  }, [requestState.type, setForceDuplicateDonation, setRequestState, setCart]);

  const sendDonation = useCallback(() => {
    if (donee.type !== 'REQUEST_SUCCESS') {
      return;
    }
    requestRecaptchaV3Token(
      (captchaToken: string | undefined) => {
        makeRequest(
          sendDonationRequest(
            cart,
            donee.data.organization.doneeId,
            forceDuplicateDonation,
            captchaToken
          )
        );
      },
      undefined,
      'CheckoutWizard'
    );
  }, [cart, donee, forceDuplicateDonation, makeRequest]);

  const addAmount = useCallback(
    (amount: SelectedEnvelope['amount']) => {
      setCart((s) => ({
        ...s,
        envelopes: { ...s.envelopes, new: amount }
      }));
    },
    [setCart]
  );

  const setMessage = useCallback(
    (message: string) => {
      setCart((s) => ({ ...s, message }));
    },
    [setCart]
  );

  const setWallet = useCallback(
    (wallet: Wallet | undefined) => {
      setCart((s) => ({ ...s, wallet }));
    },
    [setCart]
  );

  const setRecurring = useCallback(
    (recurring: RecurringTypes) => {
      setCart((s) => ({ ...s, recurring }));
    },
    [setCart]
  );

  const addToCart = useCallback(
    (e: SelectedEnvelope) => {
      setCart((s) => {
        const envelope = s.envelopes[e.envelopeId];
        if (envelope) {
          e.amount += envelope.amount;
        }
        return {
          ...s,
          envelopes: { ...s.envelopes, [e.envelopeId]: e, new: undefined }
        };
      });
    },
    [setCart]
  );

  const removeFromCart = useCallback(
    (envelopeId: SelectedEnvelope['envelopeId']) => {
      setCart((s) => {
        const deletedEnvelope = s.envelopes[envelopeId];
        const newState = {
          ...s,
          envelopes: { ...s.envelopes, [envelopeId]: undefined }
        };
        const { envelopes, amount } = getTotalCartAmount(newState.envelopes);
        pushEvent(deleteDonationOnGiftSummary, {
          loggedIn: Boolean(donor),
          doneeId: window.doneeIdShort,
          deletedAmount: deletedEnvelope?.amount,
          deletedEnvelope: deletedEnvelope?.name,
          amount: amount,
          envelopeCount: envelopes.length,
          paymentPresent: s.wallet ? 'Yes' : 'No',
          envelope:
            envelopes.length === 0
              ? 'No Envelopes'
              : envelopes.length === 1
              ? envelopes[0].name
              : 'Multiple'
        });
        return newState;
      });
    },
    [setCart, pushEvent]
  );

  const isCart = route === 'cart';

  const metaTags = useMemo(() => {
    const organization = donee.data?.organization;
    return {
      title: organization?.official
        ? `${organization.name} with ${organization.official.name}`
        : organization?.name,
      description: organization?.doneeDescription || undefined,
      bannerUrl: organization?.banner || undefined,
      shareUrl: organization?.shareUrl || undefined
    };
  }, [donee]);

  return (
    <StyledDiv
      className={className}
      isCart={isCart}
    >
      <MetaTags {...metaTags} />
      <WizardSteps
        cart={cart}
        route={route}
        setRoute={setRoute}
        addAmount={addAmount}
        addToCart={addToCart}
        setWallet={setWallet}
        removeFromCart={removeFromCart}
        setMessage={setMessage}
        setRecurring={setRecurring}
      />
      <StepsAndDonee
        cart={cart}
        route={route}
        setRoute={setRoute}
        addAmount={addAmount}
        addToCart={addToCart}
        setWallet={setWallet}
        removeFromCart={removeFromCart}
        setMessage={setMessage}
        setRecurring={setRecurring}
      />
      <div className={'wizard-content'}>
        {requestState.type === 'REQUEST_START' ? (
          <HeartLoading className={'loading'} />
        ) : (
          <CheckoutWizardRouter
            cart={cart}
            route={route}
            donee={donee}
            setRoute={setRoute}
            addAmount={addAmount}
            addToCart={addToCart}
            setWallet={setWallet}
            removeFromCart={removeFromCart}
            setMessage={setMessage}
            setRecurring={setRecurring}
          />
        )}
      </div>
      {isCart && requestState.type !== 'REQUEST_START' && (
        <GiveButton
          cart={cart}
          setCart={setCart}
          donee={donee}
          setWallet={setWallet}
          onSubmit={sendDonation}
          errorState={
            requestState.type === 'REQUEST_ERROR'
              ? requestState.error
              : undefined
          }
        />
      )}
    </StyledDiv>
  );
};

export default CheckoutWizard;
