import React, { useCallback, useState } from 'react';
import MessageTextarea from './MessageTextarea';
import MessageCapsuleButtons from './MessageCapsuleButtons';
import { GivelifyButton } from '../../../common/Button/GivelifyButton';

interface AddMessageFieldProps {
  message: string;
  handleDone: () => unknown;
  setMessage: (text: string) => unknown;
}

const AddMessageField: React.FC<AddMessageFieldProps> = ({
  message,
  setMessage,
  handleDone
}) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState<string>(message);
  const handleTextChange = useCallback(
    (text: string) => {
      setText((s) => {
        const newText = s + text;
        return newText.length > 140 ? s : newText;
      });
      inputRef.current?.focus();
    },
    [inputRef.current, setText]
  );
  const onDone = () => {
    setMessage(text);
    handleDone();
  };
  return (
    <>
      <MessageCapsuleButtons setMessage={handleTextChange} />
      <MessageTextarea
        inputRef={inputRef}
        message={text}
        onChange={setText}
        placeholder={'Send a message with your gift...'}
      />
      <GivelifyButton
        id={'add-message-done'}
        onClick={onDone}
      >
        Done
      </GivelifyButton>
    </>
  );
};

export default AddMessageField;
