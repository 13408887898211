import React from 'react';
import styled from 'styled-components';
import OpacityAnimation from '../../../Animations/OpacityAnimation';
import { AmountCapsule } from './AmountCapsule';

export interface AmountCapsuleGridProps {
  amounts: (number | 'Other')[];
  onClick: (amount: number | 'Other') => unknown;
}

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 80px 80px 80px;
  grid-gap: 24px;
  @media only screen and (max-width: 1000px) {
    grid-template-rows: 52px 52px 52px;
  }
`;

export const AmountCapsuleGrid: React.FC<AmountCapsuleGridProps> = ({
  amounts,
  onClick
}) => {
  return (
    <OpacityAnimation>
      <StyledDiv>
        {amounts.map((a, i) => (
          <AmountCapsule
            key={i}
            amount={a}
            onClick={onClick}
          />
        ))}
      </StyledDiv>
    </OpacityAnimation>
  );
};
