import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PaymentFormProps } from './CreditCardForm';
import { addCardRequest, AddCardResponse } from './api';
import AddressComplete from '../../../../../LoginModal/pages/AddressComplete';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { GivingApiResponse } from '../../../../../../utils/makeApiRequest';
import { useApiRequest } from '../../../../../../hooks/useGivingApiRequest';
import { User } from '../../../../../LoginModal/routes';
import { checkCardType } from '../../../../../LoginModal/utils';
import { Text } from '../../../common/Text/Text';
import { DISABLE_CAPTCHA } from '../../../../../LoginModal/pages/Landing/api';
import ReCaptchaV3, {
  requestRecaptchaV3Token
} from '../../../../../Wrappers/ReCaptchaV3';

const StyledAddress = styled(AddressComplete)`
  padding-top: 18px;
  .error {
    margin-top: 18px;
  }
`;

const AddressCompleteForm: React.FC<
  PaymentFormProps & {
    addCard: (card: Wallet) => unknown;
    handlePaymentModalClose: () => unknown;
  }
> = ({ user, setRoute, setUser, addCard, handlePaymentModalClose }) => {
  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<AddCardResponse>>();

  const handleSubmit = (user: User) => {
    requestRecaptchaV3Token(
      (token: string | undefined) => {
        makeRequest(addCardRequest(user, token));
      },
      undefined,
      'AddressCompleteForm'
    );
    setUser(user);
  };

  useEffect(() => {
    if (requestState.type !== 'REQUEST_SUCCESS') {
      return;
    }
    addCard({
      id: requestState.data.response.payload.walletId,
      number: user.card?.cardNumber.slice(-4) || '',
      paymentInstrumentUuid:
        requestState.data.response.payload.paymentInstrumentId,
      type:
        (checkCardType(user.card?.cardNumber || '')?.type as Wallet['type']) ||
        'Visa',
      isPreferred: false
    });
    setRoute('ADDRESS');
    setUser({ ...user, card: undefined });
    handlePaymentModalClose();
  }, [requestState.type, addCard, handlePaymentModalClose, setRoute, setUser]);

  return (
    <StyledAddress
      user={user}
      isLoading={requestState.type === 'REQUEST_START'}
      closeModal={() => null}
      questionText={'What’s your billing address?'}
      buttonText={'Add card'}
      onNext={handleSubmit}
      page={'ADDRESS_COMPLETE'}
      ExtraComponent={() => (
        <>
          {requestState.type === 'REQUEST_ERROR' && (
            <Text
              color={'utilityError'}
              className={'error'}
            >
              {requestState.error.message}
            </Text>
          )}
          {!DISABLE_CAPTCHA && <ReCaptchaV3 className={'recaptcha'} />}
        </>
      )}
    />
  );
};

export default AddressCompleteForm;
