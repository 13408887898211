import styled from 'styled-components';
import colors from '../theme/colors';
import layout from '../theme/layout';

const { breakpoints } = layout;
const { mobile } = breakpoints;

export const Footer = styled.div`
  background: ${colors.grayscaleGray75};
  width: 100%;
  padding: 18px 0;
  margin-top: 24px;
  text-align: left;
  .footer-content {
    background: transparent;
  }
  @media screen and (max-width: ${mobile.max}px) {
    text-align: center;
    .footer-logo {
      order: 1;
    }
  }
`;

export const GivingAppsLinks = styled.div`
  display: flex;
  justify-content: center;
`;

export const GivingAppPlatformStoreImage = styled.img`
  margin: 5px 0px 0px 6px;
`;
