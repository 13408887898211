import React from 'react';
import styled from 'styled-components';
import IconButton from '../Donee/components/common/Button/IconButton';
import { modalBreakPoint } from '../Donee/components/common/Modal/styles';

export const modalMarginValue = 40;
export const modalMarginValueMobile = 18;
const WrapperStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: ${modalMarginValue}px;
  padding-right: ${modalMarginValue}px;
  min-height: ${modalMarginValue}px;
  @media only screen and (max-width: ${modalBreakPoint}px) {
    padding-left: ${modalMarginValueMobile}px;
    padding-right: ${modalMarginValueMobile}px;
  }
  .donee-logo {
    width: 80px;
    height: 80px;
    margin-top: -40px;
    border-radius: 50%;
  }
  button {
    cursor: pointer;
  }
  button div {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  button div img {
    width: 16px;
  }
  .hidden {
    visibility: hidden;
  }
`;

export interface ButtonsWrapperProps {
  doneeUrl: string | undefined;
  doneeName: string;
  onBack?: () => unknown;
  onClose: () => unknown;
}

const ButtonsWrapper: React.FC<ButtonsWrapperProps> = ({
  doneeUrl,
  doneeName,
  onBack,
  onClose
}) => {
  return (
    <WrapperStyles>
      <IconButton
        name={'back_arrow'}
        size={'xSmall'}
        width={'link'}
        raised={'none'}
        color={'transparent'}
        onClick={onBack}
        iconProps={undefined}
        className={onBack ? undefined : 'hidden'}
      />
      {doneeUrl && (
        <img
          src={doneeUrl}
          alt={doneeName}
          className={'donee-logo'}
        />
      )}
      <IconButton
        name={'close-icon'}
        size={'xSmall'}
        width={'link'}
        raised={'none'}
        color={'transparent'}
        onClick={onClose}
        iconProps={undefined}
      />
    </WrapperStyles>
  );
};

export default React.memo(ButtonsWrapper);
