import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Textfield } from '../Textfield';
import {
  addWallet,
  DISABLE_CAPTCHA,
  SignupResponse,
  userSignup,
  WalletResponse
} from '../Landing/api';
import ReCaptchaV3, {
  requestRecaptchaV3Token
} from '../../../Wrappers/ReCaptchaV3';
import { LoginResponse } from '../../../../providers/DonorProvider/interfaces';
import {
  joinSuccessful,
  loginPopupPasswordScreenNext,
  loginPopupPasswordScreenShown
} from '../../../../hooks/PushEvents/clevertapEvents';
import { ModalRouterProps } from '../../Router';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { useDonorContext } from '../../../../providers/DonorProvider/DonorProvider';
import { GivingApiResponse } from '../../../../utils/makeApiRequest';
import { useApiRequest } from '../../../../hooks/useGivingApiRequest';
import { RequestState } from '../../../../hooks/useRequestState';
import { Text } from '../../../Donee/components/common/Text/Text';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { ContentStyles } from '../../utils';
import { setLoginToken } from '../../../../providers/DonorProvider/donorLocalStorage';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .label {
    margin-bottom: 16px;
  }
  .password-field-icon {
    cursor: pointer;
  }
  .recaptcha,
  .bullet-points {
    margin-top: 16px;
  }
`;

export type PasswordProps = ModalRouterProps;

export const Password: React.FC<PasswordProps> = ({ user, onNext }) => {
  const { pushEvent } = usePushEvents();
  const { loadDonor } = useDonorContext();
  const { setDonorContext } = useDonorContext();
  const [signup, makeSignupRequest] =
    useApiRequest<GivingApiResponse<SignupResponse>>();
  const [wallet, makeWalletRequest] =
    useApiRequest<GivingApiResponse<WalletResponse>>();
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [recaptcha, setRecaptcha] = useState<string | undefined>();

  const handleShowPassword = useCallback(() => {
    setShowPassword((s) => !s);
  }, [setShowPassword]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setPassword(value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(() => {
    pushEvent(loginPopupPasswordScreenNext);
    requestRecaptchaV3Token(
      (token: string | undefined) => {
        makeSignupRequest(userSignup(user, password, token));
        setRecaptcha(token);
      },
      undefined,
      'Password'
    );
  }, [password, setRecaptcha]);

  useEffect(() => {
    setDonorContext((s) => ({
      ...s,
      login: signup as RequestState<LoginResponse>
    }));
  }, [setDonorContext]);

  useEffect(() => {
    if (signup.type === 'REQUEST_SUCCESS') {
      setLoginToken(signup.data.response.payload);
    }
  }, [signup]);

  const getProfile = useCallback(() => {
    pushEvent(joinSuccessful, { type: 'Email' });
    loadDonor(true);
  }, []);

  useEffect(() => {
    if (user.skipsCreditCard) {
      getProfile();
      return;
    }
    if (signup.type === 'REQUEST_SUCCESS') {
      makeWalletRequest(addWallet(user, recaptcha));
    }
  }, [recaptcha, user, signup.type, getProfile]);

  useEffect(() => {
    if (wallet.type === 'REQUEST_SUCCESS') {
      getProfile();
      return;
    }
    if (wallet.type === 'REQUEST_ERROR') {
      onNext({ ...user, skipsSignup: true }, 'CREDIT');
    }
  }, [wallet.type, getProfile]);

  useEffect(() => {
    pushEvent(loginPopupPasswordScreenShown);
  }, []);

  const error =
    (signup.type === 'REQUEST_ERROR' && signup.error.message) ||
    (wallet.type === 'REQUEST_ERROR' && wallet.error.message) ||
    undefined;

  const isLoading =
    signup.type === 'REQUEST_START' || wallet.type === 'REQUEST_START';

  return (
    <StyledDiv>
      <div>
        <Text
          weight={'extraBold'}
          className={'label'}
        >
          Enter a password to keep track of your gift
        </Text>
        <Textfield
          id={'password'}
          type={showPassword ? 'text' : 'password'}
          icon={showPassword ? 'pass_hide' : 'pass_show'}
          onIconClick={handleShowPassword}
          iconClassName={'password-field-icon'}
          placeholder={'Password'}
          value={password}
          // onMouseLeave={() => onHover(email)}
          maxLength={20}
          className={'password-field'}
          errorText={error}
          onChange={handleChange}
        />
        <Text
          variant={'xSmall'}
          weight={'semiBold'}
          className={'bullet-points'}
          color={'primarySecondaryContent'}
        >
          <li>Give next time in 3 easy taps</li>
          <li>Keep track of your giving history in one place</li>
        </Text>
        {!DISABLE_CAPTCHA && <ReCaptchaV3 className={'recaptcha'} />}
      </div>
      <div className={'continue'}>
        <GivelifyButton
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!password}
        >
          Signup
        </GivelifyButton>
      </div>
    </StyledDiv>
  );
};
