import React from 'react';
import styled from 'styled-components';
import { Step, StepProps } from '.';
import { Cart } from '../Capsules/Envelopes';
import { CheckoutWizardRouterProps } from '../CheckoutWizard/CheckoutWizardInterfaces';
import colors from '../../../theme/colors';
import { TABLET_WIDTH } from '../../utils';

const getCartEmpty = (envelopes: Cart['envelopes']) => {
  const keys = Object.keys(envelopes);
  if (keys.length > 1) {
    return false;
  }
  if (keys.length === 0) {
    return true;
  }

  return keys[0] === 'new';
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 80px;
  border-bottom: 1px solid ${colors.utilitySeparatorColor};
  @media only screen and (max-width: ${TABLET_WIDTH}) {
    display: none;
  }
`;

interface WizardStepsProps extends CheckoutWizardRouterProps {
  cart: Cart;
}

const WizardSteps: React.FC<WizardStepsProps> = ({ cart, route, setRoute }) => {
  // const isCartEmpty = getCartEmpty(cart.envelopes);
  const onClick = (count: StepProps['count']) => {
    switch (count) {
      case '1':
        return setRoute('amount');
      case '2':
        return setRoute('envelopes');
      case '3':
        return setRoute('cart');
    }
  };

  if (route === 'thank-you') {
    return null;
  }

  return (
    <StyledDiv>
      <Step
        onClick={onClick}
        state={route === 'amount' ? 'selected' : 'completed'}
        count={'1'}
        name={'Gift Amount'}
      />
      <Step
        onClick={onClick}
        state={
          route === 'amount'
            ? 'disable'
            : route === 'envelopes'
            ? 'selected'
            : 'completed'
        }
        count={'2'}
        name={'Gift Purpose'}
      />
      <Step
        onClick={onClick}
        state={route === 'cart' ? 'selected' : 'disable'}
        count={'3'}
        name={'Gift Summary'}
      />
    </StyledDiv>
  );
};

export default WizardSteps;
