import { axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';

export interface RecurringDonationsResponse {
  recurringGift: {
    id: number;
    donorId: string; // '1072239505189510'
    giftAmount: number; // 0.5
    walletId: number;
    frequency: number; // 5;
    frequencyLabel: string;
    skipNextRunDate: null;
    startDate: string; // '2023-12-12 13:17:46'
    setupDate: string; //  '2023-12-12 13:17:46'
    nextRunDate: string; // '2024-01-12 13:17:46'
    lastRunDate: string; // '2023-12-12 13:17:46'
    dateCancelled: null;
    isoSkipNextRunDate: null;
    isoStartDate: string; // '2023-12-12T18:17:46.000000Z'
    isoSetupDate: string; // '2023-12-12T18:17:46.000000Z'
    isoNextRunDate: string; // '2024-01-12T18:17:46.000000Z'
    isoLastRunDate: string; // '2023-12-12T18:17:46.000000Z'
    isoDateCancelled: null;
  };
  donee: {
    id: string; // '1071126600880962'
    name: string;
    address: string; // '47 S Pennsylvania St, Indianapolis, IN, 46204'
    canAcceptDonation: boolean;
  };
  envelopes: [
    {
      id: number; //119
      name: string;
      recurringId: number;
      amount: number; //0.5
    }
  ];
}

const RECURRING_URL = '/donor/profile/recurring-gifts';
export const getAllRecurringDonations = () => {
  const httpRequest = axiosClient.get(RECURRING_URL);
  return makeApiRequest<RecurringDonationsResponse[]>(httpRequest);
};

export const cancelRecurringDonation = (recurringId: number) => {
  const httpRequest = axiosClient.delete(`${RECURRING_URL}/${recurringId}`);
  return makeApiRequest(httpRequest);
};
