import React, { ChangeEvent } from 'react';
import { StyledMaskedInput } from '../../utils';
import { Text } from '../../../Donee/components/common/Text/Text';

const mask = [/0|1/, /[0-9]/, '/', /[2-9]/, /[0-9]/];

interface ExpiryFieldProps {
  className?: string;
  placeholder?: string;
  expiry: string;
  expiryError: string | undefined;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

const ExpiryField: React.FC<ExpiryFieldProps> = ({
  expiryError,
  className,
  placeholder,
  expiry,
  handleChange
}) => {
  return (
    <div className={className}>
      <StyledMaskedInput
        placeholder={placeholder}
        mask={mask}
        guide={false}
        value={expiry}
        onChange={handleChange}
        // inputMode={'numeric'}
      />
      {expiryError && (
        <Text
          variant={'xSmall'}
          color={'red'}
        >
          {expiryError}
        </Text>
      )}
    </div>
  );
};

export default React.memo(ExpiryField);
