import styled from 'styled-components';
import layout from '../../../../theme/layout';
import colors from '../../../../theme/colors';

const { breakpoints } = layout;
const { mobile } = breakpoints;

export const LeftSide = styled.div`
  float: left;
`;

export const RightSide = styled.div`
  float: right;
`;

export const Header = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding: 26px 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  -o-box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
`;

export const MainContent = styled.div`
  min-height: 400px;
`;

export const MainSection = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  min-height: 300px;
  text-align: left;
  & input:disabled {
    background-color: ${colors.grayscaleGray100} !important;
  }
`;

export const Footer = styled.div`
  background: #fff;
  border-top: 1px solid #9ca6b8;
  width: 100%;
  padding: 18px 0;
`;

export const FooterLogo = styled.div`
  width: 100%;
  @media screen and (max-width: ${mobile.max}px) {
    width: auto;
    display: inline-block;
  }
`;

export const Footerlink = styled.a`
  display: flex;
`;

export const LogoImg = styled.img`
  height: 28px;
`;
