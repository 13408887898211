import React, { ChangeEvent, FocusEvent } from 'react';
import {
  Input as StyledInput,
  InputContainer as StyledInputContainer,
  TextSymbol as StyledTextSymbol
} from './styles';

import parseAmount from './parseAmount';

interface AmountInputProps {
  symbol?: string;
  onSubmit?: () => void;
  onChange?: (formatted: string, event: ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
}

interface AmountInputState {
  focused: boolean;
}

class AmountInput extends React.Component<AmountInputProps, AmountInputState> {
  state = {
    focused: false
  };

  onInputBlur = () => this.setState(() => ({ focused: false }));
  onInputFocus = () => this.setState(() => ({ focused: true }));
  onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const formatted = parseAmount(event.target.value);

    if (this.props.onChange) {
      this.props.onChange(formatted, event);
    }
  };

  render() {
    const { focused } = this.state;
    const { value = '', symbol, ...props } = this.props;

    const formatted = parseAmount(value.toString());

    return (
      <StyledInputContainer focused={focused}>
        <StyledTextSymbol>{symbol}</StyledTextSymbol>
        <StyledInput
          value={formatted}
          onBlur={this.onInputBlur}
          onFocus={this.onInputFocus}
          {...props}
          onChange={this.onInputChange}
        />
      </StyledInputContainer>
    );
  }
}

export default AmountInput;
