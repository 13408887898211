import colors from '../theme/colors';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { modalMarginValue } from './ButtonsWrapper';
import { CardThumb, cards } from '../Donee/components/common/constants';

export const ContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .continue {
    margin-top: 10px;
    padding-bottom: ${modalMarginValue}px;
  }
`;

export const StyledMaskedInput = styled(MaskedInput)`
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  padding: 8px 15px;
  color: ${colors.brandDarkGray};
  font-family: Area Normal;
  outline: 0;
  border: 1px solid ${colors.utilityInputColor};
  background-color: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
`;

const year = new Date().getFullYear().toString().substring(2);
export const checkExpiryDate = (expiry: string): boolean => {
  if (expiry.length !== 5) {
    return false;
  }
  const [mm, yy] = expiry.split('/');
  return mm !== '00' && mm <= '12' && yy >= year;
};

export const checkCVV = (cvv: string, thumb: CardThumb) => {
  return cvv.length === thumb.cvcLength[0];
};

const spaceRegEx = / /g;
export const isCardNumberValid = (cardNumber: string, cardThumb: CardThumb) =>
  cardNumber.replace(spaceRegEx, '').length === cardThumb.length[0] &&
  cardThumb.pattern.test(cardNumber);

export const isValidCard = (
  cardNumber: string | undefined,
  expiry: string,
  cvv: string,
  cardThumb: CardThumb | undefined
): boolean => {
  if (!cardThumb || !cardNumber) {
    return false;
  }
  return (
    isCardNumberValid(cardNumber, cardThumb) &&
    checkExpiryDate(expiry) &&
    checkCVV(cvv, cardThumb)
  );
};

export const checkCardType = (
  newNumber: string,
  useCards: CardThumb[] = cards
) => {
  for (let i = 0; i < useCards.length; i++) {
    const card = useCards[i];
    if (card.pattern.test(newNumber)) {
      return card;
    }
  }
  return undefined;
};

export const validateCardExpiry = (newExpiration: string): boolean => {
  let prefix: string | number;
  let month: number;
  let year: number;

  if (newExpiration.length === 5) {
    month = parseInt(newExpiration.toString().substr(0, 2), 10);
    year = parseInt(newExpiration.toString().substr(3, 2), 10);
  } else if (newExpiration.length === 4) {
    month = parseInt(newExpiration.toString().substr(0, 2), 10);
    year = parseInt(newExpiration.toString().substr(2, 2), 10);
  } else {
    return false;
  }

  if (year.toString().length === 2) {
    prefix = new Date().getFullYear();
    prefix = prefix.toString().slice(0, 2);
    year = parseInt(prefix + year, 10);
  }
  if (month > 12) {
    return false;
  }
  const expiry = new Date(year, month, 1);
  const currentTime = new Date();
  expiry.setMonth(expiry.getMonth() - 1);
  expiry.setMonth(expiry.getMonth() + 1, 1);

  return expiry >= currentTime;
};

export const expiryDateMask = [/\d/, /\d/, '/', /\d/, /\d/];
export const cvvAmericanExpressMask = [/\d/, /\d/, /\d/, /\d/];
export const cvvMask = [/\d/, /\d/, /\d/, /\d/];
export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const americanExpressMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const cardMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const dinersClubMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const getCardsThumbFormat = (type?: string) => {
  return type === 'american express'
    ? americanExpressMask
    : type === 'diners club'
    ? dinersClubMask
    : cardMask;
};
