import React from 'react';
import styled from 'styled-components';
import { PaymentFormProps } from './CreditCardForm';
import { User } from '../../../../../LoginModal/routes';
import { Address } from '../../../../../LoginModal/pages/Address';

const StyledAddress = styled(Address)`
  padding-top: 18px;
`;

const AddressForm: React.FC<PaymentFormProps> = ({
  user,
  setUser,
  setRoute
}) => {
  const handleSubmit = (user: User) => {
    setUser(user);
    setRoute('ADDRESS_COMPLETE');
  };
  return (
    <StyledAddress
      closeModal={() => null}
      onNext={handleSubmit}
      page={'ADDRESS'}
      user={user}
      questionText={'What’s your billing address?'}
    />
  );
};

export default AddressForm;
