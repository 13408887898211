import styled, { css } from 'styled-components';
import colors from '../../../../../theme/colors';
import fonts from '../../../../../theme/fonts';

export const getInputContainerBorderColor = ({
  focused
}: {
  focused: boolean;
}) => (focused ? colors.primaryBlue : colors.disable);

export const InputContainer = styled.div<{ focused: boolean }>`
  width: 100%;
  height: 45px;
  padding: 5px 7px 5px 10px;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: ${getInputContainerBorderColor};
  transition: border 250ms ease-in-out;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const AmountTextCSS = css`
  color: ${colors.grayscaleBlack};
  font-weight: ${fonts.fontWeight.medium};
  font-family: ${fonts.fontfamily};
  caret-color: ${colors.grayscaleBlack};
  '::placeholder' {
    color: ${colors.lightgrey};
    opacity: 1;
  }
`;

export const Input = styled.input`
  outline: 0;
  border: none;
  width: 100%;
  height: 42px;
  line-height: 24px;
  font-size: 16px;
  background-color: transparent;
  ${AmountTextCSS};
`;

export const TextSymbol = styled.div`
  ${AmountTextCSS};
  margin-right: 4px;
  height: 42px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-top: 1px;
`;
