import React from 'react';
import { Row, Col, Container, Alert } from '@bootstrap-styled/v4';
import { makeStyles } from '@material-ui/styles';
import {
  FormProfile,
  FormItem,
  SaveFormContainer,
  ChangePassword,
  ForgotPasswordContainer,
  SendLinkContainer,
  AlertMsgSticy,
  ImgAvatar,
  ImgAvatarMain,
  EditProfileLink,
  EditIcon,
  SaveButton,
  SelectInput
} from './styles';
import { states } from './constants';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components/Donee/components/common/Text/Text';
import { MainSection } from '../../components/Donee/components/common/Layout';
import H2 from '../../components/Donee/components/common/H2';
import { assetUrl } from '../../utils/webConfig';
import Label from '../../components/Donee/components/common/Label';
import { TextInput } from '../../components/Donee/components/common/Input';
import SolidButton, {
  SolidButtonV2
} from '../../components/Donee/components/common/Button/SolidButton';
import { LinkButton } from '../../components/Donee/components/common/Button';
import Modal from '../../components/Donee/components/common/Modal';
import { ROUTES } from '../../utils/routes';

const useStyles = makeStyles({
  SaveButton
});

const removePictureUrl = assetUrl('/images/svg/remove-profile-img.svg?v=1');
const editProfilePictureUrl = assetUrl(
  '/images/svg/edit-profile-image-icon.svg'
);

function ErrorAlert({ error, closeMessage }: any) {
  return (
    <Alert
      className={'common-alert'}
      color={'danger'}
      isOpen
      onClick={closeMessage()}
    >
      <Text
        variant={'small'}
        color={'red1'}
      >
        {error}
      </Text>
    </Alert>
  );
}

function ErrorMessages({ errors, closeMessage }: any) {
  if (typeof errors === 'string' && errors !== '') {
    return (
      <ErrorAlert
        error={errors}
        closeMessage={closeMessage}
      />
    );
  } else if (typeof errors === 'object' && Object.keys(errors).length > 0) {
    return (
      <>
        {Object.keys(errors).map((errorKey) => (
          <ErrorAlert
            key={errorKey}
            error={errors[errorKey]}
            closeMessage={closeMessage}
          />
        ))}
      </>
    );
  }
  return null;
}

interface DonorProfileViewProps {
  picture: string | ArrayBuffer | undefined;
  name: string | undefined;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  saveProcess: boolean;
  success: string;
  error: string | undefined;
  emailError: string;
  phoneError: string;
  addressError: string;
  cityError: string;
  stateError: string;
  zipCodeError: string;
  tempPicture: any;
  updateDetail: boolean;
  profileImageValidModal: boolean;
  message: string;
  closeMessage: () => () => void;
  updateProfile: (e: any) => boolean | undefined;
  handleError: () => void;
  handleClickChooseFile: () => boolean;
  imageReset: () => void;
  onFileUploadHandle: (files: FileList | null) => unknown;
  profileImageValidModalClose: () => void;
  setChooseFile: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>;
  setEmail: (newEmail: string) => void;
  setPhone: (newPhone: string) => void;
  setAddress: (newAddress: string) => void;
  setCity: (newCity: string) => void;
  setState: (newState: string) => void;
  setZip: (newZip: string) => boolean;
}

const DonorProfileView: React.FC<DonorProfileViewProps> = (props) => {
  const classes = useStyles();
  const push = useNavigate();
  const {
    picture,
    name,
    email,
    phone,
    address,
    city,
    state,
    zip,
    saveProcess,
    success,
    error,
    emailError,
    phoneError,
    addressError,
    cityError,
    stateError,
    zipCodeError,
    tempPicture,
    updateDetail,
    profileImageValidModal,
    message,
    closeMessage,
    updateProfile,
    handleError,
    handleClickChooseFile,
    imageReset,
    onFileUploadHandle,
    profileImageValidModalClose,
    setChooseFile: setChoosefile,
    setEmail,
    setPhone,
    setAddress,
    setCity,
    setState,
    setZip
  } = props;

  return (
    <div className={'mainpart'}>
      <Container>
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <center>
                <H2
                  size={'large'}
                  color={'grayscaleBlack'}
                  weight={'medium'}
                  value={undefined}
                >
                  Edit Profile Details
                </H2>
              </center>
              <FormProfile>
                <AlertMsgSticy>
                  <center>
                    <ErrorMessages
                      errors={error}
                      closeMessage={closeMessage}
                    />

                    {success !== '' && (
                      <Alert
                        className={'common-alert'}
                        color={'success'}
                        isOpen
                        onClick={closeMessage()}
                      >
                        <Text
                          variant={'xSmall'}
                          color={'grey3'}
                        >
                          {success}
                        </Text>
                      </Alert>
                    )}
                  </center>
                </AlertMsgSticy>
                <br />
                <form onSubmit={updateProfile}>
                  <Row className={'justify-content-center'}>
                    <Col
                      md={8}
                      sm={10}
                    >
                      <ImgAvatarMain className={'mb-20'}>
                        <ImgAvatar
                          src={
                            tempPicture === '' && typeof picture === 'string'
                              ? assetUrl(picture)
                              : (picture as string)
                          }
                          alt={''}
                          onError={handleError}
                        />
                        {tempPicture ? (
                          <EditProfileLink onClick={imageReset}>
                            <EditIcon src={removePictureUrl} />
                          </EditProfileLink>
                        ) : (
                          <EditProfileLink onClick={handleClickChooseFile}>
                            <EditIcon src={editProfilePictureUrl} />
                          </EditProfileLink>
                        )}
                      </ImgAvatarMain>
                      <input
                        type={'file'}
                        ref={(input) => setChoosefile(input)}
                        accept={'image/*'}
                        onChange={(e) => onFileUploadHandle(e.target.files)}
                        style={{ display: 'none' }}
                      />
                      <Text
                        color={'grayscaleBlack'}
                        style={{
                          position: 'absolute',
                          top: '30px',
                          marginLeft: '30px'
                        }}
                      >
                        {name}
                      </Text>
                      <FormItem>
                        <Label
                          size={'small'}
                          color={'primarySecondaryContent'}
                          htmlFor={'email'}
                          weight={undefined}
                          value={undefined}
                        >
                          Email Address
                        </Label>
                        <TextInput
                          id={'email'}
                          placeholder={'Email'}
                          value={email}
                          type={'text'}
                          disabled={true}
                          onChange={(e) => setEmail(e.target.value)}
                          maxLength={255}
                        />
                        {emailError !== '' && (
                          <Text
                            variant={'small'}
                            color={'red'}
                          >
                            {emailError}
                          </Text>
                        )}
                      </FormItem>
                      <FormItem>
                        <Label
                          size={'small'}
                          color={'primarySecondaryContent'}
                          htmlFor={'phonenumber'}
                          weight={undefined}
                          value={undefined}
                        >
                          Phone Number
                        </Label>
                        <TextInput
                          id={'phonenumber'}
                          placeholder={'Phone'}
                          value={decodeURIComponent(phone)}
                          type={'phone'}
                          onChange={(e) => setPhone(e.target.value)}
                          maxLength={25}
                        />
                        {phoneError !== '' && (
                          <Text
                            variant={'small'}
                            color={'red'}
                          >
                            {phoneError}
                          </Text>
                        )}
                      </FormItem>

                      <FormItem>
                        <Text
                          className={'mt-10 mb-10 dib'}
                          variant={'medium'}
                          color={'grey3'}
                        >
                          Billing Info
                        </Text>
                        <Label
                          size={'small'}
                          color={'primarySecondaryContent'}
                          htmlFor={'address'}
                          weight={undefined}
                          value={undefined}
                        >
                          Street Address
                        </Label>
                        <TextInput
                          id={'address'}
                          placeholder={'Street Address'}
                          value={decodeURIComponent(address)}
                          onChange={(e) => setAddress(e.target.value)}
                          maxLength={255}
                        />
                        {addressError !== '' && (
                          <Text
                            variant={'small'}
                            color={'red'}
                          >
                            {addressError}
                          </Text>
                        )}
                      </FormItem>
                      <FormItem>
                        <Label
                          size={'small'}
                          color={'primarySecondaryContent'}
                          htmlFor={'city'}
                          weight={undefined}
                          value={undefined}
                        >
                          City
                        </Label>
                        <TextInput
                          id={'city'}
                          placeholder={'City'}
                          value={decodeURIComponent(city)}
                          onChange={(e) => setCity(e.target.value)}
                          maxLength={55}
                        />
                        {cityError !== '' && (
                          <Text
                            variant={'small'}
                            color={'red'}
                          >
                            {cityError}
                          </Text>
                        )}
                      </FormItem>
                      <Row>
                        <Col xs={6}>
                          <FormItem>
                            <Label
                              size={'small'}
                              color={'primarySecondaryContent'}
                              weight={undefined}
                              value={undefined}
                            >
                              State
                            </Label>
                            <SelectInput
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              className={state === '' ? 'default' : ''}
                            >
                              <option value={''}>Select a state...</option>
                              {states &&
                                states.map((t) => (
                                  <option
                                    value={t.value}
                                    key={t.value}
                                  >
                                    {t.name}
                                  </option>
                                ))}
                            </SelectInput>
                            {stateError !== '' && (
                              <Text
                                variant={'small'}
                                color={'red'}
                              >
                                {stateError}
                              </Text>
                            )}
                          </FormItem>
                        </Col>
                        <Col xs={6}>
                          <FormItem>
                            <Label
                              size={'small'}
                              color={'primarySecondaryContent'}
                              htmlFor={'zip'}
                              weight={undefined}
                              value={undefined}
                            >
                              Zip Code
                            </Label>
                            <TextInput
                              id={'zip'}
                              placeholder={'Zip Code'}
                              value={zip}
                              onChange={(e) => setZip(e.target.value)}
                              maxLength={5}
                            />
                            {zipCodeError !== '' && (
                              <Text
                                variant={'small'}
                                color={'red'}
                              >
                                {zipCodeError}
                              </Text>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <br />
                      <SaveFormContainer>
                        <SolidButtonV2
                          type={'submit'}
                          className={classes.SaveButton}
                          disabled={!!(zipCodeError !== '' || !updateDetail)}
                        >
                          {saveProcess
                            ? 'Saving...'
                            : 'Save Profile Information'}
                        </SolidButtonV2>
                      </SaveFormContainer>
                      <ChangePassword>
                        <LinkButton
                          type={'button'}
                          onClick={() => {
                            push(ROUTES.profile());
                          }}
                        >
                          <Text color={'primaryGuidingViolet'}>Cancel</Text>
                        </LinkButton>
                      </ChangePassword>
                    </Col>
                  </Row>
                </form>
              </FormProfile>
            </Col>
          </Row>

          <Modal
            active={profileImageValidModal}
            onClose={function (): unknown {
              throw new Error('Function not implemented.');
            }}
          >
            <ForgotPasswordContainer>
              <center>
                <Text
                  className={'popup-title mb-20'}
                  color={'grayscaleBlack'}
                >
                  {message}
                </Text>
              </center>
              <SendLinkContainer>
                <SolidButton
                  fluid
                  size={'small'}
                  width={'default'}
                  raised={'skyblue'}
                  value={'OK'}
                  color={'skyblue'}
                  onClick={profileImageValidModalClose}
                  children={undefined}
                />
              </SendLinkContainer>
            </ForgotPasswordContainer>
          </Modal>
        </MainSection>
      </Container>
    </div>
  );
};

export default DonorProfileView;
