import colors from '../../../../../theme/colors';
import gradients from '../../../../../theme/gradients';
import shadows from '../../../../../theme/shadows';

export const backgroundColors = {
  muted: colors.grey5,
  blue: colors.primaryBlue,
  primary: gradients.primary,
  secondary: gradients.secondary,
  ...colors
};

export const iconColors = {
  muted: colors.grayscaleBlack,
  blue: colors.white,
  primary: colors.white,
  secondary: colors.white,
  ...colors
};

export const sizes = {
  xSmall: {
    icon: 16,
    container: 16
  },
  small: {
    icon: 20,
    container: 24
  },
  medium: {
    icon: 30,
    container: 36
  },
  large: {
    icon: 44,
    container: 52
  },
  xLarge: {
    icon: 62,
    container: 72
  }
};

export const shadowTypes = shadows.depth;

export const defaultShadowType = 'none';
export const defaultColor = 'grayscaleBlack';
export const defaultSize = 'medium';
