import styled from 'styled-components';
import colors from '../theme/colors';

export const DonationStep = styled.div`
  display: inline-block;
  margin-bottom: 8px;
`;

export const Step = styled.div`
  float: left;
  color: #888;
`;

export const Line = styled.div<{ active?: boolean }>`
  background: ${(props) =>
    props.active ? colors.primaryGuidingViolet : '#DEE1E7'};
  float: left;
  height: 2px;
  margin: 3px 0px;
  width: 12px;
`;

export const Circle = styled.div<{ active?: boolean; shadow?: boolean }>`
  background: ${(props) =>
    props.active ? colors.primaryGuidingViolet : '#DEE1E7'};
  color: #fff;
  display: inline-block;
  float: left;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  box-shadow: ${(props) =>
    props.shadow ? '0px 2px 4px rgba(96,113,155,0.26)' : 'none'};
`;
