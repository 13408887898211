import React from 'react';
import GoogleReCaptcha, { ReCAPTCHAProps } from 'react-google-recaptcha';
import { webConfig } from '../../utils/webConfig';

const ReCaptcha: React.FC<ReCAPTCHAProps> = (props) => {
  if (webConfig.disableCaptcha) {
    return null;
  }
  return <GoogleReCaptcha {...props} />;
};

export default ReCaptcha;
