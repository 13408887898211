import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Textfield } from '../Textfield';
import { ModalRouterProps } from '../../Router';
import { Text } from '../../../Donee/components/common/Text/Text';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { ContentStyles } from '../../utils';
import { usePushEvents } from '../../../../hooks/PushEvents';
import {
  loginPopupNameScreenNext,
  loginPopupNameScreenShown
} from '../../../../hooks/PushEvents/clevertapEvents';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .main-text {
    margin-top: 4px;
    margin-bottom: 16px;
  }
  .name-field {
    margin-bottom: 12px;
  }
`;

export const isNameNotValid = (name: string | undefined): boolean =>
  !name || name.trim().split(' ').length < 2;

export type NameProps = ModalRouterProps;

export const Name: React.FC<NameProps> = ({ user, onNext }) => {
  const { pushEvent } = usePushEvents();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [nameError, setNameError] = useState<string>('');

  useEffect(() => {
    pushEvent(loginPopupNameScreenShown);
  }, []);

  const handleSubmit = () => {
    const name = nameInputRef.current?.value;
    if (isNameNotValid(name)) {
      setNameError('*Please enter first and last name');
      return;
    }
    pushEvent(loginPopupNameScreenNext);
    onNext({ ...user, name });
  };

  const handlePushToLogin = () => {
    onNext(user, 'LOGIN');
  };

  return (
    <StyledDiv>
      <div>
        <Text
          variant={'medium'}
          weight={'extraBold'}
          color={'primarySecondaryContent'}
        >
          Looks like you&#39;re new!
        </Text>
        <Text
          variant={'large'}
          weight={'extraBold'}
          className={'main-text'}
        >
          What is your full name?
        </Text>
        <Textfield
          inputRef={nameInputRef}
          id={'name'}
          type={'text'}
          defaultValue={user.name}
          placeholder={'Name on Card'}
          // onMouseLeave={() => onHover(email)}
          maxLength={255}
          className={'name-field'}
          errorText={nameError}
        />
        <Text
          variant={'small'}
          weight={'semiBold'}
          onClick={handlePushToLogin}
          color={'primaryGuidingViolet'}
          className={'push-login'}
        >
          Already have an account?
        </Text>
      </div>
      <div className={'continue'}>
        <GivelifyButton onClick={handleSubmit}>Next</GivelifyButton>
      </div>
    </StyledDiv>
  );
};
