import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaTagsProps {
  title?: string;
  description?: string;
  shareUrl?: string;
  bannerUrl?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title,
  description,
  shareUrl,
  bannerUrl
}) => {
  return (
    <Helmet>
      {title && (
        <title>
          {title} Online and Mobile Giving App | Made possible by Givelify
        </title>
      )}
      <meta name={'description'} content={description} />
      <meta property={'og:title'} content={title} />
      <meta property={'og:site_name'} content={'Givelify'} />
      <meta property={'og:url'} content={shareUrl} />
      <meta property={'og:description'} content={description} />
      <meta property={'og:image'} content={bannerUrl} />

      <meta property={'twitter:card'} content={'summary_large_image'} />
      <meta property={'twitter:site'} content={'Givelify'} />
      <meta property={'twitter:title'} content={title} />
      <meta property={'twitter:description'} content={description} />
      <meta property={'twitter:image'} content={bannerUrl} />
    </Helmet>
  );
};

export default MetaTags;
