import { SignupResponse } from '../../components/LoginModal/pages/Landing/api';
import { requestInit } from '../../hooks/useRequestState';
import { LoginResponse } from './interfaces';

const OGA_TOKEN_LOCAL_STORAGE_KEY = 'OGA_TOKEN';
// for backward compatibility
const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'ACCESS_TOKEN';
const SET_LOGIN_TYPE_LOCAL_STORAGE_KEY = 'DONOR_LOGIN_TYPE';
const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'REFRESH_TOKEN';

const getSanitizedToken = (token: string | null) => {
  if (!token) return null;
  if (token.startsWith('"') && token.endsWith('"')) {
    return token.slice(1, -1);
  }
  return token;
};

export const getRefreshTokenFromLocalStorage = (): string | null => {
  const refreshToken = window.localStorage.getItem(
    REFRESH_TOKEN_LOCAL_STORAGE_KEY
  );
  return getSanitizedToken(refreshToken);
};

export const getAccessTokenFromLocalStorage = (): string | null => {
  const token = window.localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  return getSanitizedToken(token);
};

export const setRefreshTokenFromLocalStorage = (token: string) => {
  token && window.localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, token);
};

export const setAccessTokenFromLocalStorage = (token: string) => {
  token && window.localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token);
};

export const deleteAccessTokens = () => {
  window.localStorage.removeItem(OGA_TOKEN_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  window.localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
};

export const setTokensLocalStorage = (login: LoginResponse) => {
  login &&
    login.accessToken &&
    login.refreshToken &&
    window.localStorage.setItem(
      OGA_TOKEN_LOCAL_STORAGE_KEY,
      JSON.stringify(login)
    );
};

export const setLoginToken = (data: SignupResponse) => {
  window.accessToken = data.accessToken;
  setAccessTokenFromLocalStorage(data.accessToken);
  setRefreshTokenFromLocalStorage(data.refreshToken);
  setTokensLocalStorage(data);
};

export const getTokensLocalStorage = (): LoginResponse | undefined => {
  try {
    const token = window.localStorage.getItem(OGA_TOKEN_LOCAL_STORAGE_KEY);
    if (!token) return undefined;
    return JSON.parse(token);
  } catch (e) {
    return undefined;
  }
};

export const getLoginTypeLocalStorage = ():
  | undefined
  | {
      donorName: string | undefined;
      donorEmail: string | undefined;
      loginType: 'password' | 'facebook' | undefined;
    } => {
  const type = window.localStorage.getItem(SET_LOGIN_TYPE_LOCAL_STORAGE_KEY);
  if (!type) return undefined;
  return JSON.parse(type);
};

export const setLoginTypeLocalStorage = (
  donorName: string | undefined,
  donorEmail: string | undefined,
  loginType: 'password' | 'facebook' | undefined
) => {
  const type = getLoginTypeLocalStorage();
  window.localStorage.setItem(
    SET_LOGIN_TYPE_LOCAL_STORAGE_KEY,
    JSON.stringify({
      donorName: donorName || type?.donorName,
      donorEmail: donorEmail || type?.donorEmail,
      loginType: loginType || type?.loginType
    })
  );
};

export const defaultDonorContext = () => ({
  login: requestInit(),
  donor: requestInit(),
  totalGifts: 0,
  totalRecurringDonations: 0
});
