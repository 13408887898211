import colors from '../../../../../theme/colors';

export const buttonColors = {
  muted: colors.grey5,
  blue: colors.primaryBlue,
  ...colors
};

export const buttonSizes = {
  small: {
    text: 10,
    container: 32,
    horizontal: 16
  },
  medium: {
    text: 13,
    container: 40,
    horizontal: 20
  },
  large: {
    text: 15,
    container: 56,
    horizontal: 28
  }
};

export const defaultButtonColor = 'grayscaleBlack';
export const defaultButtonSize = 'medium';
