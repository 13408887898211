import React, { useCallback, useEffect } from 'react';
import Modal from '.';
import styled from 'styled-components';
import { modalBreakPoint } from './styles';
import colors from '../../../../theme/colors';
import ButtonsWrapper, {
  modalMarginValue,
  modalMarginValueMobile
} from '../../../../LoginModal/ButtonsWrapper';
import { Text } from '../Text/Text';
import { IconButton } from '../Button';
import { scrollbarStyles } from '../../../../Scrollbar';

interface GivelifyModalProps {
  open: boolean;
  maxWidth?: string;
  mainTitle?: string;
  hideHeader?: boolean;
  contentStyles?: string;
  children?: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  donee?: {
    name: string;
    url: string | undefined;
    heading: string;
    subHeading: string;
  };
  onBack?: () => unknown;
  onClose: () => unknown;
}

const StyledModal = styled(Modal)<{ maxWidth?: string }>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth} !important` : '')}
`;

const StyledModalContent = styled.div<{
  contentStyles?: string;
}>`
  .header {
    border-bottom: 1px solid ${colors['utilitySeparatorColor']};
    .header-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 18px;
      padding-bottom: 18px;
      padding-left: ${modalMarginValue}px;
      padding-right: ${modalMarginValue}px;
    }
    .done-modal-header {
      .donee-info {
        margin-top: 8px;
        margin-bottom: 18px;
      }
    }
  }
  .modal-content {
    text-align: left;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 36px;
    ${({ contentStyles }) => (contentStyles ? contentStyles : '')}
    ${scrollbarStyles}
  }
  .hidden {
    visibility: hidden;
  }
  @media only screen and (max-width: ${modalBreakPoint}px) {
    .header-buttons {
      padding-left: ${modalMarginValueMobile}px;
      padding-right: ${modalMarginValueMobile}px;
    }
  }
`;

const GivelifyModal: React.FC<GivelifyModalProps> = ({
  open,
  donee,
  maxWidth,
  mainTitle,
  hideHeader,
  contentStyles,
  shouldCloseOnOverlayClick = true,
  onBack,
  onClose,
  children
}) => {
  useEffect(() => {
    return () => {
      document.body.classList.remove('ReactModal__Body--open');
    };
  }, []);

  const handleClose = useCallback(() => {
    document.body.classList.remove('ReactModal__Body--open');
    onClose();
  }, [onClose]);

  return (
    <StyledModal
      active={open}
      maxWidth={maxWidth}
      onClose={handleClose}
      className={'givelify-modal'}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <StyledModalContent contentStyles={contentStyles}>
        {!hideHeader && (
          <div className={'header'}>
            {donee ? (
              <div className={'done-modal-header'}>
                <ButtonsWrapper
                  doneeName={donee.name}
                  doneeUrl={donee.url}
                  onClose={onClose}
                  onBack={onBack}
                />
                <div className={'donee-info'}>
                  <Text
                    variant={'large'}
                    weight={'bold'}
                  >
                    {donee.heading}
                  </Text>
                  <Text
                    variant={'large'}
                    weight={'bold'}
                    color={'primarySecondaryContent'}
                  >
                    {donee.subHeading}
                  </Text>
                </div>
              </div>
            ) : (
              <div className={'header-buttons'}>
                <IconButton
                  name={'back_arrow'}
                  size={'xSmall'}
                  width={'link'}
                  raised={'none'}
                  color={'transparent'}
                  onClick={onBack}
                  iconProps={undefined}
                  className={onBack ? undefined : 'hidden'}
                />
                {mainTitle && (
                  <Text
                    variant={'large'}
                    weight={'bold'}
                  >
                    {mainTitle}
                  </Text>
                )}
                <IconButton
                  name={'close-icon'}
                  size={'xSmall'}
                  width={'link'}
                  raised={'none'}
                  color={'transparent'}
                  onClick={onClose}
                  iconProps={undefined}
                />
              </div>
            )}
          </div>
        )}
        <div className={'modal-content'}>{children}</div>
      </StyledModalContent>
    </StyledModal>
  );
};

export default GivelifyModal;
