export const LOCAL_STORAGE_PACKAGE_VERSION_KEY = 'version';

export const setLocalStorageVersion = (value: string) =>
  window.localStorage.setItem(LOCAL_STORAGE_PACKAGE_VERSION_KEY, value);

export const getLocalStorageVersion = (): string | null =>
  window.localStorage.getItem(LOCAL_STORAGE_PACKAGE_VERSION_KEY);

export const deleteLocalStorageVersion = () =>
  window.localStorage.removeItem(LOCAL_STORAGE_PACKAGE_VERSION_KEY);

export const cleanupCache = (newVersion: string) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.localStorage.clear();
    window.location.reload();
    setLocalStorageVersion(newVersion);
  }
};
