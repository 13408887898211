import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { assetUrl } from '../../../utils/webConfig';
import { icons } from '../../Donee/components/common/Icon/constants';
import { usePushEvents } from '../../../hooks/PushEvents';
import { appDownloadEvent } from '../../../hooks/PushEvents/clevertapEvents';

const StyledDiv = styled.div`
  .download-banner-wrapper {
    min-width: 634px;
    left: 50%;
    bottom: 16px;
    position: fixed;
    transform: translate(-50%);
    z-index: 999999;
  }
  .download-banner {
    display: grid;
    grid-auto-flow: column;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0px 5.5px 30px 0px rgba(66, 89, 86, 0.12);
  }
  .download-banner-wrapper h2 {
    font-family: 'MADE Mirage';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-top: 0px;
    margin-bottom: 16px;
  }
  .download-banner-wrapper p.number-of-organizations {
    color: #73717f;
    font-family: 'Area Normal';
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  .download-banner-wrapper .banner-hand {
    width: auto;
    height: 126px;
    align-self: end;
    margin-right: 35px;
  }
  .download-banner-wrapper .bar-code {
    width: 114px;
    height: 114px;
    margin-top: 3px;
    margin-left: 40px;
    align-self: center;
  }
  .download-banner-wrapper .banner-content {
    padding-top: 32px;
    padding-bottom: 23px;
  }
  .download-banner-wrapper .banner-reviews {
    color: #73717f;
  }
  .download-banner-wrapper .banner-stars-wrapper {
    display: flex;
    align-items: center;
  }
  .download-banner-wrapper .banner-stars {
    font-family: 'Area Normal';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-left: 6px;
  }
  .download-banner-wrapper .banner-close {
    width: 70px;
    padding-top: 20px;
    padding-right: 20px;
    text-align: right;
  }
  .download-banner-wrapper .banner-close svg {
    cursor: pointer;
  }
  .download-app-button {
    display: none;
  }

  @media screen and (max-width: 634px) {
    .download-banner-wrapper {
      min-width: 100%;
      left: 0;
      bottom: 0;
      transform: none;
    }
    .download-banner {
      display: flex;
    }
    .download-banner-wrapper .banner-hand {
      display: none;
    }
    .download-banner-wrapper .bar-code {
      display: none;
    }
    .download-banner-wrapper .banner-content {
      flex: 1;
      padding-left: 24px;
    }
    .download-app-button {
      cursor: pointer;
      font-family: 'Area Normal';
      font-weight: 700;
      border: none;
      margin-top: 16px;
      padding: 9px 16px 10px 24px;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      color: #ffffff;
      text-decoration: none;
      background-color: #f85520;
      transition: all 0.3s ease-in-out;
    }
    .download-app-button:hover {
      -webkit-box-shadow: 0px 1px 6px rgba(77, 18, 0, 0.1),
        0px 6px 16px rgba(77, 18, 0, 0.15);
      box-shadow: 0px 1px 6px rgba(77, 18, 0, 0.1),
        0px 6px 16px rgba(77, 18, 0, 0.15);
    }
    .download-app-button:hover .download-app-button-arrow {
      background-color: #ffffff;
    }
    .download-app-button:hover .download-app-button-arrow svg {
      fill: #f85520;
    }
    .download-app-button .download-app-button-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
      padding: 6px;
      border-radius: 50%;
      border: 1px solid #ffffff;
      transition: all 0.3s ease-in-out;
    }
    .download-app-button .download-app-button-arrow svg {
      width: 20px;
      height: 20px;
      fill: #ffffff;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const showThis = window.innerWidth > 500;
const ThankYouBanner: React.FC = () => {
  const { pushEvent } = usePushEvents();
  const [show, setShow] = useState<boolean>(showThis);

  useEffect(() => {
    if (showThis) {
      pushEvent(appDownloadEvent, {
        location: 'thankyoubanner',
        action: 'shown'
      });
    }
  }, [pushEvent]);

  const handleClose = useCallback(() => {
    setShow(false);
    pushEvent(appDownloadEvent, {
      location: 'thankyoubanner',
      action: 'dismissed'
    });
  }, [setShow, pushEvent]);

  if (!show) return null;

  return (
    <StyledDiv>
      <div className="download-banner-wrapper">
        <div className="download-banner">
          <img
            className="banner-hand"
            src={assetUrl(icons.handHoldPhone)}
          />
          <div className="banner-content">
            <h2>Download Givelify</h2>
            <p className="number-of-organizations">
              The most loved, free giving app
            </p>
            <div className="banner-stars-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M5.28347 1.54592C5.57692 0.951326 6.42479 0.951327 6.71825 1.54592L7.82997 3.79853L10.3159 4.15976C10.9721 4.2551 11.2341 5.06148 10.7592 5.52431L8.96043 7.27772L9.38507 9.75358C9.49716 10.4071 8.81122 10.9055 8.22431 10.5969L6.00086 9.42798L3.7774 10.5969C3.19049 10.9055 2.50455 10.4071 2.61664 9.75358L3.04128 7.27772L1.24246 5.52431C0.767651 5.06148 1.02966 4.2551 1.68584 4.15976L4.17174 3.79853L5.28347 1.54592Z"
                  fill="#F85520"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M5.28347 1.54592C5.57692 0.951326 6.42479 0.951327 6.71825 1.54592L7.82997 3.79853L10.3159 4.15976C10.9721 4.2551 11.2341 5.06148 10.7592 5.52431L8.96043 7.27772L9.38507 9.75358C9.49716 10.4071 8.81122 10.9055 8.22431 10.5969L6.00086 9.42798L3.7774 10.5969C3.19049 10.9055 2.50455 10.4071 2.61664 9.75358L3.04128 7.27772L1.24246 5.52431C0.767651 5.06148 1.02966 4.2551 1.68584 4.15976L4.17174 3.79853L5.28347 1.54592Z"
                  fill="#F85520"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M5.28347 1.54592C5.57692 0.951326 6.42479 0.951327 6.71825 1.54592L7.82997 3.79853L10.3159 4.15976C10.9721 4.2551 11.2341 5.06148 10.7592 5.52431L8.96043 7.27772L9.38507 9.75358C9.49716 10.4071 8.81122 10.9055 8.22431 10.5969L6.00086 9.42798L3.7774 10.5969C3.19049 10.9055 2.50455 10.4071 2.61664 9.75358L3.04128 7.27772L1.24246 5.52431C0.767651 5.06148 1.02966 4.2551 1.68584 4.15976L4.17174 3.79853L5.28347 1.54592Z"
                  fill="#F85520"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M5.28347 1.54592C5.57692 0.951326 6.42479 0.951327 6.71825 1.54592L7.82997 3.79853L10.3159 4.15976C10.9721 4.2551 11.2341 5.06148 10.7592 5.52431L8.96043 7.27772L9.38507 9.75358C9.49716 10.4071 8.81122 10.9055 8.22431 10.5969L6.00086 9.42798L3.7774 10.5969C3.19049 10.9055 2.50455 10.4071 2.61664 9.75358L3.04128 7.27772L1.24246 5.52431C0.767651 5.06148 1.02966 4.2551 1.68584 4.15976L4.17174 3.79853L5.28347 1.54592Z"
                  fill="#F85520"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M5.28347 1.54592C5.57692 0.951326 6.42479 0.951327 6.71825 1.54592L7.82997 3.79853L10.3159 4.15976C10.9721 4.2551 11.2341 5.06148 10.7592 5.52431L8.96043 7.27772L9.38507 9.75358C9.49716 10.4071 8.81122 10.9055 8.22431 10.5969L6.00086 9.42798L3.7774 10.5969C3.19049 10.9055 2.50455 10.4071 2.61664 9.75358L3.04128 7.27772L1.24246 5.52431C0.767651 5.06148 1.02966 4.2551 1.68584 4.15976L4.17174 3.79853L5.28347 1.54592Z"
                  fill="#F85520"
                />
              </svg>
              <div className="banner-stars">
                4.9 stars,{' '}
                <span className="banner-reviews">103,000+ reviews</span>
              </div>
            </div>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&hl=en_US&gl=US"
              id="download-app-button-link"
              className="download-app-button"
              // alt="Download App"
              aria-label="Download App"
            >
              Get the App
              <div className="download-app-button-arrow">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                >
                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                </svg>
              </div>
            </a>
          </div>
          <img
            className="bar-code"
            alt="Bar Code"
            aria-label="Bar Code"
            src={assetUrl(icons.qrCode)}
          />
          <div
            className="banner-close"
            onClick={handleClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.12129 4.29289C5.73077 3.90237 5.0976 3.90237 4.70708 4.29289C4.31655 4.68342 4.31655 5.31658 4.70708 5.70711L11.0858 12.0858L4.73651 18.435C4.34599 18.8256 4.34599 19.4587 4.73651 19.8492C5.12704 20.2398 5.7602 20.2398 6.15073 19.8492L12.5 13.5L18.8492 19.8492C19.2397 20.2398 19.8729 20.2398 20.2634 19.8492C20.654 19.4587 20.6539 18.8256 20.2634 18.435L13.9142 12.0858L20.2929 5.70711C20.6834 5.31658 20.6834 4.68342 20.2929 4.29289C19.9023 3.90237 19.2692 3.90237 18.8786 4.29289L12.5 10.6716L6.12129 4.29289Z"
                fill="#6A6873"
              />
            </svg>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default ThankYouBanner;
