import styled, { keyframes } from 'styled-components';

const blink1 = keyframes`
  from {
    visibility: hidden;
  }
  50% {
    visibility: hidden;
  }
  to {
    visibility: visible;
  }
`;
const blink2 = keyframes`
  from {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  to {
    visibility: hidden;
  }
`;

export const ErrorPage = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  .background-gradient {
    fill: #b0d0e6;
    animation: ${blink1} 1s infinite;
  }
  .black-line {
    animation: ${blink2} 1s infinite;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 38px;
    margin-top: 50px;
    margin-bottom: 24px;
  }
  h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
  }
  svg {
    width: 335px;
    margin-top: 104px;
  }
  a {
    background: #0376bb;
    border-radius: 60px;
    padding: 15px 45px;
    color: #ffffff;
    margin-top: 56px;
    display: inline-block;
  }
  [theme.breakpoints.down('sm')] {
    svg {
      margin-top: 96px;
    }
    h2 {
      font-size: 18px;
      line-height: 21px;
    }
    a {
      margin-top: 32px;
    }
    h1 {
      margin-top: 30px;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
`;
