import React from 'react';
import styled from 'styled-components';
import GetAppPoint from './GetAppPoint';
import { webConfig } from '../../utils/webConfig';
import { Text } from '../Donee/components/common/Text/Text';
import { GivelifyButton } from '../Donee/components/common/Button/GivelifyButton';

export const getMobileAppHeading = (
  <Text
    variant={'large'}
    weight={'extraBold'}
  >
    Track your giving history and more in the app
  </Text>
);

export const getMobileAppPoints = (
  <>
    <GetAppPoint text={'Give next time in 3 easy taps'} />
    <GetAppPoint text={'Use reminders to give consistently'} />
    <GetAppPoint
      text={'See personalized analytics to help with your giving journey'}
    />
  </>
);

const StyledDiv = styled.div`
  max-width: 327px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  .heading {
    margin-top: 48px;
    margin-bottom: 12px;
  }
  .get-app-button {
    margin-top: 48px;
  }
`;

interface GetAppMobileProps {
  isLoading: boolean;
  handleDownload: () => unknown;
  className?: string;
}

const GetAppMobile: React.FC<GetAppMobileProps> = ({
  className,
  isLoading,
  handleDownload
}) => {
  return (
    <StyledDiv className={className}>
      <img src={webConfig.getAppMobileUrl} />
      <div className={'heading'}>{getMobileAppHeading}</div>
      {getMobileAppPoints}
      <GivelifyButton
        isLoading={isLoading}
        color={'secondary'}
        onClick={handleDownload}
        className={'get-app-button'}
      >
        Get the App
      </GivelifyButton>
    </StyledDiv>
  );
};

export default GetAppMobile;
