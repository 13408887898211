import React from 'react';
import { Label, LabelProps } from './styles';
import { ReactChildren } from '../../../../../providers/DoneeProvider';

export const Text: React.FC<
  LabelProps &
    ReactChildren & { className?: string; id?: string; style?: object }
> = ({
  id,
  color,
  variant = 'medium',
  weight,
  selectable,
  className,
  onClick,
  children
}) => {
  return (
    <Label
      id={id}
      color={color}
      weight={weight}
      variant={variant}
      className={className}
      selectable={selectable}
      onClick={onClick}
    >
      {children}
    </Label>
  );
};
