import React from 'react';
import styled from 'styled-components';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { icons } from '../../../common/Icon/constants';
import { assetUrl } from '../../../../../../utils/webConfig';

interface CardLabelProps {
  cardType: Wallet['type'];
  className?: string;
}

const getCardBade = (type: Wallet['type']) => {
  switch (type) {
    case 'mastercard':
    case 'MasterCard':
      return icons['mastercard_badge'];
    case 'visa':
    case 'Visa':
      return icons['visa_badge'];
    case 'discover':
    case 'Discover':
      return icons['discover_badge'];
    case 'american express':
    case 'American Express':
      return icons['american_express_badge'];
    case 'diners club':
    case 'Diners Club':
      return icons['diners_club_badge'];

    default:
      return undefined;
  }
};

const StyledImg = styled.img``;

const CardLabel: React.FC<CardLabelProps> = ({ cardType, className }) => {
  const type = getCardBade(cardType);
  return (
    <StyledImg
      className={className}
      src={type ? assetUrl(type) : undefined}
    />
  );
};

export default CardLabel;
