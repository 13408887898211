import styled from 'styled-components';

import { Link } from 'react-router-dom';
import layout from '../theme/layout';
import colors from '../theme/colors';
import WrapperButton from '../Donee/components/common/Button/WrapperButton';

const { breakpoints } = layout;
const { mobile, tablet } = breakpoints;

interface LinkProps {
  active?: boolean | string;
}

export const NavContainer = styled.div`
  width: 100%;
  height: 44px;
  background-color: ${colors.grayscaleGray75};
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 15px;
  z-index: 3;
`;

export const NavContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${tablet.max}px) {
    justify-content: center;
  }
`;

export const NavLogoContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${tablet.max}px) {
  }
`;

export const NavLogo = styled.img`
  max-height: 30px;
`;

export const NavMenuListMain = styled.div<LinkProps>`
  ${(props) =>
    props.active
      ? 'background: #fff; border-radius: 4px; box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); position: absolute; top: 100%; right: 0; border-top: 1px solid #ddd; width: 180px; padding: 6px 0; margin-top: 6px;'
      : ''}
  @media screen and (max-width: ${tablet.max}px) {
    margin-top: ${(props) => (props.active ? '21px' : '0')};
  }
  @media screen and (max-width: ${mobile.max}px) {
    margin-top: ${(props) => (props.active ? '18px' : '0')};
  }
`;

export const NavLinkList = styled.div<LinkProps>`
  align-items: center;
  justify-content: space-between;
  left: ${(props) => (props.active === '1' ? '0' : '-260px')};
`;

export const NavLink = styled(Link)<LinkProps>`
  text-decoration: none;
  padding: ${(props) => (props.active ? '10px 20px' : '6px 20px')};
  font-size: 14px;
  width: ${(props) => (props.active ? '100%' : 'auto')}
  display: inline-block;
  transition: background 0.15s ease-in, box-shadow 0.15s ease-out;
  border-radius: ${(props) => (props.active ? '0' : '10rem')};
  :hover {
    background:  ${(props) => (props.active ? '#F3F6FC' : '#fff')};
    box-shadow: ${(props) =>
      props.active ? '0' : '0px 1px 2px rgba(132, 151, 178, 0.8);'};
  }
  @media screen and (max-width: ${tablet.max}px) {
    padding: ${(props) => (props.active ? '10px 20px' : '3px 0px')};
  }
`;

export const NavSignInLinkButton = styled.button<LinkProps>`
  border: none;
  text-decoration: none;
  padding: ${(props) => (props.active ? '10px 20px' : '6px 20px')};
  font-size: 14px;
  width: ${(props) => (props.active ? '100%' : 'auto')}
  display: inline-block;
  transition: background 0.15s ease-in, box-shadow 0.15s ease-out;
  border-radius: ${(props) => (props.active ? '0' : '10rem')};
  :hover {
    background:  ${(props) => (props.active ? '#F3F6FC' : '#fff')};
    box-shadow: ${(props) =>
      props.active ? '0' : '0px 1px 2px rgba(132, 151, 178, 0.8);'};
  }
  @media screen and (max-width: ${tablet.max}px) {
    padding: ${(props) => (props.active ? '10px 20px' : '3px 0px')};
  }
`;

export const NavLink2 = styled.a`
  text-decoration: none;
  padding: 3px 30px;
  font-size: 14px;
`;

export const NavLinkForSignIn = styled(Link)<LinkProps>`
  text-decoration: none;
  padding: ${(props) => (props.active ? '10px 20px' : '6px 20px')};
  font-size: 14px;
  width: 100%;
  display: inline-block;
  transition: background 0.1s ease-in, box-shadow 0.1s ease-out;
  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;
  :hover {
    background: ${(props) => (props.active ? '#F3F6FC' : '#fff')};
    box-shadow: 0px 1px 2px rgba(132, 151, 178, 0.8);
    transition: background 0.15s ease-in, box-shadow 0.15s ease-out;
  }
  @media screen and (max-width: ${tablet.max}px) {
    padding: ${(props) => (props.active ? '10px 20px' : '4px 12px')};
  }
`;

export const NavLinkButton = styled(WrapperButton)<{ active?: boolean }>`
  text-decoration: none;
  padding: 10px 20px;
  font-size: 14px;
  :hover {
    background: ${(props) => (props.active ? '#fff' : '#F3F6FC')};
  }
  @media screen and (max-width: ${tablet.max}px) {
    padding: 8px 18px;
  }
`;

export const NavMenuIcon = styled.div`
  width: 20px;
  height: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NavMenuIconBar = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${colors.grayscaleBlack};
`;

export const NavMenuIconBarClose1 = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${colors.grayscaleBlack};
  transform: rotate(45deg) translateY(1px) translateX(1px);
`;

export const NavMenuIconBarClose2 = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-color: ${colors.grayscaleBlack};
  transform: rotate(-45deg);
`;

export const NavMenuButton = styled.button`
  width: 21px;
  height: 26px;
  cursor: pointer;
  top: 0px;
  left: 15px;
  position: absolute;
  border: none;
  outline: none;
  background-color: transparent;
  transition: all 0.1s linear;
  padding: 0;
  &:active {
    opacity: 0.7;
    transition: all 0.1s linear;
  }
`;

export const LinkToAmount = styled(Link)`
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #3a4c63;
`;

export const MainLogoLink = styled(Link)``;

export const MainLogo = styled.img`
  max-width: 73px;
  @media screen and (max-width: ${tablet.max}px) {
    display: none;
  }
`;

export const ProfileMain = styled.div`
  float: right;
  margin-right: 20px;
  position: relative;
  @media screen and (max-width: ${tablet.max}px) {
    margin-right: 12px;
  }
`;

export const ProfileLink = styled.a`
  display: flex;
`;

export const MobileUserThumb = styled.div`
  padding: 8px 18px;
  border-bottom: 1px solid #eaeaea;
`;

export const UserThumb = styled.img`
  float: left;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  overflow: hidden;
`;

export const DownArrow = styled.img``;

export const MenuIcon = styled.div`
  margin-right: 13px;
  width: 24px;
`;

export const UserAvatar = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  overflow: hidden;
`;

export const NavBarActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Cart = styled.div`
  box-shadow: 0px 0px 4px rgba(132, 151, 178, 0.8);
  border-radius: 50%;
  text-align: center;
  padding: 2px;
  background: #fff;
`;

export const CartInner = styled.div`
  border: 2px solid #c2cbd8;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
