import React from 'react';
import styled from 'styled-components';
import SetPasswordForm from './SetPasswordForm';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { MainSection } from '../../components/Donee/components/common/Layout/styles';
import { H2 } from '../../components/Donee/components/common/H2/styles';
import { Label } from '../../components/Donee/components/common/Text/styles';

const StyledDiv = styled.div`
  .text-center {
    text-align: center;
  }
`;

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SetPassword: React.FC = () => {
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token') || query.get('code');

  return (
    <StyledDiv className={'mainpart'}>
      <Container>
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <H2
                size={'large'}
                color={'grayscaleBlack'}
                weight={'medium'}
                className={'text-center'}
              >
                Set Password
              </H2>
              {token ? (
                <SetPasswordForm
                  email={email?.replace(/\ /g, '+')}
                  token={token}
                />
              ) : (
                <Label
                  variant={'xSmall'}
                  color={'red'}
                  weight={undefined}
                  className={'text-center'}
                >
                  The link is not valid. Please try resetting the password
                  again.
                </Label>
              )}
            </Col>
          </Row>
        </MainSection>
      </Container>
    </StyledDiv>
  );
};

export default SetPassword;
