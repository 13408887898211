import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePushEvents } from '../../hooks/PushEvents';
import { amountNumberFormat } from '../../utils/functions';
import { axiosClient } from '../../utils/axiosClient';
import { somethingWrongMsg } from '../../utils/messages';
import { useDonor } from '../../providers/DonorProvider/useDonor';
import { useApiRequest } from '../../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../../utils/makeApiRequest';
import { GiftSummaryResponse, getGiftSummaryRequest } from './api';
import { profileDetailsShown } from '../../hooks/PushEvents/clevertapEvents';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import { HeartLoading } from '../../components/HeartLoading';
import DonorProfileDetailsView from './DonorProfileDetailsView';
import { useQueryString } from '../../hooks/useQueryString';
import { ROUTES } from '../../utils/routes';

const CHANGE_PASSWORD_URL = '/access/password/reset';

const ProfileDetails: React.FC = () => {
  const { pushEvent } = usePushEvents();
  const [changePassModal, setChangePassModal] = useState(false);
  const [successChangePass, setSuccessChangePass] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<GiftSummaryResponse>>();
  const query = useQueryString();

  useEffect(() => {
    if (query.get('success')) {
      setSuccess('Profile Updated successfully.');
      push(ROUTES.profile());
    }
  }, [query]);

  const push = useNavigate();
  const { donorContext } = useDonorContext();

  useEffect(() => {
    makeRequest(getGiftSummaryRequest());
    pushEvent(profileDetailsShown);
  }, []);

  useEffect(() => {
    if (donorContext.donor.type === 'REQUEST_ERROR') {
      push(ROUTES.main);
    }
  }, [donorContext.donor.type]);

  if (
    donorContext.donor.type === 'REQUEST_INIT' ||
    donorContext.donor.type === 'REQUEST_START' ||
    requestState.type === 'REQUEST_INIT' ||
    requestState.type === 'REQUEST_START'
  ) {
    return <HeartLoading maxWidth={300} />;
  }

  if (
    donorContext.donor.type === 'REQUEST_ERROR' ||
    requestState.type === 'REQUEST_ERROR'
  ) {
    return <div>{somethingWrongMsg}</div>;
  }

  const { address, phone, email, name, pictureUrl } =
    donorContext.donor.data.donor || '';
  const { street, city, state, zip } = address;

  const changePasswordAction = () => {
    const data = {
      doneeId: 'donorResetPassword',
      emailAddress: email
    };

    axiosClient
      .post(CHANGE_PASSWORD_URL, data)
      .then((response) => {
        if (response.data.response.error) {
          setError(
            response.data.response.error.message ||
              response.data.response.error.error_message ||
              'Unable to change the password, please try again!'
          );
          setChangePassModal(false);
        } else {
          setSuccessChangePass(
            'Change password link send to your email account.'
          );
          setChangePassModal(false);
          setSuccess('');
        }
      })
      .catch(() => {
        setError(somethingWrongMsg);
      });
  };

  const closeMessage = () => () => {
    setError('');
    setSuccessChangePass('');
  };

  let fullAddress = '';
  fullAddress += street;
  if (street !== '' && city !== '') {
    fullAddress += ', ';
  }
  fullAddress += city;
  if (city !== '' && state !== '') {
    fullAddress += ', ';
  }
  fullAddress += state;
  if (state !== '' && zip !== '') {
    fullAddress += ' ';
  }
  fullAddress += zip;

  return (
    <DonorProfileDetailsView
      fullAddress={fullAddress}
      picture={pictureUrl}
      name={name}
      email={email}
      phone={phone}
      address={address}
      city={city}
      state={state}
      zip={zip}
      contributions={requestState.data.response.payload.all.count}
      totalGiving={amountNumberFormat(
        requestState.data.response.payload.all.total
      )}
      error={error}
      success={success}
      successChangePass={successChangePass}
      changePassModal={changePassModal}
      setError={setError}
      setSuccess={setSuccess}
      setChangePassModal={setChangePassModal}
      closeMessage={closeMessage}
      changePasswordAction={changePasswordAction}
    />
  );
};

export default ProfileDetails;
