import React from 'react';
import Axios from 'axios';
import get from 'lodash/get';
import HistoryDetailDesign from './HistoryDetailDesign';
import { useParams } from 'react-router-dom';
import { usePushEvents } from '../../../../../../hooks/PushEvents/index';
import { useDonor } from '../../../../../../providers/DonorProvider/useDonor';
import { webConfig } from '../../../../../../utils/webConfig';
import { historyShown } from '../../../../../../hooks/PushEvents/clevertapEvents';
import { memberIdPattern } from '../../../common/constants';
import { GiftHistoryResponse } from '../api';
import { HeartLoading } from '../../../../../HeartLoading';

interface HistoryDetailProps {
  details: GiftHistoryResponse['data'][number] & { detailType: 'history' };
  exitDetail: () => void;
}

const HistoryDetail: React.FC<HistoryDetailProps> = ({
  details,
  exitDetail
}) => {
  const { pushEvent } = usePushEvents();
  const [loader, setLoader] = React.useState(!details);
  const [memo, setMemo] = React.useState<string | undefined>(undefined);
  const [memberIdModal, setMemberIdModal] = React.useState<boolean>(false);
  const [memberId, setMemberId] = React.useState<string | undefined>(undefined);
  const [memberIdInput, setMemberIdInput] = React.useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = React.useState<string | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [apiError, setApiError] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState<string | undefined>(undefined);

  const { donor } = useDonor() || {};
  const { doneeId } = useParams();

  const creditNo = details
    ? `****${details.gift.maskedPan.substr(details.gift.maskedPan.length - 4)}`
    : '';
  const validateMemberId = (e: any) => memberIdPattern.test(e);

  // TODO
  // React.useEffect(() => {
  //   if (donorInfo.loadingState === LoadingStates.pending) {
  //     setLoader(true);
  //   } else if (donorInfo.loadingState === LoadingStates.fulfilled) {
  //     dispatch(setLoadingState(undefined));
  //     setLoader(false);
  //     setDetails(donorInfo.giftDetails);
  //   } else if (donorInfo.loadingState === LoadingStates.rejected) {
  //     setLoader(false);
  //     dispatch(setLoadingState(undefined));
  //     const errorMsg = donorInfo.error
  //       ? donorInfo.error.message
  //       : somethingWrongMsg;
  //     setError(errorMsg);
  //     setSuccess('');
  //   }
  // }, [donorInfo.loadingState]);

  const updateMemberIdAndSuccess = React.useCallback(
    (newType?: any) => {
      if (newType === 'delete') {
        setMemberId(undefined);
        setError('Member ID/Envelope deleted successfully.');
        setSuccess(undefined);
      } else if (newType === 'add') {
        setMemberId(memberIdInput);
        setError(undefined);
        setSuccess('Member ID/Envelope added successfully.');
      } else {
        setMemberId(memberIdInput);
        setError(undefined);
        setSuccess('Member ID/Envelope updated successfully.');
      }
    },
    [memberIdModal, type]
  );

  const updateMemberIdAction = () => {
    if (
      (memberIdInput === undefined && type !== 'delete') ||
      !validateMemberId(memberIdInput)
    ) {
      return false;
    }

    let newType = type === 'delete' ? type : 'update';

    let newMemberIdInput = memberIdInput;
    if (type === 'delete') {
      newMemberIdInput = memberId;
    } else if (!memberId && newMemberIdInput) {
      newType = 'add';
    } else {
      newType = 'update';
    }

    if (donor && doneeId && newMemberIdInput) {
      const data = {
        id: 'donorEnvelopeId',
        data: {
          donor_id: donor.id,
          donee_id: doneeId,
          envelope_id: decodeURIComponent(newMemberIdInput),
          option: newType
        }
      };

      setLoading(true);

      const headers = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      Axios.post(webConfig.baseAPIUrl, data, headers)
        .then((response) => {
          if (typeof response.data.Response.Result !== 'undefined') {
            memberIdModalClose();
            setType(undefined);
            updateMemberIdAndSuccess();
          } else {
            setApiError(response.data.Response.Error.message);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    return true;
  };

  const onMemberIdInput = (value: string) => {
    setMemberIdInput(value);

    if (value === undefined || validateMemberId(value)) {
      setApiError(undefined);
      return true;
    }
    setApiError(
      'Member ID only allow alphanumeric, underscore and dash (no special chars)'
    );
    return false;
  };

  const memberIdModalOpen = () => {
    setMemberIdModal(true);
    setMemberIdInput(memberId);
    setApiError(undefined);
  };

  // copy data
  const memberIdModalClose = () => {
    setMemberIdModal(false);
  };

  const memberIdDelete = () => {
    memberIdModalClose();
    if (memberId !== undefined) {
      setType('delete');
      updateMemberIdAction();
    }
  };

  const closeMessage = () => () => {
    setSuccess(undefined);
    setError(undefined);
  };

  const getMemoData = () => {
    const data = {
      id: 'donorDonationInfo',
      data: {
        txn_id: details.gift.txnId
      }
    };

    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    Axios.post(webConfig.baseAPIUrl, data, headers)
      .then((response) => {
        const responseDoneeId = get(response.data, 'Response.Result.DoneeId');
        if (responseDoneeId) {
          if (!memberId) {
            getMemberId();
          }
        }

        const firstMemo = get(
          response.data,
          'Response.Result.MemoItems.0.memo'
        );
        if (firstMemo) {
          setMemo(firstMemo);
        }
      })
      .catch(() => null);
  };

  const getMemberId = () => {
    if (!donor) return;
    const data = {
      id: 'donorEnvelopeId',
      data: {
        donor_id: donor.id,
        option: 'list'
      }
    };

    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    Axios.post(webConfig.baseAPIUrl, data, headers)
      .then((response) => {
        const members = get(response.data, 'Response.Result.info', []);
        const member = members.find((o: any) => o.donee_id === doneeId);
        if (member) {
          setMemberId(member.member_id);
        }
      })
      .catch(() => null);
  };

  React.useEffect(() => {
    if (details) {
      getMemoData(); // history detail page get memo string.
    } else {
      // TODO
      // dispatch(getGiftDetails({ giftId }));
    }
    pushEvent(historyShown);
  }, [details]);

  if (loader) {
    return <HeartLoading maxWidth={300} />;
  }

  return (
    <HistoryDetailDesign
      success={success}
      error={error}
      closeMessage={closeMessage}
      exitDetail={exitDetail}
      memberId={memberId}
      memberIdPopup={memberIdModal}
      memberIdInput={memberIdInput}
      updateMemberIdAction={updateMemberIdAction}
      memberIdDelete={memberIdDelete}
      memberIdModalOpen={memberIdModalOpen}
      memberIdModalClose={memberIdModalClose}
      apiError={apiError}
      onMemberIdInput={onMemberIdInput}
      loading={loading}
      details={details}
      memo={memo}
      creditNo={creditNo}
    />
  );
};

export default HistoryDetail;
