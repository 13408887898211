import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import StateField from './StateField';
import styled from 'styled-components';
import { Textfield } from '../Textfield';
import { Secure } from '../CreditCard/Secure';
import { WalletResponse, addWallet } from '../Landing/api';
import { ContentStyles } from '../../utils';
import { ModalRouterProps } from '../../Router';
import { usePushEvents } from '../../../../hooks/PushEvents';
import { useApiRequest } from '../../../../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../../../../utils/makeApiRequest';
import { User } from '../../routes';
import { requestRecaptchaV3Token } from '../../../Wrappers/ReCaptchaV3';
import {
  loginPopupAddressCityScreenNext,
  loginPopupAddressCityScreenShown,
  loginPopupSuccessfulSignupAfterFailure
} from '../../../../hooks/PushEvents/clevertapEvents';
import { Text } from '../../../Donee/components/common/Text/Text';
import { GivelifyButton } from '../../../Donee/components/common/Button/GivelifyButton';
import { useDonorContext } from '../../../../providers/DonorProvider/DonorProvider';

const StyledDiv = styled(ContentStyles)`
  text-align: left;
  .info {
    margin-top: 8px;
    margin-bottom: 18px;
  }
  .city {
    margin-bottom: 8px;
  }
`;

export type AddressCompleteProps = ModalRouterProps & {
  questionText?: string;
  buttonText?: string;
  isLoading?: boolean;
  className?: string;
  ExtraComponent?: React.FC;
};

const AddressComplete: React.FC<AddressCompleteProps> = ({
  user,
  className,
  isLoading,
  ExtraComponent,
  buttonText = 'Next',
  questionText = 'Billing Address',
  onNext
}) => {
  const { pushEvent } = usePushEvents();
  const [wallet, makeWalletRequest] =
    useApiRequest<GivingApiResponse<WalletResponse>>();

  const [city, setCity] = useState<string>(user.address?.city || '');
  const [state, setState] = useState<string>(user.address?.state || '');
  const [cityError] = useState<string | undefined>();
  const [stateError] = useState<string | undefined>();

  const handleCity = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCity(value);
    },
    [setCity]
  );
  const handleState = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setState(value);
    },
    [setState]
  );

  const handleSubmit = useCallback(() => {
    const address: User['address'] = {
      street: user.address?.street || '',
      zip: user.address?.zip || '',
      countryCode: user.address?.countryCode || '',
      city,
      state
    };

    pushEvent(loginPopupAddressCityScreenNext);

    if (user.skipsSignup) {
      requestRecaptchaV3Token(
        (token: string | undefined) => {
          makeWalletRequest(addWallet({ ...user, address }, token));
        },
        undefined,
        'AddressComplete'
      );
    } else {
      onNext({ ...user, address });
    }
  }, [onNext, user, city, state]);

  useEffect(() => {
    pushEvent(loginPopupAddressCityScreenShown);
  }, []);

  const { loadDonor } = useDonorContext();
  useEffect(() => {
    if (wallet.type === 'REQUEST_SUCCESS') {
      loadDonor();
      pushEvent(loginPopupSuccessfulSignupAfterFailure);
    }
    if (wallet.type === 'REQUEST_ERROR') {
      const address: User['address'] = {
        street: user.address?.street || '',
        zip: user.address?.zip || '',
        countryCode: user.address?.countryCode || '',
        city,
        state
      };
      onNext({ ...user, address, skipsSignup: true }, 'CREDIT');
    }
  }, [wallet.type]);

  return (
    <StyledDiv className={className}>
      <div>
        <Text
          variant={'medium'}
          weight={'extraBold'}
        >
          {questionText}
        </Text>
        <Secure className={'info'}>Your information is secure</Secure>
        <Textfield
          id={'city'}
          placeholder={'City'}
          value={city}
          // onMouseLeave={() => onHover(email)}
          maxLength={255}
          className={'city'}
          errorText={cityError}
          onChange={handleCity}
        />
        <StateField
          state={state}
          stateError={stateError}
          country={user.address?.countryCode}
          handleStateValue={setState}
          handleState={handleState}
        />
        {ExtraComponent && <ExtraComponent />}
      </div>
      <div className={'continue'}>
        <GivelifyButton
          isLoading={
            isLoading ||
            wallet.type === 'REQUEST_START' ||
            wallet.type === 'REQUEST_SUCCESS'
          }
          onClick={handleSubmit}
          disabled={!city || !state}
        >
          {user.skipsSignup ? 'Add card' : buttonText}
        </GivelifyButton>
      </div>
    </StyledDiv>
  );
};

export default AddressComplete;
