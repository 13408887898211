import React, { useCallback, useEffect } from 'react';
import DonorRecurringView from './DonorRecurringView';
import { useNavigate } from 'react-router-dom';
import { usePushEvents } from '../../hooks/PushEvents';
import { recurringShown } from '../../hooks/PushEvents/clevertapEvents';
import { ROUTES } from '../../utils/routes';
import { useApiRequest } from '../../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../../utils/makeApiRequest';
import {
  RecurringDonationsResponse,
  cancelRecurringDonation,
  getAllRecurringDonations
} from './api';
import { useDonorContext } from '../../providers/DonorProvider/DonorProvider';
import { recurringName } from '../../components/Donee/components/CheckoutWizard/CartWizard/SetRecurring/recurringConstants';
import { HeartLoading } from '../../components/HeartLoading';

const Recurring: React.FC = () => {
  const push = useNavigate();
  const { pushEvent } = usePushEvents();
  const [recurringPopup, setRecurringPopup] = React.useState(false);
  const [recurringId, setRecurringId] = React.useState<number | undefined>();
  const [envelopeId, setEnvelopeId] = React.useState<number | undefined>(
    undefined
  );
  const [envelopeName, setEnvelopeName] = React.useState('');
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  const [recurringTooltip, setRecurringToolTip] = React.useState<boolean>();
  const [recurringMsg, setRecurringMsg] = React.useState<string | undefined>();

  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<RecurringDonationsResponse[]>>();
  const [requestCancelState, makeCancelRequest] =
    useApiRequest<GivingApiResponse<any>>();

  const { donorContext } = useDonorContext();
  const donorInfo = donorContext.donor.data || undefined;

  const getRecurringDonations = useCallback(() => {
    makeRequest(getAllRecurringDonations());
  }, []);

  useEffect(() => {
    getRecurringDonations();
    pushEvent(recurringShown);
  }, []);

  useEffect(() => {
    if (donorContext.donor.type === 'REQUEST_ERROR') {
      push(ROUTES.login);
    }
  }, [donorContext.donor.type]);

  const getRecurringName = (frequency: number | string) => {
    for (let i = 0; i < recurringName.length; i++) {
      if (
        parseInt(recurringName[i].value, 10) === parseInt(frequency as any, 10)
      ) {
        return recurringName[i].name;
      }
    }
    return undefined;
  };

  const getCardNumber = (walletId: number) => {
    const wallets = donorInfo && donorInfo.wallets ? donorInfo.wallets : [];

    for (let j = 0; j < wallets.length; ++j) {
      if (wallets[j].id === walletId) {
        return wallets[j].number.slice(-4);
      }
    }
    return undefined;
  };

  const getCardIcon = (walletId: number) => {
    const wallets = donorInfo && donorInfo.wallets ? donorInfo.wallets : [];

    for (let k = 0; k < wallets.length; ++k) {
      if (wallets[k].id === walletId) {
        return wallets[k].type.toLowerCase();
      }
    }
    return undefined;
  };

  const closeModal = () => {
    setRecurringPopup(false);
    setRecurringId(undefined);
    setEnvelopeId(undefined);
    setEnvelopeName('');
  };

  const recurringPopupOpen = () => {
    setRecurringPopup(true);
    setRecurringToolTip(false);
  };

  useEffect(() => {
    if (requestCancelState.type === 'REQUEST_ERROR') {
      setServerError('Unable to cancel at the moment. Please try again');
    }
    if (requestCancelState.type !== 'REQUEST_SUCCESS') return;
    getRecurringDonations();
    closeModal();
  }, [requestCancelState.type]);

  const cancelRecurring = () => {
    recurringId && makeCancelRequest(cancelRecurringDonation(recurringId));
  };

  const formatDate = (date: string) => {
    const timestamp = date.split(' ');
    const newDate = timestamp[0].split('-');
    const monthList = {
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
      10: 'October',
      11: 'November',
      12: 'December'
    };
    const month = monthList[newDate[1] as keyof typeof monthList];
    return `${newDate[2]} ${month}`;
  };

  const tooltip = (
    newRecurringId: number,
    newEnvelopeId: number,
    newEnvelopeName: string
  ) => {
    setRecurringId(newRecurringId);
    setEnvelopeId(newEnvelopeId);
    setEnvelopeName(newEnvelopeName);
    setRecurringToolTip(true);
  };

  const closeMessage = () => () => {
    setRecurringMsg('');
  };

  const closeMessage2 = () => () => {
    setErrorMsg(false);
  };

  const isLoading =
    donorContext.donor.type === 'REQUEST_INIT' ||
    donorContext.donor.type === 'REQUEST_START' ||
    requestState.type === 'REQUEST_INIT' ||
    requestState.type === 'REQUEST_START';

  if (isLoading) {
    return <HeartLoading maxWidth={300} />;
  }

  return (
    <DonorRecurringView
      donorInfo={donorInfo}
      recurrings={requestState.data?.response.payload || []}
      errorMsg={
        requestState.type === 'REQUEST_ERROR' && requestState.error.message
      }
      recurringMsg={recurringMsg}
      serverError={serverError}
      error={
        requestState.type === 'REQUEST_SUCCESS' &&
        requestState.data.response.payload.length === 0
      }
      loader={isLoading}
      recurringTooltip={recurringTooltip}
      recurringId={recurringId}
      envelopeId={envelopeId}
      recurringPopup={recurringPopup}
      envelopeName={envelopeName}
      closeMessage={closeMessage}
      closeMessage2={closeMessage2}
      setServerError={setServerError}
      tooltip={tooltip}
      recurringPopupOpen={recurringPopupOpen}
      setRecurringToolTip={setRecurringToolTip}
      setRecurringId={setRecurringId}
      setEnvelopeId={setEnvelopeId}
      getRecurringName={getRecurringName}
      getCardIcon={getCardIcon}
      getCardNumber={getCardNumber}
      formatDate={formatDate}
      closeModal={closeModal}
      cancelRecurring={cancelRecurring}
    />
  );
};

export default Recurring;
