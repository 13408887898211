import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { getAddressComponents } from '../pages/Landing/api';
import { useGoogleAutocomplete } from '../../Wrappers/useGoogleAutocomplete';
import { Input } from '../../Donee/components/common/Input/TextInput/styles';

const getOptionLabel = (
  option: google.maps.places.AutocompletePrediction
): string => {
  const mainText = option.structured_formatting.main_text;
  const secondaryText = option.structured_formatting.secondary_text;
  return secondaryText ? `${mainText}, ${secondaryText}` : mainText;
};

const getOptionSelected = (
  option: google.maps.places.AutocompletePrediction,
  value: google.maps.places.AutocompletePrediction
): boolean =>
  option.structured_formatting.main_text ===
  value.structured_formatting.main_text;

export interface AddressFieldProps {
  id?: string;
  street: string;
  className?: string;
  placeholder?: string;
  streetError: string | undefined;
  setStreetError: (error: string | undefined) => unknown;
  setStreet: (street: string) => unknown;
  setZip: (zip: string) => unknown;
  setCity: (city: string) => unknown;
  setCountry?: (country: string) => unknown;
  setCountryCode: (countryCode: string) => unknown;
  setState: (state: string) => unknown;
}

export const AddressField: React.FC<AddressFieldProps> = ({
  id,
  street,
  className,
  placeholder = 'Street',
  setStreet,
  setZip,
  setCity,
  setCountry,
  setCountryCode,
  setState
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>(street);
  const [value, setValue] =
    useState<google.maps.places.AutocompletePrediction | null>(null);
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [addressLookup, makeLookupRequest, makeGeocoderRequest] =
    useGoogleAutocomplete();

  useEffect(() => {
    if (addressLookup.type === 'REQUEST_SUCCESS') {
      setOptions(addressLookup.data);
    }
  }, [addressLookup, setOptions]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setValue(null);
      setInput(value);
      setStreet(value);
      setCity('');
      setState('');
      if (value.length > 2) {
        makeLookupRequest(value);
        setOpen(true);
      } else {
        setOpen(false);
      }
    },
    [
      setInput,
      setValue,
      setStreet,
      setOpen,
      makeLookupRequest,
      setCity,
      setState
    ]
  );

  const handleAddressData = useCallback(
    (place: google.maps.GeocoderResult) => {
      const { zip, city, state, country, countryCode } = getAddressComponents(
        place.address_components
      );
      setZip(zip);
      setCity(city);
      setState(state);
      setCountry?.(country);
      setCountryCode(countryCode);
    },
    [setZip, setCity, setState, setCountry, setCountryCode]
  );

  const handleSelect = useCallback(
    (
      _e: React.ChangeEvent<unknown>,
      value: google.maps.places.AutocompletePrediction | null
    ) => {
      setValue(value);
      if (!value) {
        return;
      }
      const street = getOptionLabel(value);
      const address = street.split(',')[0];
      setInput(address);
      setStreet(address);
      makeGeocoderRequest(value.place_id, handleAddressData);
      setOpen(false);
    },
    [setValue, setInput, setOpen, makeGeocoderRequest]
  );

  return (
    <Autocomplete
      id={id}
      loading={addressLookup.type === 'REQUEST_START'}
      noOptionsText={'No address found'}
      options={options}
      size={'small'}
      value={value}
      onChange={handleSelect}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      className={className}
      open={open}
      onBlur={handleClose}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <div ref={params.InputProps.ref}>
          <Input
            {...params.inputProps}
            value={input}
            placeholder={placeholder}
            onChange={handleChange}
          />
        </div>
      )}
    />
  );
};
