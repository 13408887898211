import React, { ReactNode } from 'react';

import { H2 as StyledText } from './styles';

import {
  defaultTextSize,
  defaultTextWeight,
  defaultTextColor,
  textSizes,
  textColors,
  textWeights
} from './constants';
import { ReactChildren } from '../../../../../providers/DoneeProvider';

interface HeadingProps extends ReactChildren {
  size?: keyof typeof textSizes;
  color?: keyof typeof textColors;
  weight?: keyof typeof textWeights;
  value?: ReactNode;
}

const H2: React.FC<HeadingProps> = ({
  size = defaultTextSize,
  color = defaultTextColor,
  weight = defaultTextWeight,
  value,
  children,
  ...props
}) => (
  <StyledText
    size={size}
    color={color}
    weight={weight}
    {...props}
  >
    {children || value}
  </StyledText>
);

export default H2;
