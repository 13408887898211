import React, { ReactNode } from 'react';
import { Row as StyledRow } from './styles';

interface CustomRowProps {
  left: ReactNode;
  right: ReactNode;
}

const CustomRow: React.FC<CustomRowProps> = ({ right, left, ...props }) => (
  <StyledRow {...props}>
    <div>{left}</div>
    <div>{right}</div>
  </StyledRow>
);

export default CustomRow;
