export interface User {
  email?: string;
  name?: string;
  skipsSignup?: boolean;
  skipsCreditCard?: boolean;
  card?: {
    cardNumber: string;
    cardExpiryDate: string;
    cardCVV: string;
  };
  address?: {
    street: string;
    city: string;
    state: string;
    zip: string;
    countryCode: string;
  };
}

export type LoginPages =
  | 'LOGIN'
  | 'LANDING'
  | 'NAME'
  | 'CREDIT'
  | 'ADDRESS'
  | 'ADDRESS_COMPLETE'
  | 'PASSWORD';

type LoginRoute = {
  [key in LoginPages]: {
    next: LoginPages | undefined;
    previous: LoginPages | undefined;
  };
};

export const LOGIN_ROUTES: LoginRoute = {
  LANDING: {
    next: 'LOGIN',
    previous: undefined
  },
  LOGIN: {
    next: undefined,
    previous: 'LANDING'
  },
  NAME: {
    next: 'CREDIT',
    previous: 'LANDING'
  },
  CREDIT: {
    next: 'ADDRESS',
    previous: 'NAME'
  },
  ADDRESS: {
    next: 'ADDRESS_COMPLETE',
    previous: 'CREDIT'
  },
  ADDRESS_COMPLETE: {
    next: 'PASSWORD',
    previous: 'ADDRESS'
  },
  PASSWORD: {
    next: undefined,
    // previous: 'ADDRESS'
    previous: 'ADDRESS_COMPLETE'
  }
};

export const SIGNUP_ROUTES: LoginRoute = {
  LANDING: {
    next: 'LOGIN',
    previous: undefined
  },
  LOGIN: {
    next: undefined,
    previous: 'LANDING'
  },
  NAME: {
    next: 'PASSWORD',
    previous: 'LANDING'
  },
  CREDIT: {
    next: 'PASSWORD',
    previous: 'NAME'
  },
  ADDRESS: {
    next: 'PASSWORD',
    previous: 'NAME'
  },
  ADDRESS_COMPLETE: {
    next: 'PASSWORD',
    previous: 'NAME'
  },
  PASSWORD: {
    next: undefined,
    previous: 'NAME'
  }
};

export const LOGIN_ROUTES_SKIP_SIGNUP: LoginRoute = {
  LANDING: {
    next: 'CREDIT',
    previous: undefined
  },
  LOGIN: {
    next: undefined,
    previous: 'LANDING'
  },
  NAME: {
    next: 'CREDIT',
    previous: undefined
  },
  CREDIT: {
    next: 'ADDRESS',
    previous: 'NAME'
  },
  ADDRESS: {
    next: 'ADDRESS_COMPLETE',
    previous: 'CREDIT'
  },
  ADDRESS_COMPLETE: {
    next: undefined,
    previous: 'ADDRESS'
  },
  PASSWORD: {
    next: undefined,
    previous: 'ADDRESS'
  }
};
