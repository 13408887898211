import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import OtherAmountModal from './OtherAmountModal';
import { CheckoutWizardProps } from '../CheckoutWizardInterfaces';
import {
  AmountCapsuleGrid,
  AmountCapsuleGridProps
} from '../../Capsules/AmountCapsuleGrid';
import { TABLET_WIDTH } from '../../../utils';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import {
  amountSelected,
  giftAmountScreen,
  numberPadOkSelected,
  otherAmountSelected
} from '../../../../../hooks/PushEvents/clevertapEvents';
import { setQueryString } from '../../../../../hooks/useQueryString';
import { Text } from '../../common/Text/Text';
import FooterBanner from '../../../../Footer/FooterBanner';
import { useDonor } from '../../../../../providers/DonorProvider/useDonor';

const StyledDiv = styled.div`
  max-width: 546px;
  margin-left: auto;
  margin-right: auto;
  .text {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 66px;
  }
  @media only screen and (max-width: ${TABLET_WIDTH}) {
    .text {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
`;

type AmountWizardProps = CheckoutWizardProps;

const AmountWizard: React.FC<AmountWizardProps> = ({
  donee,
  setRoute,
  addAmount
}) => {
  const donor = useDonor();
  const { pushEvent } = usePushEvents();
  const [otherModal, setOtherModal] = useState<boolean>(false);

  useEffect(() => {
    pushEvent(giftAmountScreen, {
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort
    });
  }, [pushEvent]);

  const handleClick: AmountCapsuleGridProps['onClick'] = (
    amount: number | 'Other'
  ) => {
    if (amount === 'Other') {
      setOtherModal(true);
      pushEvent(otherAmountSelected, {
        loggedIn: Boolean(donor),
        doneeId: window.doneeIdShort
      });
      return;
    }
    pushEvent(amountSelected, {
      amount,
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort
    });
    setQueryString('amount', amount);
    setRoute('envelopes');
    addAmount(amount);
  };

  const handleOtherAmount = (amount: number) => {
    handleClick(amount);
    pushEvent(numberPadOkSelected, {
      amount,
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort
    });
  };

  return (
    <>
      <StyledDiv>
        <Text
          className={'text'}
          variant={'large'}
          weight={'extraBold'}
          color={'primarySecondaryContent'}
        >
          Choose a gift amount
        </Text>
        <AmountCapsuleGrid
          amounts={[...donee.offeringAmounts, 'Other']}
          onClick={handleClick}
        />
      </StyledDiv>
      <OtherAmountModal
        open={otherModal}
        onSubmit={handleOtherAmount}
        onClose={() => setOtherModal(false)}
      />
      <FooterBanner />
    </>
  );
};

export default AmountWizard;
