import React from 'react';
import styled from 'styled-components';
import CardInfo from '../CartWizard/Payment/CardInfo';
import { Donee } from '../../../../../providers/DoneeProvider';
import { colors } from '../../../../theme';
import { RequestState } from '../../../../../hooks/useRequestState';
import { GivingApiResponse } from '../../../../../utils/makeApiRequest';
import { ReceiptResponse } from '../CartWizard/Payment/api';
import { Text } from '../../common/Text/Text';
import { HeartLoading } from '../../../../HeartLoading';
import { assetUrl } from '../../../../../utils/webConfig';
import { icons } from '../../common/Icon/constants';
import { BREAKPOINTS } from '../../../../theme/breakpoints';
import { DonorContextProps } from '../../../../../providers/DonorProvider/interfaces';
import { TABLET_WIDTH } from '../../../utils';

interface ReceiptCardProps {
  donee: Donee;
  isReceiptBlur: boolean;
  donorContext: DonorContextProps;
  requestState: RequestState<GivingApiResponse<ReceiptResponse, number>>;
  className?: string;
}

const StyledDiv = styled.div<{ isBur?: boolean }>`
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 8px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px 1px 6px rgba(10, 0, 77, 0.04),
    0px 5.5px 30px rgba(10, 0, 77, 0.05);
  background-color: ${colors.utilityBackgroundLightColor};
  position: relative;
  .receipt-organization-name {
    text-align: center;
  }
  .receipt-date {
    margin-top: 8px;
    text-align: center;
    margin-bottom: 24px;
  }
  .envelopes {
    border-top: 1px solid ${colors.utilitySeparatorColor};
    .receipt-envelope {
      display: flex;
      padding: 12px;
      justify-content: space-between;
      border-bottom: 1px solid ${colors.utilitySeparatorColor};
      .envelope-name {
        color: ${colors.primarySecondaryContent};
      }
    }
  }
  .receipt-details {
    .receipt-number {
      display: flex;
      padding: 32px 12px 12px 12px;
      justify-content: space-between;
    }
    .receipt-card-number {
      display: flex;
      align-items: center;
      padding: 0 12px 12px 12px;
      justify-content: space-between;
      .receipt-card-info {
        margin-right: 0;
        .last-digits {
          font-size: 14px;
        }
      }
    }
  }
  .receipt-container-div {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 10px;
    background-color: ${colors.utilityBackgroundLightColor};
    height: 10px;
    z-index: 11;
  }
  .bottom-image-container {
    position: absolute;
    left: 0px;
    bottom: -18px;
    width: 100%;
    display: flex;
    z-index: 10;
    overflow: hidden;
    padding-bottom: 1px;
    .receipt-triangle-triangle {
      flex: 1;
      position: relative;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS.LAPTOP}px) {
    .bottom-image-container {
      bottom: -11px;
    }
  }
  @media screen and (max-width: 390px) {
    .bottom-image-container {
      bottom: -7px;
    }
  }
  @media screen and (max-width: ${TABLET_WIDTH}) {
    .receipt-details {
      ${({ isBur }) => (isBur ? `display: none;` : '')}
    }
  }
`;

const ReceiptCard: React.FC<ReceiptCardProps> = ({
  donee,
  isReceiptBlur,
  requestState,
  className
}) => {
  const parentRef = React.useRef<HTMLDivElement>(null);

  if (requestState.type !== 'REQUEST_SUCCESS') {
    return <HeartLoading maxWidth={200} />;
  }
  const receipt = requestState.data.response.payload;
  return (
    <StyledDiv
      isBur={isReceiptBlur}
      className={className}
    >
      <Text
        variant="medium"
        weight="extraBold"
        className="receipt-organization-name"
      >
        {donee.organization.name}
      </Text>
      <Text
        variant="xSmall"
        color="primarySecondaryContent"
        className="receipt-date"
      >
        {receipt.gift.date}
      </Text>
      <div className="envelopes">
        {receipt.envelopes.map((e) => (
          <Text
            key={e.id}
            variant="small"
            className="receipt-envelope"
          >
            <div className="envelope-name">{e.name}</div>
            <div>${e.amount}</div>
          </Text>
        ))}
      </div>
      <Text className="receipt-details">
        <Text
          variant="small"
          className="receipt-number"
        >
          <div>Receipt #</div>
          <div>{receipt.gift.receiptNumber}</div>
        </Text>
        <Text
          variant="small"
          className="receipt-card-number"
        >
          <div>Credit Card</div>
          <CardInfo
            card={{
              number: receipt.gift.maskedPan,
              type: receipt.gift.accountType
            }}
            className="receipt-card-info"
          />
        </Text>
      </Text>
      <div className="receipt-container-div" />
      <div
        ref={parentRef}
        className="bottom-image-container"
      >
        <img
          src={assetUrl(icons['receipt-triangle-full'])}
          className="receipt-triangle-triangle"
        />
      </div>
    </StyledDiv>
  );
};

export default ReceiptCard;
