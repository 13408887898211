import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import MetaTags from '../components/MetaTags';
import { webConfig } from '../utils/webConfig';
import {
  activateDonorProfile,
  resendActivateEmail
} from '../providers/DonorProvider/api';
import colors from '../components/theme/colors';
import { GivingApiResponse } from '../utils/makeApiRequest';
import { useApiRequest } from '../hooks/useGivingApiRequest';
import { useQueryString } from '../hooks/useQueryString';
import { GivelifyLogo } from '../components/icons';
import { GivelifyButton } from '../components/Donee/components/common/Button/GivelifyButton';
import ActivateState from '../components/ActivateState';

export const StyledActivateDiv = styled.div`
  max-width: 500px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 18px auto;
  .givelify-logo {
    display: block;
    margin: auto;
  }
  .heart-loading {
    margin: auto;
    width: 200px;
    height: 200px;
  }
  .failure-box,
  .success-box {
    padding: 16px;
    margin: 18px auto;
    border-radius: 10px;
  }
  .success-box {
    color: ${colors.utilitySuccess};
    border: 1px solid ${colors.utilitySuccess};
    background-color: ${colors.utilitySuccessLightColor};
  }
  .failure-box {
    color: ${colors.utilityError};
    border: 1px solid ${colors.utilityError};
    background-color: ${colors.utilityErrorLightColor};
  }
`;

const unableToActivate =
  'Unable to activate your account, please try generating another link below.';
const error = (
  <>
    We are unable to resend another link at this time. Please contact our{' '}
    <a
      href={webConfig.supportContactUrl}
      rel={'noreferrer'}
    >
      support team
    </a>{' '}
    if the issue persist.
  </>
);

const handleHomePage = () => {
  window.location.href = webConfig.mainSearchSite;
};

const activateSuccessState = (
  <>
    <div className={'success-box'}>
      You have successfully activated your account. You may close this tab.
    </div>
    <GivelifyButton onClick={handleHomePage}>Go to homepage</GivelifyButton>
  </>
);

const resendSuccessState = (
  <>
    <div className={'success-box'}>
      Activation sent! Please check your inbox for an email from Givelify and
      click the link there.
    </div>
    <GivelifyButton onClick={handleHomePage}>Go to homepage</GivelifyButton>
  </>
);

const Activate: React.FC = () => {
  const query = useQueryString();
  const expiryTime = query.get('expiry_time') || query.get('expires');
  const hash = query.get('hash');
  const id = query.get('id');
  const email = query.get('email');
  const signature = query.get('signature');
  const [errorText, setErrorText] = useState<JSX.Element | string | undefined>(
    undefined
  );

  const [activate, makeActivateRequest] =
    useApiRequest<GivingApiResponse<unknown>>();
  const [resend, makeResendRequest] =
    useApiRequest<GivingApiResponse<unknown>>();

  useEffect(() => {
    setErrorText(undefined);
    makeActivateRequest(
      activateDonorProfile({
        email,
        expiryTime,
        id,
        signature,
        hash
      })
    );
  }, [email, expiryTime, hash, id, signature]);

  const resendEmail = useCallback(() => {
    if (!email) {
      setErrorText(error);
      return;
    }
    makeResendRequest(
      resendActivateEmail({
        emailAddress: email
      })
    );
  }, [email, setErrorText]);

  const errorState = resend.type === 'REQUEST_ERROR' ? error : errorText;

  const activateFailedState = useMemo(
    () => (
      <>
        <div className={'failure-box'}>{errorState || unableToActivate}</div>
        {!errorState && (
          <GivelifyButton onClick={resendEmail}>
            Send another link
          </GivelifyButton>
        )}
      </>
    ),
    [errorState, resendEmail]
  );

  return (
    <StyledActivateDiv>
      <GivelifyLogo className={'givelify-logo'} />
      <MetaTags
        title={'Activate Account'}
        description={'Activate your account with Givelify'}
      />
      <ActivateState
        activateType={activate.type}
        resendType={resend.type}
        activateFailedState={activateFailedState}
        activateSuccessState={activateSuccessState}
        resendSuccessState={resendSuccessState}
      />
    </StyledActivateDiv>
  );
};

export default Activate;
