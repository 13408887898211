import React, { useCallback, useEffect } from 'react';
import { MainWrapper } from './MainWrapper';
import { webConfig } from '../../utils/webConfig';
import { removeQueryString } from '../../hooks/useQueryString';
import { useDonee } from '../../providers/DoneeProvider';
import { useDonor } from '../../providers/DonorProvider/useDonor';
import Modal from '../Donee/components/common/Modal';

export interface LoginModalProps {
  donation: string | undefined;
  open?: boolean;
  skipsCreditCard: boolean;
  setOpen: (open: boolean) => unknown;
}

export const LoginModal: React.FC<LoginModalProps> = ({
  open = false,
  donation,
  skipsCreditCard,
  setOpen
}) => {
  const { donee } = useDonee();
  const name = donee.data?.organization.name;
  const logo = donee.data?.organization.logo;
  const banner = donee.data?.organization.banner;

  const donor = useDonor();

  const handleClose = useCallback(() => {
    setOpen(false);
    removeQueryString('login');
  }, [setOpen]);

  useEffect(() => {
    // TODO: look into this logic
    if (donor) {
      handleClose();
    }
  }, [donor, handleClose]);

  return (
    <Modal
      active={open}
      onClose={handleClose}
      className={'login-modal'}
      shouldCloseOnOverlayClick={false}
    >
      <MainWrapper
        donation={donation}
        skipsCreditCard={skipsCreditCard}
        doneeName={name || 'Organization'}
        doneeUrl={logo || banner || webConfig.doneeAvatarDefault}
        onClose={handleClose}
      />
    </Modal>
  );
};
