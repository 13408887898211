import { Logger } from './logger';
import { LogLevel } from './logger/models';
import { getLocalStorageVersion } from './versionLocalStorage';

export const logInfo = (name: string, payload: object | string) => {
  Logger.log(name || 'Custom Info', {
    payload,
    doneeUrl: window.location.href,
    version: window.version || getLocalStorageVersion() || 'Not set'
  });
};

export const logError = (message: string, error?: object) => {
  Logger.log(
    message || 'Custom Error',
    {
      error,
      doneeUrl: window.location.href,
      version: window.version || getLocalStorageVersion() || 'Not set'
    },
    LogLevel.ERROR
  );
};

export const logging = (
  name: string,
  payload: object | string,
  priority: LogLevel
) => {
  Logger.log(
    name || 'Custom',
    {
      payload,
      doneeUrl: window.location.href,
      version: window.version || getLocalStorageVersion() || 'Not set'
    },
    priority
  );
};
