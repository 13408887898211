import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import FacebookLogin, {
  RenderProps,
  ReactFacebookResponse
} from 'react-facebook-login/dist/facebook-login-render-props';
import colors from '../theme/colors';
import { usePushEvents } from '../../hooks/PushEvents';
import {
  errorFacebookLogin,
  facebookLoginSuccess,
  facebookOnMainAuth
} from '../../hooks/PushEvents/clevertapEvents';
import { webConfig } from '../../utils/webConfig';
import Icon from '../Donee/components/common/Icon';
import { useDonorLogin } from '../../providers/DonorProvider/useDonorLogin';
import { getFacebookProfilePicture } from '../../providers/DonorProvider/getFacebookProfilePicture';
import { FacebookLoginResponse } from '../../providers/DonorProvider/api';
import { useLoginToastContext } from '../../providers/LoginToastProvider';
import { setLoginTypeLocalStorage } from '../../providers/DonorProvider/donorLocalStorage';

const StyledButton = styled.button`
  font-size: 16px;
  height: 52px;
  width: 100%;
  border-radius: 100px;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${colors.white};
  background-color: ${colors.externalFacebookColor};
  .facebook-icon {
    margin-right: 10px;
  }
  .hide {
    visibility: hidden;
  }
`;

export interface FacebookButtonProps {
  className?: string;
  onSuccess?: (facebookUser: FacebookLoginResponse) => unknown;
}

export const FacebookButton: React.FC<FacebookButtonProps> = ({
  className,
  onSuccess
}) => {
  const { pushEvent } = usePushEvents();
  const { handleOpenLoginToast } = useLoginToastContext();
  const { state, facebookLogin, setProfilePicture } = useDonorLogin();
  const [facebookUser, setFacebookUser] = useState<
    FacebookLoginResponse | undefined
  >(undefined);

  const loadProfilePicture = useCallback(
    async (facebookUser: FacebookLoginResponse) => {
      setProfilePicture(await getFacebookProfilePicture(facebookUser));
    },
    [setProfilePicture]
  );

  useEffect(() => {
    if (state.type !== 'REQUEST_SUCCESS' || !facebookUser) return;
    pushEvent(facebookLoginSuccess);
    onSuccess?.(facebookUser);
    loadProfilePicture(facebookUser);
    handleOpenLoginToast();
    setLoginTypeLocalStorage(facebookUser.name, facebookUser.email, 'facebook');
  }, [state.type, facebookUser, handleOpenLoginToast]);

  const handleResponse = (userInfo: ReactFacebookResponse) => {
    if (userInfo.status === 'unknown') {
      pushEvent(errorFacebookLogin, { reason: JSON.stringify(userInfo) });
    } else {
      facebookLogin({
        facebookId: userInfo.userID,
        facebookToken: userInfo.accessToken,
        emailAddress: userInfo.email
      });
      setFacebookUser(userInfo);
    }
  };

  return (
    <FacebookLogin
      appId={webConfig.facebookAppId}
      fields={'id,first_name,last_name,name,email,gender,locale,picture'}
      isMobile={false}
      callback={handleResponse}
      render={(props: RenderProps) => (
        <StyledButton
          className={className}
          onClick={() => {
            props.onClick();
            pushEvent(facebookOnMainAuth);
          }}
        >
          <Icon
            fluid={false}
            name={'facebook-white-circle'}
            size={'medium'}
            width={'link'}
            raised={'none'}
            color={'transparent'}
            iconProps={undefined}
            className={'facebook-icon'}
          />
          <div>Continue with Facebook</div>
          <Icon
            fluid={false}
            name={'facebook-white-circle'}
            size={'medium'}
            width={'link'}
            raised={'none'}
            color={'transparent'}
            iconProps={undefined}
            className={'facebook-icon hide'}
          />
        </StyledButton>
      )}
    />
  );
};
