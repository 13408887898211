import React from 'react';
import { ErrorPage } from './styles';
import { serverErrorSvg } from './svgComponent';

const ErrorBoundary = () => (
  <ErrorPage>
    <div>{serverErrorSvg}</div>
    <h1>Oops, something went wrong.</h1>
    <h2 className={'label'}>
      Try refreshing the page or click the button below.
    </h2>
    <a href={'https://www.givelify.com/givers/'}>
      Find my organization
    </a>
  </ErrorPage>
);

export default ErrorBoundary;
