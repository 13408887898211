import React from 'react';
import ProgressiveImage from 'react-progressive-image';
import { Row, Col, Strong } from '@bootstrap-styled/v4';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import {
  WalletContainer,
  WalletVisa,
  WalletMaster,
  WalletDiscover,
  WalletHandlerContainer,
  WalletMainImage,
  WalletMainPart,
  CardIcon,
  CardNumber,
  CardIcon2,
  CardNumber2,
  OffsetDismissButton,
  PaymentMethodLabelContainer,
  PaymentMethodLabel,
  PreferredCardTitle,
  MakePreferredLink,
  DeleteLink,
  CancelLink
} from './styles';
import {
  ModalSection,
  ModalBody,
  ModalFooter
} from '../../DonorRecurring/Cancel/styles';
import { PrimaryGuidingVioletButton } from '../../../components/Donee/components/common/Button/SolidButton/styles';
import { Text } from '../../../components/Donee/components/common/Text/Text';
import { assetUrl } from '../../../utils/webConfig';
import Modal from '../../../components/Donee/components/common/Modal';
import { SolidButtonV2 } from '../../../components/Donee/components/common/Button/SolidButton';
import WrapperOffset from '../../../components/Donee/components/common/WrapperOffset';
import RadioOption from '../../../components/Donee/components/common/Radio/RadioOption';
import { LinkButton } from '../../../components/Donee/components/common/Button';
import CustomRow from '../../../components/Row';
import { Wallet } from '../../../providers/DonorProvider/interfaces';

const useStyles = makeStyles({
  PreferredCardTitle: PreferredCardTitle,
  MakePreferredLink: MakePreferredLink,
  DeleteLink: DeleteLink,
  CancelLink,
  StandardConfirmButton: PrimaryGuidingVioletButton
});

interface WalletSectionViewProps {
  account_id: number;
  account_type: Wallet['type'];
  preferred_account: boolean;
  wallets: Wallet[];
  openNewCardPopup: () => void;
  account_number: string;
  preferredCardDelete: boolean;
  recurringCardDelete: boolean;
  recurringCard: boolean;
  show: boolean;
  type?: 'delete' | 'default' | 'preferred' | undefined;
  accountId: number | undefined;
  loader: boolean;
  setRecurringCardDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setPreferredCardDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOldCardId: React.Dispatch<React.SetStateAction<number | undefined>>;
  openModal: (newType: any) => void;
  closeModal: () => void;
  makeDefault: (newAccountId: number | undefined) => void;
  deleteWallet: (newAccountId: number | undefined) => void;
  selectCardTemp: (account: any) => void;
  cardSelectDone: () => void;
}

const WalletSectionView: React.FC<WalletSectionViewProps> = (props) => {
  const classes = useStyles();
  const {
    account_id,
    account_type,
    preferred_account,
    wallets,
    openNewCardPopup,
    account_number,
    preferredCardDelete,
    recurringCardDelete,
    recurringCard,
    show,
    type,
    accountId,
    loader,
    setPreferredCardDelete,
    setRecurringCardDelete,
    setOldCardId,
    openModal,
    closeModal,
    makeDefault,
    deleteWallet,
    selectCardTemp,
    cardSelectDone
  } = props;

  const lastFour = account_number.substr(account_number.length - 4);

  return (
    <WalletContainer>
      <WalletHandlerContainer>
        <br />
        <CustomRow
          left={
            <div>
              {!preferred_account && (
                <Link
                  className={classes.MakePreferredLink}
                  onClick={() => {
                    openModal('default');
                  }}
                >
                  Make Preferred
                </Link>
              )}
              {preferred_account && (
                <Text className={(classes as any).MakePreferredTitle}>
                  Preferred Card
                </Text>
              )}
            </div>
          }
          right={
            <Link
              className={classes.DeleteLink}
              onClick={() => {
                if (recurringCard) {
                  setRecurringCardDelete(true);
                  setOldCardId(account_id);
                } else if (preferred_account && wallets.length > 1) {
                  setPreferredCardDelete(true);
                  setOldCardId(account_id);
                } else {
                  openModal('available');
                }
              }}
            >
              Delete
            </Link>
          }
        />
      </WalletHandlerContainer>

      <WalletMainPart>
        <WalletMainImage
          className={!preferred_account ? 'simple-card' : 'preferred-card'}
        >
          {/* TODO */}
          {/* @ts-ignore */}
          <ProgressiveImage
            src={assetUrl(
              `/images/svg/${account_type
                .toLowerCase()
                .replace(' ', '_')}-card.svg?v=2`
            )}
            placeholder={assetUrl('/images/svg/default-card.svg')}
          >
            {(src: any, loading: any) =>
              loading ? (
                <img
                  src={src}
                  alt={''}
                />
              ) : (
                <img
                  src={assetUrl(src)}
                  alt={''}
                />
              )
            }
          </ProgressiveImage>
        </WalletMainImage>
        {account_type.toLowerCase() === 'Visa'.toLowerCase() ? (
          <WalletVisa>
            <CardIcon>
              <img
                alt={'Visa'}
                src={assetUrl('/images/svg/visa_logo@1x.svg')}
              />
            </CardIcon>

            <CardNumber>
              <Text
                color={'white'}
                variant={'small'}
              >
                {account_number}
              </Text>
            </CardNumber>
          </WalletVisa>
        ) : null}

        {account_type.toLowerCase() === 'MasterCard'.toLowerCase() ||
        account_type.toLowerCase() === 'Mastercard'.toLowerCase() ? (
          <WalletMaster>
            <CardIcon>
              <img
                alt={'MasterCard'}
                src={assetUrl('/images/svg/mastercard_logo.svg')}
              />
            </CardIcon>
            <CardNumber>
              <Text
                color={'white'}
                variant={'small'}
              >
                {account_number}
              </Text>
            </CardNumber>
          </WalletMaster>
        ) : null}
        {account_type.toLowerCase() === 'Discover'.toLowerCase() ? (
          <WalletDiscover>
            <CardIcon>
              <img
                alt={'Discover'}
                src={assetUrl('/images/svg/discover_logo.svg')}
              />
            </CardIcon>
            <CardNumber>
              <Text
                color={'white'}
                variant={'small'}
              >
                {account_number}
              </Text>
            </CardNumber>
          </WalletDiscover>
        ) : null}

        {account_type.toLowerCase() === 'American Express'.toLowerCase() ? (
          <WalletDiscover>
            <CardIcon>
              <img
                alt={'Discover'}
                src={assetUrl('/images/svg/american_expresscard_logo.svg')}
              />
            </CardIcon>
            <CardNumber>
              <Text
                color={'white'}
                variant={'small'}
              >
                {account_number}
              </Text>
            </CardNumber>
          </WalletDiscover>
        ) : null}

        {account_type.toLowerCase() === 'Diners Club'.toLowerCase() ? (
          <WalletDiscover>
            <CardIcon>
              <img
                alt={'Diners Club'}
                src={assetUrl('/images/svg/diners_club_logo.svg')}
              />
            </CardIcon>
            <CardNumber>
              <Text
                color={'white'}
                variant={'small'}
              >
                {account_number}
              </Text>
            </CardNumber>
          </WalletDiscover>
        ) : null}
      </WalletMainPart>

      <Modal
        active={show}
        onClose={closeModal}
      >
        <ModalSection>
          <ModalBody>
            <Text
              variant={'medium'}
              color={'grayscaleBlack'}
            >
              {type === 'default'
                ? 'Are you sure you want to make this card your preferred payment method?'
                : 'Are you sure you want to remove this payment method from your wallet?'}
            </Text>
            {type !== 'default' && (
              <div className={'mt-15'}>
                <Text
                  variant={'medium'}
                  color={'grayscaleBlack'}
                >
                  Any recurring gifts associated with this card will be
                  cancelled.
                </Text>
              </div>
            )}
            <br />
            <br />
            <WalletMainPart>
              <WalletMainImage>
                <img
                  src={assetUrl(
                    `/images/svg/${account_type
                      .toLowerCase()
                      .replace(' ', '_')}-card.svg?v=2`
                  )}
                  alt={''}
                />
              </WalletMainImage>
              {account_type.toLowerCase() === 'Visa'.toLowerCase() ? (
                <WalletVisa>
                  <CardIcon>
                    <img
                      alt={'Visa'}
                      src={assetUrl('/images/svg/visa_logo@1x.svg')}
                    />
                  </CardIcon>
                  <CardNumber>
                    <Text
                      color={'white'}
                      variant={'small'}
                    >
                      {account_number}
                    </Text>
                  </CardNumber>
                </WalletVisa>
              ) : (
                ''
              )}

              {account_type.toLowerCase() === 'MasterCard'.toLowerCase() ||
              account_type.toLowerCase() === 'Mastercard'.toLowerCase() ? (
                <WalletMaster>
                  <CardIcon>
                    <img
                      alt={'MasterCard'}
                      src={assetUrl('/images/svg/mastercard_logo.svg')}
                    />
                  </CardIcon>
                  <CardNumber>
                    <Text
                      color={'white'}
                      variant={'small'}
                    >
                      {account_number}
                    </Text>
                  </CardNumber>
                </WalletMaster>
              ) : (
                ''
              )}

              {account_type.toLowerCase() === 'Discover'.toLowerCase() ? (
                <WalletDiscover>
                  <CardIcon>
                    <img
                      alt={'Discover'}
                      src={assetUrl('/images/svg/discover_logo.svg')}
                    />
                  </CardIcon>
                  <CardNumber>
                    <Text
                      color={'white'}
                      variant={'small'}
                    >
                      {account_number}
                    </Text>
                  </CardNumber>
                </WalletDiscover>
              ) : (
                ''
              )}

              {account_type.toLowerCase() ===
              'American Express'.toLowerCase() ? (
                <WalletDiscover>
                  <CardIcon>
                    <img
                      alt={'American Express'}
                      src={assetUrl(
                        '/images/svg/american_expresscard_logo.svg'
                      )}
                    />
                  </CardIcon>
                  <CardNumber>
                    <Text
                      color={'white'}
                      variant={'small'}
                    >
                      {account_number}
                    </Text>
                  </CardNumber>
                </WalletDiscover>
              ) : (
                ''
              )}

              {account_type.toLowerCase() === 'Diners Club'.toLowerCase() ? (
                <WalletDiscover>
                  <CardIcon>
                    <img
                      alt={'Diners Club'}
                      src={assetUrl('/images/svg/diners_club_logo.svg')}
                    />
                  </CardIcon>
                  <CardNumber>
                    <Text
                      color={'white'}
                      variant={'small'}
                    >
                      {account_number}
                    </Text>
                  </CardNumber>
                </WalletDiscover>
              ) : (
                ''
              )}
            </WalletMainPart>
          </ModalBody>
          <ModalFooter>
            <SolidButtonV2
              className={classes.StandardConfirmButton}
              onClick={() => {
                if (type === 'default') {
                  makeDefault(account_id);
                } else {
                  deleteWallet(account_id);
                }
              }}
            >
              Yes
            </SolidButtonV2>
            <Link
              className={classes.CancelLink}
              onClick={closeModal}
            >
              Cancel
            </Link>
          </ModalFooter>
        </ModalSection>
      </Modal>

      <Modal
        active={preferredCardDelete}
        onClose={() => setPreferredCardDelete(false)}
      >
        <div>
          <center>
            <Text
              className={'popup-title mb-30'}
              color={'grayscaleBlack'}
            >
              Select a Preferred Card
            </Text>
          </center>
          <WrapperOffset
            component={
              <Link
                className={classes.CancelLink}
                onClick={() => setPreferredCardDelete(false)}
              >
                Cancel
              </Link>
            }
            wrapper={
              <div>
                <Text
                  variant={'medium'}
                  color={'grayscaleBlack'}
                >
                  We will delete your card ending in{' '}
                  <Strong>{lastFour}.</Strong> Any recurring gifts associated
                  with this card will be cancelled.
                </Text>
                <div className={'mt-15'}>
                  <Text
                    variant={'medium'}
                    color={'grayscaleBlack'}
                  >
                    At least one card must be set as preferred. Please select
                    from your other cards below.
                  </Text>
                </div>
                <br />
                <Row>
                  {wallets &&
                    wallets.map((method: any) => {
                      const {
                        id: newAccountId,
                        number: newAccountNumber,
                        type: newAccountType,
                        is_preferred: newPreferredAccount
                      } = method;
                      const currentLastFourDigits = newAccountNumber.slice(
                        newAccountNumber.length - 4
                      );
                      if (currentLastFourDigits === lastFour) return;

                      let creditCardImageUrl = '';
                      if (newAccountType.toLowerCase() === 'visa') {
                        creditCardImageUrl = `/images/svg/${newAccountType.toLowerCase()}_logo@1x.svg?v=4`;
                      } else {
                        creditCardImageUrl = `/images/svg/${newAccountType
                          .toLowerCase()
                          .replace(' ', '_')}_logo.svg`;
                      }

                      return newPreferredAccount ? (
                        ''
                      ) : (
                        <Col
                          xs={12}
                          key={newAccountId}
                        >
                          <RadioOption
                            key={newAccountId}
                            value={`${newAccountId}`}
                            label={
                              <PaymentMethodLabelContainer>
                                <CardIcon2 className={'card-icon'}>
                                  <img
                                    src={creditCardImageUrl}
                                    alt={''}
                                  />
                                </CardIcon2>
                                <CardNumber2>
                                  <PaymentMethodLabel
                                    color={'grayscaleBlack'}
                                    variant={'medium'}
                                    weight={'regular'}
                                  >
                                    **** {currentLastFourDigits}
                                  </PaymentMethodLabel>
                                </CardNumber2>
                              </PaymentMethodLabelContainer>
                            }
                            selected={
                              accountId === newAccountId
                                ? `${newAccountId}`
                                : ''
                            }
                            onSelect={() => selectCardTemp(method)}
                            type={'preferredcard'}
                          />
                        </Col>
                      );
                    })}
                </Row>
                <br />
                <br />
                <SolidButtonV2
                  fluid
                  disabled={loader}
                  className={classes.StandardConfirmButton}
                  onClick={() =>
                    accountId === undefined ? undefined : cardSelectDone()
                  }
                >
                  {loader ? 'Processing...' : 'Select'}
                </SolidButtonV2>
              </div>
            }
          />
        </div>
      </Modal>

      <Modal
        active={recurringCardDelete}
        onClose={() => setRecurringCardDelete(false)}
      >
        <div>
          <center>
            <Text
              className={'popup-title mb-30'}
              color={'grayscaleBlack'}
            >
              Card Associated with Recurring Gifts
            </Text>
          </center>
          <WrapperOffset
            component={
              <OffsetDismissButton
                fluid={undefined}
                name={'close-circle'}
                size={'medium'}
                color={'transparent'}
                width={'link'}
                weight={'regular'}
                onClick={() => setRecurringCardDelete(false)}
                value={'Cancel'}
                children={undefined}
              />
            }
            wrapper={
              <div>
                <div className={'mt-15'}>
                  <Text
                    variant={'medium'}
                    color={'grey3'}
                  >
                    The card you have chosen to delete is associated with the
                    following recurring gifts:
                  </Text>
                </div>
                <div className={'mt-15'}>
                  <Text
                    variant={'medium'}
                    color={'grayscaleBlack'}
                  >
                    Anointed City of the Lord - Weekly ($34.00)
                  </Text>
                </div>
                <div className={'mt-15'}>
                  <Text
                    variant={'medium'}
                    color={'grey3'}
                  >
                    Please select or add a new card to continue the recurring
                    gifts otherwise they will be cancelled.
                  </Text>
                </div>
                <br />
                <Row>
                  {wallets &&
                    wallets.map((method: any) => {
                      const {
                        id: newAccountId,
                        number: newAccountNumber,
                        type: newAccountType,
                        is_preferred: newPreferredAccount
                      } = method;
                      return newPreferredAccount ? (
                        ''
                      ) : (
                        <Col
                          xs={12}
                          key={newAccountId}
                        >
                          <RadioOption
                            key={newAccountId}
                            value={`${newAccountId}`}
                            label={
                              <PaymentMethodLabelContainer>
                                <CardIcon2 className={'card-icon'}>
                                  <img
                                    src={assetUrl(
                                      newAccountType.toLowerCase() === 'visa'
                                        ? `/images/svg/${newAccountType.toLowerCase()}_logo@1x.svg?v=4`
                                        : `/images/svg/${newAccountType
                                            .toLowerCase()
                                            .replace(' ', '_')}_logo.svg`
                                    )}
                                    alt={''}
                                  />
                                </CardIcon2>
                                <CardNumber2>
                                  <PaymentMethodLabel
                                    color={'grayscaleBlack'}
                                    variant={'medium'}
                                    weight={'regular'}
                                  >
                                    ****{' '}
                                    {newAccountNumber.slice(
                                      newAccountNumber.length - 4
                                    )}
                                  </PaymentMethodLabel>
                                </CardNumber2>
                              </PaymentMethodLabelContainer>
                            }
                            selected={
                              accountId === newAccountId ? `${accountId}` : ''
                            }
                            onSelect={(e: any) =>
                              selectCardTemp(method.account)
                            }
                            type={'preferredcard'}
                          />
                        </Col>
                      );
                    })}
                </Row>
                <div className={''}>
                  <LinkButton
                    onClick={() => {
                      openNewCardPopup();
                    }}
                  >
                    <Text
                      variant={'medium'}
                      color={'primaryGuidingViolet'}
                    >
                      + Add New Card
                    </Text>
                  </LinkButton>
                </div>
                <br />
                <br />
                <Link
                  // fluid
                  // size={'small'}
                  // width={'minwidth'}
                  // raised={accountId === '' ? 'none' : 'primaryGuidingViolet'}
                  color={
                    accountId === undefined
                      ? 'disable'
                      : ('primaryGuidingViolet' as any)
                  }
                  onClick={() =>
                    accountId === undefined ? '' : cardSelectDone()
                  }
                >
                  {loader ? 'Processing...' : 'Select'}
                </Link>
                <Link
                  // fluid
                  className={'mt-15'}
                  // size={'small'}
                  // width={'minwidth'}
                  // raised={accountId === '' ? 'none' : 'primaryGuidingViolet'}
                  color={
                    accountId === undefined
                      ? 'disable'
                      : ('primaryGuidingViolet' as any)
                  }
                >
                  {loader ? 'Processing...' : 'Continue Without Card'}
                </Link>
              </div>
            }
          />
        </div>
      </Modal>
    </WalletContainer>
  );
};

export default WalletSectionView;
