import styled from 'styled-components';
import layout from '../../components/theme/layout';
import ContentLayout from '../../components/Donee/components/common/Layout/Content';
import { assetUrl } from '../../utils/webConfig';
import { LinkButton } from '../../components/Donee/components/common/Button';

const { breakpoints } = layout;
const { tablet, mobile } = breakpoints;

export const AddPaymentMethodContainer = styled(ContentLayout)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
  @media screen and (min-width: ${tablet.min}px) and (max-width: ${tablet.max}px) {
    width: 100%;
  }
  text-align: left;
`;

export const FormAddCardHeaderContainer = styled.div`
  text-align: left;
  padding: 9px;
`;

export const FormAddCard = styled.div``;

export const FormItem = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;

export const SelectInput = styled.select`
  border: 1px solid #b1bac4;
  background: url(${assetUrl('/images/svg/select_arrow.svg')}) no-repeat scroll
    right 15px;
  background-color: #fff !important;
  color: #3a4c63;
  padding: 11px 15px;
  width: 100%;
  height: 45px;
  font-size: 16px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

export const StateZipSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveFormContainer = styled.div`
  margin-top: 30px;
`;

export const CancelButton = styled(LinkButton)`
  margin-top: 15px;
`;

export const PaymentMessageError = styled.div`
  text-align: left;
  color: #a9445a;
`;

export const BtnVisible = styled.div`
  height: 56px;
  border-radius: 28px;
  color: #fff;
  line-height: 56px;
  background: linear-gradient(126.76deg, #00cdff 0%, #0e91fd 100%);
  box-shadow: 0 10px 20px 0 rgba(47, 176, 255, 0.3);
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.5;
`;

export const CardType = styled.div`
  position: relative;
`;

export const CardThumb = styled.div`
  position: absolute;
  top: 9px;
  right: 7px;
`;

export const LockIcon = styled.div`
  display: inline-block;
  margin-right: 3px;
`;

export const HelpLink = styled.a`
  margin-left: 5px;
`;

export const HelpImg = styled.img``;

export const LabelCover = styled.div`
  display: inline-block;
`;

export const CVVCardImg = styled.img``;

export const BackArrow = styled.div`
  position: absolute;
  top: 4px;
  left: 0px;
  cursor: pointer;
`;

export const IconBox = styled.div`
  display: inline-block;
  margin-right: 6px
  float: left;
  margin-top: 5px;
`;

export const Popupstap = styled.div`
  line-height: 10px;
`;
