import React, { useEffect } from 'react';
import AmountDisplay from '../AmountDisplay';
import DonationsCard from './DonationsCard';
import DonationDetails from './DonationDetails';
import { CheckoutWizardProps } from '../CheckoutWizardInterfaces';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import {
  addDonationOnGiftSummary,
  giftSummaryScreen
} from '../../../../../hooks/PushEvents/clevertapEvents';
import OpacityAnimation from '../../../../Animations/OpacityAnimation';
import { useDonorContext } from '../../../../../providers/DonorProvider/DonorProvider';
import { removeQueryString } from '../../../../../hooks/useQueryString';
import { useDonor } from '../../../../../providers/DonorProvider/useDonor';
import { getTotalCartAmount } from './Payment/api';

type CartWizardProps = CheckoutWizardProps;

const CartWizard: React.FC<CartWizardProps> = ({
  cart,
  setMessage,
  setRecurring,
  removeFromCart,
  setRoute
}) => {
  const { donor, wallets } = useDonor() || {};
  const { pushEvent } = usePushEvents();
  const { loadDonor } = useDonorContext();
  const { amount, envelopes } = getTotalCartAmount(cart.envelopes);

  const handleAddDonation = () => {
    amount &&
      pushEvent(addDonationOnGiftSummary, {
        loggedIn: Boolean(donor),
        doneeId: window.doneeIdShort,
        amount,
        paymentPresent: wallets?.length ? 'Yes' : 'No',
        envelopeCount: envelopes.length,
        envelope:
          envelopes.length === 0
            ? 'No Envelopes'
            : envelopes.length === 1
            ? envelopes[0].name
            : 'Multiple'
      });
    setRoute('amount');
  };

  useEffect(() => {
    removeQueryString('amount');
    // make sure user info is up to date
    loadDonor();
    pushEvent(giftSummaryScreen, {
      loggedIn: Boolean(donor),
      doneeId: window.doneeIdShort,
      amount,
      envelopeCount: envelopes.length,
      paymentPresent: wallets && wallets.length > 0 ? 'Yes' : 'No',
      envelope: envelopes.length === 1 ? envelopes[0].name : 'Multiple'
    });
  }, []);

  if (amount === 0) {
    handleAddDonation();
    return null;
  }

  return (
    <OpacityAnimation>
      <AmountDisplay
        amount={amount}
        amountText={'Gift Summary'}
      />
      <DonationsCard
        donations={envelopes}
        handleAddDonation={handleAddDonation}
        removeEnvelope={removeFromCart}
      />
      <DonationDetails
        setRoute={setRoute}
        message={cart.message || ''}
        setMessage={setMessage}
        recurring={cart.recurring}
        setRecurring={setRecurring}
      />
      {/* <DonationAttributes
        setRoute={setRoute}
        message={cart.message || ''}
        setMessage={setMessage}
        recurring={cart.recurring}
        setRecurring={setRecurring}
      /> */}
    </OpacityAnimation>
  );
};

export default CartWizard;
