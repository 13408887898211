import React from 'react';
import { LinkButtonStyle } from './styles'


// needs to exist so props can be passed in correctly,
// and still be passed around as a full component
export default function LinkButton(props) {
  return (
    <LinkButtonStyle
      {...props}
    />
  )
}
