import { v4 as uuidv4 } from 'uuid';
import { RecurringTypes } from '../../../../utils';
import { Cart, SelectedEnvelope } from '../../../Capsules/Envelopes';
import { axiosClient } from '../../../../../../utils/axiosClient';
import { makeApiRequest } from '../../../../../../utils/makeApiRequest';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { User } from '../../../../../LoginModal/routes';
import { mapUserToWallet } from '../../../../../LoginModal/pages/Landing/api';

interface DonationPayload {
  memo: string | undefined;
  doneeId: string;
  forceDuplicate: 0 | 1;
  giftUuid: string; // uuid
  paymentInstrumentId: string;
  recurrence: RecurringTypes | null;
  captchaToken: string | undefined;
  verification: string | undefined;
  contributions: { amount: number; causeId: number }[];
}

export interface DonationResponse {
  amount: number;
  receiptNumber: number;
  transactionId: string; // string number
}

export const getTotalCartAmount = (
  envelopes: Cart['envelopes']
): { amount: number; envelopes: SelectedEnvelope[] } => {
  let amount = 0;
  const result = [];
  for (const key in envelopes) {
    if (key === 'new') {
      continue;
    }
    const e = envelopes[key];
    if (!e) {
      continue;
    }
    result.push(e);
    amount += e.amount;
  }
  return { amount, envelopes: result };
};

const mapContributions = (
  envelopes: Cart['envelopes']
): DonationPayload['contributions'] => {
  const result: DonationPayload['contributions'] = [];
  for (const key in envelopes) {
    if (key === 'new') {
      continue;
    }
    const e = envelopes[key];
    if (!e) {
      continue;
    }
    result.push({
      amount: e.amount,
      causeId: e.envelopeId
    });
  }
  return result;
};

const mapToPayload = (
  cart: Cart,
  doneeId: string,
  forceDuplicate: boolean,
  captchaToken: string | undefined
): DonationPayload => {
  return {
    doneeId,
    forceDuplicate: forceDuplicate ? 1 : 0,
    giftUuid: uuidv4(),
    memo: cart.message,
    paymentInstrumentId: cart.wallet?.paymentInstrumentUuid || '',
    recurrence: cart.recurring === 'None' ? null : cart.recurring,
    contributions: mapContributions(cart.envelopes),
    captchaToken,
    verification: captchaToken
  };
};

const donateUrl = '/gift/donate';
export const sendDonationRequest = (
  cart: Cart,
  doneeId: string,
  forceDuplicate: boolean,
  captchaToken: string | undefined
) => {
  const httpRequest = axiosClient.post(
    donateUrl,
    mapToPayload(cart, doneeId, forceDuplicate, captchaToken)
  );
  return makeApiRequest<DonationResponse>(httpRequest);
};

export interface ReceiptResponse {
  donee: {
    id: string;
    name: string;
    address: string;
  };
  envelopes: {
    id: number;
    name: string;
    amount: number;
  }[];
  gift: {
    accountType: Wallet['type'];
    amount: number;
    approvalCode: string;
    date: string; // "Mar 8 2023 @ 11:02 PM EST"
    isoDate: string; // "2023-03-09T04:02:36.000000Z"
    maskedPan: string; // "XXXXXXXXXXXX1111"
    receiptNumber: number;
    recurringId: number;
    txnId: number;
  };
  memos: {
    memo: string;
    reply: string | null;
  }[];
}

const getReceiptUrl = (receiptId: number | string) =>
  `/donor/profile/gifts/${receiptId}`;
export const getReceiptRequest = (receiptId: number | string) => {
  const httpRequest = axiosClient.get(getReceiptUrl(receiptId));
  return makeApiRequest<ReceiptResponse>(httpRequest);
};

export interface AddCardResponse {
  walletId: number;
  paymentInstrumentId: string;
}

const addCardUrl = '/gift/wallet/add';
export const addCardRequest = (user: User, recaptcha: string | undefined) => {
  const httpRequest = axiosClient.post(
    addCardUrl,
    mapUserToWallet(user, recaptcha)
  );
  return makeApiRequest<AddCardResponse>(httpRequest);
};
