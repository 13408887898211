import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MUILink from '@material-ui/core/Link';
import {
  Row,
  Col,
  Container,
  Alert,
  Ul,
  Li,
  Tooltip
} from '@bootstrap-styled/v4';
import {
  RecurringContainer,
  DonationsDataLabel,
  IconBox,
  ModalSection,
  ModalBody,
  ModalFooter,
  TooltipAction,
  TooltipOverlay,
  DonationsDatavalue,
  PaymentInfoPreview,
  PaymentInfoCardPreview
} from './styles';
import { PrimaryGuidingVioletButton } from '../../components/Donee/components/common/Button/SolidButton/styles';
import colors from '../../components/theme/colors';
import { Text } from '../../components/Donee/components/common/Text/Text';
import { MainSection } from '../../components/Donee/components/common/Layout';
import H2 from '../../components/Donee/components/common/H2';
import Icon from '../../components/Donee/components/common/Icon';
import { noRecurringFoundMsg } from '../../utils/messages';
import {
  LinkButton,
  SolidButtonV2
} from '../../components/Donee/components/common/Button';
import { amountNumberFormat } from '../../utils/functions';
import { RecurringDonationsResponse } from './api';
import { DonorWithWallet } from '../../providers/DonorProvider/interfaces';
import GivelifyModal from '../../components/Donee/components/common/Modal/GivelifyModal';

const useStyles = makeStyles({
  ConfirmButton: PrimaryGuidingVioletButton,
  CancelLink: {
    color: colors.primaryGuidingViolet,
    paddingTop: '20px'
  }
});

interface DonorRecurringViewProps {
  recurrings: RecurringDonationsResponse[];
  donorInfo: DonorWithWallet | undefined;
  errorMsg: string | false;
  recurringMsg: string | undefined;
  serverError: string;
  error: boolean;
  loader: boolean;
  recurringTooltip: boolean | undefined;
  recurringId: number | undefined;
  envelopeId: number | undefined;
  recurringPopup: boolean;
  envelopeName: string;
  closeMessage: () => () => void;
  closeMessage2: () => () => void;
  setServerError: React.Dispatch<React.SetStateAction<string>>;
  tooltip: (
    newRecurringId: number,
    newEnvelopeId: number,
    newEnvelopeName: string
  ) => void;
  recurringPopupOpen: () => void;
  setRecurringToolTip: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  setRecurringId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setEnvelopeId: React.Dispatch<React.SetStateAction<number | undefined>>;
  getRecurringName: (frequency: number) => string | undefined;
  getCardIcon: (walletId: number) => string | undefined;
  getCardNumber: (walletId: number) => string | undefined;
  formatDate: (date: string) => string;
  closeModal: () => void;
  cancelRecurring: () => void;
}

const DonorRecurringView: React.FC<DonorRecurringViewProps> = ({
  recurrings: recurringList,
  errorMsg,
  recurringMsg,
  serverError,
  error,
  loader,
  recurringTooltip,
  recurringId,
  envelopeId,
  recurringPopup,
  envelopeName,
  closeMessage,
  closeMessage2,
  setServerError,
  tooltip,
  recurringPopupOpen,
  setRecurringToolTip,
  setRecurringId,
  setEnvelopeId,
  getRecurringName,
  getCardIcon,
  getCardNumber,
  formatDate,
  closeModal,
  cancelRecurring
}) => {
  const classes = useStyles();
  const [doneeNameTooltipOpen, setDonneNameTooltipOpen] = React.useState(false);

  const { doneeId } = useParams();

  return (
    <div className={'mainpart'}>
      <Container>
        <center>
          {errorMsg && recurringMsg && (
            <Alert
              className={'recurring-alert'}
              color={'success'}
              isOpen
              onClick={closeMessage()}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {recurringMsg}
              </Text>
            </Alert>
          )}
          {errorMsg && (
            <Alert
              className={'recurring-alert'}
              color={'info'}
              isOpen
              onClick={closeMessage2()}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                You don’t have any recurring gifts for this organization.{' '}
                <Link to="/">
                  <Text
                    color={'primaryGuidingViolet'}
                    variant={'xSmall'}
                  >
                    Click here
                  </Text>
                </Link>{' '}
                to give
              </Text>
            </Alert>
          )}
          {recurringMsg !== undefined && recurringMsg && !errorMsg && (
            <Alert
              className={'common-alert'}
              color={'success'}
              isOpen
              onClick={closeMessage()}
            >
              <Text
                variant={'xSmall'}
                color={'grey3'}
              >
                {recurringMsg}
              </Text>
            </Alert>
          )}
          {serverError !== '' && (
            <Alert
              className={'common-alert'}
              color={'danger'}
              isOpen
              onClick={() => {
                setServerError('');
              }}
            >
              <Text
                variant={'xSmall'}
                color={'red1'}
              >
                {serverError}
              </Text>
            </Alert>
          )}
        </center>
        <MainSection>
          <Row className={'justify-content-center'}>
            <Col
              lg={6}
              md={8}
              sm={10}
            >
              <center>
                <H2
                  size={'large'}
                  color={'grayscaleBlack'}
                  weight={'medium'}
                  value={undefined}
                >
                  Recurring Gifts
                </H2>
              </center>
              <br />
              {error && (
                <center className={'mt-30'}>
                  <Icon
                    size={'xLarge'}
                    name={'empty_recurring_icon'}
                  />
                  <Row className={'justify-content-center mt-15'}>
                    <Col lg={10}>
                      <Text
                        color={'grey3'}
                        variant={'medium'}
                      >
                        {noRecurringFoundMsg}
                      </Text>
                    </Col>
                  </Row>
                </center>
              )}

              {!loader &&
                recurringList.map((recurring: RecurringDonationsResponse) => (
                  <div key={`recurring${recurring.recurringGift.id}`}>
                    <Row className={'justify-content-center'}>
                      <Col sm={10}>
                        <RecurringContainer key={recurring.recurringGift.id}>
                          <Ul>
                            <Li className={'mb-15'}>
                              <div className={'mb-15'}>
                                <Li
                                  key={recurring.envelopes[0].recurringId}
                                  className={'recurring-box mb-30'}
                                >
                                  <Row className={'pb-10'}>
                                    <Col xs={9}>
                                      <DonationsDataLabel>
                                        <Text
                                          color={'grey3'}
                                          id={'donee-name'}
                                        >
                                          {recurring.donee.name}
                                        </Text>
                                        <Tooltip
                                          placement={'top'}
                                          target={'donee-name'}
                                          isOpen={doneeNameTooltipOpen}
                                          toggle={() =>
                                            setDonneNameTooltipOpen(
                                              !doneeNameTooltipOpen
                                            )
                                          }
                                        >
                                          {recurring.donee.name}
                                        </Tooltip>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={3}>
                                      <div className={'right-side'}>
                                        <LinkButton
                                          onClick={(e: any) =>
                                            tooltip(
                                              recurring.envelopes[0]
                                                .recurringId,
                                              recurring.envelopes[0].id,
                                              recurring.envelopes[0].name
                                            )
                                          }
                                        >
                                          <Icon
                                            size={'medium'}
                                            name={'menu-recurring'}
                                          />
                                        </LinkButton>
                                        <div
                                          style={
                                            recurringTooltip &&
                                            recurringId ===
                                              recurring.envelopes[0]
                                                .recurringId &&
                                            envelopeId ===
                                              recurring.envelopes[0].id
                                              ? {}
                                              : { display: 'none' }
                                          }
                                        >
                                          <TooltipAction
                                            onClick={recurringPopupOpen}
                                          >
                                            <IconBox>
                                              <Icon
                                                size={'medium'}
                                                name={'cancel_recurrin_icon'}
                                              />
                                            </IconBox>
                                            <Text
                                              className={'recurrin-text'}
                                              color={'primaryGuidingViolet'}
                                            >
                                              Cancel Recurring
                                            </Text>
                                          </TooltipAction>
                                          <TooltipOverlay
                                            onClick={() => {
                                              setRecurringToolTip(false);
                                              setRecurringId(undefined);
                                              setEnvelopeId(undefined);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <DonationsDataLabel>
                                        <Text
                                          color={'primarySecondaryContent'}
                                          variant={'medium'}
                                        >
                                          Repeat
                                        </Text>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={6}>
                                      <DonationsDatavalue>
                                        <Text weight={'semiBold'}>
                                          {getRecurringName(
                                            recurring.recurringGift.frequency
                                          )}
                                        </Text>
                                      </DonationsDatavalue>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <DonationsDataLabel>
                                        <Text
                                          color={'primarySecondaryContent'}
                                          variant={'medium'}
                                        >
                                          Credit card
                                        </Text>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={6}>
                                      <DonationsDatavalue>
                                        <PaymentInfoPreview>
                                          <Icon
                                            size={'large'}
                                            name={
                                              `${getCardIcon(
                                                recurring.recurringGift.walletId
                                              )}-logo` as any
                                            }
                                            className={'recurring-card'}
                                          />
                                          <PaymentInfoCardPreview
                                            className={'pr-0'}
                                          >
                                            <Text weight={'semiBold'}>
                                              ****
                                              {getCardNumber(
                                                recurring.recurringGift.walletId
                                              )}
                                            </Text>
                                          </PaymentInfoCardPreview>
                                        </PaymentInfoPreview>
                                      </DonationsDatavalue>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <DonationsDataLabel>
                                        <Text
                                          color={'primarySecondaryContent'}
                                          variant={'medium'}
                                        >
                                          Donation
                                        </Text>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={6}>
                                      <div className={'right-side'}>
                                        <DonationsDatavalue>
                                          <Text
                                            color={'grayscaleBlack'}
                                            variant={'medium'}
                                            weight={'semiBold'}
                                          >
                                            {amountNumberFormat(
                                              recurring.recurringGift.giftAmount
                                            )}
                                          </Text>
                                        </DonationsDatavalue>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <DonationsDataLabel>
                                        <Text color={'primarySecondaryContent'}>
                                          Last donation
                                        </Text>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={6}>
                                      <DonationsDatavalue>
                                        <Text
                                          color={'grayscaleBlack'}
                                          weight={'semiBold'}
                                        >
                                          {formatDate(
                                            recurring.recurringGift.lastRunDate
                                          )}
                                        </Text>
                                      </DonationsDatavalue>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs={6}>
                                      <DonationsDataLabel>
                                        <Text color={'primarySecondaryContent'}>
                                          Next donation
                                        </Text>
                                      </DonationsDataLabel>
                                    </Col>
                                    <Col xs={6}>
                                      <DonationsDatavalue>
                                        <Text
                                          color={'grayscaleBlack'}
                                          weight={'semiBold'}
                                        >
                                          {formatDate(
                                            recurring.recurringGift.nextRunDate
                                          )}
                                        </Text>
                                      </DonationsDatavalue>
                                    </Col>
                                  </Row>
                                </Li>
                              </div>
                            </Li>
                          </Ul>
                        </RecurringContainer>
                      </Col>
                    </Row>
                  </div>
                ))}
            </Col>
          </Row>
          <GivelifyModal
            open={recurringPopup}
            onClose={closeModal}
          >
            <ModalSection>
              <ModalBody>
                <Text variant={'medium'}>
                  Are you sure you want to cancel your recurring donation to{' '}
                  {envelopeName}?
                </Text>
              </ModalBody>
              <ModalFooter>
                <SolidButtonV2
                  className={classes.ConfirmButton}
                  onClick={cancelRecurring}
                >
                  Yes, Cancel Recurring
                </SolidButtonV2>
                <br />
                <MUILink
                  className={classes.CancelLink}
                  onClick={closeModal}
                >
                  No, Do Not Cancel
                </MUILink>
              </ModalFooter>
            </ModalSection>
          </GivelifyModal>
        </MainSection>
      </Container>
    </div>
  );
};

export default DonorRecurringView;
