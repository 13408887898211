import React, { useState } from 'react';
import styled from 'styled-components';
import AddRecurringField from './AddRecurringField';
import {
  recurringFrequencies,
  recurringMapping
} from '../SetRecurring/recurringConstants';
import { DonationAddonProps } from '../../../../utils';
import colors from '../../../../../theme/colors';
import { RecurringIcon } from '../../../../../icons';
import { Text } from '../../../common/Text/Text';
import GivelifyModal from '../../../common/Modal/GivelifyModal';

interface AddRecurringProps extends DonationAddonProps {
  className?: string;
}

const StyledDiv = styled.div`
  padding: 15px 24px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  transition: background 0.5s ease-in-out;
  &:hover {
    background-color: ${colors.utilitySeparatorColor};
  }
  & * {
    cursor: pointer;
  }
  .icon {
    margin-top: -4px;
    margin-right: 14px;
  }
  .recurring-text {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .edit-text {
    margin-left: 14px;
  }
`;

const AddRecurring: React.FC<AddRecurringProps> = ({
  recurring,
  className,
  setRecurring
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleRecurring = (
    recurring: (typeof recurringFrequencies)[number]
  ) => {
    setRecurring(recurring.value);
  };
  const recurringLabel = recurringMapping[recurring];
  return (
    <>
      <StyledDiv
        onClick={handleOpen}
        className={className}
      >
        <RecurringIcon className={'icon'} />
        <Text className={'recurring-text'}>
          {recurringLabel.value === 'None'
            ? 'Set Recurring'
            : recurringLabel.label}
        </Text>
        {recurring !== 'None' && (
          <Text
            color={'primaryGuidingViolet'}
            className={'edit-text'}
          >
            Edit
          </Text>
        )}
      </StyledDiv>
      <GivelifyModal
        open={open}
        onClose={handleClose}
      >
        <AddRecurringField
          recurring={recurring}
          setRecurring={handleRecurring}
          handleClose={handleClose}
        />
      </GivelifyModal>
    </>
  );
};

export default AddRecurring;
