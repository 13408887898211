import React from 'react';
import styled from 'styled-components';
import { ReactChildren } from '../../../providers/DoneeProvider';
import colors from '../../theme/colors';

const StyledDiv = styled.div<GivelifyPaperProps>`
  border-radius: 24px;
  background-color: ${colors.white};
  padding: ${({ padding }) => padding};
  box-shadow: 0px 0px 1px rgba(10, 0, 77, 0.039),
    0px 0.5px 1.5px rgba(10, 0, 77, 0.19);
`;

interface GivelifyPaperProps {
  padding?: string;
  className?: string;
}

const GivelifyPaper: React.FC<GivelifyPaperProps & ReactChildren> = ({
  padding = '16px',
  className,
  children
}) => {
  return (
    <StyledDiv
      padding={padding}
      className={className}
    >
      {children}
    </StyledDiv>
  );
};

export default GivelifyPaper;
