import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { User } from '../../../../../LoginModal/routes';
import { isNameNotValid } from '../../../../../LoginModal/pages/Name';
import { Text } from '../../../common/Text/Text';
import { Secure } from '../../../../../LoginModal/pages/CreditCard/Secure';
import { Textfield } from '../../../../../LoginModal/pages/Textfield';
import { CreditCard } from '../../../../../LoginModal/pages/CreditCard';

export type PaymentRoutes = 'CREDIT' | 'ADDRESS' | 'ADDRESS_COMPLETE';
export interface PaymentFormProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  setRoute: React.Dispatch<React.SetStateAction<PaymentRoutes>>;
}

const StyledDiv = styled.div`
  padding-top: 18px;
  .name-field {
    margin-bottom: 8px;
  }
  .secured-info {
    margin-top: 6px;
    margin-bottom: 18px;
  }
`;

const CreditCardForm: React.FC<PaymentFormProps> = ({
  user,
  setUser,
  setRoute
}) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string>(user.name || '');
  const [nameError, setNameError] = useState<string>('');

  const handleSubmit = (user: User) => {
    const name = nameRef.current?.value;
    if (isNameNotValid(name)) {
      setNameError('*Please enter first and last name');
      return;
    }
    setName(name || '');
    setNameError('');
    setUser({ ...user, name });
    setRoute('ADDRESS');
  };

  const HeaderComponent: React.FC = () => {
    return (
      <StyledDiv>
        <Text>Add new payment method</Text>
        <Secure className={'secured-info'} />
        <Textfield
          id={'name'}
          type={'text'}
          defaultValue={name}
          placeholder={'Name on Card'}
          maxLength={255}
          className={'name-field'}
          errorText={nameError}
          inputRef={nameRef}
        />
      </StyledDiv>
    );
  };

  return (
    <CreditCard
      user={user}
      HeaderComponent={HeaderComponent}
      page={'CREDIT'}
      onNext={handleSubmit}
      closeModal={() => null}
    />
  );
};

export default CreditCardForm;
