import React from 'react';
import styled from 'styled-components';
import colors from '../../../../theme/colors';
import { Text } from '../Text/Text';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    margin-left: 6px;
    margin-right: 6px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: ${colors.utilitySeparatorColor};
  }
`;

export interface DividerProps {
  text: string;
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ text, className }) => {
  return (
    <StyledDiv className={className}>
      <div className={'line'}></div>
      <Text
        weight={'extraBold'}
        variant={'medium'}
        className={'text'}
      >
        {text}
      </Text>
      <div className={'line'}></div>
    </StyledDiv>
  );
};
