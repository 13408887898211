import React, { useMemo } from 'react';
import styled from 'styled-components';
import MetaTags from '../../../MetaTags';
import WalletScreen from '../../../../pages/DonorWallet';
import { COMPONENT_VALUES, colors } from '../../../theme';

interface DonorWalletProps {
  className?: string;
}

const StyledDiv = styled.div`
  ${COMPONENT_VALUES.box}
  background-color: ${colors.white};
  .container {
    width: 100% !important;
  }
`;

const DonorWallet: React.FC<DonorWalletProps> = ({ className }) => {
  const metaTags = useMemo(
    () => ({
      title: 'Wallet | Givelify',
      description: 'Wallet screen'
    }),
    []
  );
  return (
    <StyledDiv className={className}>
      <MetaTags {...metaTags} />
      <WalletScreen />
    </StyledDiv>
  );
};

export default DonorWallet;
