import styled from 'styled-components';
import {
  backgroundColors,
  textColors,
  buttonSizes,
  buttonWidth,
  buttonWeights
} from './constants';
import colors from '../../../../../theme/colors';
import fonts from '../../../../../theme/fonts';
import shadows from '../../../../../theme/shadows';
import { sizes } from '../../Icon/constants';

const disabled = (props: { disabled?: boolean }) =>
  props.disabled ? 'auto;' : 'pointer;';

const ButtonStylesBase = {
  justifyContent: 'center',
  flexGrow: 1,
  // text color inside button
  color: colors.grayscaleBlack,
  // actual color of button
  background: colors.white,
  minWidth: '94px',
  maxWidth: '360px',
  minHeight: '64px',
  maxHeight: '64px',
  borderRadius: '32px',
  marginBottom: '16px',
  fontFamily: fonts.fontfamily
  // textTransform: 'none'
};

export const SolidButtonStyle = {
  ...ButtonStylesBase,
  '&:hover': {
    backgroundColor: colors.white,
    color: colors.grayscaleBlack
  },
  '&:active': {
    color: colors.primaryGuidingViolet,
    background: colors.utilityGuidingVioletLight
  },
  '&:disabled': {
    backgroundColor: colors.disable,
    color: colors.white
  }
};

export const SolidOrangeButtonStyle = {
  ...ButtonStylesBase,
  backgroundColor: `${colors.primaryGenerosityOrange} !important`,
  '&:hover': {
    backgroundColor: colors.white,
    color: colors.grayscaleBlack
  },
  '&:active': {
    color: colors.white,
    background: colors.buttonsHoverOrange
  },
  '&:disabled': {
    backgroundColor: colors.disable,
    color: colors.white
  }
};

export const PrimaryGuidingVioletButton = {
  ...SolidButtonStyle,
  color: colors.white,
  backgroundColor: colors.primaryGuidingViolet,
  justifyContent: 'center',
  width: '100%',
  minHeight: '52px',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: colors.buttonsHoverViolet,
    color: colors.white
  }
};

export const SolidButton = styled.button<{
  color: keyof typeof colors;
  raised?: any;
  fluid?: boolean;
  size: keyof typeof sizes;
  width: keyof typeof buttonWidth;
  weight?: keyof typeof buttonWeights;
}>`
  outline: 0;
  border: none;
  font-family: ${fonts.fontfamily};
  color: ${(props) => textColors[props.color]};
  font-weight: ${(props) => props.weight && buttonWeights[props.weight]};
  width: ${(props) => buttonWidth[props.width].width};
  min-width: ${(props) =>
    props.width === 'minwidth' ? buttonWidth[props.width].minWidth : ''};
  padding: ${(props) => buttonWidth[props.width].padding};
  font-size: ${(props) => buttonSizes[props.size].text}px;  
  border-radius: ${(props) =>
    props.color === 'transparent' ? '0' : buttonSizes[props.size].container}px;
  background: ${(props) => backgroundColors[props.color]};
  box-shadow: ${(props) => shadows.get(props.color as any, props.raised)};
  transition: all 0.1s linear 0.1s;
  &:active {
    opacity: (props.disabled) ?'1':'0.8';
    transition: all 0.1s ease-in;
    box-shadow: ${shadows.get()};
  }
  cursor: ${disabled};

`;

export const SolidButtonWrapper = styled.div<{
  color?: keyof typeof colors;
  fluid?: boolean;
  size?: keyof typeof sizes;
  width?: keyof typeof buttonWidth;
  raised?: any;
}>`
  background: {
    ${(props) => (props.color === 'transparent' ? 'transparent' : '#ffffff')};
  }
  padding: 0px !important;
  transition: all 0.1s linear 0.1s;
  width: auto;
`;
