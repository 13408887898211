import { ById, axiosClient } from '../../utils/axiosClient';
import { makeApiRequest } from '../../utils/makeApiRequest';

interface GiftSummaryResponseData {
  count: number;
  total: number;
}

export interface GiftSummaryResponse {
  all: GiftSummaryResponseData;
  years: ById<GiftSummaryResponseData>;
}

const GIFTS_SUMMARY_URL = '/donor/profile/gifts-summary';
export const getGiftSummaryRequest = () => {
  const httpRequest = axiosClient.get(GIFTS_SUMMARY_URL);
  return makeApiRequest<GiftSummaryResponse>(httpRequest);
};
