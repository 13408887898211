import React from 'react';
import styled from 'styled-components';
import {
  recurringMapping,
  recurringFrequencies
} from '../SetRecurring/recurringConstants';
import { RecurringTypes } from '../../../../utils';
import colors from '../../../../../theme/colors';
import { Text } from '../../../common/Text/Text';
import { SelectedCheckmark } from '../../../../../icons';

interface AddRecurringFieldProps {
  recurring: RecurringTypes;
  setRecurring: (recurring: (typeof recurringFrequencies)[number]) => unknown;
  handleClose: () => unknown;
}

const StyledDiv = styled.div`
  .modal-heading {
    max-width: 289px;
    text-align: center;
    margin: 24px auto 32px auto;
  }
  .recurring-buttons {
    button {
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      transition: border 0.5s, background-color 0.5s;
      background-color: inherit;
      border-radius: 100px;
      justify-content: space-between;
      margin-bottom: 8px;
      border: 1.5px solid ${colors.utilityInputColor};
      & * {
        cursor: pointer;
      }
      &:hover {
        background-color: ${colors.utilitySeparatorColor};
      }
    }
    button.selected {
      color: ${colors.primaryGuidingViolet};
      border: 1.5px solid ${colors.primaryGuidingViolet};
      .recurring-label {
        margin-right: -37px;
      }
      &:hover {
        background-color: inherit;
      }
    }
    .icon {
      padding-top: 3px;
      padding-right: 4px;
    }
  }
  .no-recurring-button {
    width: 100%;
    border: none;
    background: none;
    padding-top: 16px;
    padding-bottom: 0;
    transition: 0.5s color;
    &:focus * {
      color: ${colors.primaryGenerosityOrange};
    }
  }
`;

const AddRecurringField: React.FC<AddRecurringFieldProps> = ({
  recurring,
  setRecurring,
  handleClose
}) => {
  const handleChange = (recurring: (typeof recurringFrequencies)[number]) => {
    setRecurring(recurring);
    handleClose();
  };
  return (
    <StyledDiv>
      <Text
        variant={'large'}
        className={'modal-heading'}
      >
        How often would you like to send this gift?
      </Text>
      <div className={'recurring-buttons'}>
        {recurringFrequencies.map((r) => {
          if (r.value === 'None') return null;
          const isSelected = r.value === recurring;
          return (
            <button
              key={r.value}
              className={isSelected ? 'selected' : undefined}
              onClick={() => handleChange(r)}
            >
              <div />
              <Text
                color={
                  isSelected
                    ? 'primaryGuidingViolet'
                    : 'primarySecondaryContent'
                }
                className={'recurring-label'}
              >
                {r.label}
              </Text>
              {isSelected ? (
                <div className={'icon'}>
                  <SelectedCheckmark />
                </div>
              ) : (
                <div />
              )}
            </button>
          );
        })}
      </div>
      <button
        className="no-recurring-button"
        onClick={() => handleChange(recurringMapping['None'])}
      >
        <Text color="primaryGuidingViolet">
          Don’t set this as a recurring gift
        </Text>
      </button>
    </StyledDiv>
  );
};

export default AddRecurringField;
