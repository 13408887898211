import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { icons } from '../Donee/components/common/Icon/constants';
import { colors } from '../theme';
import { Text } from '../Donee/components/common/Text/Text';
import { GivelifyButton } from '../Donee/components/common/Button/GivelifyButton';
import { isAppleDevice } from '../Donee/components/common/utils';
import { CrossIcon } from '../icons';
import { appLinks, assetUrl } from '../../utils/webConfig';
import {
  isDonorLoggedIn,
  useDonorContext
} from '../../providers/DonorProvider/DonorProvider';
import { usePushEvents } from '../../hooks/PushEvents';
import { appDownloadEvent } from '../../hooks/PushEvents/clevertapEvents';
import { copyDoneeIdToClipboard } from '../../utils/clipboard';
import DeepLinkCopiedModal from '../Donee/components/CheckoutWizard/ThankYouWizard/DeepLinkCopiedModal';
import { useDeepLink } from '../../hooks/useDeepLink';
import { Donee, useDonee } from '../../providers/DoneeProvider';

const eventPayload = {
  location: 'giftmountsbanner',
  action: 'clicked'
};

const deepLinkTitle = (donee: Donee | null) => (
  <>
    We’ve <span className="deep-link-copied-text">copied</span> a link
    {donee && (
      <>
        <br />
        to {donee.organization.name}
      </>
    )}
  </>
);

const StyledDiv = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: fixed;
  left: 0;
  bottom: 0vh;
  z-index: 999;
  border-top: 1px solid ${colors.utilitySeparatorColor};
  background: rgba(250, 250, 252, 0.8);
  backdrop-filter: blur(10px);
  .givelify-icon {
    width: 54px;
    height: 54px;
    border-radius: 12px;
  }
  .footer-banner-button {
    .MuiButtonBase-root {
      height: 40px;
      min-width: 80px;
      font-size: 14px;
      min-height: 40px;
      max-height: 40px;
    }
  }
  .banner-close-icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    fill: ${colors.primarySecondaryContent};
  }
`;

let isClosed = false;
const hideBanner = window.innerWidth > 500;
const link = appLinks.giftAmountBanner;
const handleDownload = () => {
  window.location.href = isAppleDevice() ? link.apple : link.android;
};

const FooterBanner: React.FC = () => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { donee } = useDonee();
  const { pushEvent } = usePushEvents();
  const { donorContext } = useDonorContext();
  const isLoggedIn = isDonorLoggedIn(donorContext);
  const [show, setShow] = useState<boolean>(
    isLoggedIn && donorContext.totalGifts > 0
  );

  useEffect(() => {
    if (!show && donorContext.totalGifts > 0) {
      setShow(true);
    }
  }, [donorContext]);

  useEffect(() => {
    const shouldShow = isLoggedIn && donorContext.totalGifts > 0;
    setShow(shouldShow);
    if (shouldShow) {
      pushEvent(appDownloadEvent, {
        location: 'giftmountsbanner',
        action: 'shown'
      });
    }
  }, [isLoggedIn, pushEvent]);

  const handleClose = useCallback(() => {
    setShow(false);
    isClosed = true;
    pushEvent(appDownloadEvent, {
      location: 'giftmountsbanner',
      action: 'dismissed'
    });
  }, [setShow, pushEvent]);

  const handleDownloadClicked = useCallback(() => {
    pushEvent(appDownloadEvent, eventPayload);
    copyDoneeIdToClipboard();
    handleDownload();
  }, [pushEvent]);

  const {
    deepLinkRequestState,
    showCopiedModal,
    getDeepLink,
    isDeferredDeepLinkOn
  } = useDeepLink(eventPayload, handleDownload);

  const handleDownloadApp = useCallback(() => {
    getDeepLink(
      donee?.data,
      donorContext?.donor?.data,
      '/dl/giftAmount',
      undefined
    ).then((s) => {
      s && setUrl(s);
    });
  }, [donee, donorContext]);

  if (!show || hideBanner || isClosed) return null;

  return (
    <>
      <StyledDiv>
        <img
          src={assetUrl(icons.givelifyIcon)}
          alt="Givelify Icon"
          title="Givelify Icon"
          className="givelify-icon"
        />
        <div className="banner-text-wrapper">
          <Text
            variant="small"
            weight="extraBold"
          >
            Safe. Secure. Free!
          </Text>
          <Text variant="small">
            Keep giving cheerfully with the Givelify app.
          </Text>
        </div>
        <GivelifyButton
          id="footer-banner-open"
          isLoading={
            deepLinkRequestState.type === 'REQUEST_START' ||
            deepLinkRequestState.type === 'REQUEST_SUCCESS'
          }
          onClick={
            isDeferredDeepLinkOn() ? handleDownloadApp : handleDownloadClicked
          }
          className="footer-banner-button"
        >
          Open
        </GivelifyButton>
        <CrossIcon
          className="banner-close-icon"
          onClick={handleClose}
        />
      </StyledDiv>
      <DeepLinkCopiedModal
        url={url}
        open={showCopiedModal}
        title={deepLinkTitle(donee.data)}
        onClick={handleDownload}
      />
    </>
  );
};

export default FooterBanner;
