import { RequestState } from '../hooks/useRequestState';
import { HeartLoading } from './HeartLoading';

interface ActivateStateProps {
  activateFailedState: JSX.Element | string;
  activateSuccessState: JSX.Element | string;
  resendSuccessState: JSX.Element | string;
  activateType: RequestState<unknown>['type'];
  resendType: RequestState<unknown>['type'];
}

const ActivateState: React.FC<ActivateStateProps> = ({
  activateType,
  resendType,
  resendSuccessState,
  activateSuccessState,
  activateFailedState
}) => {
  const isLoading =
    activateType === 'REQUEST_INIT' ||
    activateType === 'REQUEST_START' ||
    resendType === 'REQUEST_START';

  if (isLoading) {
    return <HeartLoading className={'heart-loading'} />;
  }
  if (activateType === 'REQUEST_SUCCESS') {
    return activateSuccessState;
  }
  if (resendType === 'REQUEST_SUCCESS') {
    return resendSuccessState;
  }
  return activateFailedState;
};

export default ActivateState;
