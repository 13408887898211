import React, { Component } from 'react';

import { ImageWrapper } from './styles';
import { assetUrl } from '../../../../../../utils/webConfig';

class Image extends Component {
  image: any;
  state = {
    loaded: false
  };

  componentDidMount() {
    this.image = document.createElement('img');
    this.image.src = this.getBackgroundURL(this.image);
    this.image.src = assetUrl(this.image.src);

    this.image.onload = () =>
      this.setState(() => ({
        loaded: true
      }));
  }

  getBackgroundURL = (el: any) => {
    let background = '';

    if (el.currentStyle) {
      background = el.currentStyle.backgroundImage;
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
      background = document.defaultView.getComputedStyle(
        el,
        ''
      ).backgroundImage;
    } else {
      background = el.style.backgroundImage;
    }

    return background.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
  };

  render() {
    const { loaded } = this.state;
    return (
      <ImageWrapper
        loaded={loaded}
        // @ts-ignore
        innerRef={(ref) => {
          this.image = ref;
        }}
        {...this.props}
      />
    );
  }
}

export default Image;
