import React, { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { createContext, useContext } from 'react';
import { ReactChildren } from '../../providers/DoneeProvider';
import { webConfig } from '../../utils/webConfig';
import { useEnabledFeatures } from '../EnabledFeatures';
import {
  getAppInfo,
  pushEvent,
  addProfile,
  trackLinks,
  CleverTapDonorProfile,
  extendProfile
} from './clevertap';
import { Donor } from '../../providers/DonorProvider/interfaces';
import { appDownloadEvent } from './clevertapEvents';

const addProfileEventName = 'Add profile';
let lastEventName: string | undefined = undefined;

const defaultPushEvent = (
  eventName: string,
  payload: object | undefined = {}
): void =>
  // eslint-disable-next-line
  console.log(eventName, payload);

const defaultAddProfile = (donor: CleverTapDonorProfile): void =>
  // eslint-disable-next-line
  console.log('Add profile', donor);

const defaultExtendProfile = (gifts?: number, recurring?: boolean): void =>
  // eslint-disable-next-line
  console.log('Extend profile', gifts, recurring);

const defaultTrackLinks = (link: string, properties?: object): void =>
  // eslint-disable-next-line
  console.log(link, properties);

export const PushEventsContext = createContext<{
  pushEvent: (eventName: string, payload?: object) => unknown;
  addProfile: (donor: CleverTapDonorProfile) => unknown;
  trackLinks: (link: string) => unknown;
  extendProfile: (gifts?: number, recurring?: boolean) => unknown;
}>({
  pushEvent: defaultPushEvent,
  addProfile: defaultAddProfile,
  trackLinks: defaultTrackLinks,
  extendProfile: defaultExtendProfile
});

export const usePushEvents = () => useContext(PushEventsContext);

const PushEventsProvider: React.FC<
  ReactChildren & { donorEmailRef: React.MutableRefObject<Donor | undefined> }
> = ({ donorEmailRef, children }) => {
  const [clevertapLogState, setClevertapLogState] = useState<boolean>(false);
  const { isFeatureEnabled } = useEnabledFeatures();

  useEffect(() => {
    if (!window.clevertap) {
      window.clevertap = {};
    }
    const clevertapLog = () => {
      setClevertapLogState(true);
      console.log('Clevertap logs are turned on.');
    };
    window.setLogLevel = clevertapLog;
    window.clevertap.setLogLevel = clevertapLog;
  }, []);

  const handleEvents = useCallback(
    (eventName: string, payload?: object) => {
      if (eventName !== appDownloadEvent && eventName === lastEventName) return;
      lastEventName = eventName;
      // const isNewOGAEnabled = isFeatureEnabled('show-new-oga');
      const properties = {
        ...(payload || {}),
        ...getAppInfo(),
        donor: donorEmailRef.current?.email || 'unknown'
      };
      if (isFeatureEnabled('oga-clevertap') && !webConfig.isPrerenderBot) {
        pushEvent(eventName, properties);
        window.fbq?.('trackCustom', eventName, properties);
        TagManager.dataLayer({
          dataLayer: {
            event: eventName,
            eventProps: properties
          }
        });
      }
      if (clevertapLogState) {
        defaultPushEvent(eventName, properties);
      }
    },
    [donorEmailRef, clevertapLogState, isFeatureEnabled]
  );

  const handleAddProfile = useCallback(
    (donor?: CleverTapDonorProfile) => {
      if (!donor) return;
      if (lastEventName === addProfileEventName) return;
      lastEventName = addProfileEventName;
      if (isFeatureEnabled('oga-clevertap') && !webConfig.isPrerenderBot) {
        if (
          window.clevertap?.profile?.[0]?.Site.Identity ===
          donor.donor_id?.toString()
        )
          return;
        addProfile(donor);
      } else if (!webConfig.isProduction) {
        defaultAddProfile(donor);
      } else {
        console.log('Unable to publish clevertap profile');
      }
      if (clevertapLogState) {
        defaultPushEvent('Add Profile', donor);
      }
    },
    [isFeatureEnabled, clevertapLogState]
  );

  const handleTrackLinks = useCallback(
    (link: string) => {
      // const isNewOGAEnabled = isFeatureEnabled('show-new-oga');
      const properties = {
        ...getAppInfo(),
        donor: donorEmailRef.current?.email || 'unknown'
      };
      if (isFeatureEnabled('oga-clevertap') && !webConfig.isPrerenderBot) {
        trackLinks(link, properties);
      } else if (!webConfig.isProduction) {
        defaultTrackLinks(link, properties);
      }
    },
    [donorEmailRef, isFeatureEnabled]
  );

  const handleExtendProfile = useCallback(
    (gifts?: number, recurring?: boolean) => {
      extendProfile(gifts, recurring);
      if (clevertapLogState) {
        defaultPushEvent('Extend Profile', { gifts, recurring });
      }
    },
    [clevertapLogState]
  );

  return (
    <PushEventsContext.Provider
      value={{
        pushEvent: handleEvents,
        addProfile: handleAddProfile,
        extendProfile: handleExtendProfile,
        trackLinks: handleTrackLinks
      }}
    >
      {children}
    </PushEventsContext.Provider>
  );
};

export default PushEventsProvider;
