import React, { ChangeEvent, useEffect } from 'react';
// import { useApiRequest } from '../../../api_v2';
// import { getAddressComponents, GoogleLookupResponse, googleZipLookup } from '../pages/Landing/api';
import { Textfield } from '../pages/Textfield';

interface ZipFieldProps {
  id?: string;
  zip: string;
  zipError: string | undefined;
  className?: string;
  placeholder?: string;
  setZipError: (error: string | undefined) => unknown;
  setZip: (e: ChangeEvent<HTMLInputElement>) => unknown;
  // setCity: (city: string) => unknown;
  // setCountry: (country: string) => unknown;
  // setCountryCode: (countryCode: string) => unknown;
  // setState: (state: string) => unknown;
}

const ZipField: React.FC<ZipFieldProps> = ({
  zip,
  zipError,
  className,
  id = 'zip',
  placeholder = 'Zip/Postal Code',
  setZip,
  setZipError
  // setCity,
  // setCountry,
  // setCountryCode,
  // setState
}) => {
  // const [ googleLookup, makeRequest ] = useApiRequest<
  //       GoogleLookupResponse
  //   >();
  useEffect(() => {
    if (zip.length === 5 && isNaN(Number(zip))) {
      setZipError('*Please enter a valid Zip/Postal Code');
    } else {
      setZipError(undefined);
    }
  }, [ zip, setZipError ]);
  // Google lookup for Zip code if we need in the future
  // useEffect(() => {
  //   if (googleLookup.type === 'REQUEST_SUCCESS') {
  //     if (googleLookup.data.results?.length > 0) {
  //       const {city, state, countryCode} = getAddressComponents(
  //         googleLookup.data.results[0].addressComponents
  //       );
  //       setCity(city);
  //       setState(state);
  //       setCountry(countryCode);
  //       setCountryCode(countryCode);
  //       setZipError(undefined);
  //     } else {
  //       setZipError('Zip code is not valid');
  //     }
  //   } else if (googleLookup.type === 'REQUEST_ERROR') {
  //     setZipError('Unable to lookup the zip code');
  //   }
  // }, [ googleLookup ])
  return (
    <Textfield
      id={id}
      placeholder={placeholder}
      value={zip}
      maxLength={7}
      className={className}
      errorText={zipError}
      onChange={setZip}
      // onMouseLeave={() => onHover(email)}
    />
  );
}

export default ZipField;
