import colors from '../../../../theme/colors';
import fonts from '../../../../theme/fonts';

export const textColors = colors;
export const textSizes = fonts.fontSizing;
export const textWeights = fonts.fontWeight;
export const lineHeights = fonts.fontLeading;

export const defaultTextSize = 'medium';
export const defaultTextWeight = 'regular';
export const defaultTextColor = 'grayscaleBlack';
