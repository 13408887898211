import React from 'react';
import CardRow from '../CardRow';
import styled from 'styled-components';
import GivelifyPaper from '../../GivelifyPaper';
import { SelectedEnvelope } from '../../Capsules/Envelopes';
import { CheckoutWizardRouterProps } from '../CheckoutWizardInterfaces';
import colors from '../../../../theme/colors';
import { Text } from '../../common/Text/Text';
import { CrossIcon, PlusIcon } from '../../../../icons';

const StyledDiv = styled.div`
  .donations {
    padding: 0px 24px 0px 24px;
    .donation-row {
      display: flex;
      align-items: center;
      .icon {
        margin-top: -4px;
        margin-left: 10px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }
  .add-donation-button {
    width: 100%;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    cursor: pointer;
    transition: background 0.5s ease-in-out;
    overflow: hidden;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    * {
      cursor: pointer;
    }
    .plus-icon {
      margin-top: -1px;
      margin-right: 5px;
    }
    &:hover {
      background-color: ${colors.utilitySeparatorColor};
    }
  }
`;

interface DonationsCardProps {
  donations: SelectedEnvelope[];
  handleAddDonation: () => unknown;
  removeEnvelope: CheckoutWizardRouterProps['removeFromCart'];
}

const DonationsCard: React.FC<DonationsCardProps> = ({
  donations,
  handleAddDonation,
  removeEnvelope
}) => {
  return (
    <StyledDiv>
      <GivelifyPaper padding={'0'}>
        <div className={'donations'}>
          {donations.map((d) => (
            <CardRow
              key={d.envelopeId}
              LeftSideComponent={() => (
                <Text
                  variant={'medium'}
                  weight={'bold'}
                >
                  {d.name}
                </Text>
              )}
              RightSideComponent={() => (
                <div className={'donation-row'}>
                  <Text
                    variant={'medium'}
                    color={'primarySecondaryContent'}
                  >
                    ${d.amount.toFixed(2)}
                  </Text>
                  <CrossIcon
                    onClick={() => removeEnvelope(d.envelopeId)}
                    className={'icon'}
                  />
                </div>
              )}
            />
          ))}
        </div>
        <button
          onClick={handleAddDonation}
          className={'add-donation-button'}
        >
          <PlusIcon className={'plus-icon'} />
          <Text color={'primarySecondaryContent'}>Add Donation</Text>
        </button>
      </GivelifyPaper>
    </StyledDiv>
  );
};

export default DonationsCard;
