import { webConfig } from '../../utils/webConfig';

export const PHONE_WIDTH = '600px';
export const TABLET_WIDTH = '900px';

export type RecurringTypes =
  | 'None'
  | 'FREQ=DAILY'
  | 'FREQ=WEEKLY'
  | 'FREQ=WEEKLY;INTERVAL=2'
  | 'FREQ=MONTHLY;BYMONTHDAY=1,16'
  | 'FREQ=MONTHLY';

export interface DonationAddonProps {
  message: string;
  setMessage: (message: string) => unknown;
  recurring: RecurringTypes;
  setRecurring: (recurring: RecurringTypes) => unknown;
}

export const getElementHeight = (
  element: HTMLElement | null,
  parentElement: HTMLElement | null
): number => {
  if (!element || !parentElement) {
    return 0;
  }
  const clone = element.cloneNode(true) as HTMLElement;
  clone.setAttribute('style', 'height: auto;');
  parentElement.appendChild(clone);
  const height = clone.offsetHeight + 1; // +1 for the border
  parentElement.removeChild(clone);
  return height;
};

export const getDefaultBannerImageUrl = (
  type: 'church' | 'nonprofit',
  isVerified: boolean
) => {
  if (type === 'nonprofit') {
    return isVerified
      ? webConfig.nonProfitBannerVerifiedDefault
      : webConfig.nonProfitBannerUnverifiedDefault;
  }
  return isVerified
    ? webConfig.churchBannerVerifiedDefault
    : webConfig.churchBannerUnverifiedDefault;
};
