import styled from 'styled-components';

export const SupportLink = styled.a<{ last?: boolean }>`
  border-top: 1px solid #d8dce0;
  padding: 15px 0;
  width: 100%;
  display: inline-block;
  border-bottom: ${({ last }) => (last ? '1px solid #D8DCE0' : '')};
`;

export const SocialLink = styled.a``;

export const PreviousArrow = styled.img`
  float: right;
  margin-top: 5px;
`;

export const FollowUs = styled.a`
  display: inline-block;
`;

export const IconBox = styled.div`
  float: left;
  margin-right: 5px;
`;
