import React from 'react';

import { WrapperButton as StyledWrapperButton } from './styles';

interface WrapperButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  value: JSX.Element | React.ReactNode;
  children: JSX.Element | React.ReactNode;
}

const WrapperButton: React.FC<WrapperButtonProps> = ({
  children,
  value,
  ...props
}) => <StyledWrapperButton {...props}>{children || value}</StyledWrapperButton>;

export default WrapperButton;
