import styled from 'styled-components';


export const LinkButtonStyle = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  cursor: pointer;
`;
