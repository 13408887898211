import colors from '../../../../theme/colors';

export const backgroundColors = {
  muted: colors.grey5,
  // white: colors.white,
  blue: colors.primaryBlue,
  ...colors
};

export const textColors = {
  // black: colors.white,
  muted: colors.grayscaleBlack,
  // white: colors.grayscaleBlack,
  blue: colors.white,
  ...colors
};

export const tagSizes = {
  xSmall: {
    text: 12,
    container: 24,
    vertical: 3,
    horizontal: 16
  },
  small: {
    text: 12,
    container: 24,
    vertical: 3,
    horizontal: 16
  },
  medium: {
    text: 14,
    container: 32,
    vertical: 6,
    horizontal: 20
  },
  large: {
    text: 16,
    container: 40,
    vertical: 7.5,
    horizontal: 28
  },
  xLarge: {
    text: 16,
    container: 40,
    vertical: 7.5,
    horizontal: 28
  }
};

export const defaultColor = 'blue';
export const defaultTagSize = 'small';
