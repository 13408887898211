import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import colors from '../../components/theme/colors';
import TextInput from '../../components/Donee/components/common/Input/TextInput';
import { SolidButtonV2 } from '../../components/Donee/components/common/Button/SolidButton';
import { userSetPassword } from '../../components/LoginModal/pages/Landing/api';
import { useApiRequest } from '../../hooks/useGivingApiRequest';
import { Label } from '../../components/Donee/components/common/Text/styles';
import { InputIcon } from '../../components/Donee/components/common/Input/TextInput/styles';
import { PrimaryGuidingVioletButton } from '../../components/Donee/components/common/Button/SolidButton/styles';

const AuthButton = {
  ...PrimaryGuidingVioletButton,
  color: colors.white,
  backgroundColor: colors.primaryGuidingViolet,
  justifyContent: 'center',
  width: '100%',
  minHeight: '50px'
};

const FormItem = styled.div`
  padding-bottom: 10px;
`;

const FormSubmitContainer = styled.div`
  width: 360px;
  max-width: 100%;
  position: relative;
  display: inline-block;
  flex-direction: column;
  align-items: center;
`;

const TextInputContainer = styled.div`
  position: relative;
`;

const StyledForm = styled.form`
  input:disabled {
    background-color: ${colors.grayscaleGray100} !important;
    color: ${colors.lightgrey};
  }
  .button-icon {
    top: 14px;
    right: 12px;
    position: absolute;
  }
`;

const useStyles = makeStyles({ AuthButton: AuthButton as any });

interface SetPasswordFormProps {
  email: string | undefined;
  token: string;
}

// const defaultDoneeId = '123';
const successMessageText =
  'Successfully set the password. We will redirect you to the main page shortly.';
const SetPasswordForm: React.FC<SetPasswordFormProps> = ({ email, token }) => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [setPassword, makeRequest] = useApiRequest<unknown>();
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  // const navigate = useNavigate();
  // const { doneeId } = useParams();
  const { AuthButton } = useStyles();

  useEffect(() => {
    let timeout = 0;
    if (setPassword.type === 'REQUEST_SUCCESS') {
      setSuccessMessage(successMessageText);
      timeout = window.setTimeout(() => {
        // if (doneeId && doneeId !== defaultDoneeId) {
        //   navigate(`/donate/${doneeId}`);
        //   return;
        // }
        window.location.href = 'https://www.givelify.com/';
      }, 3000);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [setPassword, setSuccessMessage]);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPassword = (e.target as any)[1].value;
    const confirmPassword = (e.target as any)[2].value;
    if (newPassword !== confirmPassword) {
      setError('Please check if both fields have the same password value.');
      return;
    }
    setError(undefined);
    makeRequest(userSetPassword(newPassword, token, email));
  };
  const loading = setPassword.type === 'REQUEST_START';
  const apiError =
    setPassword.type === 'REQUEST_ERROR'
      ? setPassword.error.message
      : undefined;
  return (
    <StyledForm onSubmit={onSubmit}>
      {email && (
        <FormItem>
          <Label
            variant={'small'}
            color={'primarySecondaryContent'}
            marginBottom={8}
          >
            Email
          </Label>
          <TextInputContainer>
            <TextInput
              disabled
              required
              type={'email'}
              id={'email'}
              name={'email'}
              value={email}
              maxLength={255}
            />
          </TextInputContainer>
        </FormItem>
      )}
      <FormItem>
        <Label
          variant={'small'}
          color={'primarySecondaryContent'}
          marginBottom={8}
        >
          New Password
        </Label>
        <TextInputContainer>
          <TextInput
            required
            id={'new-password'}
            name={'new-password'}
            type={!showNewPassword ? 'password' : 'text'}
            maxLength={255}
          />
          <InputIcon
            onClick={() => setShowNewPassword((s) => !s)}
            size={'xSmall'}
            className="button-icon"
            name={!showNewPassword ? 'pass_hide' : 'pass_show'}
          />
        </TextInputContainer>
      </FormItem>
      <FormItem>
        <Label
          variant={'small'}
          color={'primarySecondaryContent'}
          marginBottom={8}
        >
          Confirm Password
        </Label>
        <TextInputContainer>
          <TextInput
            required
            id={'confirm-password'}
            name={'confirm-password'}
            type={!showConfirmPassword ? 'password' : 'text'}
            maxLength={255}
          />
          <InputIcon
            onClick={() => setShowConfirmPassword((s) => !s)}
            size={'xSmall'}
            className="button-icon"
            name={!showConfirmPassword ? 'pass_hide' : 'pass_show'}
          />
        </TextInputContainer>
      </FormItem>
      {(error || apiError) && (
        <Label
          variant={'xSmall'}
          color={'red'}
          weight={undefined}
        >
          {error || apiError || 'Unable to set password.'}
        </Label>
      )}
      {successMessage && (
        <Label
          color={'utilitySuccess'}
          weight={undefined}
        >
          {successMessage}
        </Label>
      )}
      <div className={'mt-30 text-center'}>
        <FormSubmitContainer>
          <SolidButtonV2
            fluid
            disabled={loading || successMessage}
            className={AuthButton}
            type={'submit'}
          >
            {loading ? 'Setting Password' : 'Set Password'}
          </SolidButtonV2>
        </FormSubmitContainer>
      </div>
    </StyledForm>
  );
};

export default SetPasswordForm;
