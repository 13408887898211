import styled from 'styled-components';
import { assetUrl } from '../../utils/webConfig';
import { SolidButtonStyle } from '../../components/Donee/components/common/Button/SolidButton/styles';
import layout from '../../components/theme/layout';
import colors from '../../components/theme/colors';
import ContentLayout from '../../components/Donee/components/common/Layout/Content';

const { breakpoints } = layout;
const { desktop, tablet, laptop, mobile } = breakpoints;

export const SaveButton = {
  ...SolidButtonStyle,
  color: colors.white,
  background: colors.primaryGuidingViolet,
  width: '290px',
  height: '44px',
  '&:hover': {
    color: colors.white,
    background: colors.primaryGuidingViolet,
    opacity: 0.9
  },
  '&:disabled': {
    ...SolidButtonStyle,
    background: colors.grayscaleGray200,
    color: colors.white,
    width: '290px',
    height: '44px'
  }
};

export const FormProfile = styled.div``;

export const FormProfileHeaderContainer = styled.div`
  text-align: left;
`;

export const FormItem = styled.div`
  padding-bottom: 20px;
`;

export const SaveFormContainer = styled.div`
  text-align: center;
`;

export const ChangePassword = styled.div`
  text-align: left;
  color: #0678fe;
  padding-top: 20px;
  text-align: center;
`;

export const ProfileMessageSuccess = styled.div`
  text-align: left;
  color: #3c763d;
`;

export const ProfileSuccess = styled.div`
  background-color: #dff0d8;
  padding: 16px;
  color: #3c763d;
`;

export const ProfileMessageError = styled.div`
  text-align: left;
  color: #a9445a;
`;

export const ProfileError = styled.div`
  background-color: #f2dede;
  padding: 16px;
  color: #a9445a;
`;

export const SelectInput = styled.select`
  border: 1px solid #b1bac4;
  background: url(${assetUrl('/images/svg/select_arrow.svg')}) no-repeat scroll
    right 15px;
  background-color: #fff !important;
  color: ${colors.grayscaleBlack};
  display: inline-block;
  padding: 11px 15px;
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;

export const ForgotPasswordContainer = styled(ContentLayout)`
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
`;

export const SendLinkContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${mobile.max}px) {
    width: 100%;
  }
`;

export const AlertMsgSticy = styled.div`
  position: fixed;
  top: 64px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1040px;
  z-index: 1;
  @media (min-width: ${laptop.min}px) and (max-width: ${desktop.max}px) {
    max-width: 960px;
  }
  @media screen and (max-width: ${tablet.max}px) {
    top: 52px;
  }
`;

export const ImgAvatarMain = styled.div`
  display: inline-block;
  position: relative;
`;

export const ImgAvatar = styled.img`
  border-radius: 50%;
  height: 86px;
  width: 86px;
  overflow: hidden;
  display: inline-block;
  object-fit: cover;
`;

export const ImgAvatar2 = styled.img``;

export const ImgAvatarPopup = styled.div`
  border-radius: 50%;
  height: 120px;
  width: 120px;
  overflow: hidden;
  display: inline-block;
`;

export const UploadLoading = styled.div`
  position: relative;
  :after {
    background: url(${assetUrl('/images/history-loading.gif?v=1')}) no-repeat
      scroll center #fff;
    position: absolute;
    width: 64px;
    content: '';
    z-index: 1;
    top: 30px;
    height: 64px;
    left: 30px;
  }
`;

export const EditProfileLink = styled.a`
  background-color: #715aff;
  display: inline-block;
  height: 32px;
  width: 32px;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  border-radius: 50%;
  padding: 6px;
`;

export const EditIcon = styled.img``;
