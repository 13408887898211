export type Color =
  | 'darkblue'
  | 'skyblue'
  | 'green'
  | 'whiteshadow'
  | 'primary'
  | 'secondary'
  | 'default';

type Depth =
  | 'darkblue'
  | 'skyblue'
  | 'green'
  | 'whiteshadow'
  | 'low'
  | 'medium'
  | 'high'
  | 'none';

export default {
  depth: {
    none: '0px 0px 0px 0px',
    low: '0px 4px 8px 0px',
    medium: '0px 6px 12px 0px',
    high: '0px 10px 20px 0px',
    whiteshadow: '',
    green: '',
    skyblue: '',
    darkblue: ''
  },
  color: {
    default: 'rgba(0, 40, 121, 0.15)',
    primary: 'rgba(47, 176, 255, 0.30)',
    secondary: 'rgba(255, 155, 24, 0.40)',
    whiteshadow:
      '0px 7px 12px rgba(237, 242, 248, 0.496258), 0px 2px 4px rgba(132, 151, 178, 0.330966), 0px -1px 4px rgba(132, 151, 178, 0.1),' +
      'inset 0px 2px 3px rgba(255, 255, 255, 0.5), inset 0px -3px 4px rgba(195, 205, 220, 0.158463)',
    green:
      '0px 5px 8px rgba(58,76,99,0.07), 0px 3.5px 4px rgba(126,157,210,0.44), inset 0px 2px 9px rgba(130,255,180,0.68), inset 0px -2px 6px rgba(12,12,12,0.09)',
    skyblue:
      '0px 5px 8px rgba(58,76,99,0.07), 0px 3.5px 4px rgba(126,157,210,0.44), inset 0px 2px 3px rgba(255,255,255,0.2), inset 0px -3px 6px rgba(64,95,143,0.25)',
    darkblue:
      '0px 5px 8px rgba(58,76,99,0.07), 0px 3.5px 4px rgba(126,157,210,0.44), inset 0px 2px 3px rgba(255,255,255,0.2), inset 0px -3px 6px rgba(64,95,143,0.25)'
  },

  getColor(color: Color) {
    switch (color) {
      case 'darkblue':
        return this.color.darkblue;
      case 'skyblue':
        return this.color.skyblue;
      case 'green':
        return this.color.green;
      case 'whiteshadow':
        return this.color.whiteshadow;
      case 'primary':
        return this.color.primary;
      case 'secondary':
        return this.color.secondary;
      default:
        return this.color.default;
    }
  },
  getDepth(depth: Depth) {
    switch (depth) {
      case 'darkblue':
        return this.depth.darkblue;
      case 'skyblue':
        return this.depth.skyblue;
      case 'green':
        return this.depth.green;
      case 'whiteshadow':
        return this.depth.whiteshadow;
      case 'low':
        return this.depth.low;
      case 'medium':
        return this.depth.medium;
      case 'high':
        return this.depth.high;
      default:
        return this.depth.none;
    }
  },
  get(color: Color = 'default', depth: Depth = 'none') {
    return `${this.getDepth(depth)} ${this.getColor(color)}`;
  }
};

export const cardElevationHigh =
  '0px 1px 6px 0px #0a004d0a, 0px 5.5px 30px 0px #0a004d0d';
export const cardElevationMediumHigh =
  '0px 0.5px 3px 0px #0a004d0a, 0px 4px 15px 0px #0a004d0f';
export const cardElevationMedium =
  '0px 0.25px 2.75px 0px #0a004d0a, 0px 2.4000000953674316px 8px 0px #0a004d14';
export const cardElevationMediumLow =
  '0px 0.25px 1px 0px #0a004d0a, 0px 0.8500000238418579px 3px 0px #0a004d30';
export const cardElevationLow =
  '0px 0px 1px 0px #0a004d0a, 0px 0.5px 1.5px 0px #0a004d30';
