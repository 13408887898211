import React from 'react';
import styled from 'styled-components';
import colors from '../../../../../theme/colors';
import { PlusIcon } from '../../../../../icons';
import { Text } from '../../../common/Text/Text';

interface AddPaymentMethodButtonProps {
  color?: keyof typeof colors;
  buttonColor?: keyof typeof colors;
  handleAddPaymentOpen: () => unknown;
}

const StyledDiv = styled.div<{
  color: keyof typeof colors;
  buttonColor: keyof typeof colors;
}>`
  cursor: pointer;
  color: ${({ color }) => colors[color]};
  button {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    svg path {
      stroke: ${({ buttonColor }) => colors[buttonColor]};
    }
    .text {
      cursor: pointer;
      margin-top: 2px;
      margin-left: 10px;
    }
  }
`;

const AddPaymentMethodButton: React.FC<AddPaymentMethodButtonProps> = ({
  color = 'primarySecondaryContent',
  buttonColor,
  handleAddPaymentOpen
}) => {
  return (
    <StyledDiv
      color={color}
      buttonColor={buttonColor || color}
    >
      <button onClick={handleAddPaymentOpen}>
        <PlusIcon />{' '}
        <Text
          className={'text'}
          color={color}
        >
          Add Payment Method
        </Text>
      </button>
    </StyledDiv>
  );
};

export default AddPaymentMethodButton;
