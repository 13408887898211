import React from 'react';
import styled from 'styled-components';
import { TopNavBar, maxContainerWidth } from './TopNavBar';
import { PHONE_WIDTH, TABLET_WIDTH } from './Donee/utils';
import CheckoutWizard from './Donee/components/CheckoutWizard';
import { DoneeRoutes } from './Donee/components/CheckoutWizard/utils';
import DonationHistory from './Donee/components/DonationHistory';
import DoneeDialog from './Donee/components/DoneeDialog';
import DonorProfile from './Donee/components/DonorProfile';
import DonorWallet from './Donee/components/DonorWallet';
import EditProfile from './Donee/components/EditProfile';
import RecurringDonations from './Donee/components/RecurringDonations';
import Support from './Donee/components/Support';
import FooterComponent from './Footer';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${maxContainerWidth}px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 12px auto 0 auto;
  .donee-profile {
    width: 360px;
  }
  .checkout-grid {
    flex: 1 1 auto;
    margin-left: 24px;
  }
  @media screen and (max-width: 1000px) {
    .checkout-grid {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  @media only screen and (max-width: ${TABLET_WIDTH}) {
    .donee-profile {
      display: none;
    }
  }
  @media screen and (max-width: ${PHONE_WIDTH}) {
    padding-left: 3px;
    padding-right: 3px;
  }
`;

interface DoneeProps {
  route?: DoneeRoutes;
}

const Router: React.FC<DoneeProps> = ({ route }) => {
  switch (route) {
    case 'profile':
      return <DonorProfile className={'checkout-grid'} />;
    case 'edit-profile':
      return <EditProfile className={'checkout-grid'} />;
    case 'history':
      return <DonationHistory className={'checkout-grid'} />;
    case 'wallet':
      return <DonorWallet className={'checkout-grid'} />;
    case 'recurring':
      return <RecurringDonations className={'checkout-grid'} />;
    case 'support':
      return <Support className={'checkout-grid'} />;

    default:
      return <CheckoutWizard className={'checkout-grid'} />;
  }
};

const StyledFooter = styled.footer`
  .footer-main {
    padding-top: 0px;
    .footer-content.container {
      width: 100% !important;
      padding-left: 0;
      padding-right: 0;
      max-width: ${maxContainerWidth}px;
      .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
`;

const Donee: React.FC<DoneeProps> = ({ route }) => {
  return (
    <>
      <TopNavBar />
      <StyledDiv>
        <div className={'donee-profile'}>
          <DoneeDialog />
        </div>
        <Router route={route} />
      </StyledDiv>
      <StyledFooter>
        <FooterComponent />
      </StyledFooter>
    </>
  );
};

export default Donee;
