import React from 'react';
import { ROUTES } from '../utils/routes';
import { Route, Routes } from 'react-router-dom';
import Activate from '../pages/Activate';
import Donee from './RouteWrapper';
import RedirectToDonee from './RedirectToDonee';
import SomethingWentWrong from './SomethingWentWrong';
import SetPassword from '../pages/SetPassword';
import ActivateEmail from '../pages/ActivateEmail';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route
        caseSensitive
        path={ROUTES.activate}
        element={<Activate />}
      />
      <Route
        caseSensitive
        path={ROUTES.activateEmail}
        element={<ActivateEmail />}
      />
      <Route
        caseSensitive
        path={ROUTES.profile()}
        element={<Donee route={'profile'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.editProfile}
        element={<Donee route={'edit-profile'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.history}
        element={<Donee route={'history'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.wallet}
        element={<Donee route={'wallet'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.recurring}
        element={<Donee route={'recurring'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.support}
        element={<Donee route={'support'} />}
      />
      <Route
        caseSensitive
        path={ROUTES.main}
        element={<RedirectToDonee />}
      />
      <Route
        caseSensitive
        path={'/:doneeId'}
        element={<Donee />}
      />
      <Route
        path={`/:doneeId${ROUTES.setPassword}`}
        element={<SetPassword />}
      />
      <Route
        caseSensitive
        path={'/:doneeId/donation/amount'}
        element={<Donee />}
      />
      <Route
        caseSensitive
        path={'/:doneeId/selection'}
        element={<Donee />}
      />
      <Route
        path={'/:doneeId/donation/*'}
        element={<RedirectToDonee />}
      />
      <Route
        path={'*'}
        element={<SomethingWentWrong />}
      />
    </Routes>
  );
};

export default Router;
