import styled, { css } from 'styled-components';
import { textSizes, textColors, textWeights, lineHeights } from './constants';
import colors from '../../../../theme/colors';

export const noSelect = css`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export interface TextProps {
  color?: keyof typeof colors;
  size: keyof typeof textSizes;
  weight: keyof typeof textWeights;
  lineheight?: keyof typeof lineHeights;
  selectable?: boolean;
}

export const Text = styled.span<TextProps>`
  color: ${(props) => textColors[props.color || 'primaryPrimaryContent']};
  font-size: ${(props) => textSizes[props.size]}px;
  font-weight: ${(props) => textWeights[props.weight]};
  line-height: ${(props) =>
    props.lineheight ? props.lineheight : lineHeights[props.size]}px;
  ${(props) => (props.selectable ? '' : noSelect)};
`;

export interface LabelProps {
  onClick?: () => unknown;
  color?: TextProps['color'];
  variant?: 'xlarge' | 'large' | 'medium' | 'small' | 'xSmall' | 'xxSmall';
  weight?: TextProps['weight'];
  selectable?: boolean;
  marginBottom?: number;
}

const getFontSize = (variant: LabelProps['variant']): string => {
  switch (variant) {
    case 'xlarge':
      return '28px';
    case 'large':
      return '18px';
    case 'small':
      return '14px';
    case 'xSmall':
      return '12px';
    case 'xxSmall':
      return '10px';
    default:
    case 'medium':
      return '16px';
  }
};

const getLineHeight = (variant: LabelProps['variant']): string => {
  switch (variant) {
    case 'large':
      return '24px';
    default:
    case 'medium':
    case 'small':
    case 'xSmall':
    case 'xxSmall':
      return '20px';
  }
};

export const Label = styled.div<LabelProps>`
  color: ${(props) => textColors[props.color || 'primaryPrimaryContent']};
  font-size: ${(props) => getFontSize(props.variant)};
  line-height: ${(props) => getLineHeight(props.variant)};
  font-weight: ${(props) => textWeights[props.weight || 'regular']};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  ${(props) =>
    props.marginBottom ? `margin-bottom: ${props.marginBottom}px;` : undefined}
  ${(props) => (props.selectable ? '' : noSelect)};
`;
