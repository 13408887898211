import React from 'react';
import { Content, ContentContainer } from './styles';

const ContentLayout: React.FC<any> = ({ children, ...props }) => (
  <ContentContainer {...props}>
    <Content>{children}</Content>
  </ContentContainer>
);

export default ContentLayout;
