import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { axiosClient } from '../utils/axiosClient';
import { makeApiRequest } from '../utils/makeApiRequest';
import { RequestState, requestInit } from '../hooks/useRequestState';
import { useQueryString } from '../hooks/useQueryString';
import { webConfig } from '../utils/webConfig';
import { useGivingApiRequest } from '../hooks/useGivingApiRequest';
import { useClientToken } from './ClientTokenProvider';
import { HeartLoading } from '../components/HeartLoading';
import SomethingWentWrong from '../components/SomethingWentWrong';
import {
  getLastDoneeIdFromLocalStorage,
  pushDoneeIds
} from '../components/RedirectToDonee';
import { ROUTES, applicationRoutes } from '../utils/routes';

const veryOldRoute = '/selection';
const oldRoute = '/donation/amount';
const getRouteDoneeId = (pathname: string) => {
  if (applicationRoutes.includes(pathname)) return undefined;
  if (pathname.includes(oldRoute)) {
    pathname = pathname.split(oldRoute)[0];
  }
  if (pathname.includes(veryOldRoute)) {
    pathname = pathname.split(veryOldRoute)[0];
  }
  const split = pathname.split('/');
  if (split.length !== 2) return;
  return split[1];
};

const getDoneeId = (doneeIdStr: string | undefined) => {
  let doneeId: string | undefined;

  if (doneeIdStr) {
    const doneeIdArr = doneeIdStr.split(webConfig.doneeIdHashPrefix);
    // allowed pattern: <name>-<city>-<state>-base64(<donee_id>
    if (doneeIdArr.length === 2) {
      // Corrected for lint rule prefer-destructuring
      [, doneeId] = doneeIdArr;
      sessionStorage.setItem('doneeId', JSON.stringify(doneeId));
    } else {
      doneeId = doneeIdStr;
    }
  }

  return doneeId;
};

interface Envelope {
  description: string;
  envelopeId: number;
  name: string;
  priority: number; // order of the envelopes
}

interface MemoTemplate {
  description: string; // "In memory of "
  memoId: number;
}

// Just to tell the dev that the property is string URL
export type URLString = string;

interface Organization {
  doneeId: string; //"1071226606339934"
  type: 'church' | 'nonprofit';
  name: string;
  doneeDescription: string | null;
  address: string; // "1314 Oak Ridge Dr Neosho MO 64850"
  streetAddress: string; // "1314 Oak Ridge Dr"
  phone: string; // "(417) 451-1958"
  city: string;
  state: string;
  zip: string; // "64850"
  latitude: number; // 36.85649
  longitude: number; //-94.37638
  logo: URLString | null;
  banner: URLString;
  isRegistered: boolean;
  promptMemberId: boolean;
  isTaxDeductible: boolean;
  isPaymentVerified: boolean;
  isVerified: boolean;
  shareUrl: string;
  official: {
    name: string;
    title: string;
    picture: URLString | null;
  } | null;
}

export interface Donee {
  idShort?: string;
  envelopes: Envelope[];
  memoTemplates: MemoTemplate[];
  offeringAmounts: number[];
  organization: Organization;
}

const DONEE_URL = '/home/donee/';
const getDoneeUrl = (id: string) => DONEE_URL + id;
const loadDoneeFromApi = (id: string, clientAccessToken: string) => {
  const httpRequest = axiosClient.get(getDoneeUrl(id), {
    headers: {
      clientAccessToken: clientAccessToken
        ? `Bearer ${clientAccessToken}`
        : undefined
    }
  });
  return makeApiRequest<Donee>(httpRequest);
};

export const DoneeContext = createContext<{
  donee: RequestState<Donee>;
  doneeId: string | undefined;
  loadDonee: (id?: string) => unknown;
}>({
  doneeId: undefined,
  donee: requestInit(),
  loadDonee: () => null
});

export const useDonee = () => useContext(DoneeContext);

export type ReactChildren = {
  children?: React.ReactNode;
};

const pathExceptions = [
  ROUTES.activate,
  ROUTES.setPassword,
  ROUTES.activateEmail
];
const isPathException = (path: string): boolean => {
  for (const p of pathExceptions) {
    if (path.endsWith(p)) return true;
  }
  return false;
};

const DoneeProvider: React.FC<ReactChildren> = ({ children }) => {
  const location = useLocation();
  const { accessToken } = useClientToken();
  const doneeIdRef = React.useRef<string>();
  const [donee, makeRequest] = useGivingApiRequest<Donee>();
  const query = useQueryString();
  const doneeId = getRouteDoneeId(location.pathname);
  const id = useMemo<string | undefined>(
    () => doneeId || query.get('doneeId') || query.get('id') || undefined,
    [doneeId, query]
  );

  const loadDonee = useCallback(
    (doneeId?: string) => {
      const loadId = getDoneeId(doneeId || id);
      if (loadId && donee.type === 'REQUEST_INIT') {
        doneeIdRef.current = loadId;
        makeRequest(loadDoneeFromApi(loadId, accessToken));
      }
    },
    [id, donee.type, accessToken, makeRequest]
  );

  useEffect(() => {
    if (isPathException(window.location.pathname)) {
      return;
    }
    const doneeIdLocal = getLastDoneeIdFromLocalStorage();
    const loadId = !id && doneeIdLocal ? doneeIdLocal : undefined;
    loadDonee(loadId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPathException(window.location.pathname)) {
    return (
      <DoneeContext.Provider
        value={{ donee, doneeId: doneeIdRef.current, loadDonee }}
      >
        {children}
      </DoneeContext.Provider>
    );
  }

  switch (donee.type) {
    case 'REQUEST_SUCCESS':
      pushDoneeIds(id);
      window.doneeIdShort = doneeIdRef.current;
      window.doneeIdHashed = donee.data.organization.doneeId;
      return (
        <DoneeContext.Provider
          value={{ donee, doneeId: doneeIdRef.current, loadDonee }}
        >
          {children}
        </DoneeContext.Provider>
      );

    case 'REQUEST_ERROR':
      return <SomethingWentWrong />;

    case 'REQUEST_INIT':
    case 'REQUEST_START':
    default:
      return (
        <HeartLoading
          maxWidth={500}
          className="heart-loading"
        />
      );
  }
};

export default React.memo(DoneeProvider);
