import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DonationAmountAnimation from './DonationAmountAnimation';
import { CheckoutWizardProps } from '../CheckoutWizardInterfaces';
import { webConfig } from '../../../../../utils/webConfig';
import {
  Donor,
  DonorContextProps
} from '../../../../../providers/DonorProvider/interfaces';
import { useQueryString } from '../../../../../hooks/useQueryString';
import { Text } from '../../common/Text/Text';
import { useApiRequest } from '../../../../../hooks/useGivingApiRequest';
import { GivingApiResponse } from '../../../../../utils/makeApiRequest';
import { ReceiptResponse, getReceiptRequest } from '../CartWizard/Payment/api';
import ReceiptCard from './ReceiptCard';
import ThankYouBanner from '../../../../DownloadBanner/ThankYouBanner';
import ReceiptModal from './ReceiptModal';
import { usePushEvents } from '../../../../../hooks/PushEvents';
import {
  appDownloadEvent,
  thankyouScreenShown
} from '../../../../../hooks/PushEvents/clevertapEvents';
import { GivelifyButton } from '../../common/Button/GivelifyButton';
import { isAppleDevice } from '../../common/utils';
import { isMobile } from '../../../../GetAppModal';
import { useDeepLink } from '../../../../../hooks/useDeepLink';
import DeepLinkCopiedModal from './DeepLinkCopiedModal';

const eventPayload = {
  location: 'receiptthankyou',
  action: 'clicked',
  name: 'thank screen bottom sheet only shown on mobile'
};

const deepLinkTitle = (
  <>
    We’ve <span className="deep-link-copied-text">copied</span> a link
    <br />
    to your full receipt
  </>
);
const appStoreLink =
  'https://apps.apple.com/app/apple-store/id725815127?pt=8388804&ct=oga_appdownload_receiptthankyou&mt=8';
const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.pushcontrolz.givelify&referrer=utm_source%3Doga%26utm_medium%3Dweb%26utm_campaign%3Doga_appdownload_receiptthankyou';

const handleDownloadButton = () => {
  // copyDoneeIdToClipboard();
  window.location.href = isAppleDevice() ? appStoreLink : playStoreLink;
};

const FACEBOOK_LINK = webConfig.followFacebookURL;
const TWITTER_LINK = webConfig.followTwitterURL;
const LINKEDIN_LINK = webConfig.followLinkedinURL;
const MAIL_LINK = webConfig.supportContactUrl;

type ThankYouWizardProps = CheckoutWizardProps & {
  donorContext: DonorContextProps;
};

const StyledDiv = styled.div`
  text-align: center;
  padding: 20px;
  max-width: 552px;
  margin-left: auto;
  margin-right: auto;
  .thank-you-text {
    margin-bottom: 12px;
  }
  .donee-and-receipt {
    margin-top: 16px;
    filter: drop-shadow(0px 1px 2px #ddd);
  }
  .app-download-button {
    margin-top: 40px;
  }
  .thank-you-footer {
    margin-top: 41px;
    .thank-you-footer-heading {
      margin-bottom: 24px;
    }
    .social-media-icons {
      display: grid;
      gap: 24px;
      justify-content: center;
      grid-template-columns: 52px 52px 52px 52px;
    }
  }
`;

const pixelId = '0:n0mtnal';
const style = { borderStyle: 'none' };
const pixelUrl = `https://insight.adsrvr.org/track/pxl/?adv=wdfbd5e&ct=${pixelId}&fmt=3`;

export const getPixelUrl = (
  doneeId: string | undefined | null,
  donor: Donor | undefined,
  amount: number | undefined
) => {
  if (!amount || !doneeId || !webConfig.isProduction) {
    return undefined;
  }
  return `${pixelUrl}&v=${amount}&td1=${donor?.uuid}&td2=${doneeId}`;
};

const ThankYouWizard: React.FC<ThankYouWizardProps> = ({
  cart,
  donorContext,
  donee
}) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { deepLinkRequestState, getDeepLink, showCopiedModal } = useDeepLink(
    eventPayload,
    handleDownloadButton
  );
  const donor = donorContext.donor?.data || undefined;
  const { pushEvent } = usePushEvents();
  const [requestState, makeRequest] =
    useApiRequest<GivingApiResponse<ReceiptResponse>>();
  const query = useQueryString();
  const totalAmount = cart.receipt?.totalAmount;

  useEffect(() => {
    pushEvent(thankyouScreenShown);
    if (isMobile) {
      pushEvent(appDownloadEvent, {
        location: 'receiptthankyou',
        action: 'shown',
        name: 'thank screen bottom sheet only shown on mobile'
      });
    }
  }, [pushEvent]);

  useEffect(() => {
    if (!cart.receipt) {
      return;
    }
    makeRequest(getReceiptRequest(cart.receipt.receiptId));
  }, [makeRequest, cart.receipt]);

  const receipt = requestState.data?.response.payload.gift;
  const handleDownload = useCallback(() => {
    pushEvent(appDownloadEvent, eventPayload);
    if (receipt) {
      getDeepLink(donee, donorContext?.donor?.data, '/dl/receipt', {
        key: 'receipt',
        data: receipt
      }).then((s) => {
        s && setUrl(s);
      });
    } else {
      handleDownloadButton();
    }
  }, [pushEvent, receipt, donee, donorContext]);

  const pixelImage = React.useMemo(
    () => (
      <img
        height={'1'}
        width={'1'}
        style={style}
        alt={''}
        src={getPixelUrl(query.get('doneeId'), donor?.donor, totalAmount)}
      />
    ),
    [totalAmount]
  );

  const isReceiptBlur = donorContext.totalGifts < 3;
  return (
    <>
      <StyledDiv>
        <Text
          variant={'medium'}
          className={'thank-you-text'}
        >
          Thank you{donor && donor.donor.name ? `, ${donor.donor.name}` : ''}!
        </Text>
        <Text color={'primarySecondaryContent'}>
          We appreciate your kind and generous gift.
        </Text>
        <DonationAmountAnimation amount={totalAmount} />
        <ReceiptCard
          donee={donee}
          isReceiptBlur={isReceiptBlur}
          donorContext={donorContext}
          requestState={requestState}
          className={'donee-and-receipt'}
        />
        {isMobile && (
          <GivelifyButton
            color="primary"
            isLoading={
              requestState.type === 'REQUEST_INIT' ||
              requestState.type === 'REQUEST_START' ||
              deepLinkRequestState.type === 'REQUEST_START' ||
              deepLinkRequestState.type === 'REQUEST_SUCCESS'
            }
            onClick={handleDownload}
            className="app-download-button"
          >
            Get the Givelify App
          </GivelifyButton>
        )}
        <DeepLinkCopiedModal
          url={url}
          open={showCopiedModal}
          title={deepLinkTitle}
          onClick={handleDownloadButton}
        />
        {/* <div className={'thank-you-footer'}>
          <Text
            variant={'medium'}
            weight={'extraBold'}
            className={'thank-you-footer-heading'}
          >
            Enjoying the Givelify App?
          </Text>
          <div className={'social-media-icons'}>
            <a
              href={FACEBOOK_LINK}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <IconButton
                className={'social-icon'}
                name={'facebook-icon'}
                size={'large'}
                color={'primaryGuidingViolet'}
                iconProps={undefined}
                raised={undefined}
              />
            </a>
            <a
              href={TWITTER_LINK}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <IconButton
                className={'social-icon'}
                name={'twitter-icon'}
                size={'large'}
                color={'primaryGuidingViolet'}
                iconProps={undefined}
                raised={undefined}
              />
            </a>
            <a
              href={LINKEDIN_LINK}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <IconButton
                className={'social-icon'}
                name={'linkedin-icon'}
                size={'large'}
                color={'primaryGuidingViolet'}
                iconProps={undefined}
                raised={undefined}
              />
            </a>
            <a
              href={MAIL_LINK}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <IconButton
                className={'social-icon'}
                name={'mail-icon'}
                size={'large'}
                color={'primaryGuidingViolet'}
                iconProps={undefined}
                raised={undefined}
              />
            </a>
          </div>
        </div> */}
      </StyledDiv>
      {pixelImage}
      <ThankYouBanner />
      <ReceiptModal
        donee={donee}
        isReceiptBlur={isReceiptBlur}
        donorContext={donorContext}
        requestState={requestState}
      />
    </>
  );
};

export default ThankYouWizard;
