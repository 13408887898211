import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import CardDetailsForm from './CardDetailsForm';
import { PaymentRoutes } from './CreditCardForm';
import { useDonorContext } from '../../../../../../providers/DonorProvider/DonorProvider';
import { Wallet } from '../../../../../../providers/DonorProvider/interfaces';
import { User } from '../../../../../LoginModal/routes';
import GivelifyModal from '../../../common/Modal/GivelifyModal';
import { SignupModalContentHeight } from '../../../../../LoginModal/MainWrapper';

interface AddPaymentModalProps {
  addPaymentOpen: boolean;
  addCard: (card: Wallet) => unknown;
  handleAddPaymentClose: () => unknown;
}

const StyledDiv = styled.div`
  height: 100%;
`;

const AddPaymentModal: React.FC<AddPaymentModalProps> = ({
  addPaymentOpen,
  addCard,
  handleAddPaymentClose
}) => {
  const {
    donorContext: {
      donor: { data }
    }
  } = useDonorContext();
  const [route, setRoute] = useState<PaymentRoutes>('CREDIT');

  const handleBack = useCallback(() => {
    switch (route) {
      case 'ADDRESS':
        setRoute('CREDIT');
        return;
      case 'ADDRESS_COMPLETE':
        setRoute('ADDRESS');
        return;
      default:
        break;
    }
  }, [route, setRoute]);

  const [user, setUser] = useState<User>({
    address: {
      street: data?.donor.address.street || '',
      city: data?.donor.address.city || '',
      state: data?.donor.address.state || '',
      zip: data?.donor.address.zip || '',
      countryCode: data?.donor.address.country.isoAlpha2 || 'US'
    }
  });

  return (
    <GivelifyModal
      open={addPaymentOpen}
      onClose={handleAddPaymentClose}
      onBack={route !== 'CREDIT' ? handleBack : undefined}
      contentStyles={`height: ${SignupModalContentHeight}; padding-bottom: 0;`}
    >
      <StyledDiv>
        <CardDetailsForm
          user={user}
          setUser={setUser}
          route={route}
          setRoute={setRoute}
          addCard={addCard}
          handlePaymentModalClose={handleAddPaymentClose}
        />
      </StyledDiv>
    </GivelifyModal>
  );
};

export default AddPaymentModal;
