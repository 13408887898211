import { logError } from './logError';
import { somethingWrongMsg } from './messages';

export default class ApiError extends Error {
  constructor(code: number, message: string) {
    super(message || somethingWrongMsg);
    this.name = 'Api Error';
    if (code === 500 || code === 401) {
      logError('API Request Error: 500 or 401', this);
      this.message = somethingWrongMsg;
    }
  }
}
