import React from 'react';
import ImageLoader from '../ImageLoader';
import { getDefaultBannerImageUrl } from '../../utils';
import { Text } from '../common/Text/Text';
import { AddressIcon, PhoneIcon, VerifiedIcon } from '../../../icons';

interface DoneeProps {
  doneeType: 'church' | 'nonprofit';
  coverUrl: string | undefined;
  profileUrl: string | undefined | null;
  doneeName: string;
  pastorName: string;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
  };
  phone: string;
  isVerified?: boolean;
  isTaxableOrg?: boolean;
}

export type DoneeDialogCardProps = DoneeProps;

export const DoneeDialogCard: React.FC<DoneeDialogCardProps> = ({
  doneeType,
  coverUrl,
  profileUrl,
  doneeName,
  pastorName,
  address,
  phone,
  isVerified = false,
  isTaxableOrg = false
}) => {
  return (
    <>
      <ImageLoader
        url={coverUrl}
        defaultUrl={getDefaultBannerImageUrl(doneeType, isVerified)}
        className={'cover-photo'}
      />
      {profileUrl && (
        <ImageLoader
          url={profileUrl}
          defaultUrl={undefined}
          className={'profile-photo'}
        />
      )}
      <div className={'content'}>
        <div className={'donee-info'}>
          <div className={'donee'}>
            <Text
              variant={'xlarge'}
              weight={'bold'}
              className={'donee-name'}
            >
              {doneeName}
            </Text>
            {isVerified && (
              <div className={'verified'}>
                <VerifiedIcon />
              </div>
            )}
          </div>
          {pastorName && (
            <Text
              className={'pastor'}
              variant={'large'}
              weight={'extraBold'}
              color={'primarySecondaryContent'}
            >
              {pastorName}
            </Text>
          )}
          <Text
            className={'address'}
            weight={'bold'}
            color={'primarySecondaryContent'}
          >
            <AddressIcon className={'address-icon'} />
            <div>
              {address.street}
              <br />
              {address.city}, {address.state} {address.zip}
            </div>
          </Text>
          <Text
            className={'phone'}
            weight={'bold'}
            color={'primarySecondaryContent'}
          >
            <PhoneIcon className={'phone-icon'} />
            <div>{phone}</div>
          </Text>
        </div>
        {isTaxableOrg && (
          <div className={'taxable'}>
            <Text color={'primarySecondaryContent'}>
              Tax deductible organization
            </Text>
          </div>
        )}
      </div>
    </>
  );
};
