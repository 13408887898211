import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import axiosRetry from 'axios-retry';
import { getAccessTokenFromLocalStorage } from '../../providers/DonorProvider/donorLocalStorage';
import { webConfig } from '../webConfig';

declare module 'axios' {
  export interface AxiosRequestConfig {
    metadata?: any;
  }
  export interface AxiosResponse {
    duration?: any;
  }
}

const startTimeRequestInterceptor = (req: InternalAxiosRequestConfig) => {
  req.metadata = req.metadata || {};
  req.metadata.start = new Date();
  return req;
};

const setEndTimeAndDuration = (res: AxiosResponse<any>) => {
  if (res.config.metadata?.start) {
    res.config.metadata.end = new Date();

    const milliseconds =
      res.config.metadata.end.getTime() - res.config.metadata.start.getTime();

    res.duration = milliseconds;
  }

  return res;
};

const endTimeSuccessRequestInterceptor = (res: AxiosResponse<any>) => {
  setEndTimeAndDuration(res);
  return res;
};

const endTimeFailedRequestInterceptor = (err: any) => {
  setEndTimeAndDuration(err);
  return Promise.reject(err);
};

export const getAxiosClient = (): AxiosInstance => {
  const apiUrl = webConfig.baseLoggerAPIUrl;
  const axiosLoggerClient = applyCaseMiddleware(
    axios.create({
      baseURL: new URL('api', apiUrl).toString(),
      withCredentials: true
    }),
    {
      preservedKeys: ['_method']
    }
  );

  axiosRetry(axiosLoggerClient);

  axiosLoggerClient.interceptors.request.use(startTimeRequestInterceptor);
  axiosLoggerClient.interceptors.response.use(
    endTimeSuccessRequestInterceptor,
    endTimeFailedRequestInterceptor
  );

  axiosLoggerClient.interceptors.request.use((req) => {
    if (!req.headers.authorization) {
      const token = window.accessToken || getAccessTokenFromLocalStorage();

      req.headers.authorization = token?.includes('Bearer')
        ? token
        : `Bearer ${token}`;
    }
    return req;
  });

  return axiosLoggerClient;
};
