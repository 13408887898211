import styled from 'styled-components';
import ContentLayout from '../../../components/Donee/components/common/Layout/Content';
import SolidButton from '../../../components/Donee/components/common/Button/SolidButton';

export const CancelRecurringContainer = styled(ContentLayout)`
  display: block;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`;

export const RecurringAmount = styled.div`
  margin: 10px;
  padding-top: 32px;
`;

export const RecurringFrequency = styled.div`
  margin: 10px;
`;

export const PaymentInfo = styled.div`
  display: inline-flex;
  margin-bottom: 32px;
`;

export const SkipNextDonation = styled.div`
  text-align: left;
  padding: 16px 0;
  color: #0075f7;
`;

export const ChangePaymentMethod = styled.div`
  text-align: left;
  padding: 16px 0;
  color: #0075f7;
`;

export const CancelRecurring = styled.div`
  text-align: left;
  padding: 16px 0;
  color: #ff3b5b;
`;

export const ModalSection = styled.div`
  text-align: center;
`;

export const ModalHeader = styled.div`
  border-bottom: 1px solid #ababab;
  font-size: 20px;
  padding: 16px;
`;

export const ModalBody = styled.div``;

export const ModalFooter = styled.div`
  padding-top: 16px;
`;

export const CancelButton = styled(SolidButton).attrs({
  // fluid: true,
  color: 'transparent',
  size: 'medium',
  width: 'link',
  raised: 'transparent',
  weight: 'regular'
})`
  margin-top: 15px;
`;
