import { useCallback, useEffect, useRef } from 'react';
import { useGivingApiRequest } from '../../hooks/useGivingApiRequest';
import { FacebookLoginRequestBody, facebookLoginRequest, login } from './api';
import { LoginResponse } from './interfaces';
import { setLoginToken } from './donorLocalStorage';
import { usePushEvents } from '../../hooks/PushEvents';
import {
  errorOnSignIn,
  signInSuccessful
} from '../../hooks/PushEvents/clevertapEvents';
import { logInfo } from '../../utils/logError';
import { useDonorContext } from './DonorProvider';
import { removeQueryString } from '../../hooks/useQueryString';

export const useDonorLogin = () => {
  const { setDonorContext, loadDonor } = useDonorContext();
  const donorEmail = useRef<string | undefined>(undefined);
  const { pushEvent } = usePushEvents();
  const [loginState, makeLoginRequest] = useGivingApiRequest<LoginResponse>();

  useEffect(() => {
    if (loginState.type === 'REQUEST_ERROR') {
      pushEvent(errorOnSignIn, { donorEmail: donorEmail.current });
    }
    if (loginState.type !== 'REQUEST_SUCCESS') return;
    setDonorContext((s) => ({
      ...s,
      login: loginState
    }));
    setLoginToken(loginState.data);
    loadDonor(true);
    removeQueryString('login');
    pushEvent(signInSuccessful, {
      donorId: loginState.data.donorId,
      donorEmail: donorEmail.current
    });
    logInfo(`${donorEmail.current || 'Unknown User'} logged in successfully.`, {
      id: donorEmail.current,
      donorEmail: donorEmail.current,
      ogaVersions: '4.0'
    });
  }, [loginState]);

  const setProfilePicture = useCallback(
    (pic?: string) => {
      if (!pic) return;
      setDonorContext((s) => {
        if (s.donor.type !== 'REQUEST_SUCCESS') return s;
        const newState = { ...s };
        if (!newState.donor.data) return s;
        newState.donor.data.donor.pictureUrl = pic;
        return newState;
      });
    },
    [setDonorContext]
  );

  return {
    state: loginState,
    setProfilePicture,
    login: (username: string, password: string) => {
      donorEmail.current = username;
      makeLoginRequest(login(username, password));
    },
    facebookLogin: (body: FacebookLoginRequestBody) => {
      donorEmail.current = body.emailAddress;
      makeLoginRequest(facebookLoginRequest(body));
    }
  };
};
