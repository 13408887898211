import React from 'react';
import styled from 'styled-components';
import colors from '../../../theme/colors';

const StyledDiv = styled.div`
  margin-bottom: 40px;
  text-align: center;
  .amount-text {
    font-size: 18px;
    font-weight: 800;
    font-weight: 18px;
    color: ${colors.primarySecondaryContent};
  }
  .amount {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 16px;
    .dollar-sign {
      font-size: 27px;
      color: ${colors.primarySecondaryContent};
    }
    h1 {
      font-size: 56px;
      font-weight: 700;
      margin: 0;
      line-height: 48px;
    }
  }
`;

interface AmountDisplayProps {
  amount: number | undefined;
  amountText: string;
  className?: string;
}

const AmountDisplay: React.FC<AmountDisplayProps> = ({
  amount,
  amountText,
  className
}) => {
  return (
    <StyledDiv className={className}>
      <div className={'amount-text'}>{amountText}</div>
      <div className={'amount'}>
        <span className={'dollar-sign'}>$</span>
        <h1>{(amount || 0).toFixed(2)}</h1>
      </div>
    </StyledDiv>
  );
};

export default AmountDisplay;
